import request from 'tools/request'
import { DocumentListResponse, DocumentResponse, UpdateDocumentRequest, UploadDocumentParams } from 'types/document'

export const uploadDocument = async (file: File, params?: UploadDocumentParams) => {
  const formData = new FormData()
  formData.append('file', file)
  const data = await request
    .post<DocumentResponse>('documents', formData, {
      params: params,
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const getDocumentByCompanyId = async (companyId: string, active: string | null) => {
  const data = await request
    .get<DocumentListResponse>(`documents`, {
      params: {
        companyId: companyId,
        active: active,
      },
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const getDocumentMetadata = async (documentId: string) => {
  const data = await request.get<DocumentResponse>(`documents/metadata/${documentId}`).then((res) => {
    return res.data
  })
  return data
}

export const updateDocument = async (documentId: string, payload: UpdateDocumentRequest) => {
  const data = await request.put<DocumentResponse>(`documents/${documentId}`, payload).then((res) => {
    return res.data
  })
  return data
}

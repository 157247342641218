import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import styles from './ScrollSpy.module.scss'
import useScrollSpy from 'hooks/useScrollSpy'
import Icon from './Icon'

type SectionData = {
  id: string
  icon?: string
  label: string
  content: JSX.Element
}

type ScrollSpyButtonProps = {
  id: string
  icon?: string
  label: string
  selected?: boolean
  onClick: (id: string) => void
}

type ScrollSpyProps = {
  data: SectionData[]
}

const ScrollSpyButton = (props: ScrollSpyButtonProps) => {
  const { id, icon, label, onClick, selected = false } = props

  const clickHandler = () => {
    onClick(id)
  }

  return (
    <ListItemButton onClick={clickHandler} selected={selected}>
      {icon && (
        <ListItemIcon>
          <Icon name={icon} />
        </ListItemIcon>
      )}
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

const ScrollSpy = (props: ScrollSpyProps) => {
  const { activeId, scrollRef, onClick, onScroll } = useScrollSpy({
    defaultId: props.data[0]?.id,
  })

  return (
    <div className={`fc-scrollspy ${styles.scrollSpyContainer}`}>
      <div className={styles.scrollSpySidebar}>
        <List>
          {props.data.map((s) => (
            <div id={s.id} key={s.id}>
              <ScrollSpyButton
                id={s.id}
                icon={s?.icon}
                label={s.label}
                onClick={onClick}
                selected={activeId === s.id}
              />
            </div>
          ))}
        </List>
      </div>
      <div className={`${styles.scrollSpyContent} factorCloudScrollbar`} ref={scrollRef} onScroll={onScroll}>
        {props.data.map((s) => (
          <div key={s.id} id={s.id} className="fc-scrollspy-section">
            {s.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScrollSpy

import { uploadDocument } from 'api/documents'
import { DocumentInfo, UploadDocumentClientParams } from 'types/document'
import { addDocumentToInvoice } from 'api/invoices'
import { errorMsg } from 'App'
import { updatePayment } from 'api/payments'
import { EntityWithDocuments } from 'components/form/DocumentUpload'
import { updateFuelAdvance } from 'api/fuelAdvance'
import { addDocumentsOutOfSystemInvoice } from 'api/outOfSystemInvoice'

export async function uploadFiles(
  files: File[],
  entity: EntityWithDocuments,
  additionalParams?: UploadDocumentClientParams,
  handleFileStatus?: (file: File, status: boolean) => void
) {
  try {
    const uploadedDocuments: DocumentInfo[] = []
    for (const f of files) {
      const res = await uploadDocument(f, {
        companyId: entity.companyId,
        type: entity.type ? entity.type.toUpperCase() : additionalParams?.documentType || null,
        otherType: additionalParams?.otherDocumentType || null,
        expirationDate: additionalParams?.expirationDate || null,
      })
      handleFileStatus && handleFileStatus(f, true)
      uploadedDocuments.push(res.document)
    }

    const documents = (entity.documents ?? []).map((d) => d.id)
    for (const ud of uploadedDocuments) {
      if (documents.indexOf(ud.id) === -1) {
        documents.push(ud.id)
      }
    }

    if (entity.type === 'invoice' && documents.length > 0) {
      await addDocumentToInvoice(entity.id, documents)
    }

    if (entity.type === 'payment') await updatePayment(entity.id, { documents })

    if (entity.type === 'fuel_advance') await updateFuelAdvance(entity.id, { documents })

    if (entity.type === 'out_of_system_invoice') await addDocumentsOutOfSystemInvoice(entity.id, documents)

    return true
  } catch (error) {
    return errorMsg(error)
  }
}

import MaterialButton from '@mui/material/Button'

import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Tooltip from 'components/ui/dataDisplay/Tooltip'
import Icon from '../ui/dataDisplay/Icon'
import colors from 'theme/colors'
import { useMemo } from 'react'

export type ButtonProps = {
  /** The variant specifies the style of the button according to material ui, it takes one of three possible string values('text','contained','outlined'). */
  variant?: 'text' | 'contained' | 'outlined'
  /** The disable is a boolean value indicating whether the button is disabled or not. */
  disabled?: boolean
  /** The size variable can take one of three possible values ('small', 'medium' or 'large') that specify the size of the button according to Material UI. */
  size?: 'small' | 'medium' | 'large'
  /** The onClik is a function that is called when the button is clicked*/
  onClick?: (e?: any) => void
  /** The startIcon is a string that specifies the name of an Icon to be displayed at the beginning of the button text, if the 
  startIcon is undefined, the component will not render any icon at the start of the button text.*/
  startIcon?: string
  /** The endIcon is a string that specifies the name of an Icon to be displayed at the end of the button text, if the 
  endIcon is undefined, the component will not render any icon at the end of the button text.*/
  endIcon?: string
  /** The label is a required string that specifies the text to be displayed on the button*/
  label: string
  /** The fullWidth is a boolean indicating whether the button should take up the full width of its parent container.*/
  fullWidth?: boolean
  /** The className is a string containing additional CSS classes to apply to the button*/
  className?: string
  /** The loading is a boolean indicating whether a loading spinner should be displayed in place of the button text while it is loading.*/
  loading?: boolean
  /** The pill is a boolean indicating whether the button should have a pill shape or the default Material UI shape.*/
  pill?: boolean
  /** The type is used to indicate the type of button, which could be: 'button' for a normal button, 'reset' for a form clear button or 
  'submit' for a form submit button.*/
  type?: 'button' | 'submit' | 'reset' | undefined
  /** The startIconColor is a string consisting of a hexadecimal color code or a named color (e.g. 'red') that applies the specified color to the start icon.*/
  startIconColor?: string
  /** The endIconColor is a string consisting of a hexadecimal color code or a named color (e.g. 'blue') that applies the specified color to the end icon.*/
  endIconColor?: string
  /** The tooltip is a string that displays a tooltip when the user hovers over the button.*/
  tooltip?: string
  /** The textTransform is a string with two possible values ('none' or 'uppercase') that specifies whether the button text should appear in uppercase or not.*/
  textTransform?: 'none' | 'uppercase'
  /** The tooltipClassName is a string containing additional CSS classes to apply to the tooltip container.*/
  tooltipClassName?: string
  /** The color variable determines the color of the button, where only the colors determined in the attribute type can be used.*/
  color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'neutral'
  /** The tooltipBackGroundColor is a string consisting of a hexadecimal color code or a named color that applies the specified color to the tooltip background.*/
  tooltipBackGroundColor?: string
  /** The tooltipTextColor is a string consisting of a hexadecimal color code or a named color that applies the specified color to the tooltip text.*/
  tooltipTextColor?: string
  /** The tooltipArrowColor is a string consisting of a hexadecimal color code or a named color that applies the specified color to the tooltip arrow.*/
  tooltipArrowColor?: string
  /** The tooltipMaxWidth is a number that specifies the maximum width of the tooltip.*/
  tooltipMaxWidth?: number
  /** The  is a string that specifies the id of the button.*/
  id?: string
  /** The sx is used to pass in any custom styles to the container of the button.*/
  sx?: object
}

const Button = (props: ButtonProps) => {
  const {
    type,
    sx = {},
    variant = 'contained',
    disabled = false,
    size = 'medium',
    startIcon,
    endIcon,
    onClick = () => {},
    label,
    fullWidth = false,
    className,
    loading = false,
    pill = true,
    startIconColor,
    endIconColor,
    color,
    tooltip,
    textTransform = 'none',
    tooltipClassName,
    tooltipBackGroundColor,
    tooltipTextColor,
    tooltipArrowColor,
    tooltipMaxWidth,
    id,
  } = props

  const MuiButton = useMemo(
    () =>
      styled(MaterialButton)(({ theme }) => ({
        borderRadius: pill ? 8 : theme.shape.borderRadius,
        textTransform: textTransform,
        fontWeight: 700,
      })),
    [pill, textTransform]
  )

  if (!label) {
    return <></>
  }

  return (
    <Tooltip
      title={tooltip ?? ''}
      sx={{ width: '10rem' }}
      containerClassName={tooltipClassName}
      backgroundColor={tooltipBackGroundColor}
      textColor={tooltipTextColor}
      arrowColor={tooltipArrowColor}
      maxWidth={tooltipMaxWidth}
    >
      <MuiButton
        id={id}
        sx={sx}
        type={type}
        variant={variant}
        color={color}
        size={size}
        disabled={disabled}
        onClick={(e) => !loading && onClick(e)}
        fullWidth={fullWidth}
        className={className}
        startIcon={startIcon && <Icon name={startIcon} color={startIconColor} />}
        endIcon={endIcon && <Icon name={endIcon} color={endIconColor} />}
      >
        {loading && (
          <CircularProgress
            size={20}
            sx={{
              color: variant === 'contained' ? colors.white : colors.primary,
              marginRight: '15px',
            }}
          />
        )}
        {label}
      </MuiButton>
    </Tooltip>
  )
}

export default Button

import { CreditInfoRequestType } from './creditRequest'

export type CompanyListResponse = {
  companies: CompanyInfo[]
}

export type DebtorListResponse = {
  debtors: DebtorInfo[]
}

export type CompanyType = 'Client' | 'Debtor' | 'Broker' | 'Vendor'
export type Currency = 'USD' | 'CAD' | 'EUR' | 'GBP' | 'CNY'

export type CompanyInfo = {
  id: string
  compCode: string
  companyName: string
  active: boolean
  address1: string
  address2: string
  city: string
  stateCode: string
  zipCode: string
  countryCode: string
  phone: string
  fax: string
  email: string
  factorName: string
  companyType: CompanyType
  ein: string
  enableFuelAdvance: boolean
  fuelAdvanceMaxPercent: number
  fuelAdvanceMinFee: number
  overAdvance: boolean
  overAdvanceType: string
}

export type CompaniesListParams = {
  type?: string
  active?: boolean
  q?: string
  sort?: string
  term?: string
  creditLimitApproved?: boolean
  interestActive?: boolean
  amountMin?: number
  amountMax?: number
}

export type DebtorListParams = {
  active?: boolean
  q?: string
}

export type ClientInfo = CompanyInfo & {
  phoneAlternate: string
  ediId: string
  fedTaxNumber: string
  dunsNumber: string
  mcNumber: string
  dotNumber: string
  creditLimit: number
  ineligibleDays: number
  termId: string
  termName: string
  creditLimitApproved: boolean
  currency: Currency
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
  interestActive: boolean
  interestPrincipal: string
  interestType: string
  interestRate: number
  interestRateMinimum: number
  interestDaysPerYear: number
  negativeFeeDollarAmount: number
  negativeFeePercentageAmount: number
  enableFuelAdvance: boolean
  fuelAdvanceMaxPercent: number
  fuelAdvanceMinFee: number
  fundingNotes?: string
  generateInvoice?: boolean
}

export type DebtorInfo = CompanyInfo & {
  mcNumber: number
  dotNumber: number
  creditLimit: number
  creditLimitApproved: boolean
  billingEmail: string
  billingNoaEmail: string
  billingNeedOriginal: boolean
  billingPortal: string
  billingNotes: string
  billingDelivery: 'Email' | 'Portal' | 'Mail'
  billingEmailTemplateSubject: string
  billingEmailTemplateBody: string
  debtorParentId: string
  hasChildren: boolean
  childrenCount: number
  statementDelivery: string
  statementEnable: boolean
  statementEmail: string
  creditLimitExpirationDate: string
  factorBillingEmailTemplateSubject: boolean
  factorBillingEmailTemplateBody: boolean
}

export type BrokerInfo = CompanyInfo & {
  phoneAlternate: string
  currency: Currency
  fedTaxNumber: string
  dunsNumber: string
  mcNumber: string
  dotNumber: string
  termId: string
  termName: string
  creditLimit: number
  creditLimitApproved: boolean
  negativeFeeDollarAmount: number
  negativeFeePercentageAmount: number
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
  autochargeInterestFee: boolean
  autochargeInterestFeeCronLastExecution: string
  frequencyOfAutochargeInterestFee: string
  changeFactorAccruedInterest: boolean
  factorFrequencyOfCollectionOfAccruedFees: string
  overAdvance: boolean
  overAdvanceType: string
  interestActive: boolean
  interestPrincipal: string
  interestType: string
  interestRate: number
  interestRateMinimum: number
  interestDaysPerYear: number
  fundingNotes?: string
}

export type VendorInfo = CompanyInfo & {
  phoneAlternate: string
  currency: Currency
  fedTaxNumber: string
  dunsNumber: string
  mcNumber: string
  dotNumber: string
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
}

export type CompanyDetailsResponse = {
  company: CompanyInfo
}

export type ClientDetailsResponse = {
  client: ClientInfo
}

export type DebtorDetailsResponse = {
  debtor: DebtorInfo
}

export type BrokerDetailsResponse = {
  broker: BrokerInfo
}

export type VendorDetailsResponse = {
  vendor: VendorInfo
}

export type CompanyCreateRequest = {
  name: string
  address1: string
  address2: string
  city: string
  stateCode: string
  zipCode: string
  countryCode: string
  phone: string
  fax: string
  email: string
  companyType: string
  ein?: string
}

export type CompanyClientCreateRequest = CompanyCreateRequest & {
  currency: string
  termId: string
  creditLimitApproved: boolean
  creditLimit: number
}

export type CompanyBrokerCreateRequest = CompanyCreateRequest & {
  phoneAlternate: string
  currency: string
  fedTaxNumber: string
  dunsNumber: string
  mcNumber: string
  dotNumber: string
  termId: string
  creditLimitApproved: boolean
  creditLimit: number | null
}

export type CompanyVendorCreateRequest = CompanyCreateRequest & {
  phoneAlternate: string
  currency: string
  fedTaxNumber: string
  dunsNumber: string
  mcNumber: string
  dotNumber: string
}

export type CreateCompanyClientDebtorRequest = {
  companyDebtorIds: string[]
  termId: string
  paymentTerm: number
  creditLimit: number
  ineligibleDays: number
  creditLimitApproved: boolean
  creditRating: number
  sendNoa: boolean
}

export type UpdateCompanyClientDebtorRequest = {
  termId: string
  paymentTerm: number
  creditLimit: number
  ineligibleDays: number
  creditLimitApproved: boolean
  creditRating: number
  additionalNotes?: string
}

export function isClientInfo(
  company: ClientInfo | DebtorInfo | BrokerInfo | VendorInfo | undefined
): company is ClientInfo {
  if (!company) return false

  if (company.companyType === 'Client' && 'ediId' in company) {
    return true
  }
  return false
}

export function isDebtorInfo(
  company: ClientInfo | DebtorInfo | BrokerInfo | VendorInfo | undefined
): company is DebtorInfo {
  if (!company) return false

  if (company.companyType === 'Debtor') {
    return true
  }
  return false
}

export function isBrokerInfo(
  company: ClientInfo | DebtorInfo | BrokerInfo | VendorInfo | undefined
): company is BrokerInfo {
  if (!company) return false

  if (company.companyType === 'Broker') {
    return true
  }
  return false
}

export function isVendorInfo(
  company: ClientInfo | DebtorInfo | BrokerInfo | VendorInfo | undefined
): company is VendorInfo {
  if (!company) return false

  if (company.companyType === 'Vendor') {
    return true
  }
  return false
}

export type CompanyUpdateRequest = {
  name?: string
  code?: string
  address1?: string
  address2?: string
  city?: string
  stateCode?: string
  zipCode?: string
  countryCode?: string
  phone?: string
  fax?: string
  email?: string[]
  active?: boolean
  ein?: string
}

export type CreditInfoUpdateRequest = {
  originalTermId?: string
  newTermId?: string
  originalCreditLimit?: number
  newCreditLimit?: number
  originalCreditLimitApproved?: boolean
  newCreditLimitApproved?: boolean
  originalExpirationDate?: Date
  newExpirationDate?: Date
  type?: CreditInfoRequestType
}

export type ClientUpdateRequest = CompanyUpdateRequest & {
  phoneAlternate?: string
  ediId?: string
  fedTaxNumber?: string
  dunsNumber?: string
  mcNumber?: string
  dotNumber?: string
  creditLimit?: number
  ineligibleDays?: number
  currency?: string
  termId?: string
  creditLimitApproved?: boolean
  achFee?: number
  wireFee?: number
  checkFee?: number
  fuelCardFee?: number
  sameDayAchFee?: number
  moneyCodeFee?: number
  interestActive?: boolean
  interestPrincipal?: string | null
  interestType?: string | null
  interestRate?: number | null
  interestRateMinimum?: number | null
  interestDaysPerYear?: number | null
  negativeFeeDollarAmount?: number | null
  negativeFeePercentageAmount?: number | null
  enableFuelAdvance?: boolean | null
  fuelAdvanceMaxPercent?: number | null
  fuelAdvanceMinFee?: number | null
  fundingNotes?: string
  generateInvoice?: boolean
  additionalNotes?: string
}

export type DebtorUpdateRequest = CompanyUpdateRequest & {
  mcNumber?: number
  dotNumber?: number
  creditLimit?: number
  creditLimitApproved?: boolean
  creditLimitExpirationDate?: string
  additionalNotes?: string
}

export type BrokerUpdateRequest = CompanyUpdateRequest & {
  phoneAlternate?: string
  currency?: string
  fedTaxNumber?: string
  dunsNumber?: string
  mcNumber?: string
  dotNumber?: string
  termId?: string
  termType?: string
  creditLimit?: number
  creditLimitApproved?: boolean
  negativeFeeDollarAmount?: number | null
  negativeFeePercentageAmount?: number | null
  achFee?: number
  wireFee?: number
  checkFee?: number
  fuelCardFee?: number
  sameDayAchFee?: number
  moneyCodeFee?: number
  autochargeInterestFee?: boolean | null
  autochargeInterestFeeCronLastExecution?: string
  frequencyOfAutochargeInterestFee?: string
  overAdvance?: boolean
  overAdvanceType?: string | null
  fundingNotes?: string
  additionalNotes?: string
}

export type VendorUpdateRequest = CompanyUpdateRequest & {
  phoneAlternate?: string
  currency?: string
  fedTaxNumber?: string
  dunsNumber?: string
  mcNumber?: string
  dotNumber?: string
  achFee?: number
  wireFee?: number
  checkFee?: number
  fuelCardFee?: number
  sameDayAchFee?: number
  moneyCodeFee?: number
}

type SectionProps = {
  title: string
}

export type SectionCompanyProps = SectionProps & {
  company?: CompanyInfo
  canWriteClients: boolean
  canWriteDebtors: boolean
  canWriteBrokers: boolean
  canWriteVendors: boolean
}

export type CompanyChangelogInfo = {
  id: string
  username: string
  createdOn: string
  notes: string
  logType: string
  userEmail: string
  userId: string
  additionalNotes: string
}

export type CompanyChangelogList = {
  changelogs: CompanyChangelogInfo[]
}

export type SectionCompanyChangeLogsProps = {
  companyData?: CompanyInfo
  title: string
}

export type CompanyChangelogParams = {
  companyId?: string
  q?: string
  sort: string
}

export type SectionClientBrokerProps = SectionProps & {
  client?: ClientInfo
  broker?: BrokerInfo
  vendor?: VendorInfo
  canWriteClients?: boolean
  canWriteBrokers?: boolean
  canWriteVendors?: boolean
}

export type SectionClientProps = SectionProps & {
  client?: ClientInfo
  canWriteClients: boolean
}

export type SectionFuelAdvanceProps = SectionProps & {
  client?: ClientInfo
  canWriteClients: boolean
}

export type SectionGenerateInvoiceProps = SectionProps & {
  client?: ClientInfo
  canWriteClients: boolean
}

export type SectionBrokerProps = SectionProps & {
  broker?: BrokerInfo
  canWriteBrokers: boolean
}

export type SectionVendorProps = SectionProps & {
  vendor?: VendorInfo
  canWriteVendors: boolean
}

export type SectionDebtorProps = SectionProps & {
  debtor?: DebtorInfo
  canWriteDebtors: boolean
}

export type CompanyContactListResponse = {
  contacts: CompanyContactInfo[]
}

export type CompanyContactInfo = {
  id: string
  firstName: string
  lastName: string
  jobTitle: string
  phone: string
  email: string
  ext: string
  notes: string
}

export type CompanyContactResponse = {
  contact: CompanyContactInfo
}

export type CreateCompanyContactRequest = {
  firstName: string
  lastName: string
  jobTitle?: string
  phone: string
  email?: string
  ext?: string
  notes?: string
}

export type UpdateCompanyContactRequest = {
  firstName?: string
  lastName?: string
  jobTitle?: string
  phone?: string
  email?: string
  ext?: string
  notes?: string
}

export type BillingEmailInfo = {
  id: string
  billingEmail: string
}

export type StatementEmailInfo = {
  id: string
  statementEmail: string
}

export type NoaEmailInfo = {
  id: string
  billingNoaEmail: string
}

export type BillingInformationInfo = {
  billingNoaEmail?: string
  billingNeedOriginal?: boolean
  billingPortal?: string
  billingNotes?: string
  billingDelivery?: string
}

export type UpdateBillingInformationRequest = {
  billingEmail?: string[]
  billingNoaEmail?: string[]
  billingNeedOriginal?: boolean
  billingPortal?: string
  billingNotes?: string
  billingDelivery?: string
}

export type UpdateStatementInformationRequest = {
  statementEmail?: string[]
  statementEnable?: boolean
  statementDelivery?: string
}

export type DebtorCreditInfoResponse = {
  debtorCreditLimit: number
  debtorCredit: number
  debtorCreditScore: number
  clientDebtorCreditLimit: number
  clientDebtorCredit: number
  creditAverageDaysToPay: number
  creditInvoicesRiskPastDue: number
  creditInvoicesPastDue: number
}

export type UpdateBillingEmailTemplateRequest = {
  billingEmailTemplateSubject?: string
  billingEmailTemplateBody?: string
}

export type SendNoaRequest = {
  companyClientId: string
  companyDebtorIds: string[]
}

export type DebtorParentChildRelationRequest = {
  companyDebtorChildIds?: string[]
}

export type CompanyClientAdminSettingsResponse = {
  factorId: string
  changeFactorAccruedInterest: boolean
  factorFrequencyOfCollectionOfAccruedFees: string
  companyClientId: string
  changeClientAccruedInterest: boolean
  clientFrequencyOfCollectionOfAccruedFees: string
}

export type UpdateCompanyClientAdminSettingsRequest = {
  companyClientId: string
  chargeAccruedInterest: boolean | null
  frequencyOfCollectionOfAccruedFees: string | null
}

export type UpdateCompanyBrokerSettingsRequest = {
  overAdvance: boolean
  overAdvanceType: 'CASH_RESERVE' | 'BROKER_PAYMENT' | null
}

// Company Employees
export type CompanyEmployeeType = 'ACCOUNT_EXECUTIVE' | 'COLLECTION_AGENT' | 'SALES_EXECUTIVE'

export enum CommissionBasedOnEnum {
  OPEN_AND_CLOSED_INVOICE_FEES = 'OPEN_AND_CLOSED_INVOICE_FEES',
  CLOSED_INVOICE_FEES = 'CLOSED_INVOICE_FEES',
}

export enum CompanyEmployeeEnum {
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  COLLECTION_AGENT = 'COLLECTION_AGENT',
  SALES_EXECUTIVE = 'SALES_EXECUTIVE',
}

export type CompaniesEmployeesParams = {
  employeeType?: CompanyEmployeeType
  q?: string
  sort?: string
}

export type CompanyEmployeeInfo = {
  id: string
  employeeType: CompanyEmployeeType
  userFirstName: string
  userLastName: string
  userEmail: string
  userId: string
  salesCommissionPercentage: number
  commissionBasedOn: CommissionBasedOnEnum
  purchaseFee: boolean
  collectionFee: boolean
  feePerInvoice: boolean
  interestRateFee: boolean
  additionalFee: boolean
  fuelAdvanceFee: boolean | null
  negativeInterestFee: boolean
  paymentFee: boolean
  qpFee: boolean | null
  reserveReleaseFee: boolean
}

export type CompanyEmployeesResponse = {
  employees: CompanyEmployeeInfo[]
}

export type CompanyEmployeeRequest = {
  userId?: string
  employeeType?: CompanyEmployeeType
  salesCommissionPercentage?: number
  commissionBasedOn?: CommissionBasedOnEnum
  purchaseFee?: boolean
  collectionFee?: boolean
  feePerInvoice?: boolean
  interestRateFee?: boolean
  additionalFee?: boolean
  fuelAdvanceFee?: boolean
  negativeInterestFee?: boolean
  paymentFee?: boolean
  qpFee?: boolean
  reserveReleaseFee?: boolean
}

export type UpdateCompanyEmployeeRequest = {
  salesCommissionPercentage: number
  commissionBasedOn: CommissionBasedOnEnum
  purchaseFee: boolean
  collectionFee: boolean
  feePerInvoice: boolean
  interestRateFee: boolean
  additionalFee: boolean
  fuelAdvanceFee?: boolean | null
  negativeInterestFee: boolean
  paymentFee: boolean
  qpFee?: boolean | null
  reserveReleaseFee: boolean
}

export type CompanyEmployeeSingletonResponse = {
  employee: CompanyEmployeeInfo
}

export type SectionQuickPayFeesProps = SectionProps & {
  broker?: BrokerInfo
  canWriteBrokers: boolean
}

export type GetCompanyDebtorParams = {
  q?: string
  active?: boolean
  notRelatedWithClientId?: string
  notRelatedWithBrokerId?: string
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { DropdownFormField, TextFormField } from 'components/hookForm'
import { Blade, Container, Toast as toast } from 'components/ui'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import strings from 'l10n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getValueForDropdownWithEmptyOption,
  minValueLimit,
  parseBooleanValueFromForm,
  parseNumericValueFromForm,
} from 'tools/common'
import {
  FactorDefaultRequest,
  FactorRelationshipDefaultInfo,
  FactorRelationshipDefaultRequest,
} from 'types/factorDefault'
import styles from '../SystemDefaultsSectionsUpdateBlade.module.scss'
import { Button } from 'components/form'

type RelationshipSystemDefaultUpdateBladeProps = {
  title: string
  relationshipDefaultValues: FactorRelationshipDefaultInfo
  handleClose: () => void
  showBlade: boolean
}

type RelationshipSystemDefaultUpdateBladeForm = {
  creditLimit: number
  creditLimitApproved: string
  sendNoa: string
}

export const RelationshipSystemDefaultUpdateBlade = (props: RelationshipSystemDefaultUpdateBladeProps) => {
  const { title, relationshipDefaultValues, handleClose, showBlade } = props
  const form = useForm<RelationshipSystemDefaultUpdateBladeForm>()
  const { handleSubmit, setValue } = form
  const queryClient = useQueryClient()

  const { mutate: updateRelationShipDefaultValues, isLoading: isUpdateRelationShipDefaultValuesLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        subTitle: strings.RELATIONSHIP_DEFAULT_VALUES_UPDATED,
      })
      setInitialValues(data?.relationshipDefaultValues)
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      handleClose()
    },
  })

  const setInitialValues = (values: FactorRelationshipDefaultInfo) => {
    setValue('creditLimit', values.relationshipCreditLimit)
    setValue('creditLimitApproved', getValueForDropdownWithEmptyOption(values.relationshipCreditLimitApproved))
    setValue('sendNoa', getValueForDropdownWithEmptyOption(values.relationshipSendNoa))
  }

  const onSubmit = (data: RelationshipSystemDefaultUpdateBladeForm) => {
    const payload: FactorDefaultRequest = {}
    const relationshipDefaultValues: FactorRelationshipDefaultRequest = {
      relationshipCreditLimit: parseNumericValueFromForm(data.creditLimit),
      relationshipCreditLimitApproved: parseBooleanValueFromForm(data.creditLimitApproved),
      relationshipSendNoa: parseBooleanValueFromForm(data.sendNoa),
    }
    payload.relationshipDefaultValues = relationshipDefaultValues
    updateRelationShipDefaultValues(payload)
  }
  const bladeContent = (
    <FormProvider {...form}>
      <Container>
        <TextFormField
          name="creditLimit"
          label={strings.CREDIT_LIMIT}
          type="number"
          startAdornment="$"
          initialValue={relationshipDefaultValues?.relationshipCreditLimit}
          min={minValueLimit(0)}
          disabled={isUpdateRelationShipDefaultValuesLoading}
        />
        <DropdownFormField
          className={styles.dropDownContainer}
          name="creditLimitApproved"
          label={strings.CREDIT_LIMIT_APPROVED}
          options={[
            { label: '-', value: '' },
            { label: strings.YES, value: '1' },
            { label: strings.NO, value: '0' },
          ]}
          disabled={isUpdateRelationShipDefaultValuesLoading}
          initialValue={getValueForDropdownWithEmptyOption(relationshipDefaultValues?.relationshipCreditLimitApproved)}
        />
        <DropdownFormField
          className={styles.dropDownContainer}
          name="sendNoa"
          label={strings.SEND_NOA}
          options={[
            { label: '-', value: '' },
            { label: strings.YES, value: '1' },
            { label: strings.NO, value: '0' },
          ]}
          disabled={isUpdateRelationShipDefaultValuesLoading}
          initialValue={getValueForDropdownWithEmptyOption(relationshipDefaultValues?.relationshipSendNoa)}
        />
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button
        variant="outlined"
        label={strings.CANCEL}
        disabled={isUpdateRelationShipDefaultValuesLoading}
        onClick={handleClose}
      />
      <Button
        id="btnCreateBladeCreateCompany"
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isUpdateRelationShipDefaultValuesLoading}
        loading={isUpdateRelationShipDefaultValuesLoading}
      />
    </Container>
  )

  return (
    <Blade
      variant="persistent"
      open={showBlade}
      title={title}
      onClose={handleClose}
      loading={isUpdateRelationShipDefaultValuesLoading}
      bodyNode={bladeContent}
      footerNode={bladeFooter}
      hideBackdrop
    />
  )
}

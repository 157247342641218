import MuiTooltip from '@mui/material/Tooltip'

type TooltipProps = {
  /** The title is used to set the text or React element that is displayed in the tooltip when the user hovers over the children*/
  title: string | React.ReactElement
  /** The children is used to pass in the React element(s) that the tooltip is associated with. The tooltip will be displayed when the user hovers over the children. */
  children: React.ReactElement
  /** The sx is used to pass in custom styles to the tooltip. It accepts an object of CSS properties. */
  sx?: object
  /** The maxWidth is used to set the maximum width of the tooltip. */
  maxWidth?: number
  /** The show is used to determine whether or not the tooltip is displayed. If set to false, the children are rendered without the tooltip. */
  show?: boolean
  /** The containerClassName is used to pass in a custom class name to the container div that wraps around the children. This can be useful for applying custom styles to the container. */
  containerClassName?: string
  /** The placement is used to set the position of the tooltip relative to the children. It is of type string and can be one of the values defined in the variable type. */
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'

  margin?: string

  backgroundColor?: string
  textColor?: string
  arrow?: boolean
  arrowColor?: string
}

const Tooltip = (props: TooltipProps) => {
  const {
    title,
    children,
    placement = 'top',
    maxWidth = 300,
    show = true,
    containerClassName,
    backgroundColor = '#000000',
    textColor = '#ffffff',
    arrow = true,
    arrowColor = '#000000',
    margin = 'auto',
  } = props
  if (!show) {
    return children
  }
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      arrow={arrow}
      componentsProps={{
        tooltip: {
          sx: {
            color: textColor,
            backgroundColor: backgroundColor,
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '1.2',
            letterSpacing: '0.03333em',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            margin: '',
            maxWidth: maxWidth,
            boxShadow: '0px 0px 10px 0px rgba(90, 90, 90, 0.75)',
          },
        },
        arrow: {
          sx: {
            color: arrowColor,
          },
        },
      }}
    >
      <div style={{ margin: margin }} className={containerClassName}>
        {children && children}
      </div>
    </MuiTooltip>
  )
}

export default Tooltip

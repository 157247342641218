import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const BbIntegrationList = lazy(() => import('pages/bbIntegration/page'))
const DocumentPreview = lazy(() => import('pages/bbIntegration/documentPreview/page'))

const BbIntegrationRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<BbIntegrationList />} />} />
      <Route path="/:name/:id" element={<LoaderPage content={<DocumentPreview />} />} />
    </Routes>
  )
}

export default BbIntegrationRoutes

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { Blade, Container, Toast as toast } from 'components/ui'
import DividerComponent from 'components/ui/layout/DividerComponent'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import strings from 'l10n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getValueForDropdownWithEmptyOption,
  isDefined,
  minValueLimit,
  parseBooleanValueFromForm,
  parseNumericValueFromForm,
  parsePercentageValueFromForm,
} from 'tools/common'
import { percentage } from 'tools/format'
import {
  FactorBrokerDefaultInfo,
  FactorBrokerDefaultRequest,
  FactorDefaultFeesRequest,
  FactorDefaultRequest,
} from 'types/factorDefault'
import styles from '../SystemDefaultsSectionsUpdateBlade.module.scss'
import { DropdownFormField, TextFormField } from 'components/hookForm'
import { Button } from 'components/form'

type BrokerSystemDefaultUpdateBladeProps = {
  title: string
  brokerDefaultValues?: FactorBrokerDefaultInfo
  handleClose: () => void
  showBlade: boolean
}

type BrokerSystemDefaultUpdateBladeForm = {
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
  brokerNegativeFeeDollarAmount: number
  brokerNegativeFeePercentageAmount: string
  brokerCreditLimit: number
  brokerCreditLimitApproved: string
}

export const BrokerSystemDefaultUpdateBlade = (props: BrokerSystemDefaultUpdateBladeProps) => {
  const { title, brokerDefaultValues, handleClose, showBlade } = props
  const form = useForm<BrokerSystemDefaultUpdateBladeForm>()
  const { handleSubmit, setValue } = form
  const queryClient = useQueryClient()

  const { mutate: updateBrokerDefaultsValues, isLoading: isUpdateBrokerDefaultsValuesLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        subTitle: strings.BROKER_DEFAULT_VALUES_UPDATED,
      })
      setInitialValues(data?.brokerDefaultValues)
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      handleClose()
    },
  })

  const setInitialValues = (values: FactorBrokerDefaultInfo) => {
    setValue('achFee', values.brokerPaymentFees.achFee)
    setValue('wireFee', values.brokerPaymentFees.wireFee)
    setValue('checkFee', values.brokerPaymentFees.checkFee)
    setValue('fuelCardFee', values.brokerPaymentFees.fuelCardFee)
    setValue('sameDayAchFee', values.brokerPaymentFees.sameDayAchFee)
    setValue('moneyCodeFee', values.brokerPaymentFees.moneyCodeFee)
    setValue('brokerNegativeFeeDollarAmount', values.brokerNegativeFeeDollarAmount)
    setValue(
      'brokerNegativeFeePercentageAmount',
      isDefined(values.brokerNegativeFeePercentageAmount) ? percentage(values.brokerNegativeFeePercentageAmount) : ''
    )
    setValue('brokerCreditLimit', values.brokerCreditLimit)
    setValue('brokerCreditLimitApproved', getValueForDropdownWithEmptyOption(values.brokerCreditLimitApproved))
  }

  const onSubmit = (data: BrokerSystemDefaultUpdateBladeForm) => {
    const payload: FactorDefaultRequest = {}
    const brokerPaymentFees: FactorDefaultFeesRequest = {
      achFee: parseNumericValueFromForm(data.achFee),
      wireFee: parseNumericValueFromForm(data.wireFee),
      checkFee: parseNumericValueFromForm(data.checkFee),
      fuelCardFee: parseNumericValueFromForm(data.fuelCardFee),
      sameDayAchFee: parseNumericValueFromForm(data.sameDayAchFee),
      moneyCodeFee: parseNumericValueFromForm(data.moneyCodeFee),
    }
    const brokerDefaultValues: FactorBrokerDefaultRequest = {
      brokerPaymentFees,
      brokerNegativeFeeDollarAmount: parseNumericValueFromForm(data.brokerNegativeFeeDollarAmount),
      brokerNegativeFeePercentageAmount: parsePercentageValueFromForm(data.brokerNegativeFeePercentageAmount),
      brokerCreditLimit: parseNumericValueFromForm(data.brokerCreditLimit),
      brokerCreditLimitApproved: parseBooleanValueFromForm(data.brokerCreditLimitApproved),
    }
    payload.brokerDefaultValues = brokerDefaultValues
    updateBrokerDefaultsValues(payload)
  }

  const bladeContent = (
    <FormProvider {...form}>
      <DividerComponent label={strings.PAYMENT_FEES} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="achFee"
          label={strings.ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.achFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="wireFee"
          label={strings.WIRE_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.wireFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="checkFee"
          label={strings.CHECK_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.checkFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="fuelCardFee"
          label={strings.FUEL_CARD_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.fuelCardFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="sameDayAchFee"
          label={strings.SAME_DAY_ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.sameDayAchFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="moneyCodeFee"
          label={strings.MONEY_CODE_FEE}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerPaymentFees.moneyCodeFee}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
      </Container>
      <DividerComponent label={strings.NEGATIVE_CASH_RESERVE_FEE} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="brokerNegativeFeeDollarAmount"
          label={strings.DOLLAR_AMOUNT}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerNegativeFeeDollarAmount}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <TextFormField
          name="brokerNegativeFeePercentageAmount"
          label={strings.PERCENTAGE_AMOUNT}
          type="number"
          startAdornment="%"
          initialValue={
            isDefined(brokerDefaultValues?.brokerNegativeFeePercentageAmount)
              ? percentage(brokerDefaultValues?.brokerNegativeFeePercentageAmount)
              : ''
          }
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
      </Container>
      <DividerComponent label={strings.OTHERS} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="brokerCreditLimit"
          label={strings.CREDIT_LIMIT}
          type="number"
          startAdornment="$"
          initialValue={brokerDefaultValues?.brokerCreditLimit}
          min={minValueLimit(0)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
        <DropdownFormField
          name="brokerCreditLimitApproved"
          label={strings.CREDIT_LIMIT_APPROVED}
          options={[
            { value: '', label: '-' },
            { value: '1', label: strings.YES },
            { value: '0', label: strings.NO },
          ]}
          initialValue={getValueForDropdownWithEmptyOption(brokerDefaultValues?.brokerCreditLimitApproved)}
          disabled={isUpdateBrokerDefaultsValuesLoading}
        />
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button
        variant="outlined"
        label={strings.CANCEL}
        disabled={isUpdateBrokerDefaultsValuesLoading}
        onClick={handleClose}
      />
      <Button
        id="btnCreateBladeCreateCompany"
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isUpdateBrokerDefaultsValuesLoading}
        loading={isUpdateBrokerDefaultsValuesLoading}
      />
    </Container>
  )

  return (
    <Blade
      variant="persistent"
      open={showBlade}
      title={title}
      onClose={handleClose}
      loading={isUpdateBrokerDefaultsValuesLoading}
      bodyNode={bladeContent}
      footerNode={bladeFooter}
      hideBackdrop
    />
  )
}

export const bbIntegrationEn = {
  BB_INTEGRATION: 'Documents',
  DOCUMENT_ID: 'Document Id',
  UPLOAD_DOCUMENT: 'Upload Document',
  ERROR_UPLOADING_FILE: 'Error uploading file',
  UPLOAD_FILE_EXTENSIONS: 'PDF, JPEG or PNG',
  FILE_ALREADY_EXISTS: 'File already exists',
  COMPLETED: 'Completed',
  UPLOADING: 'Uploading',
  CONFIDENCE: 'Data Accuracy Confidence',
  OTHERS_DOCTYPE: 'Others',
  INVALID_ID: 'Invalid ID, it is not possible to load the data.',
  SUBMIT_DOCUMENTS: 'Submit documents',
  DEBTOR: 'Debtor',
  LOAD_NO: 'Load No',
  INVOICE: 'Invoice',
  TOTAL_RATE: 'Total rate',
  INVOICE_SUBMITTED_SUCCESS: 'Invoice Submitted Successfully!',
  RATE_CONFIRMATIONS_DOCTYPE: 'Rate confirmations',
  BILL_OF_LADING_DOCTYPE: 'Bill of lading',
  INVOICE_DOCTYPE: 'Invoice',
  JOBS: 'Jobs',
  DOCUMENT_NAME: 'Document name',
  CHANNEL: 'Channel',
  CREATOR: 'Creator',
  SPARK_NAME: 'Spark Name',
  SPARK_VALUE: 'Spark Value',
  GROUP_NAME: 'Group Name',
  DUPLICATE_FILES: 'Duplicate Files',
  DATE_AND_TIME: 'Date & Time',
  CREATED: 'Created',
  JOB_STATUS: 'Status',
  FILES: 'Files',
  TAGS: 'Tags',
  JOB_ACTIONS: 'Actions',
  NEEDS_REVIEW: 'Needs review',
  EMAIL_PROCESSING_LOG: 'Email processing log',
  PROCESSING: 'Processing',
  PAUSED: 'Paused',
  REVIEWED: 'Reviewed',
  SUBMITTED: 'Submitted',
  ERROR: 'Error',
  REJECTED: 'Rejected',
  CREATE_JOB: 'Create Job',
  REJECT: 'Reject',
  RETRY: 'Retry',
  ALL_DOCUMENTS: 'All documents',
  REJECT_DIALOG: 'Are you sure you want to reject these documents?',
  RETRY_DIALOG: 'Are you sure you want to retry?',
  INVOICE_DRAFT_ERROR: "Couldn't create draft",
  JOB_SUBMIT_ERROR: "Couldn't process documents. Please try again later",
  FILES_STILL_UPLOADING: 'Some files are still uploading. Wait until they are finished',
  DOCUMENTS_SUBMITTED_SUCCESSFULLY: 'Documents Submitted Successfully',
  CONFIRMATION_DIALOG_TITLE: 'Confirmation dialog',
  CONFIRMATION_DIALOG_TEXT: 'Are you sure you want to submit the documents?',
  GROUP_SELECT: 'Group to review',
  THE_GROUP_IS_EMPTY: 'There are no groups created or the group is empty.',
  HOW_TO_CREATE_A_GROUP:
    'To create a group you must go to the "Grouping" tab and select the pages in the Ungrouped area and then click on the "Create group" button.',
  SUBJECT: 'Subject',
  SENDER: 'Sender',
  TIMESTAMP: 'Timestamp',
  INCOMING_EMAIL_STATUS: 'Status',
  STATUS_DETAILS: 'Status details',
  SOLVED: 'Solved',
  INCOMING_EMAIL_CLEAR: 'Clear',
  INCOMING_EMAIL_RETRY: 'Retry',
  INCOMING_EMAIL_CLEAR_DIALOG: 'Are you sure you want to clear the incoming email?',
  MERGE_JOBS: 'Merge Jobs',

  INCOMING_EMAIL_RETRY_DIALOG: 'Are you sure you want to retry process the incoming email?',
  WHITELIST_DIALOG_TITLE: 'Add to whitelist',
  WHITELIST_SELECT: 'Whitelist',
  WHITELIST_DETAILS: 'This item will be processed when added to the whitelist.',
  WHITELIST_CHECKBOX: 'Reprocess all item from this sender.',
  WHITELIST_ADD_SENDER: 'Add sender',
  IMPORT: 'Import',
  CREATE_A_NEW_SOURCE_WITH: 'Create a new source with ',
  MERGE_JOBS_CONFIRMATION: 'Are you sure you would like to merge these jobs?',
  COMBINE_EXISTING_JOBS: 'Combine existing jobs into a new job',
}

export const bbIntegrationEs: typeof bbIntegrationEn = {
  BB_INTEGRATION: 'Documentos',
  DOCUMENT_ID: 'Identificación del documento',
  UPLOAD_DOCUMENT: 'Cargar documento',
  ERROR_UPLOADING_FILE: 'Error subiendo archivo',
  UPLOAD_FILE_EXTENSIONS: 'PDF, JPEG o PNG',
  FILE_ALREADY_EXISTS: 'El archivo ya existe',
  COMPLETED: 'Listo',
  UPLOADING: 'Subiendo',
  CONFIDENCE: 'Confianza',
  OTHERS_DOCTYPE: 'Otros',
  INVALID_ID: 'Id no valido, no es posible cargar la data.',
  SUBMIT_DOCUMENTS: 'Enviar documentos',
  DEBTOR: 'Deudor',
  LOAD_NO: 'Número de carga',
  INVOICE: 'Factura',
  TOTAL_RATE: 'Tarifa total',
  INVOICE_SUBMITTED_SUCCESS: '¡Factura Enviada Exitosamente!',
  RATE_CONFIRMATIONS_DOCTYPE: 'Confirmaciones de tarifas',
  BILL_OF_LADING_DOCTYPE: 'Guía de despacho',
  INVOICE_DOCTYPE: 'Factura',
  JOBS: 'Trabajos',
  DOCUMENT_NAME: 'Nombre del trabajo',
  CHANNEL: 'Canal',
  CREATOR: 'Credor',
  SPARK_NAME: 'Spark Name',
  SPARK_VALUE: 'Spark Value',
  GROUP_NAME: 'Group Name',
  DUPLICATE_FILES: 'Duplicate Files',
  DATE_AND_TIME: 'Fecha y hora',
  CREATED: 'Fecha de creación',
  JOB_STATUS: 'Estado',
  FILES: 'Archivos',
  TAGS: 'Tags',
  JOB_ACTIONS: 'Acciones',
  NEEDS_REVIEW: 'Necesita revisión',
  EMAIL_PROCESSING_LOG: 'Registro de procesamiento de emails',
  PROCESSING: 'Procesando',
  PAUSED: 'Pausado',
  REVIEWED: 'Revisado',
  SUBMITTED: 'Enviado',
  ERROR: 'Error',
  REJECTED: 'Rechazado',
  CREATE_JOB: 'Crear Trabajo',
  REJECT: 'Rechazar',
  RETRY: 'Reintentar',
  ALL_DOCUMENTS: 'Todos los documentos',
  REJECT_DIALOG: '¿Estás seguro que quieres rechazar estos documentos?',
  RETRY_DIALOG: '¿Estas seguro que quieres reintentar?',
  INVOICE_DRAFT_ERROR: 'No se pudo crear borrador',
  JOB_SUBMIT_ERROR: 'Error procesando documentos. Por favor intente de nuevo mas tarde',
  FILES_STILL_UPLOADING: 'Algunos archivos aún están subiendose. Espere por favor a que terminen',
  DOCUMENTS_SUBMITTED_SUCCESSFULLY: 'Documentos enviados exitosamente',
  CONFIRMATION_DIALOG_TITLE: 'Diálogo de confirmación',
  CONFIRMATION_DIALOG_TEXT: '¿Está seguro de que desea enviar los documentos?',
  GROUP_SELECT: 'Grupo a revisar',
  THE_GROUP_IS_EMPTY: 'No hay grupos creados o el grupo está vacío.',
  HOW_TO_CREATE_A_GROUP:
    'Para crear un grupo debes ir a la pestaña "Agrupar" y seleccionar las páginas en el área Desagrupados y luego hacer clic en el botón "Crear grupo".',
  SUBJECT: 'Asunto',
  SENDER: 'Remitente',
  TIMESTAMP: 'Marca de tiempo',
  INCOMING_EMAIL_STATUS: 'Estado',
  STATUS_DETAILS: 'Detalles de estado',
  SOLVED: 'Resuelto',
  INCOMING_EMAIL_CLEAR: 'Despejar',
  INCOMING_EMAIL_RETRY: 'Reintentar',
  INCOMING_EMAIL_CLEAR_DIALOG: '¿Está seguro de que desea borrar el correo electrónico entrante?',
  INCOMING_EMAIL_RETRY_DIALOG: '¿Está seguro de que desea volver a intentar procesar el correo electrónico entrante?',
  MERGE_JOBS: 'Combinar trabajos',
  WHITELIST_DIALOG_TITLE: 'Agregar a la lista blanca',
  WHITELIST_SELECT: 'Lista blanca',
  WHITELIST_DETAILS: 'Este elemento se procesará cuando se agregue a la lista blanca.',
  WHITELIST_CHECKBOX: 'Vuelva a procesar todos los elementos de este remitente.',
  WHITELIST_ADD_SENDER: 'Agregar remitente',
  IMPORT: 'Importar',
  CREATE_A_NEW_SOURCE_WITH: 'Crea una nueva fuente con',
  MERGE_JOBS_CONFIRMATION: '¿Estás seguro de que deseas combinar estos trabajos?',
  COMBINE_EXISTING_JOBS: 'Combinar trabajos existentes en un nuevo trabajo',
}

import { Typography } from '@mui/material'
import { Container, Icon } from 'components/ui'
import { ObjectStrings } from 'interfaces'
import { environment } from 'tools/common'
//import strings from 'l10n'

type MarketingChangelogProps = {
  styles: ObjectStrings
}

const MarketingChangelog = (props: MarketingChangelogProps) => {
  const { styles } = props

  const appVersion = process.env.REACT_APP_VERSION
  const appYear = new Date().getFullYear()
  const env = environment()
  const envTsx = env === 'PRODUCTION' ? <></> : <span>{env} - </span>

  return (
    <Container className={styles.marketingContainer}>
      <Icon boxClassName={styles.marketingIconContainer} name="icon_marketing_logo" size={250} />
      <Typography className={styles.marketingContent}>The Best Invoice Factoring Software</Typography>
      {/*<div className={styles.betaContainer}>
        <div className={styles.infoContainer}>
          <Typography variant="h4" align="center" fontFamily="Inter-Medium" marginBottom={2} fontWeight={600}>
            {strings.BETA_TITLE}
          </Typography>
          <Typography variant="h5" align="center" fontFamily="Inter-Medium" color="#666" marginBottom={3}>
            {strings.BETA_SUBTITLE}
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            This beta product is a work-in-progress and may have bugs, unfinished features, and compatibility issues.
            Data loss and security vulnerabilities are possible. Limited customer support and incomplete documentation
            are available. Not suitable for production use or handling critical data. User experience and functionality
            might change without notice.
          </Typography>
          <Typography variant="body1" marginBottom={3}>
            Participation is voluntary, and feedback is appreciated.
          </Typography>
          <Typography variant="body1" align="center">
            <a
              className={styles.surveyLink}
              target="_blank"
              rel="noreferrer"
              href="https://zsnk5ec8jfk.typeform.com/to/QCpCa4Hm"
            >
              Beta Feedback Survey
            </a>
          </Typography>
        </div>
  </div>*/}
      <Typography variant="caption" className={styles.betaVersion}>
        {envTsx}
        {`© ${appYear}, Version ${appVersion}`}
      </Typography>
    </Container>
  )
}

export default MarketingChangelog

import { useEffect, useState } from 'react'
import { CssBaseline, Box } from '@mui/material'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import jwt_Decode from 'jwt-decode'
import { useLocation, useNavigate } from 'react-router'
import ApplicationRoutes from 'routes'
import { lightTheme } from 'theme/themeOverride'
import { Container, ToastContainer } from 'components/ui'
import MenuBar from './MenuBar'
import LoginPage from 'pages/login/page'
import strings from 'l10n'
import Breadcrumbs from 'app/Breadcrumbs/Breadcrumbs'
import useUserSession from 'hooks/useUserSession'

const AppContainer: React.FunctionComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [path, setPath] = useState(location.pathname)
  const { _loadTokenFromStorage, _initPendo, _initIntercom, logout, isAuth, _token } = useUserSession()

  useEffect(() => {
    const interval = setInterval(() => {
      if (_token != null) {
        const decodedToken: any = jwt_Decode(_token)
        const currentTime = Math.floor(Date.now() / 1000)
        const remainingTime = decodedToken.exp - currentTime
        if (remainingTime < 0) {
          logout({
            title: strings.SESSION_HAS_TIMED_OUT,
            subTitle: strings.PLEASE_RE_ENTER_YOUR_CREDENTIALS,
          })
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [logout, _token])

  useEffect(() => {
    setPath(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    if (isAuth) {
      _initPendo()
      _initIntercom()
    }
  }, [isAuth, _initPendo, _initIntercom])

  // This will load the token from session storage.
  // We need to do this just once during the Application lifecycle
  useEffect(() => {
    _loadTokenFromStorage()
  }, [_loadTokenFromStorage])

  let appContent = <></>
  if (isAuth === false) {
    appContent = (
      <ThemeProvider theme={lightTheme}>
        <Routes>
          <Route path="/login" element={<LoginPage currentStep={0} />}></Route>
          <Route path="/reset-password" element={<LoginPage currentStep={2} />} />
          <Route path="*" element={<Navigate to={'/login'} />} />
        </Routes>
      </ThemeProvider>
    )
  } else if (isAuth === true) {
    appContent = (
      <Box sx={{ display: 'flex' }}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <MenuBar path={path} navigate={navigate} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 2,
              margin: 'auto',
              position: 'relative',
            }}
          >
            <Breadcrumbs path={path} />
            <ApplicationRoutes isAuth={isAuth} />
          </Box>
        </ThemeProvider>
      </Box>
    )
  }

  return (
    <Container>
      <ToastContainer />
      {appContent}
    </Container>
  )
}

export default AppContainer

import axios from 'axios'
import { getCurrentToken, getTokenContent, isDefined } from './common'

const API_URL = process.env.REACT_APP_API_URL

const request = axios.create({
  baseURL: API_URL,
  timeout: 300000,
})

request.interceptors.request.use(
  (config) => {
    const token = getCurrentToken()
    config.headers = {
      'X-LOCALIZATION-LANG': navigator.language.split('-')[0],
      ...config.headers,
    }
    if (isDefined(token)) {
      const isIntegration = Boolean(config.headers.Authorization)
      config.headers['Authorization'] = isIntegration ? config.headers.Authorization : `Bearer ${token}`
      config.headers['factorId'] = getTokenContent(token).factorId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    return response
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

export default request

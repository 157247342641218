import { useQuery } from '@tanstack/react-query'
import { getFactorDefaults } from 'api/factorDefault'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import { generateSections } from './SystemDefaultsTabData'
import { Container, ScrollSpy } from 'components/ui'
import { FactorDefaultInfo } from 'types/factorDefault'
import usePermissions from 'hooks/usePermissions'

export type SystemDefaultsSectionProps = {
  factorDefault?: FactorDefaultInfo
  canModifyFactorDefaultValues: boolean
}

const SystemDefaultsComponent = () => {
  const { canModifyFactorDefaultValues } = usePermissions()
  const { data: factorDefault } = useQuery({
    queryKey: qkBaseFactorDefaults,
    queryFn: () => {
      return getFactorDefaults()
    },
  })

  const sectionData = generateSections({ factorDefault, canModifyFactorDefaultValues })

  return (
    <Container>
      <ScrollSpy data={sectionData} />
    </Container>
  )
}

export default SystemDefaultsComponent

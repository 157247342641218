import { Icon, TypoGraph, Container } from 'components/ui'
import Button, { ButtonProps } from 'components/form/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import strings from 'l10n'
import { ReactNode } from 'react'

type SubmitDialogProps = {
  /** The open is a boolean value that controls whether the SubmitDialog component is visible or not.*/
  open: boolean
  /** The primaryBtnProps is an optional object that contains props for the primary button. */
  primaryBtnProps?: ButtonProps
  /** The secondaryBtnProps is an optional object that contains props for the secondary button.*/
  secondaryBtnProps?: ButtonProps
  /** The type is an optional string that determines the type of dialog box to display (default is "success").*/
  type?: 'success' | 'warning' | 'delete'
  /** The title is an optional string that sets the title of the dialog box (default is "Confirmation").*/
  title?: string | ReactNode
  /** The titleClassName is an optional string that sets the class name for the title of the dialog box.*/
  titleClassName?: string
  /** The body1 is an optional string that sets the main body text of the dialog box.*/
  body1?: string
  /** The body1ClassName is an optional string that sets the class name for the main body text of the dialog box.*/
  body1ClassName?: string
  /** The body2 is an optional string that sets the secondary body text of the dialog box.*/
  body2?: string
  /** The body2ClassName is an optional string that sets the class name for the secondary body text of the dialog box.*/
  body2ClassName?: string
  subTitle?: string
  /** The showCancel is an optional boolean that determines whether the cancel button is displayed (default is true).*/
  showCancel?: boolean
  /** The showCrossButton is an optional boolean that determines whether the cross button is displayed (default is true).*/
  showCrossButton?: boolean
  /** The disabledCrossButton is an optional boolean that determines whether the cross button is disabled (default is false).*/
  loading?: boolean
  /** The handleClose is a function that is called when the dialog box is closed.*/
  handleClose: () => void

  customBody?: ReactNode
}

const SubmitDialog = (props: SubmitDialogProps) => {
  const {
    open,
    handleClose,
    primaryBtnProps,
    secondaryBtnProps,
    type,
    title = 'Confirmation',
    showCancel = true,
    body1,
    body2,
    loading = false,
    body1ClassName,
    body2ClassName,
    titleClassName,
    showCrossButton = true,
    subTitle,
    customBody,
  } = props

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={titleClassName} sx={{ margin: '10px 25px 0 5px' }}>
        {title}
        {subTitle && <TypoGraph variant="subtitle1" content={subTitle} />}
      </DialogTitle>
      {showCrossButton && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        {type && <Icon name={`icon_dialog_${type}`} height={150} width={600} />}
        {!customBody && (
          <>
            <TypoGraph className={body1ClassName} content={body1} variant="body2" mb={1} align="center" />
            <TypoGraph className={body2ClassName} content={body2} variant="body1" align="center" />
          </>
        )}
        {customBody}
      </DialogContent>
      <DialogActions>
        <Container
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'end',
            gridGap: '1rem',
            padding: '12px 20px',
          }}
        >
          {showCancel && (
            <Button
              label={strings.CANCEL}
              variant="text"
              onClick={handleClose}
              {...secondaryBtnProps}
              disabled={loading}
              color="neutral"
            />
          )}
          {primaryBtnProps && (
            <Button
              color={type === 'delete' ? 'error' : 'primary'}
              {...primaryBtnProps}
              loading={loading}
              disabled={loading}
            />
          )}
        </Container>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitDialog

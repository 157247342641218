import request from 'tools/request'
import { BaseResponse } from 'types/common'
import {
  AuthorizedGmailAccountResponse,
  GmailCallbackParams,
  GmailCallbackResponse,
  GmailSendEmailRequest,
} from 'types/gmailCallback'

export const getGmailCallbackResponse = async (params: GmailCallbackParams) => {
  const data = await request
    .get<GmailCallbackResponse>('/gmail/callback', {
      params: params,
    })
    .then((res) => res.data)
  return data
}

export const sendTestEmail = async (factorId: string, payload: GmailSendEmailRequest) => {
  const data = await request.post<BaseResponse>(`/gmail/${factorId}/emailtest`, payload).then((res) => {
    return res.data
  })
  return data
}

export const unlinkAccount = async (factorId: string) => {
  const data = await request.post<BaseResponse>(`/gmail/${factorId}/unlink-account`).then((res) => {
    return res.data
  })
  return data
}

export const getAuthAccount = async (factorId: string) => {
  const data = await request
    .get<AuthorizedGmailAccountResponse>(`/gmail/${factorId}/authorized-account`)
    .then((res) => {
      return res.data
    })
  return data
}

export const ledgersEn = {
  // BEGIN Michael
  TOTAL_INCREASE: 'Total Increase',
  TOTAL_DECREASE: 'Total Decrease',
  FEE: 'Fee',
  ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  OFFSETTING: 'Offsetting',
  NOTES_INFORMATION: 'The notes in this field are applied for both main and offsetting entry',
  OFFSETTING_LINK_INFORMATION: 'Main and Offsetting are linked; deleting one removes both.',
  DELETE_MANUAL_TRANSACTION_CONFIRMATION:
    'The offsetting entry and the main entry are be linked. If one is deleted both will be removed',
  DELETE_MANUAL_TRANSACTION_WARNING: 'This action cannot be undone',
  CONFIRM_DELETE: 'Confirm Delete',
  ASSETS_AMOUNT_TYPE_INFO: 'Assets: Increase (Debit) / Decrease (Credit)',
  REVENUE_AMOUNT_TYPE_INFO: 'Revenue: Increase (Credit) / Decrease (Debit)',
  LIABILITY_AMOUNT_TYPE_INFO: 'Liability: Increase (Credit) / Decrease (Debit)',
  AR_LEDGER_AMOUNT_LEDGER_TYPE: 'AR Ledger (Open AR) -> Assets',
  FUEL_ADVANCE_LEDGER_AMOUNT_TYPE: 'Fuel Advance Ledger -> Assets',
  ACCRUED_FEES_LEDGER_AMOUNT_TYPE: 'Accrued Fees Ledger -> Assets',
  ACCRUED_INTEREST_LEDGER_AMOUNT_TYPE: 'Accrued Interest Ledger -> Assets',
  AP_BANK_ACCOUNT_LEDGER_AMOUNT_TYPE: 'AP Bank Account Ledger -> Assets',
  AR_BANK_ACCOUNT_LEDGER_AMOUNT_TYPE: 'AR Bank Account Ledger -> Assets',
  FEES_LEDGER_AMOUNT_TYPE: 'Fees Ledger -> Revenue',
  ESCROW_LEDGER_AMOUNT_TYPE: 'Escrow Ledger -> Liability',
  CASH_RESERVE_LEDGER_AMOUNT_TYPE: 'Cash Reserve Ledger -> Liability',
  LIABILITY_LEDGER_AMOUNT_TYPE: 'Liability Ledger -> Liability',
  UNAPPLIED_LEDGER_AMOUNT_TYPE: 'Unapplied Ledger -> Liability',
  MAIN_ENTRY: 'Main Entry',
  // END Michael
  // BEGIN Fco
  ACCRUED_FEE: 'Accrued Fees',
  ACCRUED_INTEREST: 'Accrued Interest',
  INCREASE: 'Increase',
  DECREASE: 'Decrease',
  // END Fco
  // BEGIN Fabian
  DATE_START: 'Date Start',
  DATE_END: 'Date End',
  DESCRIPTION: 'Description',
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  OPEN_AR: 'Open AR',
  NFE: 'NFE',
  TITLE_TYPE_AR: '{title} Ledgers',
  LEDGER: 'Ledger',
  LEDGERS: 'Ledgers',
  LIABILITY: 'Liability',
  INCREASE_OR_DECREASE: 'Increase or Decrease',
  LEDGER_TYPE: 'Ledger Type',
  CREATE_MANUAL_TRANSACTION: 'Create Manual Transaction',
  OFFSETTING_ENTRY: 'Offsetting Entry',
  MANUAL_TRANSACTION: 'Manual Transaction',
  MANUAL_TRANSACTION_DELETE: 'Manual transaction deleted successfully',
  MANUAL_TRANSACTION_CREATED_SUCCESSFULLY: 'Manual transaction created successfully',
  ACTIVE: 'Active',
  // END Fabian
  //BEGIN Esteban
  GL_CODE: 'GL Code',
  FUEL_ADVANCE: 'Fuel Advance',
  //END Esteban

  // BEGIN Patricio
  PAYMENT_TRANSACTION_REF: 'Payment Ref',
  LEDGER_EXPORT_CONFIRMATION: `You are trying to export {totalRows} rows of data, this action will take some time to complete, proceed?`,
  LEDGER_EXPORT: 'Export Ledger Data',
  //END Patricio
}

export const ledgersEs: typeof ledgersEn = {
  // BEGIN Michael
  TOTAL_INCREASE: 'Total Incremento',
  TOTAL_DECREASE: 'Total Decremento',
  FEE: 'Comisión',
  ACCOUNTS_RECEIVABLE: 'Cuentas por Cobrar',
  OFFSETTING: 'Compensación',
  NOTES_INFORMATION: 'Las notas en este campo se aplican tanto para la entrada principal como para la compensatoria',
  OFFSETTING_LINK_INFORMATION: 'Principal y Compensatoria están vinculados; eliminar uno elimina ambos.',
  DELETE_MANUAL_TRANSACTION_CONFIRMATION:
    'La entrada compensatoria y la principal están vinculadas. Si se elimina una, ambas se eliminarán',
  DELETE_MANUAL_TRANSACTION_WARNING: 'Esta acción no se puede deshacer',
  CONFIRM_DELETE: 'Confirm Delete',
  ASSETS_AMOUNT_TYPE_INFO: 'Activos: Incremento (Débito) / Decremento (Crédito)',
  REVENUE_AMOUNT_TYPE_INFO: 'Ingresos: Incremento (Crédito) / Decremento (Débito)',
  LIABILITY_AMOUNT_TYPE_INFO: 'Pasivo: Incremento (Crédito) / Decremento (Débito)',
  AR_LEDGER_AMOUNT_LEDGER_TYPE: 'Libro de Cuentas por Cobrar (Open AR) -> Activos',
  FUEL_ADVANCE_LEDGER_AMOUNT_TYPE: 'Libro de Avance por Combustible -> Activos',
  ACCRUED_FEES_LEDGER_AMOUNT_TYPE: 'Libro de Comisiones Acumuladas -> Activos',
  ACCRUED_INTEREST_LEDGER_AMOUNT_TYPE: 'Libro de Intereses Acumulados -> Activos',
  AP_BANK_ACCOUNT_LEDGER_AMOUNT_TYPE: 'Libro de Cuenta Bancaria de AP -> Activos',
  AR_BANK_ACCOUNT_LEDGER_AMOUNT_TYPE: 'Libro de Cuenta Bancaria de AR -> Activos',
  FEES_LEDGER_AMOUNT_TYPE: 'Libro de Comisiones -> Ingresos',
  ESCROW_LEDGER_AMOUNT_TYPE: 'Libro de Fideicomiso -> Pasivo',
  CASH_RESERVE_LEDGER_AMOUNT_TYPE: 'Libro de Reserva de Efectivo -> Pasivo',
  LIABILITY_LEDGER_AMOUNT_TYPE: 'Libro de Pasivo -> Pasivo',
  UNAPPLIED_LEDGER_AMOUNT_TYPE: 'Libro de No Aplicado -> Pasivo',
  MAIN_ENTRY: 'Entrada Principal',
  // END Michael
  // BEGIN Fco
  ACCRUED_FEE: 'Comisiones Acumuladas',
  ACCRUED_INTEREST: 'Intereses Acumulados',
  INCREASE: 'Incremento',
  DECREASE: 'Decremento',
  // END Fco
  // BEGIN Fabian
  DATE_START: 'Fecha Inicio',
  DATE_END: 'Fecha fin',
  DESCRIPTION: 'Descripción',
  CREDIT: 'Crédito',
  DEBIT: 'Débito',
  OPEN_AR: 'Open AR',
  NFE: 'NFE',
  TITLE_TYPE_AR: 'Libros {title}',
  LEDGERS: 'Libros',
  LEDGER: 'Libro',
  LIABILITY: 'Pasivo',
  INCREASE_OR_DECREASE: 'Débito o Crédito',
  LEDGER_TYPE: 'Tipo de Libro',
  CREATE_MANUAL_TRANSACTION: 'Crear transacción manual',
  OFFSETTING_ENTRY: 'Entrada de Compensación',
  MANUAL_TRANSACTION: 'Transacción Manual',
  MANUAL_TRANSACTION_DELETE: 'Transacción manual eliminada con éxito',
  MANUAL_TRANSACTION_CREATED_SUCCESSFULLY: 'Transacción manual creada con éxito',
  ACTIVE: 'Activo',
  // END Fabian
  //BEGIN Esteban
  GL_CODE: 'GL Code',
  FUEL_ADVANCE: 'Avance por Combustible',
  //END Esteban

  // BEGIN Patricio
  PAYMENT_TRANSACTION_REF: 'Referencia Pago',
  LEDGER_EXPORT_CONFIRMATION: `Estás intentando exportar {totalRows} filas de datos, esta acción tomará algo de tiempo en completarse, ¿deseas continuar?`,
  LEDGER_EXPORT: 'Exportar Datos de Libro',
  //END Patricio
}

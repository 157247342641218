export const gmailEn = {
  UNLINK: 'Unlink',
  ACCOUNT_UNLINK_SUCCESSFULLY: 'Account unlinked successfully',
  ALLOW_TO_USE_YOUR_EMAIL_ADDRESS: 'Allow to use your email address',
  ALLOW: 'Allow',
  SEND_EMAIL_TEST: 'Send email test to this email address',
  LINKED_ACCOUNT: 'Linked account',
  SEND: 'Send',
  GMAIL_ERROR: 'There is a problem with your linked account, please unlink and try again',
}

export const gmailEs: typeof gmailEn = {
  UNLINK: 'Desvincular',
  ACCOUNT_UNLINK_SUCCESSFULLY: 'Cuenta desvinculada correctamente',
  ALLOW_TO_USE_YOUR_EMAIL_ADDRESS: 'Permitir usar tu dirección de correo electrónico',
  ALLOW: 'Permitir',
  SEND_EMAIL_TEST: 'Enviar correo de prueba a esta dirección de correo',
  LINKED_ACCOUNT: 'Cuenta vinculada',
  SEND: 'Enviar',
  GMAIL_ERROR: 'Hay un problema con tu cuenta vinculada, desvincula e intenta de nuevo',
}

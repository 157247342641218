import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const TermsListPage = lazy(() => import('pages/terms/page'))
const TermPreviewPage = lazy(() => import('pages/terms/[termId]/page'))

const TermsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<TermsListPage />} />} />
      <Route path="/:termName/:termId" element={<LoaderPage content={<TermPreviewPage />} />} />
    </Routes>
  )
}

export default TermsRoutes

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const PaymentsListPage = lazy(() => import('pages/payments/page'))

const PaymentsPreviewPage = lazy(() => import('pages/payments/[paymentId]/page'))

const PaymentsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<PaymentsListPage />} />} />
      <Route path="/:transactionRef/:paymentId" element={<LoaderPage content={<PaymentsPreviewPage />} />} />
    </Routes>
  )
}

export default PaymentsRoutes

import { isDefined } from 'tools/common'
import { UserSessionContextState } from './UserSessionContext'

export type UserSessionReducerActions = UserSessionContextState & {
  type:
    | 'setUserInfo'
    | 'setPermissions'
    | 'setToken'
    | 'clearSession'
    | 'loadToken'
    | 'loadFirstToken'
    | 'setFirstToken'
    | 'setFactorTimezone'
}

export function saveToken(token?: string | null) {
  if (!isDefined(token)) return
  localStorage.setItem('factorcloud_token', token)
}

export function saveFirstToken(token?: string | null) {
  if (!isDefined(token)) return
  localStorage.setItem('factorcloud_first_token', token)
}

export function loadToken() {
  return localStorage.getItem('factorcloud_token')
}

export function loadFirstToken() {
  return localStorage.getItem('factorcloud_first_token')
}

export function userSessionReducer(state: UserSessionContextState, action: UserSessionReducerActions) {
  const newState = { ...state }
  if (action.type === 'setUserInfo') {
    newState.userInfo = action.userInfo
  } else if (action.type === 'setPermissions') {
    newState.permissions = action.permissions
  } else if (action.type === 'setToken') {
    newState.token = action.token
    saveToken(action.token)
  } else if (action.type === 'loadToken') {
    const token = loadToken()
    newState.token = token
  } else if (action.type === 'loadFirstToken') {
    const token = loadFirstToken()
    newState.firstToken = token
  } else if (action.type === 'setFirstToken') {
    newState.firstToken = action.firstToken
    saveFirstToken(action.firstToken)
  } else if (action.type === 'setFactorTimezone') {
    newState.factorTimezone = action.factorTimezone
  }
  else if (action.type === 'clearSession') {
    return {
      token: null,
    }
  }
  return newState
}

export const emailHistoryEn = {
  CLIENT_REMITTANCE: 'Client Remittance',
  NOA: 'NOA',
  BILLING: 'Billing',
  BROKER_REMITTANCE: 'Broker Remittance',
  VENDOR_REMITTANCE: 'Vendor Remittance',
  STATEMENT: 'Statement',
  UPDATE_RELATIONSHIP_REQUEST: 'Update Relationship Request',
  UPDATE_DEBTOR_REQUEST: 'Update Debtor Request',
  SEND_DATE: 'Send Date',
  SEND_TYPE: 'Send Type',
  EMAIL_HISTORY: 'Email History',
  EMAIL_HISTORY_DETAIL: 'Email History Detail',
  SENT_BY_USER: 'Sent by user',
  SENT: 'Sent',
  FAILED: 'Failed',
  SYSTEM: 'System',
  NOT_HISTORY_ASSOCIATED: 'The mail currently has no history associated with it.',
  //BEGIN MICHAEL
  SCHEDULE: 'Schedule',
  SCHEDULED: 'Scheduled',
  //END MICHAEL
}

export const emailHistoryEs: typeof emailHistoryEn = {
  CLIENT_REMITTANCE: 'Remesa del cliente',
  NOA: 'NOA',
  BILLING: 'Facturación',
  BROKER_REMITTANCE: 'Remesa del corredor',
  VENDOR_REMITTANCE: 'Remesa del proveedor',
  STATEMENT: 'Declaración',
  UPDATE_RELATIONSHIP_REQUEST: 'Solicitud de actualización de relación',
  UPDATE_DEBTOR_REQUEST: 'Solicitud de actualización del deudor',
  SEND_DATE: 'Fecha de envío',
  SEND_TYPE: 'Tipo de envío',
  EMAIL_HISTORY: 'Historial de correo electrónico',
  EMAIL_HISTORY_DETAIL: 'Detalle del historial de correo',
  SENT_BY_USER: 'Enviado por el usuario',
  SENT: 'Enviado',
  FAILED: 'Fallido',
  SYSTEM: 'Sistema',
  NOT_HISTORY_ASSOCIATED: 'El correo actualmente no tiene un historial asociado.',
  //BEGIN MICHAEL
  SCHEDULE: 'Programar',
  SCHEDULED: 'Programado',
  //END MICHAEL
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMsg } from 'App'
import { exchangeTankCode } from 'api/tank'
import { Toast as toast } from 'components/ui'
import { qkBaseFactor } from 'constants/QueryKeys/factor'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TankExchangeRequest } from 'types/tank'

const url = new URL(window.location.href)
const redirectUri = url.origin + '/auth/tank/callback'

const TankCallback = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const state = params.get('state')

  const payload: TankExchangeRequest = {
    auth_code: code as string,
    state: state as string,
    redirect_uri: redirectUri,
  }

  const { mutate } = useMutation({
    mutationFn: () => {
      return exchangeTankCode(payload)
    },
    onSuccess: (data) => {
      const isAuthorized = data.is_authorized === 1
      if (!isAuthorized) {
        toast({
          type: 'error',
          subTitle: 'Authorization code not found.',
        })
      } else {
        toast({
          type: 'success',
          subTitle: 'Access Token Stored Successfully!',
        })
      }
      queryClient.invalidateQueries(qkBaseFactor)
    },
    onError: (error) => {
      toast({
        type: 'error',
        subTitle: errorMsg(error),
      })
    },
  })

  useEffect(() => {
    mutate()
    navigate('/settings/adminSettings')
  }, [navigate, mutate])

  return <></>
}

export default TankCallback

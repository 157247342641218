import { useState, useEffect, FormEvent } from 'react'
import { Container, Toast as toast, PasswordValidator } from 'components/ui'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { changePassword } from 'api/login'
import { TextField, Button } from 'components/form'
import { ObjectStrings } from 'interfaces'
import strings from 'l10n'
import { useMutation } from '@tanstack/react-query'
import { ChangePasswordRequest } from 'types/login'
import { loadToken } from 'context/UserSession/userSessionReducer'

/**
 * Reset Password link with token to access locallt
 * http://localhost:3000/reset-password?token=3cd3f659-bb31-46f5-b836-fc36d0b5b743
 */

type ResetPasswordFormProps = {
  styles: ObjectStrings
  handleStep: (val: number) => void
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { styles, handleStep } = props
  const [formValues, setFormValues] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [error, setError] = useState<string>()
  const [error2, setError2] = useState<string>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const resetToken: string | null = searchParams.get('token')

  const { newPassword, confirmPassword } = formValues

  useEffect(() => {
    if (loadToken()) {
      localStorage.clear()
      window.location.reload()
    }
  }, [resetToken])

  const handleFormChange = (e: React.ChangeEvent<any>, name: string) => {
    setFormValues({
      ...formValues,
      [name]: e.target.value,
    })
  }

  const { mutate: changePasswordMutation, isLoading } = useMutation({
    mutationFn: (data: ChangePasswordRequest) => {
      return changePassword(data)
    },
    onSuccess: () => {
      toast({
        title: `${strings.PASSWORD_RESET_SUCCESSFULLY}`,
        subTitle: `${strings.PASSWORD_RESET_FEEDBACK}`,
      })
      handleStep(0)
      navigate('/login')
    },
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (error2) {
      return
    }
    if (formValues.newPassword === formValues.confirmPassword && resetToken && !error2) {
      const payload: ChangePasswordRequest = {
        token: resetToken,
        newPassword: formValues.newPassword,
      }
      changePasswordMutation(payload)
    } else {
      setError(`${strings.PASSWORDS_DONT_MATCH}`)
    }
  }

  const handlePassValidation = (bool: boolean) => {
    if (bool) {
      setError2('')
    } else {
      setError2(`${strings.PASSWORD_RESET_FAILURE}`)
    }
  }

  return (
    <form onSubmit={onSubmit} id="resetPasswordForm">
      <Container className={styles.formContainer}>
        <PasswordValidator password={formValues.newPassword} isValid={handlePassValidation}>
          <TextField
            id="newPasswordResetPasswordForm"
            label={strings.NEW_PASSWORD}
            onChange={(e) => handleFormChange(e, 'newPassword')}
            className={styles.fullWidth}
            helperText={error2}
            error={Boolean(error2)}
            showPasswordIcon
          />
        </PasswordValidator>
        <TextField
          id="confirmPasswordResetPasswordForm"
          label={strings.CONFIRM_PASSWORD}
          onChange={(e) => handleFormChange(e, 'confirmPassword')}
          helperText={error}
          error={Boolean(error)}
          showPasswordIcon
        />
        <Container className={styles.buttonContainer}>
          <Button
            id="resetPasswordButton"
            type="submit"
            disabled={newPassword === '' || confirmPassword === ''}
            variant="contained"
            label={strings.RESET_PASSWORD}
            loading={isLoading}
          />
        </Container>
      </Container>
    </form>
  )
}

export default ResetPasswordForm

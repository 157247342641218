import strings from 'l10n'
import { forEach, kebabCase } from 'lodash'
import sha256 from 'crypto-js/sha256'
import { loadFirstToken, loadToken } from 'context/UserSession/userSessionReducer'
import { StringMap } from 'types/common'
import { TabData } from 'components/ui/navigation/Tabs'
import { GridColDef } from '@mui/x-data-grid'
import { AutocompleteInfo, AutocompleteResponse } from 'types/autocomplete'
import regex from './regex'
import {
  ACCRUED_FEES_KEY,
  ACCRUED_INTEREST_KEY,
  AP_BANK_ACCOUNT_KEY,
  AR_BANK_ACCOUNT_KEY,
  CASH_RESERVE_KEY,
  ESCROW_RESERVE_KEY,
  FEES_KEY,
  FUEL_ADVANCE_KEY,
  LIABILITY_KEY,
  OPEN_AR_KEY,
  UNAPPLIED_KEY,
} from 'constants/ledgerNameKeys'
import { toDecPercent } from './format'

export function minValueLimit(value: number, text?: string) {
  const message =
    text ||
    `${strings.formatString(strings.NUMBER_MUST_BE_GREATER_EQUALS_THAN, {
      number: value,
    })}`
  return {
    value: value,
    message: message,
  }
}

export function maxValueLimit(value?: number | string | null, text?: string) {
  if (!isDefined(value) || value === '') return undefined
  const message =
    text ||
    `${strings.formatString(strings.NUMBER_MUST_BE_LESS_EQUALS_THAN, {
      number: value,
    })}`

  return {
    value: value,
    message: message,
  }
}

export const minDateLimit = (date: string, current: boolean) => {
  let showDate = date
  if (current) {
    showDate = strings.CURRENT_DATE.toLowerCase()
  }
  return {
    value: date,
    message: `${strings.formatString(strings.DATE_CANNON_BE_EARLIER_THAN, {
      date: showDate,
    })}`,
  }
}

export const isDateBeforeToday = (date: string) => {
  return new Date(date) < new Date()
}

export function getCurrentToken() {
  const firstToken = loadFirstToken()
  const loginToken = loadToken()
  const currentToken = firstToken ? firstToken : loginToken
  return currentToken
}

export function getTokenContent(token: string | null) {
  const base64Url = token?.split('.')[1]
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')
  const tokenContent = JSON.parse(atob(base64 ?? ''))
  return tokenContent
}

export function getDocumentDownloadUrl(fileUrl: string, params: StringMap = {}) {
  const jwtToken = loadToken()
  const factorId = getTokenContent(jwtToken).factorId

  if (factorId) params['factorId'] = factorId
  if (jwtToken) params['authToken'] = jwtToken

  const url = new URL(fileUrl)
  forEach(params, (value, key) => {
    url.searchParams.append(key, value)
  })
  return url.toString()
}

export function getDisbursementDownloadUrl(fileUrl: string, disbursementId: string) {
  return getDocumentDownloadUrl(fileUrl, { disbursementId })
}

export function triggerDisbursementDownload(fileUrl: string, disbursementId: string) {
  const url = getDisbursementDownloadUrl(fileUrl, disbursementId)
  window.open(url, '_blank')
}

export function triggerFactorcloudDocumentDownload(fileUrl: string, params: StringMap = {}) {
  const url = getDocumentDownloadUrl(fileUrl, params)
  window.open(url, '_blank')
}

export function slugify(arg: string) {
  return kebabCase(arg)
}

export function hash(arg: any) {
  const str = JSON.stringify(arg)
  const hash = sha256(str).toString()
  return hash
}

/**
 * Returns true is val is not null nor undefined
 * @param arg to check
 * @returns boolean result
 */
export function isDefined<T>(arg: T | null | undefined): arg is T {
  return typeof arg != 'undefined' && arg !== null
}

/**
 * Returns true if arg is null, undefined or ""
 * @param arg to check
 * @returns boolean result
 */
export function empty(arg: string | null | undefined) {
  return isDefined(arg) !== true || arg === ''
}

/**
 * Returns val if condition is True. Return undefined otherwise
 * @param arg to be returned
 * @param condition to check
 * @returns val or undefined
 */
export function ift<T>(arg: T, condition: boolean) {
  if (condition) return arg
}

export function formatDate(invoiceDate: string) {
  const currentDate = new Date().toISOString().split('T')[1]
  return invoiceDate.concat('T').concat(currentDate)
}

export function isUpdatedPaymentDate(invoiceDate: string, dataInvoiceDate: string) {
  if (!invoiceDate) {
    return dataInvoiceDate ? formatDate(dataInvoiceDate) : undefined
  }

  return invoiceDate.split('T')[0] === dataInvoiceDate
    ? invoiceDate
    : dataInvoiceDate
    ? formatDate(dataInvoiceDate)
    : undefined
}

export function minLengthLimit(value: number, text?: string) {
  const message =
    text ||
    `${strings.formatString(strings.TEXT_MUST_BE_GREATER_THAN, {
      number: value,
    })}`
  return {
    value: value,
    message: message,
  }
}

export function maxLengthLimit(value: number, text?: string) {
  const message =
    text ||
    `${strings.formatString(strings.TEXT_MUST_BE_LESS_THAN, {
      number: value,
    })}`

  return {
    value: value,
    message: message,
  }
}

export function isBlank(value: string | null) {
  return value === null || value.trim() === ''
}

export function isNotBlank(value?: string | null) {
  return isDefined(value) && !isBlank(value)
}

/**
 * Review the two values and return the first one that is not null.
 * @param value1 or @param value2 to be returned
 * @returns the corresponding value or undefined
 */
export function getNotNullValue<T>(value1: T, value2: T) {
  return isDefined(value1) ? value1 : isDefined(value2) ? value2 : undefined
}

export function getTabIndex(tabs: TabData[], value: String) {
  return tabs.findIndex((tab) => tab.label === value)
}

/**
 * Returns the array of columns without the columns that are in the columnFieldNames array
 * @param array of columns to check @param columnFieldNames to be removed from @param array
 * @returns the filtered array of columns
 */

export function removeColumnFromTable(array: GridColDef[], columnFieldNames: String[]) {
  let newArray = array
  columnFieldNames.forEach((columnFieldName) => {
    newArray = newArray.filter((column: GridColDef) => column.field !== columnFieldName)
  })
  return newArray
}

export function updateSelectedElements(selected: any, prevSelected: any, selectedIds?: string[]) {
  //Remove from prev if the element is not selected. For this we compare the ids on array. If the element is not in the ids array, we remove it from prev.
  const newPrev =
    prevSelected.length > 0 ? prevSelected.filter((element: any) => selectedIds?.includes(element.id)) : []
  /*Add only the elements that are not in prev. The Table only return the selected rows per page. So, if you back to a previous page,
    the table send the previous selected rows again. We need to filter the elements that are already in prev.*/
  const newSelected = selected.filter(
    (element: any) => !prevSelected.find((prevElement: any) => prevElement.id === element.id)
  )
  //Concat the new prev and new selected invoices to get the final selected invoices
  const finalSelectedElements = [...newPrev, ...newSelected]
  return finalSelectedElements
}

export function buildUri(...components: string[]) {
  return components.map((c) => encodeURIComponent(c)).join('/')
}

export function getNextDayFromDate(dateString: string) {
  const currentDate = new Date(dateString.split('T')[0])
  const nextDay = new Date(currentDate)
  nextDay.setDate(nextDay.getDate() + 1)
  return nextDay.toISOString().split('T')[0]
}

export function getAutocompleteListValues(value?: AutocompleteResponse) {
  return value?.data.map((item: AutocompleteInfo) => {
    return {
      key: item.id,
      name: item.name,
      value: item.nInvoices,
      companyType: item.companyType,
    }
  })
}

export function handleZipLabel(countryCode?: string) {
  if (countryCode === 'US' || isDefined(countryCode) === false) {
    return strings.ZIP
  }
  return strings.POSTAL_CODE
}

export function environment() {
  const apiUrl = process.env.REACT_APP_API_URL
  if (apiUrl?.includes('api.dev')) return 'DEVELOPMENT'
  if (apiUrl?.includes('api.int')) return 'SANDBOX'
  if (apiUrl?.includes('api.app')) return 'PRODUCTION'
  if (apiUrl?.includes('localhost')) return 'LOCAL'
  return ''
}

export function handleZipPostalCodeLength(countryCode?: string) {
  if (countryCode === 'CA' || isDefined(countryCode) === false) {
    return 7
  }
  return 10
}

export function handleZipLabelMaxLengthMessage(countryCode?: string) {
  if (countryCode === 'CA' || isDefined(countryCode) === false) {
    return strings.MAX_LENGTH_POSTAL_CODE
  }
  return strings.MAX_LENGTH_ZIP_CODE
}

export function handleZipCodeRegex(countryCode?: string) {
  switch (countryCode) {
    case 'CA':
      return regex.POSTAL_CODE_REGEX_PATTERN_CANADA
    case 'UK':
      return regex.POSTAL_CODE_REGEX_PATTERN_UK
    case 'MX':
      return regex.POSTAL_CODE_REGEX_PATTERN_MEXICO
    case 'AU':
      return regex.POSTAL_CODE_REGEX_PATTERN_AUSTRALIA
    case 'US':
      return regex.ZIP_CODE_REGEX_PATTERN
    default:
      return regex.POSTAL_ANY_CHARACTERS
  }
}

export function handleRegexErrorMessage(countryCode?: string) {
  if (isDefined(countryCode) && countryCode !== 'US') {
    return strings.INVALID_POSTAL_CODE
  }
  return strings.INVALID_ZIP_CODE
}

export function getIndexOfColumn(column: string, columns: GridColDef[]) {
  return columns.findIndex((col) => col.field === column)
}

export function isClient(companyClientOrBrokerId: any, clientBrokerState?: AutocompleteResponse) {
  let isClient = true
  const clientBrokerList = clientBrokerState?.data
  const clientBroker = clientBrokerList?.find((item) => {
    if (item.id === companyClientOrBrokerId) {
      return item
    }
    return undefined
  })
  if (clientBroker?.companyType === 'BROKER') isClient = false
  return isClient
}

export function isBrokerOrClient(companyClientOrBrokerId: string, clientBrokerState?: AutocompleteResponse) {
  let companyType: 'CLIENT' | 'BROKER' | '' = ''
  const clientBrokerList = clientBrokerState?.data
  const clientBroker = clientBrokerList?.find((item) => item.id === companyClientOrBrokerId)
  if (clientBroker?.companyType === 'BROKER') {
    companyType = 'BROKER'
  }
  if (clientBroker?.companyType === 'CLIENT') {
    companyType = 'CLIENT'
  }
  return companyType
}

export const getLedgerKey = (ledgerType: string) => {
  if (!isDefined(ledgerType) || isBlank(ledgerType)) return ''
  switch (ledgerType) {
    case 'AR':
      return OPEN_AR_KEY
    case 'FEE':
      return FEES_KEY
    case 'ACCRUED_FEE':
      return ACCRUED_FEES_KEY
    case 'ESCROW':
      return ESCROW_RESERVE_KEY
    case 'CASH_RESERVE':
      return CASH_RESERVE_KEY
    case 'ACCRUED_INTEREST':
      return ACCRUED_INTEREST_KEY
    case 'AP_BANK_ACCOUNT':
      return AP_BANK_ACCOUNT_KEY
    case 'AR_BANK_ACCOUNT':
      return AR_BANK_ACCOUNT_KEY
    case 'LIABILITY':
      return LIABILITY_KEY
    case 'FUEL_ADVANCE':
      return FUEL_ADVANCE_KEY
    case 'UNAPPLIED':
      return UNAPPLIED_KEY
    default:
      return ''
  }
}

export const getCreditOrDebitByLedgerType = (amountType: string, ledgerNameType?: string) => {
  if (!isDefined(amountType) || isBlank(amountType)) return ''
  const debit = strings.DEBIT.toUpperCase()
  const credit = strings.CREDIT.toUpperCase()
  switch (ledgerNameType) {
    case 'ASSETS':
      return amountType === 'INCREASE' ? debit : credit
    case 'REVENUE':
      return amountType === 'INCREASE' ? credit : debit
    case 'LIABILITY':
      return amountType === 'INCREASE' ? credit : debit
    default:
      return ''
  }
}

export const getAmountTypeByLedgerType = (creditDebit: string, LedgerNameType?: string) => {
  if (!isDefined(creditDebit) || isBlank(creditDebit)) return ''
  const debit = strings.DEBIT.toUpperCase()
  switch (LedgerNameType) {
    case 'ASSETS':
      return creditDebit === debit ? 'INCREASE' : 'DECREASE'
    case 'REVENUE':
      return creditDebit === debit ? 'DECREASE' : 'INCREASE'
    case 'LIABILITY':
      return creditDebit === debit ? 'DECREASE' : 'INCREASE'
    default:
      return ''
  }
}

export const toNumber = (val: string | number) => {
  if (typeof val === 'number') return val
  return parseFloat(val)
}

export function getAutocompleteListValuesReserveRelease(value?: AutocompleteResponse) {
  return value?.data.map((item: AutocompleteInfo) => {
    return {
      key: item.id,
      name: item.name,
      value: item.nReserveReleases,
      companyType: item.companyType,
    }
  })
}

// This function is used to get the value from the form and convert it to a number or null (If the null reset the value)
// Used by TextFormField number type
export const parseNumericValueFromForm = (value?: number | string): number | null => {
  let safeValue = null
  if (isDefined(value) && value !== '') {
    safeValue = Number(value)
  }
  return safeValue
}

// This function is used to get the value from the form and convert it to a boolean or null (If the null reset the value).
// Used by DropdownFormField
export const parseBooleanValueFromForm = (value?: string): boolean | null => {
  let safeValue = null
  if (isDefined(value) && value !== '') {
    safeValue = value === '1'
  }
  return safeValue
}

// This function is used to get the value from the form and convert it to a percentage or null (If the null reset the value).
// Used by TextFormField number type with percentage
export const parsePercentageValueFromForm = (value?: number | string): number | null => {
  let safeValue = null
  if (isDefined(value) && value !== '') {
    safeValue = toDecPercent(value)
    return Number(safeValue)
  }
  return safeValue
}

// This function is used to get the value from BE and convert to YES, NO or '' if the dropdown has an empty option
export const getValueForDropdownWithEmptyOption = (value?: boolean) => {
  if (isDefined(value)) {
    return value ? '1' : '0'
  }
  return ''
}

/**
 * Maps a min/max number filter object to the corresponding states to be sent to the API
 * @param rangeList one list with two values, [0] is for the min, [1] is for the max
 * @param setMin the function to set the min value in state
 * @param setMax the function to set the max value in state
 */
export const mapMinMaxFilterToFilterStates = (
  rangeList: [number | undefined, number | undefined],
  setMin: (amount: number | undefined) => void,
  setMax: (amount: number | undefined) => void
) => {
  if (rangeList) {
    rangeList[0] === undefined ? setMin(undefined) : setMin(rangeList[0])
    rangeList[1] === undefined ? setMax(undefined) : setMax(rangeList[1])
  } else {
    setMin(undefined)
    setMax(undefined)
  }
}

export const handleStateLabel = (stateType?: string) => {
  switch (stateType) {
    case 'PROVINCE':
      return strings.PROVINCE
    case 'REGION':
      return strings.REGION
    case 'DISTRICT':
      return strings.DISTRICT
    default:
      return strings.STATE
  }
}

export function getErrorsFromErrorResponse(error: any): string[] {
  if (isDefined(error)) {
    if (isDefined(error.response)) {
      if (isDefined(error.response.data)) {
        return error.response.data.errors
      }
    }
  }
  return []
}

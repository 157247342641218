import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import styles from './SidebarFilter.module.scss'

type SimplifyData = {
  key?: string
  name: string
  value: number | string
}

type SidebarFilterProps = {
  data?: SimplifyData[]
  className?: string
  handleClick: (value: string) => void
  selectedRow: string
  selectedRowClassName?: string
}

const SidebarFilter = (props: SidebarFilterProps) => {
  const { data, handleClick, className = '', selectedRowClassName = '', selectedRow } = props

  const handleClickRow = (value: string = '') => {
    handleClick(value)
  }

  const getSelectedRowClassName = () => {
    return selectedRowClassName || `selected ${styles.selectedRow}`
  }

  return (
    <TableContainer className={`${className} ${styles.tableContainer}`}>
      <Table aria-label="simple table">
        <TableBody className={styles.tableBody}>
          {data?.map((item) => {
            const selected = selectedRow === item.name
            const className = selected ? getSelectedRowClassName() : styles.tableRow
            return (
              <TableRow key={item.name} className={className} onClick={() => handleClickRow(item.name)}>
                <TableCell component="th" scope="row" className={styles.tableCell}>
                  <p className={styles.nameCell}>{item.name}</p>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SidebarFilter

export const disbursementsEn = {
  //BEGIN Michael
  DISBURSEMENT: 'Disbursement',
  DISBURSEMENTS: 'Disbursements',

  PAYMENT_METHOD: 'Payment Method',
  PAYMENT_METHODS: 'Payment Methods',
  TITLE_TYPE_PAYMENT_DISBURSEMENT: '{type_payment}: Funded',
  NOT_BATCHES_FOUND: 'No batches found',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  NUMBER_OF_FUEL_ADVANCES: 'Number of Fuel Advances',
  MONEY_CODE: 'MONEY CODE',
  //END Fabian

  // BEGIN Patricio
  BATCH_ID: 'Batch ID',
  BATCHES: 'Batches',
  DATE_OF_FUNDING: 'Date of Funding',
  NUMBER_OF_COMPANIES: 'Number of Companies',
  TOTAL_RESERVES: 'Total Reserves',
  TOTAL_BATCH_AMOUNT: 'Total Batch Amount',
  TOTAL_FEES: 'Total Fees',
  // END Patricio
}

export const disbursementsEs: typeof disbursementsEn = {
  //BEGIN Michael
  DISBURSEMENT: 'Desembolso',
  DISBURSEMENTS: 'Desembolsos',

  PAYMENT_METHOD: 'Método de pago',
  PAYMENT_METHODS: 'Métodos de pago',

  TITLE_TYPE_PAYMENT_DISBURSEMENT: 'Desembolsos: {type_payment}',
  NOT_BATCHES_FOUND: 'No se encontraron lotes',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  NUMBER_OF_FUEL_ADVANCES: 'Número de anticipos de combustible',
  MONEY_CODE: 'Código de Dinero',
  //END Fabian

  // BEGIN Patricio
  BATCH_ID: 'ID Lote',
  BATCHES: 'Lotes',
  DATE_OF_FUNDING: 'Fecha de Desembolso',
  NUMBER_OF_COMPANIES: 'Número de Compañías',
  TOTAL_RESERVES: 'Reservas Totales',
  TOTAL_BATCH_AMOUNT: 'Monto Total del Lote',
  TOTAL_FEES: 'Total de Comisiones',
  // END Patricio
}

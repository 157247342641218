import { ReactNode } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TypoGraph from 'components/ui/dataDisplay/TypoGraph'
import TextField, { TextFieldProps } from './TextField'
import { DropDownOptions } from 'types/common'

export type DropdownFieldProps = TextFieldProps & {
  /** The option is used to pass an array of objects that represent the options displayed in the dropdown menu. 
  Each object within the array must have two properties: label and value. */
  options?: DropDownOptions[]
  /** The notOptionText is a optional props to display a custom message when not have options. */
  notOptionText?: string
  menuNode?: ReactNode
  endAdornment?: ReactNode
}

const DropdownField = (props: DropdownFieldProps) => {
  const { options = [], notOptionText, menuNode } = props
  return (
    <TextField select={true} {...props}>
      {options?.length > 0 ? (
        options.map((option) => (
          <MenuItem id={option.id} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))
      ) : (
        <TypoGraph content={notOptionText ? notOptionText : 'No options found'} align="center" />
      )}
      {menuNode && menuNode}
    </TextField>
  )
}

export default DropdownField

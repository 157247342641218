import { TankStatusResponse } from 'types/integration'
import request from 'tools/request'
import { TankExchangeRequest } from 'types/tank'

const API_URL = process.env.REACT_APP_FC_INTEGRATION_API_URL

export const exchangeTankCode = async (payload: TankExchangeRequest) => {
  const data = await request
    .post<TankStatusResponse>(`${API_URL}/integration/tank/exchange`, payload, {})
    .then((res) => {
      return res.data
    })
  return data
}

import request from 'tools/request'
import { FactorDefaultRequest, FactorDefaultResponse } from 'types/factorDefault'

export const getFactorDefaults = async () => {
  const data = await request.get<FactorDefaultResponse>('/factor-defaults').then((res) => res.data.factorDefault)
  return data
}

export const updateFactorDefaultValues = async (payload: FactorDefaultRequest) => {
  const data = await request
    .put<FactorDefaultResponse>('/factor-defaults', payload)
    .then((res) => res.data.factorDefault)
  return data
}

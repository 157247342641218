export const profileEn = {
  // BEGIN Michael
  PROFILE: 'Profile',
  PROFILE_SETTINGS: 'Profile Settings',
  UPDATE_PASSWORD: 'Update Password',
  // END Michael

  // BEGIN Fco
  PROFILE_INFO: 'Profile Info',
  // END Fco

  // BEGIN Fabian
  UPDATE_EMAIL: 'Update Email',
  CURRENT_EMAIL: 'Current Email',
  NEW_EMAIL: 'New Email',
  EMAIL_NOTIFICATIONS: 'Email Notifications',
  RECEIVE_EMAIL_NOTIFICATIONS: 'Receive email notifications from FactorCloud',
  UPDATE_PHONE: 'Update Phone',
  CURRENT_PHONE: 'Current Phone',
  NEW_PHONE: 'New Phone',
  TEXT_NOTIFICATIONS: 'Text Notifications',
  RECEIVE_TEXT_NOTIFICATIONS: 'Receive text notifications from FactorCloud',
  PHONE_FORMAT: 'Phone format should be XXX-XXX-XXXX',
  // END Fabian
  OPTIONAL_FEATURES: 'Optional Features',
  FUEL_ADVANCE_DESCRIPTION: 'Enable or disable Fuel Advance',
  SET_FREQUENCY: 'Set the frequency FactorCloud will charge accrued interest to clients.',
  AUTOCHARGE_CLIENT_INTEREST: 'Autocharge Client Interest',
  FUNDING_CSV: 'Funding CSV',
}

export const profileEs: typeof profileEn = {
  // BEGIN Michael
  PROFILE: 'Perfil',
  PROFILE_SETTINGS: 'Configuración de Perfil',
  UPDATE_PASSWORD: 'Actualizar Contraseña',
  // END Michael

  // BEGIN Fco
  PROFILE_INFO: 'Información de Perfil',
  // END Fco

  // BEGIN Fabian
  UPDATE_EMAIL: 'Actualizar Email',
  CURRENT_EMAIL: 'Email Actual',
  NEW_EMAIL: 'Nuevo Email',
  EMAIL_NOTIFICATIONS: 'Notificaciones por Email',
  RECEIVE_EMAIL_NOTIFICATIONS: 'Recibir notificaciones por email de FactorCloud',
  UPDATE_PHONE: 'Actualizar Teléfono',
  CURRENT_PHONE: 'Teléfono Actual',
  NEW_PHONE: 'Nuevo Teléfono',
  TEXT_NOTIFICATIONS: 'Notificaciones por Texto',
  RECEIVE_TEXT_NOTIFICATIONS: 'Recibir notificaciones por texto de FactorCloud',
  PHONE_FORMAT: 'El formato del teléfono debe ser XXX-XXX-XXXX',
  // END Fabian
  OPTIONAL_FEATURES: 'Características Opcionales',
  FUEL_ADVANCE_DESCRIPTION: 'Habilitar o Deshabilitar Fuel Advance',
  SET_FREQUENCY: 'Establezca la frecuencia con la que FactorCloud cobrará intereses devengados a los clientes.',
  AUTOCHARGE_CLIENT_INTEREST: 'Autocarga de Interés para Cliente',
  FUNDING_CSV: 'CSV de Financiamiento',
}

import { ReactNode } from 'react'
import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

type CardProps = {
  /** The children is used to render any child components within theCardContent component.*/
  children?: ReactNode
  /** The raised determines whether the card should have a raised appearance or not.*/
  raised?: boolean
  /** The classes is used to pass in any custom CSS classes to the Card component.*/
  classes?: object
  /** The sxContainer is used to pass in any custom styles to the container of the card.*/
  sxContainer?: object
  /** The sxContent is used to pass in any custom styles to the content of the card.*/
  sxContent?: object
  /** The onClick is used to attach an event handler function to the Card component. When the Card component is clicked, the function specified in the onClick prop will be executed.*/
  onClick?: any
  className?: string
}

const Card = (props: CardProps) => {
  const { children, raised = false, classes, sxContainer, sxContent, onClick, className } = props
  return (
    <MuiCard
      sx={{
        border: '1px solid #E9E9E9',
        boxShadow: '1px 5px 16px rgb(0 0 0 / 3%)',
        borderRadius: '14px',
        ...sxContainer,
      }}
      classes={classes}
      raised={raised}
      onClick={onClick}
      className={className}
    >
      <CardContent sx={sxContent}>{children}</CardContent>
    </MuiCard>
  )
}

export default Card

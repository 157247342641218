export const accountingEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  ACCOUNTING: 'Accounting',
  JOURNAL_ENTRY: 'Journal Entry',
  START_BALANCE: 'Beginning Balance',
  END_BALANCE: 'Ending Balance',
  TOTAL_CREDIT: 'Total Credit',
  TOTAL_DEBIT: 'Total Debit',
  BALANCE_CHANGE: 'Balance Change',
  PORTFOLIO: 'Portfolio',
  // END Fabian

  // BEGIN Patricio
  ACCOUNTING_EXPORT_CONFIRMATION: 'Do you want to export the accounting data to CSV?',
  ACCOUNTING_EXPORT: 'Export Accounting Data',

  IFF_EXPORT_CONFIRMATION: 'Do you want to export the accounting data to IIF?',
  IFF_EXPORT: 'Export to IIF',
  // END Patricio
  // BEGIN Esteban
  ACCOUNTING_DETAILS: 'Accounting Details',
  ACCOUNTING_DETAILS_EXPORT_CONFIRMATION: 'Do you want to export the accounting details to CSV?',
  // END Esteban
}

export const accountingEs: typeof accountingEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  ACCOUNTING: 'Contabilidad',
  JOURNAL_ENTRY: 'Asiento contable',
  START_BALANCE: 'Saldo inicial',
  END_BALANCE: 'Saldo final',
  TOTAL_CREDIT: 'Total crédito',
  TOTAL_DEBIT: 'Total débito',
  BALANCE_CHANGE: 'Cambio de saldo',
  PORTFOLIO: 'Cartera',
  // END Fabian

  // BEGIN Patricio
  ACCOUNTING_EXPORT_CONFIRMATION: '¿Desea exportar los datos contables a CSV?',
  ACCOUNTING_EXPORT: 'Exportar datos contables',

  IFF_EXPORT_CONFIRMATION: '¿Desea exportar los datos contables a IIF?',
  IFF_EXPORT: 'Exportar a IIF',
  // END Patricio
  // BEGIN Esteban
  ACCOUNTING_DETAILS: 'Detalles de contabilidad',
  ACCOUNTING_DETAILS_EXPORT_CONFIRMATION: '¿Desea exportar los detalles de contabilidad a CSV?',
  // END Esteban
}

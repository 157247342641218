import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button } from 'components/form'
import strings from 'l10n'
import { FactorDefaultQuickPayFeeInfo } from 'types/factorDefault'

export type AllowedValueTypes = string | number | boolean | null
export type QuickPayFeesDenseTableProps = {
  columns: {
    displayName: string
    valueName: string
    formatter?: (value: any) => JSX.Element
    maxWidthCss?: string
    height?: string
    fontSize?: string
    hide?: boolean
  }[]
  rows: FactorDefaultQuickPayFeeInfo[]
  allowEdit?: boolean
  handleEdit?: (value: any) => void
  handleDelete?: (value: any) => void
  allowDelete?: boolean
}

const QuickPayFeesDenseTable = (props: QuickPayFeesDenseTableProps) => {
  const { columns, rows, handleEdit, allowEdit, handleDelete, allowDelete } = props
  const editable = handleEdit && allowEdit
  const deletable = handleDelete && allowDelete

  return (
    <TableContainer>
      <Table sx={{ maxWidth: '100%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                !column.hide && (
                  <TableCell
                    key={column.valueName}
                    style={{ maxWidth: column.maxWidthCss, height: column.height, fontSize: column.fontSize }}
                  >
                    {column.displayName}
                  </TableCell>
                )
            )}
            {editable && <TableCell sx={{ textAlign: 'center' }}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {columns.map((column) => {
                const value = row[column.valueName as keyof FactorDefaultQuickPayFeeInfo]
                return (
                  !column.hide && (
                    <TableCell
                      key={column.valueName}
                      style={{ maxWidth: column.maxWidthCss, height: column.height, fontSize: column.fontSize }}
                    >
                      {column.formatter ? column.formatter(value) : value}
                    </TableCell>
                  )
                )
              })}
              <TableCell sx={{ display: 'flex', height: '56px' }}>
                {editable && (
                  <Button label={strings.EDIT} variant="outlined" color="primary" onClick={() => handleEdit(row)} />
                )}
                {deletable && (
                  <Button label={strings.DELETE} variant="outlined" color="error" onClick={() => handleDelete(row)} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default QuickPayFeesDenseTable

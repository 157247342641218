import { PAGINATION_LIMIT_VALUE_NUM, PAGINATION_NUM_VALUE_NUM } from 'constants/headers'
import { getPaginationHeaders } from 'tools/headers'
import request from 'tools/request'
import {
  InvoiceApproveForFundingRequest,
  InvoiceApproveForFundingResponse,
  InvoiceChangelogList,
  InvoiceChangelogParams,
  InvoiceCreateRequest,
  InvoiceCsvResponse,
  InvoiceListParams,
  InvoiceListResponse,
  InvoicePreviewRequest,
  InvoicePreviewResponse,
  InvoiceResponse,
  InvoiceVerifyRequest,
  UpdateInvoiceRequest,
  RejectAllInvoicesRequest,
  InvoicesRebillRequest,
  InvoiceRebillResponse,
} from 'types/invoice'
import {AssociateInvoiceLabelResponse, InvoiceLabelCollectionResponse, LabelAssignRequest} from 'types/labels'
import { TermResponse } from 'types/terms'

export const listInvoices = async (
  params: InvoiceListParams,
  pageNumber: number = PAGINATION_NUM_VALUE_NUM,
  pageLimit: number = PAGINATION_LIMIT_VALUE_NUM
) => {
  const data = await request
    .get<InvoiceListResponse>('invoices', {
      params: params,
      headers: getPaginationHeaders(pageNumber, pageLimit),
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data.invoices,
      }
    })
  return data
}

export const invoicesPreviewInvoiceGroup = async (params: InvoicePreviewRequest) => {
  const data = await request
    .get<InvoicePreviewResponse>('invoices/approve-for-funding-summary', { params: params })
    .then((res) => {
      return res.data
    })
  return data
}

export const createInvoice = async (payload: InvoiceCreateRequest) => {
  return await request.post<InvoiceResponse>('invoices', payload)
}

export const getInvoice = async (invoiceId: string, state: string) => {
  const data = await request
    .get<InvoiceResponse>(`invoices/${invoiceId}`, {
      params: {
        active: state,
      },
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const updateInvoice = async (invoiceId: string, payload: UpdateInvoiceRequest) => {
  const data = await request.put<InvoiceResponse>(`invoices/${invoiceId}`, payload).then((res) => {
    return res.data
  })
  return data
}

export const rejectAllInvoices = async (payload: RejectAllInvoicesRequest) => {
  const data = await request.patch<InvoiceResponse>('invoices/reject-invoices', payload)
  return data
}

export const addDocumentToInvoice = async (invoiceId: String, documents?: string[]) => {
  const data = await request
    .post<InvoiceResponse>(`invoices/${invoiceId}/documents`, {
      documentIds: documents,
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const invoiceApproveForFunding = async (payload: InvoiceApproveForFundingRequest) => {
  const data = await request
    .patch<InvoiceApproveForFundingResponse>(`invoices/approve-for-funding`, payload)
    .then((res) => {
      return res.data
    })
  return data
}

export const invoiceVerify = async (payload: InvoiceVerifyRequest) => {
  const data = await request.patch<InvoiceResponse>('invoices/verification', payload)
  return data
}

export const getInvoiceChangelogList = async (
  params: InvoiceChangelogParams,
  pageNumber: number = PAGINATION_NUM_VALUE_NUM
) => {
  const data = await request
    .get<InvoiceChangelogList>(`invoices/${params.invoiceId}/changelogs`, {
      params: params,
      headers: getPaginationHeaders(pageNumber),
    })
    .then((res) => {
      return res
    })
  return data
}

export const uploadCsv = async (inputFile: File[]) => {
  const formData = new FormData()
  formData.append('inputFile', inputFile[0])
  const data = await request.post<InvoiceCsvResponse>('invoices/csv-upload', formData).then((res) => {
    return res.data
  })
  return data
}

export const getInvoiceTermInfo = async (companyClientOrBrokerId: string, companyDebtorId: string) => {
  const data = await request
    .get<TermResponse>(`invoices/term-info`, {
      params: { companyClientOrBrokerId, companyDebtorId },
    })
    .then((res) => {
      return res.data.term
    })
  return data
}

export const deleteInvoice = async (invoiceId: string) => {
  const data = await request.delete(`invoices/${invoiceId}`).then((res) => {
    return res.data
  })
  return data
}

export const getInvoiceLabels = async (invoiceId: string) => {
  const data = await request.get<InvoiceLabelCollectionResponse>(`invoices/${invoiceId}/labels`).then((res) => {
    return res.data
  })
  return data
}

export const removeLabelFromInvoice = async (invoiceId: string, labelId: string) => {
  const data = await request
    .patch<InvoiceLabelCollectionResponse>(`invoices/${invoiceId}/labels/${labelId}`)
    .then((r) => r.data)
  return data
}

export const exportInvoices = async (params: InvoiceListParams) => {
  const data = await request
    .get<string>('invoices/export', {
      params: params,
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const rebillInvoices = async (params: InvoicesRebillRequest) => {
  const data = await request.put<InvoiceRebillResponse>('invoices/rebill', params).then((res) => {
    return {
      headers: res.headers,
      data: res.data,
    }
  })
  return data
}

export const associateInvoiceLabel = async (invoiceId: string, payload: LabelAssignRequest) => {
  const data = await request.patch<AssociateInvoiceLabelResponse>(`invoices/${invoiceId}/labels`, payload).then((res) => {
    return res.data
  })
  return data
}

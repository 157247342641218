export const dashboardEn = {
  DASHBOARD: 'Dashboard',
  DOWNLOAD_REPORT: 'Download Report',
  DEBTOR_COLLECTION_TITLE: 'Collections - Debtor Open AR',
  CREDIT_REQUEST: 'Credit Requests',
  CLIENT_DEBTOR_REQUEST: 'Client:Debtor Relationship Request',
  NEW_DEBTOR_REQUEST: 'New Debtor Request',
  VIEW_MORE: 'View More',
  FEE_EARNINGS: 'Fee Earnings',
  FUNDING: 'Funding',
  TOTAL_AMOUNT_FUNDED: 'Total amount funded',
  INVOICE_OVERVIEW: 'Invoice Overview',
  TOTAL: 'Total',
  PAST_DUE_INVOICES: 'Past Due Invoices',
}

export const dashboardEs: typeof dashboardEn = {
  DASHBOARD: 'Tablero',
  DOWNLOAD_REPORT: 'Descargar informe',
  DEBTOR_COLLECTION_TITLE: 'Colecciones - Deudor Open AR',
  CREDIT_REQUEST: 'Solicitudes de crédito',
  CLIENT_DEBTOR_REQUEST: 'Cliente: Solicitud de relación de deudor',
  NEW_DEBTOR_REQUEST: 'Nueva solicitud de deudor',
  VIEW_MORE: 'Ver más',
  FEE_EARNINGS: 'Ganancias de tarifas',
  FUNDING: 'Financiamiento',
  TOTAL_AMOUNT_FUNDED: 'Monto total financiado',
  INVOICE_OVERVIEW: 'Resumen de facturas',
  TOTAL: 'Total',
  PAST_DUE_INVOICES: 'Facturas vencidas',
}

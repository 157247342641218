import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import CheckBoxField, { CheckBoxProps } from 'components/form/CheckBoxField'

type CheckboxFieldProps = CheckBoxProps & {
  /** The name is required and defines the name of the form field. It will be used as a key by react-hook-form to bind the field value, validation rules, and error messages.*/
  name: string
  /** The required is optional and can be used to make the field required or not. When set to true, a required field validation rule is added to the field's validation rules.*/
  required?: boolean

  initialValue?: boolean | null

  onClick?: () => void

  checkboxSize?: number
  labelClassName?: string
  checkBoxClassName?: string
}

const CheckboxFormField = (props: CheckboxFieldProps) => {
  const {
    name,
    required = false,
    initialValue = false,
    onClick,
    checkboxSize = 30,
    labelClassName,
    checkBoxClassName,
  } = props
  const { control } = useFormContext()
  const isRequired = required ? 'Field is Required' : undefined
  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <>
            <CheckBoxField
              {...props}
              checkboxSize={checkboxSize}
              value={value ? Boolean(value) : false}
              onChange={onChange}
              inputRef={ref}
              onClick={onClick}
              labelClassName={labelClassName}
              checkBoxClassName={checkBoxClassName}
            />
            <ErrorMessage errors={errors} name={name} />
          </>
        )
      }}
    />
  )
}

export default CheckboxFormField

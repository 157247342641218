import { PAGINATION_LIMIT_VALUE_NUM, PAGINATION_NUM_VALUE_NUM } from 'constants/headers'
import { getPaginationHeaders } from 'tools/headers'
import request from 'tools/request'
import {
  ApproveForFundingFuelAdvancesRequest,
  ApproveForFundingFuelAdvancesSummaryResponse,
  AutocompleteFuelAdvanceMatchInvoiceParams,
  CreateFuelAdvanceRequest,
  FuelAdvanceApproveForFundingSummaryParams,
  FuelAdvanceCollectionResponse,
  FuelAdvanceGetCollectionParams,
  FuelAdvanceSingletonResponse,
  TotalFuelAdvanceParams,
  TotalFuelAdvanceResponse,
  UpdateFuelAdvanceRequest,
  RejectFuelAdvanceRequest
} from 'types/fuelAdvances'
import { InvoiceGroupSingletonResponse } from 'types/invoiceGroup'

export const getFuelAdvances = async (
  params: FuelAdvanceGetCollectionParams,
  pageNumber: number = PAGINATION_NUM_VALUE_NUM,
  pageLimit: number = PAGINATION_LIMIT_VALUE_NUM
) => {
  const data = await request
    .get<FuelAdvanceCollectionResponse>('fuel-advances', {
      params: params,
      headers: getPaginationHeaders(pageNumber, pageLimit),
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data.fuelAdvances,
      }
    })
  return data
}

export const getFuelAdvance = async (fuelAdvanceId: string) => {
  const data = await request.get<FuelAdvanceSingletonResponse>(`fuel-advances/${fuelAdvanceId}`).then((res) => {
    return res.data.fuelAdvance
  })
  return data
}

export const getTotalFuelAdvances = async (params: TotalFuelAdvanceParams) => {
  const data = await request
    .get<TotalFuelAdvanceResponse>('fuel-advances/totals', {
      params: params,
    })
    .then((res) => {
      return {
        headers: res.headers,
        data: res.data.totalFuelAdvanceInfo,
      }
    })
  return data
}

export const createFuelAdvance = async (payload: CreateFuelAdvanceRequest) => {
  const data = await request.post<FuelAdvanceSingletonResponse>('fuel-advances', payload).then((res) => {
    return res.data.fuelAdvance
  })
  return data
}

export const updateFuelAdvance = async (fuelAdvanceId: string, payload: UpdateFuelAdvanceRequest) => {
  const data = await request
    .put<FuelAdvanceSingletonResponse>(`fuel-advances/${fuelAdvanceId}`, payload)
    .then((res) => {
      return res.data.fuelAdvance
    })
  return data
}

export const getApproveForFundingSummaryFuelAdvances = async (params: FuelAdvanceApproveForFundingSummaryParams) => {
  const data = await request
    .get<ApproveForFundingFuelAdvancesSummaryResponse>('fuel-advances/approve-for-funding-summary', {
      params: params,
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const approveForFundingFuelAdvances = async (payload: ApproveForFundingFuelAdvancesRequest) => {
  const data = await request
    .patch<InvoiceGroupSingletonResponse>('fuel-advances/approve-for-funding', payload)
    .then((res) => {
      return res.data.invoiceGroup
    })
  return data
}

export const getFuelAdvancesByInvoiceMatch = async (params: AutocompleteFuelAdvanceMatchInvoiceParams) => {
  const data = await request
    .get<FuelAdvanceCollectionResponse>('fuel-advances/invoice-match', {
      params: params,
    })
    .then((res) => {
      return res.data.fuelAdvances
    })
  return data
}

export const rejectFueldAdvance = async (payload: RejectFuelAdvanceRequest) => {
  const data = await request
    .patch<InvoiceGroupSingletonResponse>('fuel-advances/reject', payload)
    .then((res) => {
      return res.data.invoiceGroup;
    });
  return data;
}

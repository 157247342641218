import { ObjectAnyKeys } from 'interfaces'
import Route from 'route-parser'

/** **** Get Path Tokens *****
 *
 * Returns path segments (tokens)
 * @param {String} pathname Full pathname to split
 */
const getPathTokens = (pathname: string) => {
  const paths = ['/']

  if (pathname === '/') {
    return paths
  }

  pathname.split('/').reduce((prev: string, current: string) => {
    const currentPath = `${prev}/${current}`
    paths.push(currentPath)

    return currentPath
  })

  return paths
}

/** **** Get Route Match *****
 *
 * Returns matched route
 * @param {Object} routes Object with valid routes
 * @param {String} path Path to match
 */
const getRouteMatch = (routes: ObjectAnyKeys | null, path: string) => {
  return (
    routes &&
    Object.keys(routes)
      .map((key) => {
        const params = new Route(key).match(path)

        return {
          didMatch: params !== false,
          params,
          key,
        }
      })
      .filter((item) => item.didMatch)[0]
  )
}

/** **** Get Breadcrumbs *****
 *
 * Returns array of breadcrumbs
 * @param {Object} routes Object with valid routes
 * @param {Undefined} match Current match (not used)
 * @param {String} location Current path
 */
export const getBreadcrumbs = (routes: ObjectAnyKeys | null, location: any) => {
  const pathTokens = getPathTokens(location.pathname)

  return pathTokens.map((path) => {
    const routeMatch = getRouteMatch(routes, path)

    if (!routeMatch) {
      return { name: '', path: '/' }
    }

    const routeValue = routes && routes[routeMatch.key]
    const name = typeof routeValue === 'function' ? routeValue(routeMatch.params) : routeValue

    return { name, path }
  })
}

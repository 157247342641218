import { useState, useEffect, useMemo } from 'react'
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronLeftRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { DrawerHeader, MuiDrawer } from 'theme/themeOverride'
import styles from './MenuBar.module.scss'
import { TypoGraph, Tooltip, Icon } from 'components/ui'
import { Link } from 'react-router-dom'
import { footerMenuBar } from './menuBarData'
import usePermissions from 'hooks/usePermissions'
import strings from 'l10n'
import { GLOBAL_CONFIG_SHOW_DASHBHOARD } from 'constants/globalConfig'
import useAdminSettings from 'hooks/useAdminSettings'
import { environment } from 'tools/common'

const reportingEnabled = process.env.REACT_APP_FEATURE_REPORTS?.toLowerCase() === 'true'

type MenuBarProps = {
  path: string
  navigate: Function
}

export type MenuBarListItem = {
  name: string
  icon?: string
  id: string
  subItems?: MenuBarListItem[]
}

type MenuListItemProps = {
  item: MenuBarListItem
  selectedItem: string
  isMenubarOpen: boolean
}

type MenuListProps = {
  data: MenuBarListItem[]
  isMenubarOpen: boolean
  selectedItem: string
  className?: string
}

function MenuListItem(props: MenuListItemProps) {
  const { item, selectedItem, isMenubarOpen } = props

  const selected = selectedItem === item.id

  const iconClass = selected ? 'active' : 'disabled'
  let itemActiveClass = ''
  if (selected) itemActiveClass = 'active'

  return (
    <Tooltip key={item.name} title={item.name} placement="right" show={!isMenubarOpen}>
      <ListItem disablePadding sx={{ display: 'block' }} className={`fc-menu-bar-item ${itemActiveClass}`}>
        <Link to={item.id} className={styles.openInTab}>
          <ListItemButton
            sx={{
              height: isMenubarOpen ? 50 : 55,
              justifyContent: 'initial',
              borderRadius: 2,
              marginTop: 0,
              marginBottom: 0,
              flexDirection: isMenubarOpen ? 'row' : 'column',
            }}
            selected={selected}
          >
            {item.icon && (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isMenubarOpen ? 1 : 0,
                  justifyContent: 'center',
                }}
              >
                <Icon name={`${item.icon}_${iconClass}`} size={25} boxClassName={iconClass} />
              </ListItemIcon>
            )}
            <TypoGraph variant="body1" mb={0} sx={!isMenubarOpen ? { fontSize: '11px', marginTop: '2px' } : {}} link>
              {item.name}
            </TypoGraph>
          </ListItemButton>
        </Link>
      </ListItem>
    </Tooltip>
  )
}

function MenuListItemCollapse(props: MenuListItemProps) {
  const { item, selectedItem, isMenubarOpen } = props
  const [open, setOpen] = useState(true)

  return (
    <>
      <Tooltip key={item.name} title={item.name} placement="right" show={!isMenubarOpen}>
        <ListItem disablePadding sx={{ display: 'block' }} className="fc-menu-bar-item">
          <ListItemButton
            sx={{
              height: isMenubarOpen ? 50 : 55,
              justifyContent: 'initial',
              borderRadius: 2,
              marginTop: 1,
              marginBottom: 1,
              flexDirection: isMenubarOpen ? 'row' : 'column',
            }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isMenubarOpen ? 1 : 0,
                justifyContent: 'center',
              }}
            >
              <Icon name={item.icon} size={25} boxClassName={selectedItem === item.id ? 'active' : ''} />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{ sx: !isMenubarOpen ? { fontSize: '11px', marginTop: '2px' } : {} }}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
      </Tooltip>
      {isMenubarOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ pl: 2, pt: 0, pb: 0 }}>
            {item.subItems?.map((subItem) => (
              <MenuListItem key={subItem.id} item={subItem} selectedItem={selectedItem} isMenubarOpen={isMenubarOpen} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

const MenuList = (props: MenuListProps) => {
  const { data, isMenubarOpen, selectedItem, className } = props
  return (
    <List className={className ? className : styles.menuList}>
      {data.map((item) =>
        item.subItems ? (
          <MenuListItemCollapse key={item.id} item={item} selectedItem={selectedItem} isMenubarOpen={isMenubarOpen} />
        ) : (
          <MenuListItem key={item.id} item={item} selectedItem={selectedItem} isMenubarOpen={isMenubarOpen} />
        )
      )}
    </List>
  )
}

function MenuBar(props: MenuBarProps) {
  const { path } = props
  const [selectedItem, setSelectedItem] = useState('companies')
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [open, setOpen] = useState(true)
  const { enableFuelAdvance, enableBrightboltIntegration, enableCreditCheck, enableReserveReleases } =
    useAdminSettings()

  const {
    canReadTerms,
    canReadClients,
    canReadDebtors,
    canReadInvoices,
    canReadDisbursements,
    canReadPayments,
    canReadLedgers,
    canAccessAdminLevelReporting,
    canAccessNormalLevelReporting,
    canReadFuelAdvances,
    canReadReserveReleases,
    canAccessDocuments
  } = usePermissions()

  const mainMenuBar: MenuBarListItem[] = useMemo(() => {
    const menu: MenuBarListItem[] = []
    if (GLOBAL_CONFIG_SHOW_DASHBHOARD) {
      menu.push({
        name: 'Dashboard',
        icon: 'menubar/icon_dashboard',
        id: 'dashboard',
      })
    }
    if (canReadClients || canReadDebtors) {
      menu.push({
        name: strings.COMPANIES,
        icon: 'menubar/icon_companies',
        id: 'companies',
      })
    }
    if (canReadTerms) {
      menu.push({
        name: strings.TERMS,
        icon: 'menubar/icon_terms',
        id: 'terms',
      })
    }
    if (enableBrightboltIntegration && canAccessDocuments) {
      menu.push({
        name: strings.BB_INTEGRATION,
        icon: 'menubar/icon_documents',
        id: 'documents',
      })
    }
    if (canReadInvoices) {
      menu.push({
        name: strings.INVOICES,
        icon: 'menubar/icon_invoices',
        id: 'invoices',
      })
    }
    if (canReadFuelAdvances && enableFuelAdvance) {
      menu.push({
        name: strings.FUEL_ADVANCES,
        icon: 'menubar/icon_fuel_advances',
        id: 'fuel-advances',
      })
    }
    if (canReadDisbursements) {
      menu.push({
        name: strings.FUNDING,
        icon: 'menubar/icon_funding',
        id: 'funding',
      })
    }
    if (canReadPayments) {
      menu.push({
        name: strings.PAYMENTS,
        icon: 'menubar/icon_payments',
        id: 'payments',
      })
    }
    menu.push({
      name: strings.CHARGEBACKS,
      icon: 'menubar/icon_chargeback',
      id: 'chargebacks',
    })
    if (canReadLedgers) {
      menu.push({
        name: strings.LEDGERS,
        icon: 'menubar/icon_ledgers',
        id: 'ledgers',
      })
    }
    if (canAccessAdminLevelReporting || canAccessNormalLevelReporting) {
      menu.push({
        name: strings.ACCOUNTING,
        icon: 'menubar/icon_accounting',
        id: 'accounting',
      })
    }
    menu.push({
      name: strings.COLLECTIONS,
      icon: 'menubar/icon_collections',
      id: 'collections',
    })
    if (canReadClients && canReadDebtors && enableCreditCheck) {
      menu.push({
        name: strings.CREDIT_REQUEST,
        icon: 'menubar/icon_credit_request',
        id: 'credit-request',
      })
    }
    if (reportingEnabled) {
      menu.push({
        name: strings.REPORTS,
        icon: 'menubar/icon_reports',
        id: 'reports',
      })
    }
    if (canReadReserveReleases && enableReserveReleases) {
      menu.push({
        name: strings.RESERVE_RELEASES,
        icon: 'menubar/icon_reserve_release',
        id: 'reserve-releases',
      })
    }

    return menu
  }, [
    canReadTerms,
    canReadClients,
    canReadDebtors,
    canReadInvoices,
    canReadDisbursements,
    canReadPayments,
    canReadLedgers,
    canAccessAdminLevelReporting,
    canAccessNormalLevelReporting,
    canReadFuelAdvances,
    enableFuelAdvance,
    enableBrightboltIntegration,
    canAccessDocuments,
    enableCreditCheck,
    enableReserveReleases,
    canReadReserveReleases,
  ])

  /*
   * This useffect will trigger whenever the current url path changes
   * It will check the current path and menubar list id matches once it matched,
   * It will set the active menuBarItem to hightlight.
   */
  useEffect(() => {
    const flatMainMenu = mainMenuBar.flatMap((item) => {
      if (!item.subItems) {
        return item
      } else {
        return [item, ...item.subItems]
      }
    })
    const flatFooterMenu = footerMenuBar.flatMap((item) => {
      if (!item.subItems) {
        return item
      } else {
        return [item, ...item.subItems]
      }
    })
    const menuItems = [...flatMainMenu, ...flatFooterMenu]

    const findItem = menuItems.find((ele: MenuBarListItem) => {
      return path.includes(`/${ele.id}`) ? ele : null
    })
    if (findItem) {
      setSelectedItem(findItem.id)
    }
  }, [path, mainMenuBar])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const openCloseIconEvent = {
    onMouseOver: () => setShowCloseIcon(true),
    onMouseLeave: () => {
      setTimeout(() => {
        if (!showCloseIcon) {
          setShowCloseIcon(false)
        }
      }, 800)
    },
    onMouseOut: () => setShowCloseIcon(false),
  }

  const env = environment()
  const envTsx = env === 'PRODUCTION' ? <></> : <div className={styles.environment}>{env}</div>

  return (
    <div {...openCloseIconEvent} className="fc-menu-bar">
      {showCloseIcon && (
        <Icon boxClassName={`${styles.leftIconButton} ${!open && styles.rightIconButton}`} onClick={handleDrawerOpen}>
          {open ? <ChevronLeftIcon sx={{ color: 'black' }} /> : <ChevronLeftRight sx={{ color: 'black' }} />}
        </Icon>
      )}
      <MuiDrawer variant="permanent" open={open}>
        <DrawerHeader>
          <Icon name={open ? 'factorcloud-new' : 'logo-new'} width={200} height={45} />
          {envTsx}
        </DrawerHeader>
        <MenuList isMenubarOpen={open} data={mainMenuBar} selectedItem={selectedItem} />
        <MenuList
          isMenubarOpen={open}
          data={footerMenuBar}
          selectedItem={selectedItem}
          className={styles.stickyMenuList}
        />
      </MuiDrawer>
    </div>
  )
}

export default MenuBar

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Blade, Container, Toast as toast } from 'components/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { FactorDefaultQuickPayFeeInfo, FactorDefaultRequest } from 'types/factorDefault'
import styles from '../../SystemDefaultsSectionsUpdateBlade.module.scss'
import { TextFormField } from 'components/hookForm'
import strings from 'l10n'
import { integerPattern } from 'tools/patterns'
import { isDefined, maxValueLimit, minValueLimit } from 'tools/common'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import { Button } from 'components/form'
import { toDecPercent } from 'tools/format'

type QuickPayFeeAddBladeProps = {
  title: string
  handleClose: () => void
  showBlade: boolean
  quickPayFees?: FactorDefaultQuickPayFeeInfo[]
}

type QuickPayFeeAddBladeForm = {
  name: string
  brokerFee: number
  brokerRate: string
  days: number
  factorFee: number
  factorRate: string
}

export const QuickPayFeeAddBlade = (props: QuickPayFeeAddBladeProps) => {
  const { title, handleClose, showBlade, quickPayFees } = props
  const form = useForm<QuickPayFeeAddBladeForm>()
  const { handleSubmit } = form
  const queryClient = useQueryClient()

  const { mutate: addNewQuickPayFee, isLoading: isAddNewQuickPayFeeLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: () => {
      toast({
        type: 'success',
        subTitle: strings.QUICK_PAY_FEE_CREATED_SUCCESSFULLY,
      })
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      form.reset()
      handleClose()
    },
  })

  const onSubmit = (data: QuickPayFeeAddBladeForm) => {
    let newQuickPayFees: FactorDefaultQuickPayFeeInfo[] = []
    const newQuickPayFee: FactorDefaultQuickPayFeeInfo = {
      name: data.name,
      days: data.days,
      factorFee: data.factorFee,
      factorRate: Number(toDecPercent(data.factorRate)),
      brokerFee: data.brokerFee,
      brokerRate: Number(toDecPercent(data.brokerRate)),
    }

    if (isDefined(quickPayFees)) {
      newQuickPayFees = [...quickPayFees, newQuickPayFee]
    } else {
      newQuickPayFees = [newQuickPayFee]
    }

    const payload: FactorDefaultRequest = {
      brokerQuickPayFeeValues: {
        quickPayFees: newQuickPayFees,
      },
    }
    addNewQuickPayFee(payload)
  }

  const bladeContent = (
    <FormProvider {...form}>
      <Container>
        <Container className={styles.twoRowFormat}>
          <TextFormField name="name" label={strings.NAME} required maxLength={20} disabled={false} />
          <TextFormField
            name="days"
            label={strings.DAYS}
            type="number"
            pattern={integerPattern}
            min={minValueLimit(0)}
            disabled={isAddNewQuickPayFeeLoading}
          />
          <TextFormField
            name="factorRate"
            label={strings.FACTOR_RATE}
            min={minValueLimit(0)}
            type="number"
            max={maxValueLimit(100)}
            startAdornment="%"
            disabled={isAddNewQuickPayFeeLoading}
          />
          <TextFormField
            name="brokerRate"
            label={strings.BROKER_RATE}
            min={minValueLimit(0)}
            type="number"
            max={maxValueLimit(100)}
            startAdornment="%"
            disabled={isAddNewQuickPayFeeLoading}
          />
          <TextFormField
            name="factorFee"
            label={strings.FACTOR_FEE}
            min={minValueLimit(0)}
            type="number"
            startAdornment="$"
            disabled={isAddNewQuickPayFeeLoading}
          />
          <TextFormField
            name="brokerFee"
            label={strings.BROKER_FEE}
            min={minValueLimit(0)}
            type="number"
            startAdornment="$"
            disabled={isAddNewQuickPayFeeLoading}
          />
        </Container>
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button variant="outlined" label={strings.CANCEL} onClick={handleClose} disabled={isAddNewQuickPayFeeLoading} />
      <Button
        variant="contained"
        label={strings.CREATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isAddNewQuickPayFeeLoading}
        loading={isAddNewQuickPayFeeLoading}
      />
    </Container>
  )
  return (
    <>
      <Blade
        variant="persistent"
        open={showBlade}
        title={title}
        onClose={handleClose}
        bodyNode={bladeContent}
        footerNode={bladeFooter}
        loading={isAddNewQuickPayFeeLoading}
        hideBackdrop
      />
    </>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getGmailCallbackResponse } from 'api/gmail'
import { Toast as toast } from 'components/ui'
import { qkBaseGmail } from 'constants/QueryKeys/gmail'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const GmailCallback = () => {
  const queryClient = useQueryClient()
  const json = window.location.search
  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationFn: () => {
      return getGmailCallbackResponse({ url: json })
    },
    onSuccess: () => {
      toast({
        type: 'success',
        subTitle: 'Access Token Stored Successfully!',
      })
      queryClient.invalidateQueries(qkBaseGmail)
    },
  })

  useEffect(() => {
    mutate()
    navigate('/settings/adminSettings')
  }, [navigate, mutate])

  return <></>
}

export default GmailCallback

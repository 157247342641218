import MuiPopover, { PopoverProps as MuiPopoverProps } from '@mui/material/Popover'

type PopoverProps = MuiPopoverProps & {
  /** The children is used to pass the content that should be displayed inside the popover. It is an optional prop that can accept any valid React node.*/
  children?: React.ReactNode
  /** The anchorEl is used to specify the element that the popover should be anchored to. It can be either an HTML element or null.*/
  anchorEl: Element | null
  /** The onClose is used to specify a function that should be called when the popover is closed. It is an optional prop that takes no arguments and returns nothing. If this prop is not specified, the popover cannot be closed by clicking outside of it.*/
  onClose?: () => void
}

const Popover = (props:PopoverProps) => {
  const {
    children,
    anchorEl,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    onClose,
  } = props

  const open = Boolean(anchorEl)

  return (
    <MuiPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      {children}
    </MuiPopover>
  )
}

export default Popover

export const labelsEn = {
  // BEGIN Esteban
  ENTITY_TYPE: 'Entity Type',
  CATEGORY: 'Category',
  UPDATE_LABEL_SUCCESS: 'Label updated successfully',
  CREATE_LABEL_SUCCESS: 'Label created successfully',
  TITLE_CREATE_LABEL : 'Create Label',
  ENTITY_TYPE_INVOICE: 'Invoice',
  ENTITY_TYPE_COMPANY: 'Company',
  ENTITY_TYPE_PAYMENT: 'Payment',
  ENTITY_TYPE_TERM: 'Term',
  CATEGORY_GENERAL: 'General',
  CATEGORY_INVOICE_COLLECTIONS: 'Invoice Collections',
  CATEGORY_INVOICE_CHARGEBACK: 'Invoice Chargeback',
  CATEGORY_INVOICE_PAYMENT: 'Invoice Payment',
  ASSIGN_LABEL: 'Assign Label',
  DELETE_LABEL_FROM_INVOICE: 'Are you sure you want to delete this label from the invoice?',
  DELETE_LABEL_FROM_PAYMENT: 'Are you sure you want to delete this label from the payment?',
  DELETE_LABEL_SUCCESS: 'Label deleted successfully',
  ASSIGN_LABELS_SUCCESS: 'Labels assigned successfully',
  ADD_LABELS: 'Add Labels',
  // END Esteban
}

export const labelsEs: typeof labelsEn = {
  // BEGIN Esteban
  ENTITY_TYPE: 'Tipo de entidad',
  CATEGORY: 'Categoría',
  UPDATE_LABEL_SUCCESS: 'Etiqueta actualizada con éxito',
  CREATE_LABEL_SUCCESS: 'Etiqueta creada con éxito',
  TITLE_CREATE_LABEL : 'Crear etiqueta',
  ENTITY_TYPE_INVOICE: 'Factura',
  ENTITY_TYPE_COMPANY: 'Empresa',
  ENTITY_TYPE_PAYMENT: 'Pago',
  ENTITY_TYPE_TERM: 'Término',
  CATEGORY_GENERAL: 'General',
  CATEGORY_INVOICE_COLLECTIONS: 'Cobranza de facturas',
  CATEGORY_INVOICE_CHARGEBACK: 'Devolución de factura',
  CATEGORY_INVOICE_PAYMENT: 'Pago de factura',
  ASSIGN_LABEL: 'Asignar etiqueta',
  DELETE_LABEL_FROM_INVOICE: '¿Estás seguro de que deseas eliminar esta etiqueta de la factura?',
  DELETE_LABEL_FROM_PAYMENT: '¿Estás seguro de que deseas eliminar esta etiqueta de este pago?',
  DELETE_LABEL_SUCCESS: 'Etiqueta eliminada con éxito',
  ASSIGN_LABELS_SUCCESS: 'Etiquetas asignada con éxito',
  // END Esteban
  ADD_LABELS: 'Agregar etiquetas',
}

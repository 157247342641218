import LoaderPage from 'components/ui/feedback/LoaderPage'
import React, { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const FuelAdvances = lazy(() => import('pages/fuelAdvances/page'))
const FuelAdvancePreviewPage = lazy(() => import('pages/fuelAdvances/[fuelAdvanceId]/page'))

const FuelAdvancesRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<FuelAdvances />} />} />
      <Route path="/:referenceNumber/:fuelAdvanceId" element={<LoaderPage content={<FuelAdvancePreviewPage />} />} />
    </Routes>
  )
}

export default FuelAdvancesRoutes

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { SxProps, Theme, styled } from '@mui/material/styles'
import colors from 'theme/colors'

export type SwitchFieldProps = {
  /** The label is a string that specifies the text label of the input field that is accompanying the switch, it is displayed next to the switch and provides a description or context for the switch's purpose.*/
  label?: string
  /** The name variable allows to give a name to the switch, it is used to identify the switch when it is submitted as part of a form.*/
  name?: string
  /** The id is an identifier for the component. It can be used to establish relationships with other elements in the DOM or to apply specific styles to that element.*/
  id?: string
  /** The onChange is a function that gets called whenever the user toggles the switch state. It's passed a single argument, which is a React ChangeEvent object.*/
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** The labelVariant is a string that specifies the typography variant of the label text for the switch component.*/
  labelVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'overline'
  /** The defaultChecked indicates the initial state of the switch.*/
  defaultChecked?: boolean
  /** The value indicates the current state of the switch. */
  value?: boolean
  /** The input ref variable is used to reference the input element of the switch.*/
  inputRef?: any
  /** The disabled indicates whether the switch is disabled or not*/
  disabled?: boolean
  labelsx?: SxProps<Theme>
}

const SwitchField = (props: SwitchFieldProps) => {
  const { onChange, label, defaultChecked, value, disabled, labelsx } = props

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} disabled={disabled} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : colors.primary,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }))

  return (
    <FormControlLabel
      sx={labelsx}
      control={
        <IOSSwitch sx={{ m: 1 }} defaultChecked={defaultChecked} checked={value} onChange={onChange} {...props} />
      }
      label={label}
    />
  )
}

export default SwitchField

import ErrorBoundary from './ErrorBoundary'
import { RouteProps } from 'react-router-dom'
import AppContainer from 'app/EntryPoint/AppContainer'
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toast as toast } from 'components/ui'
import { UserSessionProvider } from 'context/UserSession/UserSessionProvider'
import useUserSession from 'hooks/useUserSession'
import React, { useCallback, useEffect, useState } from 'react'
import strings from 'l10n'
import { LicenseInfo } from '@mui/x-license-pro'
import { CountriesProvider } from 'context/Countries/CountriesContext'

const REACT_APP_MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY || '')

const queryClient = new QueryClient()

// Type validator function example
function isError(args: unknown): args is unknown {
  return true
}

export function errorMsg(error: any, def = strings.WS_ERROR) {
  let message = error?.response?.data?.message?.message
  if (!message) message = error?.response?.data?.message
  return message ?? def
}

function showToast(error: unknown) {
  toast({
    type: 'error',
    subTitle: errorMsg(error),
  })
}

function onErrorHandler(error: unknown) {
  if (isError(error)) {
    showToast(error)
    return Promise.reject(error)
  }
  return error
}

const QueryConfig = () => {
  const queryClient = useQueryClient()
  const { logout } = useUserSession()

  const retryHandler = useCallback(
    (failureCount: number, error: unknown) => {
      console.log('retryHandler', failureCount, error)
      if (isError(error)) {
        if ((error as any).response?.status === 401) {
          logout({
            type: 'error',
            subTitle: errorMsg(error, strings.INVALID_SESSION),
          })
          return false
        } else if ((error as any).response?.status === 403) {
          return false
        }
        return failureCount < 3
      }
      return true
    },
    [logout]
  )

  queryClient.setDefaultOptions({
    queries: {
      retry: retryHandler,
      onError: onErrorHandler,
    },
    mutations: {
      retry: false,
      onError: onErrorHandler,
    },
  })

  return <></>
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
)

const App: React.FunctionComponent<RouteProps> = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <ErrorBoundary>
      <UserSessionProvider>
        <QueryClientProvider client={queryClient}>
          <CountriesProvider>
            <AppContainer />
            <ReactQueryDevtools initialIsOpen={false} position="top-left" />
            {showDevtools && (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            )}
            <QueryConfig />
          </CountriesProvider>
        </QueryClientProvider>
      </UserSessionProvider>
    </ErrorBoundary>
  )
}

export default App

import MuiChip from '@mui/material/Chip'

export type ChipProps = {
  /** The label is a string that represents the text displayed on the Chip component. It is the main content of the chip and is required for the component to render.*/
  label?: string
  /** The color variable specifies the background color of the chip.*/
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  /** The variant is used to determine the appearance of the Chip component. It is an optional prop that can be set to either 'filled' or 'outlined'*/
  variant?: 'filled' | 'outlined'
  /** The clickable determines whether the chip should be clickable or not.*/
  clickable?: boolean
  /** The disabled determine whether the chip is disabled or not.*/
  disabled?: boolean
  /** The size specifies the size of the chip.*/
  size?: 'medium' | 'small'
  /** The sx is a shorthand for defining custom styles for the component using the Material-UI styling solution. It allows you to apply custom CSS styles to the component without having to write CSS classes or use inline styles.*/
  sx?: object

  className?: string
  onClick?: () => void
}

const Chip = (props: ChipProps) => {
  const {
    label,
    color = 'primary',
    variant = 'outlined',
    clickable,
    disabled,
    size = 'small',
    sx,
    className,
    onClick,
  } = props
  if (!label) {
    return <></>
  }
  return (
    <MuiChip
      sx={sx}
      className={className}
      label={label}
      clickable={clickable}
      color={color}
      variant={variant}
      disabled={disabled}
      size={size}
      onClick={onClick}
    />
  )
}

export default Chip

import { useState } from 'react'
import { SystemDefaultsSectionProps } from '../../SystemDefaultsComponent'
import { ift, isDefined } from 'tools/common'
import strings from 'l10n'
import { ActionButton } from 'types/common'
import { ScrollSpySection } from 'components/ui'
import styles from '../SystemDefaultsSections.module.scss'
import SectionField from 'components/ui/dataDisplay/SectionField'
import { formatUnitTime } from 'tools/format'
import { DebtorSystemDefaultUpdateBlade } from './DebtorSystemDefaultUpdateBlade'

export const DebtorSystemDefaults = (props: SystemDefaultsSectionProps) => {
  const { factorDefault, canModifyFactorDefaultValues } = props
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const debtorDefaultValues = factorDefault?.debtorDefaultValues
  const debtorExpirationQuantity = debtorDefaultValues?.debtorCreditExpirationQuantity
  const debtorExpirationUnitTime = formatUnitTime(debtorDefaultValues?.debtorCreditExpirationUnitTime)

  const btnEdit = ift(
    {
      label: strings.EDIT,
      variant: 'outlined',
      onClick: () => {
        setShowUpdateBlade(true)
      },
    },
    canModifyFactorDefaultValues
  ) as ActionButton

  const debtorCreditExpiration = ift(
    `${debtorExpirationQuantity} ${debtorExpirationUnitTime}`,
    isDefined(debtorExpirationQuantity) && isDefined(debtorExpirationUnitTime)
  ) as string

  const debtorCreditLimitApproved = ift(
    debtorDefaultValues?.debtorCreditLimitApproved ? strings.YES : strings.NO,
    isDefined(debtorDefaultValues?.debtorCreditLimitApproved)
  ) as string

  return (
    <>
      <ScrollSpySection title={strings.DEBTORS} actionBtn={btnEdit}>
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.CREDIT_LIMIT} value={debtorDefaultValues?.debtorCreditLimit} prefix="$" />
          <SectionField label={strings.CREDIT_LIMIT_APPROVED} value={debtorCreditLimitApproved} />
          <SectionField label={strings.CREDIT_EXPIRATION} value={debtorCreditExpiration} />
        </div>
        {factorDefault && (
          <DebtorSystemDefaultUpdateBlade
            title={`${strings.UPDATE} ${strings.DEBTOR_DEFAULTS_VALUES}`}
            debtorDefaultValues={debtorDefaultValues}
            handleClose={() => setShowUpdateBlade(false)}
            showBlade={showUpdateBlade}
          />
        )}
      </ScrollSpySection>
    </>
  )
}

export const invoiceGroupsEn = {
  //BEGIN Michael
  INVOICE_GROUP: 'Invoice Group',
  INVOICE_GROUPS: 'Invoice Groups',
  FUND_CONFIRMATION: 'Are you sure you want to fund the selected batches?',
  FUND_SUCCESSFULLY: 'Invoices Funded Successfully!',
  MOVE_TO_TITLE_DIALOG_TEXT: 'Move {type} to {status}',
  MOVE_TO_DIALOG_FIRST_BODY_TEXT: 'Move {invoices} to {status} status?',
  MOVE_TO_ACTION_BUTTON_LABEL: 'Move to {status}',
  MOVE_TO_DIALOG_SECOND_BODY_TEXT: 'You may continue modifying this invoice group until it is funded.',
  MOVE_TO_SUCCESSFULLY: '{type} {invoices} moved to {status} successfully!',
  CONFIRM_STATUS_CHANGE: 'Confirm Status Change',
  FUNDING_BATCH_ID: 'Funding Batch ID',
  TOTAL_PAYMENT_FEES: 'Total Payment Fees',
  TOTAL_INVOICES_AMOUNT: 'Total Invoices Amount',
  TOTAL_RESERVE_ESCROW: 'Total Reserve Escrow',
  TOTAL_PURCHASE_FEES: 'Total Purchase Fees',
  RESERVE_HELD_RELEASED: 'Reserve Held/Released',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  FUND: {
    SINGLE: 'Fund',
    PLURAL: 'Funds',
  },

  FUNDED: {
    SINGLE: 'Funded',
    PLURAL: 'Funded',
  },

  NOT_FUNDED: {
    SINGLE: 'Not Funded',
    PLURAL: 'Not Funded',
  },

  FUND_SELECTED: 'Fund Selected',
  INVOICE_PURCHASED: 'Invoices Purchased',
  RESERVE: 'Reserve',
  PAYMENT_AMOUNT: 'Payment Amount',
  PAYMENT_BATCH: 'Payment Batch',
  BATCH_ID: 'Batch ID',
  TITLE_TYPE_PAYMENT_APPROVE_FOR_FUNDING: '{type_payment}: Approved For Funding ',
  DOWNLOAD_MESSAGE: 'Do you want to download the CSV?',
  RETRY_TANK_CONFIRMATION: 'Are you sure you want to retry the selected batch?',
  RETRY_TANK_SUCCESSFULLY: 'Batch Retried Successfully!',
  FUNDED_METHOD: 'Funded Method',
  FUNDED_STATUS: 'Funded Status',

  //END Fabian
  //BEGIN Esteban
  UPDATE_INVOICE_GROUP_SUCCEED: 'Invoice Group Updated Successfully',
  //End Esteban
}

export const invoiceGroupsEs: typeof invoiceGroupsEn = {
  //BEGIN Michael
  INVOICE_GROUP: 'Grupo de Facturas',
  INVOICE_GROUPS: 'Grupos de Facturas',
  FUND_CONFIRMATION: '¿Está seguro de que desea comprar los grupos seleccionados?',
  FUND_SUCCESSFULLY: '¡Grupos de facturas comprados con éxito!',
  MOVE_TO_TITLE_DIALOG_TEXT: 'Mover facturas a {status}',
  MOVE_TO_DIALOG_FIRST_BODY_TEXT: '¿Mover {invoices} a {status}?',
  MOVE_TO_ACTION_BUTTON_LABEL: 'Mover a {status}',
  MOVE_TO_DIALOG_SECOND_BODY_TEXT: 'Puede continuar modificando este grupo de facturas hasta que se compre.',
  MOVE_TO_SUCCESSFULLY: '¡Factura {invoices} movida a {status} con éxito!',
  CONFIRM_STATUS_CHANGE: 'Confirmar cambio de estado',
  FUNDING_BATCH_ID: 'ID de lote de compra',
  TOTAL_PAYMENT_FEES: 'Total de tarifas de pago',
  TOTAL_INVOICES_AMOUNT: 'Monto total de las facturas',
  TOTAL_RESERVE_ESCROW: 'Total de reserva de garantía',
  TOTAL_PURCHASE_FEES: 'Total de tarifas de compra',
  RESERVE_HELD_RELEASED: 'Reserva retenida/liberada',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  FUND: {
    SINGLE: 'Fondo',
    PLURAL: 'Fondos',
  },

  FUNDED: {
    SINGLE: 'Comprado',
    PLURAL: 'Comprados',
  },

  NOT_FUNDED: {
    SINGLE: 'No Comprado',
    PLURAL: 'No Comprados',
  },
  FUND_SELECTED: 'Seleccionar fondos',
  INVOICE_PURCHASED: 'Facturas compradas',
  RESERVE: 'Reserva',
  PAYMENT_AMOUNT: 'Monto de pago',
  PAYMENT_BATCH: 'Lote de pago',
  BATCH_ID: 'ID de lote',
  TITLE_TYPE_PAYMENT_APPROVE_FOR_FUNDING: 'Grupos de facturas: {type_payment}',
  DOWNLOAD_MESSAGE: '¿Desea descargar el CSV?',
  RETRY_TANK_CONFIRMATION: '¿Está seguro de que desea reintentar el lote seleccionado?',
  RETRY_TANK_SUCCESSFULLY: '¡Lote reintentado con éxito!',
  FUNDED_METHOD: 'Método de compra',
  FUNDED_STATUS: 'Estado de compra',
  //END Fabian
  //BEGIN Esteban
  UPDATE_INVOICE_GROUP_SUCCEED: 'Grupo de Facturas Actualizado Correctamente',
  //End Esteban
}

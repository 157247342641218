import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const LedgersListPage = lazy(() => import('pages/ledgers/page'))

const LedgersRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<LedgersListPage />} />} />
    </Routes>
  )
}

export default LedgersRoutes

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const CollectionsListPage = lazy(() => import('pages/collections/page'))

const CollectionsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<CollectionsListPage />} />} />
    </Routes>
  )
}

export default CollectionsRoutes

import { useRef, useState } from 'react'

function getContainerAndChildrenFromRef(ref: React.MutableRefObject<null>) {
  if (ref.current === null) return { container: null, children: null }
  const container = ref.current as HTMLElement
  const children = Array.from(container.children) as HTMLElement[]
  return {
    container,
    children,
  }
}

function findFirstElementInScroll(ref: React.MutableRefObject<null>) {
  const { container, children } = getContainerAndChildrenFromRef(ref)
  if (container === null || children === null) return null

  if (container.scrollTop + container.offsetHeight >= container.scrollHeight) {
    return children[children.length - 1]
  }

  const closestElement = children
    .map((c) => ({
      element: c,
      distance: Math.abs(c.offsetTop - container.offsetTop - container.scrollTop),
    }))
    .reduce((acc, curr) => {
      if (acc.distance < curr.distance) return acc
      return curr
    })

  return closestElement.element
}

type useScrollSpyArgs = {
  /** Id of the element selected by default */
  defaultId: string
}

type useScrollSpyReturn = {
  /** Id of the active element of the index */
  activeId: string
  /** Ref for the scroll container */
  scrollRef: React.MutableRefObject<null>
  /** Callback to use for the onScroll event for the container */
  onScroll: () => void
  /** Callback to use for the onClick event of the index */
  onClick: (id: string) => void
}

const useScrollSpy: (args: useScrollSpyArgs) => useScrollSpyReturn = (args: useScrollSpyArgs) => {
  const scrollRef = useRef(null)
  const [activeId, setActiveId] = useState(args.defaultId)

  const scrollHandler = () => {
    const first = findFirstElementInScroll(scrollRef)
    if (first && first.id !== activeId) setActiveId(first.id)
  }

  const onClick = (id: string) => {
    const { container, children } = getContainerAndChildrenFromRef(scrollRef)
    if (container === null || children === null) {
      console.warn('No container or children', container, children)
      return
    }
    const child = children.find((c) => c.id === id)
    if (!child) {
      console.warn('No children with id', id)
      return
    }
    setActiveId(id)
    const scrollPos = child.offsetTop - container.offsetTop
    container.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    })
  }

  return {
    activeId,
    scrollRef,
    onScroll: scrollHandler,
    onClick,
  }
}

export default useScrollSpy

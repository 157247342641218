import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const OutOfSystemInvoicePreviewPage = lazy(() => import('pages/outOfSystemInvoices/[outOfSystemInvoiceId]/page'))

const OutOfSystemInvoicesRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route
        path="/:outOfSystemInvoiceNumber/:outOfSystemInvoiceId"
        element={<LoaderPage content={<OutOfSystemInvoicePreviewPage />} />}
      />
    </Routes>
  )
}

export default OutOfSystemInvoicesRoutes

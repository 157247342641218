import { useState } from 'react'
import { SystemDefaultsSectionProps } from '../../SystemDefaultsComponent'
import { ift, isDefined } from 'tools/common'
import { ActionButton } from 'types/common'
import { Divider, ScrollSpySection, TypoGraph } from 'components/ui'
import strings from 'l10n'
import styles from '../SystemDefaultsSections.module.scss'
import SectionField from 'components/ui/dataDisplay/SectionField'
import { percentageFormat } from 'tools/format'
import { ClientSystemDefaultUpdateBlade } from './ClientSystemDefaultUpdateBlade'

export const ClientSystemDefaults = (props: SystemDefaultsSectionProps) => {
  const { factorDefault, canModifyFactorDefaultValues } = props
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const clientDefaultValues = factorDefault?.clientDefaultValues
  const clientPaymentFees = clientDefaultValues?.clientPaymentFees

  const btnEdit = ift(
    {
      label: strings.EDIT,
      variant: 'outlined',
      onClick: () => {
        setShowUpdateBlade(true)
      },
    },
    canModifyFactorDefaultValues
  ) as ActionButton

  const clientCreditLimitApproved = ift(
    clientDefaultValues?.clientCreditLimitApproved ? strings.YES : strings.NO,
    isDefined(clientDefaultValues?.clientCreditLimitApproved)
  ) as string

  const clientGenerateInvoice = ift(
    clientDefaultValues?.clientGenerateInvoice ? strings.YES : strings.NO,
    isDefined(clientDefaultValues?.clientGenerateInvoice)
  ) as string

  return (
    <>
      <ScrollSpySection title={strings.CLIENTS} actionBtn={btnEdit}>
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.PAYMENT_FEES} />
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.ACH_FEE} value={clientPaymentFees?.achFee} prefix="$" />
          <SectionField label={strings.WIRE_FEE} value={clientPaymentFees?.wireFee} prefix="$" />
          <SectionField label={strings.CHECK_FEE} value={clientPaymentFees?.checkFee} prefix="$" />
          <SectionField label={strings.FUEL_CARD_FEE} value={clientPaymentFees?.fuelCardFee} prefix="$" />
          <SectionField label={strings.SAME_DAY_ACH_FEE} value={clientPaymentFees?.sameDayAchFee} prefix="$" />
          <SectionField label={strings.MONEY_CODE_FEE} value={clientPaymentFees?.moneyCodeFee} prefix="$" />
        </div>
        <Divider />
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.NEGATIVE_CASH_RESERVE_FEE} />
        <div className={styles.sectionContentContainer}>
          <SectionField
            label={strings.DOLLAR_AMOUNT}
            value={clientDefaultValues?.clientNegativeFeeDollarAmount}
            prefix="$"
          />
          <SectionField
            label={strings.PERCENTAGE_AMOUNT}
            value={percentageFormat(clientDefaultValues?.clientNegativeFeePercentageAmount)}
          />
        </div>
        <Divider />
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.OTHERS} />
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.CREDIT_LIMIT} value={clientDefaultValues?.clientCreditLimit} prefix="$" />
          <SectionField label={strings.CREDIT_LIMIT_APPROVED} value={clientCreditLimitApproved} />
          <SectionField label={strings.GENERATE_INVOICE} value={clientGenerateInvoice} />
        </div>
      </ScrollSpySection>
      {factorDefault && (
        <ClientSystemDefaultUpdateBlade
          title={`${strings.UPDATE} ${strings.CLIENT_DEFAULTS_VALUES}`}
          clientDefaultValues={clientDefaultValues}
          handleClose={() => setShowUpdateBlade(false)}
          showBlade={showUpdateBlade}
        />
      )}
    </>
  )
}

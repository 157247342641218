import { Route, RouteProps, Routes } from 'react-router-dom'
import { lazy } from 'react'
import LoaderPage from 'components/ui/feedback/LoaderPage'

const Chargeback = lazy(() => import('pages/chargebacks/page'))

const ChargebacksRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<Chargeback />} />} />
    </Routes>
  )
}

export default ChargebacksRoutes

import { Button } from 'components/form'
import Card from '../surfaces/Card'
import styles from './ScrollSpySection.module.scss'
import strings from 'l10n'
import { ActionButton } from 'types/common'

type ScrollSpySectionProps = {
  children: React.ReactNode
  title: string
  onEdit?: () => void
  actionBtn?: ActionButton
  disabled?: boolean
  pr?: string
  pl?: string
  btnEditId?: string
}

const ScrollSpySection = (props: ScrollSpySectionProps) => {
  const { children, title, onEdit, actionBtn, disabled, pr, pl, btnEditId } = props

  return (
    <Card
      classes={{
        root: styles.sectionLayoutContainer,
      }}
    >
      <div className={styles.sectionLayoutHeader}>
        <div className={styles.sectionLayoutTitle}>{title}</div>
        {actionBtn && (
          <div>
            <Button
              label={actionBtn.label}
              onClick={actionBtn.onClick}
              variant={actionBtn.variant || 'contained'}
              disabled={disabled}
            />
          </div>
        )}

        {onEdit && (
          <div className={styles.sectionLayoutButton}>
            <Button
              label={strings.EDIT}
              onClick={onEdit}
              variant={actionBtn?.variant || 'outlined'}
              disabled={disabled}
              id={btnEditId}
            />{' '}
          </div>
        )}
      </div>
      <div className={styles.sectionLayoutContent} style={{ paddingRight: pr ? pr : '', paddingLeft: pl ? pl : '' }}>
        {children}
      </div>
    </Card>
  )
}

export default ScrollSpySection

import { createTheme, styled, Theme, CSSObject } from '@mui/material/styles'
import { Drawer } from '@mui/material'
import colors from './colors'

const themeSettings: any = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.white,
    },
    success: {
      main: colors.success,
      contrastText: colors.white,
    },
    background: {
      default: colors.paper,
    },
    neutral: {
      main: colors.grey,
    },
  },
  typography: {
    fontFamily: 'Inter-Medium',
    h1: {
      fontSize: '24px',
      fontWeight: 700,
      fontStyle: 'normal',
      letterSpacing: '0.75px',
      lineHeight: '36px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '30px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterpacing: '1px',
    },
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      color: colors.grey,
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 300,
    },
    button: {
      color: colors.primary,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 700,
    },
  },
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

const lightTheme = createTheme({
  ...themeSettings,
})

const darkTheme = createTheme({
  ...themeSettings,
  palette: {
    ...themeSettings.palette,
    mode: 'dark',
    background: {
      default: '#222222',
    },
  },
})

const drawerWidth = 256

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  boxShadow: '1px 0px 16px rgba(0, 0, 0, 0.08)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const MuiDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export { lightTheme, darkTheme, DrawerHeader, MuiDrawer }

const isDev =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('dev.factorcloud.com') ||
  window.location.hostname.includes('devqa.factorcloud.com') ||
  window.location.hostname.includes('devquickbook.factorcloud.com')
const isInt = window.location.hostname.includes('int.factorcloud.com')
const isProd = isDev === false && isInt === false

export const GLOBAL_CONFIG_SHOW_DASHBHOARD = isProd === false
export const GLOBAL_CONFIG_SHOW_ADMIN_SETTINGS = isProd === false
export const GLOBAL_CONFIG_SHOW_SPLIT_FUNDING = true

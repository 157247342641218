import { GridRenderCellParams, gridClasses } from '@mui/x-data-grid'
import { TypoGraph, Container, Chip } from 'components/ui'
import { ICompanyRequest } from 'interfaces'
import colors from 'theme/colors'
import strings from 'l10n'

const dataGridSx = {
  '.MuiDataGrid-main > div:first-of-type': {
    pointerEvents: 'auto!important',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '.MuiDataGrid-columnSeparator--sideRight': {
    display: 'none',
  },
  // '.MuiDataGrid-columnHeaderDraggableContainer': {
  //   width: 'auto',
  // },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .actionButton': {
    display: 'none',
  },
  '.MuiDataGrid-actionsCell': {
    position: 'absolute',
    right: '2rem',
  },
  [`& .${gridClasses.row}:hover`]: {
    '.actionButton': {
      display: 'block',
    },
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-selectedRowCount ': {
    visibility: 'hidden',
  },
  background: 'transparent',
}

//----------------------------- Filters

const userFilters: any = [
  {
    label: 'All',
    filterValue: null,
  },
  {
    label: 'Active',
    filterValue: true,
  },
  {
    label: 'Inactive',
    filterValue: false,
  },
]

const factorFilters: any = [
  {
    label: strings.STATUS.ALL.TYPE_1,
    filterValue: null,
  },
  {
    label: strings.STATUS.ACTIVE.PLURAL,
    filterValue: true,
  },
  {
    label: strings.STATUS.INACTIVE.PLURAL,
    filterValue: false,
  },
]

const clientFilters: any = [
  {
    label: 'All',
    filterValue: null,
  },
]

const debtorFilters: any = [
  {
    label: 'All',
    filterValue: null,
  },
]
//------------------------------ Table List

const factorsList = {
  factorColumns: [
    {
      field: 'factorId',
      headerName: strings.FACTOR_ID,
      minWith: 350,
      hideable: false,
      flex: 0.7,
      handleClick: (_val: number | string, _rowData: ICompanyRequest) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        )
      },
    },
    {
      field: 'factorName',
      headerName: `${strings.FACTOR_NAME}`,
      minWidth: 100,
      flex: 0.4,
      hideable: false,
    },
    {
      field: 'address1',
      headerName: `${strings.ADDRESS.TYPE_1}`,
      minWidth: 100,
      flex: 0.4,
      hideable: false,
    },
    {
      field: 'city',
      headerName: `${strings.CITY}`,
      minWidth: 50,
      flex: 0.4,
      hideable: false,
    },
    {
      field: 'phone',
      headerName: `${strings.PHONE}`,
      minWidth: 100,
      flex: 0.4,
      hideable: false,
    },
    {
      field: 'fax',
      headerName: `${strings.FAX}`,
      minWidth: 100,
      flex: 0.4,
      hideable: false,
    },
    {
      field: 'active',
      headerName: `${strings.STATUS.LABEL}`,
      width: 100,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container
            sx={{
              display: 'block',
            }}
          >
            <Chip
              label={params.value ? `${strings.STATUS.ACTIVE.SINGLE}` : `${strings.STATUS.INACTIVE.SINGLE}`}
              color={params.value ? 'success' : 'error'}
            />
          </Container>
        )
      },
    },
  ],
}

const usersList = {
  userColumns: [
    {
      field: 'id',
      headerName: strings.USER_ID,
      minWidth: 350,
      flex: 1,
      hideable: false,
      handleClick: (_val: number | string, _rowData: ICompanyRequest) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        )
      },
    },
    {
      field: 'firstName',
      headerName: strings.FIRST_NAME,
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: 'middleName',
      headerName: strings.MIDDLE_NAME,
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: 'lastName',
      headerName: strings.LAST_NAME,
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: 'email',
      headerName: strings.EMAIL,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'phone',
      headerName: strings.PHONE,
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'active',
      headerName: strings.STATUS,
      width: 100,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container
            sx={{
              display: 'block',
            }}
          >
            <Chip label={params.value ? 'Active' : 'Inactive'} color={params.value ? 'success' : 'error'} />
          </Container>
        )
      },
    },
  ],

  actionList: [
    {
      id: 'editTable',
      name: 'Edit',
      icon: 'icon_edit',
      iconSize: 17,
      className: 'tableActionButton',
    },
  ],
}

const clientsList = {
  clientColumns: [
    {
      field: 'id',
      headerName: strings.CLIENT_ID,
      minWidth: 400,
      flex: 1,
      hideable: false,
      handleClick: (_val: number | string, _rowData: ICompanyRequest) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        )
      },
    },
    {
      field: 'fedTaxNumber',
      headerName: strings.FED_TAX_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'dunsNumber',
      headerName: strings.DUNS_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'mcNumber',
      headerName: strings.MC_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'dotNumber',
      headerName: strings.DOT_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'creditLimit',
      headerName: strings.CREDIT_LIMIT,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'ineligibleDays',
      headerName: strings.INELIGIBLE_DAYS,
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'phoneAlternate',
      headerName: strings.PHONE_ALTERNATE,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'fax',
      headerName: strings.FAX,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'active',
      headerName: strings.STATUS,
      width: 100,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container
            sx={{
              display: 'block',
            }}
          >
            <Chip label={params.value ? 'Active' : 'Inactive'} color={params.value ? 'success' : 'error'} />
          </Container>
        )
      },
    },
  ],

  actionList: [
    {
      id: 'editTable',
      name: 'Edit',
      icon: 'icon_edit',
      iconSize: 17,
      className: 'tableActionButton',
    },
  ],
}

const debtorsList = {
  debtorColumns: [
    {
      field: 'id',
      headerName: strings.DEBTOR_ID,
      minWidth: 400,
      flex: 1,
      hideable: false,
      handleClick: (_val: number | string, _rowData: ICompanyRequest) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        )
      },
    },
    {
      field: 'mcNumber',
      headerName: strings.MC_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'dotNumber',
      headerName: strings.DOT_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'buyStatus',
      headerName: strings.BUY_STATUS,
      width: 100,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container
            sx={{
              display: 'block',
            }}
          >
            <Chip label={params.value ? 'Active' : 'Inactive'} color={params.value ? 'success' : 'error'} />
          </Container>
        )
      },
    },
  ],

  actionList: [
    {
      id: 'editTable',
      name: 'Edit',
      icon: 'icon_edit',
      iconSize: 17,
      className: 'tableActionButton',
    },
  ],
}

export {
  dataGridSx,
  userFilters,
  usersList,
  factorFilters,
  factorsList,
  clientFilters,
  debtorFilters,
  clientsList,
  debtorsList,
}

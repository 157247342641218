export const termsEn = {
  // BEGIN Michael
  TERM: 'Term',
  TERMS: 'Terms',
  TERM_NAME: 'Term Name',
  PURCHASE_FEES: 'Purchase Fees',
  ESCROW_RESERVE_RATE: 'Escrow Reserve Rate',
  FEE_PER_INVOICE: 'Fee Per Invoice',
  MINIMUM_PURCHASE_FEE: 'Minimum Purchase Fee',
  UPDATE_TERM_DETAILS_SUCCESSFULLY: 'Updated Term Details Successfully',
  UPDATE_PURCHASE_FEES_SUCCESSFULLY: 'Updated Purchase Fees Successfully',
  UPDATE_ESCROW_PARTIAL_PAYMENT_SUCCESSFULLY: 'Updated Escrow/Partial Payment Successfully',
  TERM_IS_INACTIVE: "The '{term}' is inactive. Please reactivate the term or select another one.",
  NFE_RATES: 'NFE Rates',
  COLLECTION_FEES_LIST: 'Collection Fees List',
  MINIMUM_COLLECTION_FEES: 'Minimum Collection Fees',
  CALCULATE_AGE_BASE_ON: 'Calculate Age Base On',
  FIRST_DAY_COUNTS: 'First Day Counts',
  COLLECTION_FEES_UPDATED_SUCCESSFULLY: 'Collection Fees Updated Successfully',
  FUNDING_DATE: 'Funding Date',
  ADD_NEW_COLLECTION_FEES: 'Add New Collection Fees',
  START_DAY: 'Start Day',
  THRU_DAY: 'Thru Day',
  COLLECTION_RATE: 'Collection Rate',
  START_DAY_WARNING: 'Start Day must be greater or equal to Thru Day of the previous collection fee.',
  THRU_DAY_WARNING: 'Thru Day must be greater than Start Day of current Collection Fee',
  TERM_IS_NOT_EDITABLE: 'Term is in use and cannot be edited',
  ESCROW_PARTIAL_PAYMENT: 'Escrow/Partial Payment',
  BASE_COLLECTION_FEES_ON: 'Base Collection Fees On',
  PARTIAL_PAYMENT: 'Partial Payment',
  PAID_IN_FULL: 'Paid in Full',
  ESCROW_RELEASED_WHEN_PARTIALLY_PAID: 'Escrow Released When Partially Paid',
  ESCROW_RELEASE_WHEN_PAID_IN_FULL: 'Escrow Released When Paid In Full',
  ESCROW_BYPASSED_TO_CASH_RESERVES: 'Escrow Bypassed To Cash Reserves',
  CHARGE_COLLECTION_FEES_WHEN: 'Charge Collection Fees When',
  CLEAR_ALL: 'Clear All',
  CLEAR_ALL_COLLECTION_FEES: 'Clear All Collection Fees',
  CLEAR_ALL_CONFIRMATION: 'Are you sure you want to clear all current collection fees?',
  QUICK_GENERATE: 'Quick Generate',
  QUICK_GENERATE_DESCRIPTION: 'Quick Generate Collection Fees. Only available if the collection fees list is empty',
  FIRST_COLLECTION_RATE_ON_DAY: 'First Collection Rate On Day',
  COLLECTION_FEE_DURATION: 'Collection Fee Duration',
  START_COLLECTION_RATE: 'Start Collection Rate',
  COLLECTION_RATE_INCREMENT: 'Collection Rate Increment',
  HOW_MANY_COLLECTION_FEES: 'How Many Collection Fees?',
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  TERM_TYPE: 'Term Type',
  INTEREST: 'Interest',
  ADVANCE_RATE: 'Advance Rate',
  PURCHASE_RATE: 'Purchase Rate',

  COLLECTION_FEES: 'Collection Fees',
  CREATE_TERM: 'Create Term',

  TERM_TYPE_NFE: 'NFE',
  TERM_TYPE_ADVANCE: 'Advanced',
  TERM_CREATE_SUCCESS: 'Term created successfully',
  TERM_DETAILS: 'Term Details',
  FLAT_INTEREST_RATE: 'Flat Interest Rate',
  INTEREST_RATE_PLUS: 'Interest Rate Plus',
  FLOOR_OF: 'Floor Of',
  PER: 'Per',
  FLOAT_DAYS_ARE: 'Float Days Are',
  FLOAT_DAYS: 'Float Days',
  NFE_TYPE: 'NFE Type',
  FLAT_RATE: 'Flat Rate',
  PRIME_PLUS: 'Prime Plus',
  LIBOR: 'Libor',
  SOFR: 'Sofr',
  UPDATE_NFE_RATE_SUCCESSFULLY: 'Updated NFE Rate Successfully',
  ALERT_CREATE_TERM_BLADE: 'You will be able to add collection fees after you create the term',
  TERM_INFO: 'Term Info',
  // END Fabian
}

export const termsEs: typeof termsEn = {
  // BEGIN Michael
  TERM: 'Término',
  TERMS: 'Términos',
  TERM_NAME: 'Nombre del Término',
  PURCHASE_FEES: 'Honorarios de Compra',
  ESCROW_RESERVE_RATE: 'Tasa de Reserva de Fideicomiso',
  FEE_PER_INVOICE: 'Honorarios por Factura',
  MINIMUM_PURCHASE_FEE: 'Honorarios Mínimos de Compra',
  UPDATE_TERM_DETAILS_SUCCESSFULLY: 'Los detalles del Término se actualizaron exitosamente',
  UPDATE_PURCHASE_FEES_SUCCESSFULLY: 'Los honorarios de compra se actualizaron exitosamente',
  UPDATE_ESCROW_PARTIAL_PAYMENT_SUCCESSFULLY: 'El Fideicomiso/Pago Parcial se actualizó exitosamente',
  TERM_IS_INACTIVE: 'El {term} está inactivo. Por favor, reactiva el término o selecciona otro.',
  NFE_RATES: 'Tasas NFE',
  COLLECTION_FEES_LIST: 'Lista de Honorarios de Cobranza',
  MINIMUM_COLLECTION_FEES: 'Honorarios Mínimos de Cobranza',
  CALCULATE_AGE_BASE_ON: 'Calcular Edad Basado En',
  FIRST_DAY_COUNTS: 'El Primer Día Cuenta',
  COLLECTION_FEES_UPDATED_SUCCESSFULLY: 'Los honorarios de cobranza se actualizaron exitosamente',
  FUNDING_DATE: 'Fecha de Financiamiento',
  ADD_NEW_COLLECTION_FEES: 'Agregar Nuevos Honorarios de Cobranza',
  START_DAY: 'Día de Inicio',
  THRU_DAY: 'Día de Finalización',
  COLLECTION_RATE: 'Tasa de Cobranza',
  START_DAY_WARNING:
    'El Día de Inicio debe ser mayor o igual al Día de Finalización del honorario de cobranza anterior.',
  THRU_DAY_WARNING: 'El Día de Finalización debe ser mayor al Día de Inicio del honorario de cobranza actual.',
  TERM_IS_NOT_EDITABLE: 'El Término está en uso y no puede ser editado',
  ESCROW_PARTIAL_PAYMENT: 'Fideicomiso/Pago Parcial',
  BASE_COLLECTION_FEES_ON: 'Basar Honorarios de Cobranza en',
  PARTIAL_PAYMENT: 'Pago Parcial',
  PAID_IN_FULL: 'Pagado en su Totalidad',
  ESCROW_RELEASED_WHEN_PARTIALLY_PAID: 'Fideicomiso liberado cuando se paga parcialmente',
  ESCROW_RELEASE_WHEN_PAID_IN_FULL: 'Fideicomiso liberado cuando se paga en su totalidad',
  ESCROW_BYPASSED_TO_CASH_RESERVES: 'Fideicomiso desviado a reservas de tesorería',
  CHARGE_COLLECTION_FEES_WHEN: 'Cobrar honorarios de cobranza cuando',
  CLEAR_ALL: 'Limpiar Todo',
  CLEAR_ALL_COLLECTION_FEES: 'Limpiar Todos los Honorarios de Cobranza',
  CLEAR_ALL_CONFIRMATION: '¿Estás seguro de que quieres limpiar todos los honorarios de cobranza actuales?',
  QUICK_GENERATE: 'Generar Rápidamente',
  QUICK_GENERATE_DESCRIPTION:
    'Generar Rápidamente Honorarios de Cobranza. Solo disponible si la lista de honorarios de cobranza está vacía',
  FIRST_COLLECTION_RATE_ON_DAY: 'Primera Tasa de Cobranza en el Día',
  COLLECTION_FEE_DURATION: 'Duración de Honorarios de Cobranza',
  START_COLLECTION_RATE: 'Tasa de Inicio de Cobranza',
  COLLECTION_RATE_INCREMENT: 'Incremento de Tasa de Cobranza',
  HOW_MANY_COLLECTION_FEES: '¿Cuántos Honorarios de Cobranza?',
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  TERM_TYPE: 'Tipo de Término',
  INTEREST: 'Interés',
  ADVANCE_RATE: 'Tasa de Avance',
  PURCHASE_RATE: 'Tasa de Compra',

  COLLECTION_FEES: 'Honorarios de Cobranza',
  CREATE_TERM: 'Crear Término',

  TERM_TYPE_NFE: 'NFE',
  TERM_TYPE_ADVANCE: 'Avanzado',
  TERM_CREATE_SUCCESS: 'Término creado exitosamente',
  TERM_DETAILS: 'Detalles del Término',
  FLAT_INTEREST_RATE: 'Tasa de Interés Plana',
  INTEREST_RATE_PLUS: 'Tasa de Interés Plus',
  FLOOR_OF: 'Piso de',
  PER: 'Por',
  FLOAT_DAYS_ARE: 'Los días flotantes son',
  FLOAT_DAYS: 'Días Flotantes',
  NFE_TYPE: 'Tipo NFE',
  FLAT_RATE: 'Tasa Plana',
  PRIME_PLUS: 'Prime Plus',
  LIBOR: 'Libor',
  SOFR: 'Sofr',
  UPDATE_NFE_RATE_SUCCESSFULLY: 'Tasa NFE actualizada exitosamente',
  ALERT_CREATE_TERM_BLADE: 'Podrás agregar honorarios de cobranza después de crear el término',
  TERM_INFO: 'Información del Término',
  // END Fabian
}

export const quickPayFeeEn = {
  // BEGIN Esteban
  ACCOUNT_NICKNAME: 'Account Nickname',
  QUICK_PAY_FEE: 'Quick Pay Fee',
  QUICK_PAY_FEES: 'Quick Pay Fees',
  QUICK_PAY_FEE_CREATED_SUCCESSFULLY: 'Quick Pay Fee Created Successfully',
  QUICK_PAY_FEE_UPDATED_SUCCESSFULLY: 'Quick Pay Fee Updated Successfully',
  FACTOR_RATE: 'Factor Rate',
  BROKER_RATE: 'Broker Rate',
  FACTOR_FEE: 'Factor Fee',
  BROKER_FEE: 'Broker Fee',
  // END Esteban
  // BEGIN Michael
  TOTAL_QUICK_PAY_FEES: 'Total QP Fee Amount',
  QP_FEES: 'QP Fees',
  NOT_QP_FEES: 'Not Quick Pay Fees found. Please add a new one.',
  // END Michael
}

export const quickPayFeeEs: typeof quickPayFeeEn = {
  // BEGIN Esteban
  ACCOUNT_NICKNAME: 'Apodo de la cuenta',
  QUICK_PAY_FEE: 'Tarifa de Pago Rápido',
  QUICK_PAY_FEES: 'Tarifas de Pago Rápido',
  QUICK_PAY_FEE_CREATED_SUCCESSFULLY: 'Tarifa de Pago Rápido Creada Exitosamente',
  QUICK_PAY_FEE_UPDATED_SUCCESSFULLY: 'Tarifa de Pago Rápido Actualizada Exitosamente',
  FACTOR_RATE: 'Tasa de Factor',
  BROKER_RATE: 'Tasa de Broker',
  FACTOR_FEE: 'Tarifa de Factor',
  BROKER_FEE: 'Tarifa de Broker',
  // END Esteban
  // BEGIN Michael
  TOTAL_QUICK_PAY_FEES: 'Monto Total de Tarifa de Pago Rápido',
  QP_FEES: 'Tarifas de Pago Rápido',
  NOT_QP_FEES: 'No se encontraron Tarifas de Pago Rápido. Por favor, añada una nueva.',
  // END Michael
}

import strings from 'l10n'
import { percentage } from 'tools/format'
import { useMemo, useState } from 'react'
import { Toast as toast, TypoGraph } from 'components/ui'
import QuickPayFeesDenseTable from './QuickPayFeesDenseTable'
import { QuickPayFeesUpdateBlade } from './QuickPayFeesUpdateBlade'
import { ift, isDefined } from 'tools/common'
import {
  FactorDefaultQuickPayFeeInfo,
  FactorDefaultQuickPayFeeRequest,
  FactorDefaultRequest,
} from 'types/factorDefault'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import uuid from 'react-uuid'
import styles from '../../SystemDefaultsSections.module.scss'

type QuickPayFeesDefaultsTableProps = {
  quickPayFees?: FactorDefaultQuickPayFeeInfo[]
}

const columns = [
  {
    valueName: 'id',
    displayName: strings.ID,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    hide: true,
  },
  {
    valueName: 'name',
    displayName: strings.NAME,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
  },
  {
    valueName: 'days',
    displayName: strings.DAYS,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    formatter: undefined,
  },
  {
    valueName: 'factorRate',
    displayName: strings.FACTOR_RATE,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    formatter: (value: number) => {
      return <TypoGraph variant="body1" content={`${percentage(value)}%`} />
    },
  },
  {
    valueName: 'brokerRate',
    displayName: strings.BROKER_RATE,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    formatter: (value: number) => {
      return <TypoGraph variant="body1" content={`${percentage(value)}%`} />
    },
  },
  {
    valueName: 'factorFee',
    displayName: strings.FACTOR_FEE,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    formatter: (value: string) => {
      return <TypoGraph variant="body1" content={`$${value}`} />
    },
  },
  {
    valueName: 'brokerFee',
    displayName: strings.BROKER_FEE,
    maxWidthCss: '175px',
    height: '56px',
    fontSize: '16px',
    formatter: (value: string) => {
      return <TypoGraph variant="body1" content={`$${value}`} />
    },
  },
]

export const QuickPayFeeSection = (props: QuickPayFeesDefaultsTableProps) => {
  const { quickPayFees } = props
  const [selectedQuickPayFee, setSelectedQuickPayFee] = useState<FactorDefaultQuickPayFeeInfo | null>(null)
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const queryClient = useQueryClient()
  //* The original quickPayFees array does not have an id field. Add it for updating purposes. Use memo to avoid unnecessary re-renders and changes in the id
  const quickPayFeesWithId = useMemo(() => {
    return quickPayFees?.map((qp) => ({ ...qp, id: uuid() }))
  }, [quickPayFees])

  const { mutate: updateQuickPayFee, isLoading: isUpdateQuickPayFeeMutation } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: () => {
      toast({
        type: 'success',
        subTitle: strings.QUICK_PAY_FEE_UPDATED_SUCCESSFULLY,
      })
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      setShowUpdateBlade(false)
    },
  })

  const handleEdit = (row: FactorDefaultQuickPayFeeInfo) => {
    setSelectedQuickPayFee(row)
    setShowUpdateBlade(true)
  }

  const handleDelete = (row: FactorDefaultQuickPayFeeInfo) => {
    const updatedQuickPayFees = quickPayFeesWithId?.filter((qp) => qp.id !== row.id)
    if (isDefined(updatedQuickPayFees)) {
      const quickPayFeeRequest: FactorDefaultQuickPayFeeRequest = {
        quickPayFees: updatedQuickPayFees,
      }
      const payload: FactorDefaultRequest = {
        brokerQuickPayFeeValues: quickPayFeeRequest,
      }
      updateQuickPayFee(payload)
    }
  }

  const handleUpdateSubmitted = (qp: FactorDefaultQuickPayFeeInfo) => {
    //* Replace the selectedQuickPayFee with the updated one. Match by id
    const updatedQuickPayFees = quickPayFeesWithId?.map((oldQuickPayFee) =>
      oldQuickPayFee.id === qp.id ? qp : oldQuickPayFee
    )
    if (isDefined(updatedQuickPayFees)) {
      const quickPayFeeRequest: FactorDefaultQuickPayFeeRequest = {
        quickPayFees: updatedQuickPayFees,
      }
      const payload: FactorDefaultRequest = {
        brokerQuickPayFeeValues: quickPayFeeRequest,
      }
      updateQuickPayFee(payload)
    }
  }

  const notQuickPayFeeMessage = ift(
    <TypoGraph className={styles.notQuickPayFeeMessage} variant="body1" content={strings.NOT_QP_FEES} />,
    !isDefined(quickPayFees) || quickPayFees.length === 0
  )

  return (
    <>
      {notQuickPayFeeMessage}
      {isDefined(quickPayFeesWithId) && (
        <QuickPayFeesDenseTable
          columns={columns}
          rows={quickPayFeesWithId}
          allowEdit
          handleEdit={handleEdit}
          allowDelete
          handleDelete={handleDelete}
        />
      )}
      {isDefined(selectedQuickPayFee) && (
        <QuickPayFeesUpdateBlade
          title={`${strings.UPDATE} ${strings.QUICK_PAY_FEE}`}
          selectedQuickPayFee={selectedQuickPayFee as FactorDefaultQuickPayFeeInfo}
          showBlade={showUpdateBlade}
          handleClose={() => setShowUpdateBlade(false)}
          handleSubmitted={handleUpdateSubmitted}
          loading={isUpdateQuickPayFeeMutation}
        />
      )}
    </>
  )
}

export const fuelAdvancesEn = {
  //Start Michael
  FUEL_ADVANCE: 'Fuel Advance',
  FUEL_ADVANCES: 'Fuel Advances',
  FUEL_ADVANCES_WERE_SELECTED_FOR_THIS_ACTION: 'fuel advances were selected for this action',
  FUEL_ADVANCE_AMOUNT: 'Fuel Advance Amount',
  TOTAL_FUEL_ADVANCE_AMOUNT: 'Total Fuel Advance Amount',
  FUEL_ADVANCE_FEE_AMOUNT: 'Fuel Advance Fee Amount',
  FUEL_ADVANCE_FEE_PERCENTAGE: 'Fuel Advance Fee Percentage',
  FUEL_ADVANCE_AMOUNT_CANNOT_GREATER_INVOICE_AMOUNT: 'Fuel Advance Amount cannot be greater than Invoice Amount',
  FUEL_ADVANCE_FEE_AMOUNT_CANNOT_GREATER_FUEL_ADVANCE_AMOUNT:
    'Fuel Advance Fee Amount cannot be greater than Fuel Advance Amount',
  FUEL_ADVANCE_CREATED_SUCCESSFULLY: 'Fuel Advance created successfully',
  FUEL_ADVANCE_UPDATED_SUCCESSFULLY: 'Fuel Advance updated successfully',
  FUEL_ADVANCE_NOTES_UPDATED_SUCCESSFULLY: 'Fuel Advance notes updated successfully',
  FUEL_ADVANCE_FEE_OR_PERCENTAGE_REQUIRED:
    'Please enter either "Fuel Advance Fee Amount" or "Fuel Advance Fee Percentage"',
  ZERO_OUT_PAYMENT_FEE: 'Zero Out Payment Fee',
  FUEL_ADVANCES_APPROVED_FOR_FUNDING_SUCCESSFULLY: 'Fuel Advances approved for funding successfully!',
  APPROVE_FOR_FUNDING_FUEL_ADVANCE_CONFIRMATION: 'Are you sure you want to approve these fuel advances for funding?',
  TOTAL_FUEL_ADVANCES: 'Total Fuel Advances',
  //End Michael
  //Start Fabian
  DOCUMENTS_FUEL_ADVANCE_UPLOADED_SUCCESSFULLY: 'Documents for Fuel Advance uploaded successfully',
  //End Fabian
  //Start Esteban
  FUEL_ADVANCE_REJECT_CONFIRMATION: 'Are you sure you want to reject these fuel advances?',
  FUEL_ADVANCE_PENDING_CONFIRMATION: 'Are you sure you want to move these fuel advances to pending?',
  FUEL_ADVANCE_REJECTED_SUCCESSFULLY: 'Fuel Advance/s rejected successfully!',
  FUEL_ADVANCE_PENDING_SUCCESSFULLY: 'Fuel Advance/s moved to pending successfully!',
  FUEL_ADVANCE_MOVE_TO_REJECTED: 'Move to Rejected',
  FUEL_ADVANCE_MOVE_TO_PENDING: 'Move to Pending',
  //End Esteban
  //Start Fco
  FUEL_ADVANCE_MAX_AMOUNT_INFO: 'Max amount: {maxAmount} ({percent}%)',
  FUEL_ADVANCE_MIN_FEE_INFO: 'Min fee: {minFee}',
  FUEL_ADVANCE_MAX_AMOUNT_WARNING: "Fuel Advance Amount exceeds the client's limit",
  FUEL_ADVANCE_MIN_FEE_WARNING: "Fuel Advance Fee Amount is below the client's limit",
  //End Fco
}

export const fuelAdvancesEs: typeof fuelAdvancesEn = {
  //Start Michael
  FUEL_ADVANCE: 'Anticipo de combustible',
  FUEL_ADVANCES: 'Anticipos de combustible',
  FUEL_ADVANCES_WERE_SELECTED_FOR_THIS_ACTION: 'anticipos de combustible fueron seleccionados para esta acción',
  FUEL_ADVANCE_AMOUNT: 'Monto del anticipo de combustible',
  TOTAL_FUEL_ADVANCE_AMOUNT: 'Monto total del anticipo de combustible',
  FUEL_ADVANCE_FEE_AMOUNT: 'Monto de la tarifa de anticipo de combustible',
  FUEL_ADVANCE_FEE_PERCENTAGE: 'Porcentaje de tarifa de anticipo de combustible',
  FUEL_ADVANCE_AMOUNT_CANNOT_GREATER_INVOICE_AMOUNT:
    'El monto del anticipo de combustible no puede ser mayor que el monto de la factura',
  FUEL_ADVANCE_FEE_AMOUNT_CANNOT_GREATER_FUEL_ADVANCE_AMOUNT:
    'El monto de la tarifa de anticipo de combustible no puede ser mayor que el monto del anticipo de combustible',
  FUEL_ADVANCE_CREATED_SUCCESSFULLY: 'Anticipo de combustible creado correctamente',
  FUEL_ADVANCE_UPDATED_SUCCESSFULLY: 'Anticipo de combustible actualizado correctamente',
  FUEL_ADVANCE_NOTES_UPDATED_SUCCESSFULLY: 'Notas de anticipo de combustible actualizadas correctamente',
  FUEL_ADVANCE_FEE_OR_PERCENTAGE_REQUIRED:
    'Ingrese "Monto de la tarifa de anticipo de combustible" o "Porcentaje de tarifa de anticipo de combustible"',
  ZERO_OUT_PAYMENT_FEE: 'Tarifa de pago cero',
  FUEL_ADVANCES_APPROVED_FOR_FUNDING_SUCCESSFULLY: '¡Anticipos de combustible aprobados para financiamiento con éxito!',
  APPROVE_FOR_FUNDING_FUEL_ADVANCE_CONFIRMATION:
    '¿Estás seguro de que deseas aprobar estos anticipos de combustible para su financiamiento?',
  TOTAL_FUEL_ADVANCES: 'Total de anticipos de combustible',
  //End Michael
  //Start Fabian
  DOCUMENTS_FUEL_ADVANCE_UPLOADED_SUCCESSFULLY: 'Documentos para anticipo de combustible cargados correctamente',
  //End Fabian
  //Start Esteban
  FUEL_ADVANCE_REJECT_CONFIRMATION: '¿Estás seguro de que deseas rechazar estos anticipos de combustible?',
  FUEL_ADVANCE_PENDING_CONFIRMATION: '¿Estás seguro de que deseas poner estos anticipos de combustible en pendiente?',
  FUEL_ADVANCE_REJECTED_SUCCESSFULLY: 'Anticipo de combustible rechazado correctamente',
  FUEL_ADVANCE_PENDING_SUCCESSFULLY: 'Anticipo de combustible pendiente correctamente',
  FUEL_ADVANCE_MOVE_TO_REJECTED: 'Mover a Rechazado',
  FUEL_ADVANCE_MOVE_TO_PENDING: 'Mover a Pendiente',
  //End Esteban
  //Start Fco
  FUEL_ADVANCE_MAX_AMOUNT_INFO: 'Monto máximo: {maxAmount} ({percent}%)',
  FUEL_ADVANCE_MIN_FEE_INFO: 'Tarifa mínima: {minFee}',
  FUEL_ADVANCE_MAX_AMOUNT_WARNING: 'El monto del anticipo de combustible excede el límite del cliente',
  FUEL_ADVANCE_MIN_FEE_WARNING:
    'El monto de la tarifa de anticipo de combustible está por debajo del límite del cliente',
  //End Fco
}

import MuiDivider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

type DividerProps = {
  /** The title is an optional string displayed at the center of the divider.*/
  title?: string
  /** The textAlign variable represent the alignment of the title, with valid values being center, left, or right. The default value is left.*/
  textAlign?: 'center' | 'left' | 'right'
  /** The variant is responsible for setting the width and style of the divider. Valid options are fullWidth (default), inset and middle. */
  variant?: 'fullWidth' | 'inset' | 'middle' | undefined
  /** The orientation variable indicates the direction of the line. Valid options are horizontal (default) and vertical.*/
  orientation?: 'horizontal' | 'vertical'
  /** The sx  is a shorthand for defining custom styles for the component using the Material-UI styling solution. It allows you to apply custom CSS styles to the component without having to write CSS classes or use inline styles.*/
  sx?: object
  marginTop?: string
  marginBottom?: string
}

const DividerRoot = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.h3,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const Divider = (props: DividerProps) => {
  const {
    title,
    textAlign = 'left',
    variant = 'fullWidth',
    orientation = 'horizontal',
    sx,
    marginBottom,
    marginTop,
  } = props
  return (
    <DividerRoot
      sx={{
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      <MuiDivider sx={sx} textAlign={textAlign} variant={variant} orientation={orientation}>
        {title ? title : ''}
      </MuiDivider>
    </DividerRoot>
  )
}

export default Divider

import { LoginRequest, VerifyOtpRequest, LoginResponse, ChangePasswordRequest, LoginHeaders } from 'types/login'
import request from 'tools/request'

export async function login(loginRequest: LoginRequest, header: LoginHeaders) {
  const data = await request
    .post<LoginResponse>('authentications/generate-token', loginRequest, {
      headers: header,
    })
    .then((r) => r.data)
  return data
}

export async function verifyOtp(payload: VerifyOtpRequest) {
  const data = await request.post<LoginResponse>('authentications/login', payload).then((r) => r.data)
  return data
}

export const resendOtp = async (email: string) => {
  const url = `authentications/resend-otp-code`
  return await request.post(url, {
    username: email,
  })
}

export const resetPassword = async (email: string, factorId?: string) => {
  const url = 'authentications/reset-password-link'
  return await request.post(
    url,
    {
      username: email,
    },
    {
      headers: {
        factorId: factorId || '',
      },
    }
  )
}

export const changePassword = async (payload: ChangePasswordRequest) => {
  return await request.post(`authentications/update-password`, payload)
}

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const DashboardPage = lazy(() => import('pages/dashboard/page'))

const DashboardRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<DashboardPage />} />} />
    </Routes>
  )
}

export default DashboardRoutes

import { useFormContext, Controller } from 'react-hook-form'
import DropdownField, { DropdownFieldProps } from 'components/form/DropdownField'

type DropdownFormFieldProps = DropdownFieldProps & {
  /** The name is mandatory and specifies the name of the field in the form.*/
  name: string
  /** The required is optional and sets whether the field is required for form submission. Its default value is false.*/
  required?: boolean
  /** The initialValue is optional and sets the initial value of the field. Its default value is undefined.*/
  initialValue?: string
  /** The notOptionText is a optional props to display a custom message when not have options. */
  notOptionText?: string
  endAdornment?: React.ReactNode
  endAdornmentMarginTop?: string
}

const DropdownFormField = (props: DropdownFormFieldProps) => {
  const {
    name,
    required = false,
    options = [],
    initialValue = null,
    notOptionText = '',
    endAdornment,
    endAdornmentMarginTop,
  } = props
  const { control } = useFormContext()
  const isRequired = required ? 'Field is Required' : undefined
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue}
      rules={{
        required: isRequired,
        validate: {},
      }}
      render={({ field: { onChange, onBlur, value = '', ref }, fieldState: { error } }) => {
        return (
          <DropdownField
            options={options}
            notOptionText={notOptionText}
            value={value ? value : ''}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            endAdornment={endAdornment}
            endAdornmentMarginTop={endAdornmentMarginTop}
            {...props}
          />
        )
      }}
    />
  )
}

export default DropdownFormField

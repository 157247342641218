import request from 'tools/request'
import {RoleResponse, RoleListResponse, PermissionListResponse, ListRolesParams, ListPermissionParams} from 'types/rolePermission'

export const listRoles = async (  params?: ListRolesParams) => {
  return await request.get<RoleListResponse>('roles', {params: params}  ).then((res) => res.data)
}

export const listCompanyRoles = async () => {
  return await request.get<RoleListResponse>('roles', {params: {isCompanyRole: true}}).then((res) => res.data)
}

export const loadPermissions = async (roleId: string) => {
  return await request.get<RoleResponse>(`roles/${roleId}`).then((res) => res.data)
}

export const getMyRole = async () => {
  return await request.get<RoleResponse>(`roles/me`).then((res) => res.data)
}

export const listPermissions = async (params?: ListPermissionParams) => {
  return await request.get<PermissionListResponse>('permissions', { params: params }).then((res) => res.data)
}

export const createRole = async (payload: any) => {
  return await request.post<any>('roles', payload)
}

export const updateRole = async (roleId: string, payload: any) => {
  const data = await request.put<any>(`roles/${roleId}`, payload)
  return data
}

import strings from 'l10n'

const BreadcrumbList = {
  dashboard: {
    '/dashboard': 'Dashboard',
  },
  companies: {
    '/companies': strings.COMPANIES,
    '/companies/:companyName/:companyId': (params: { companyName: string }) => params.companyName,
  },
  terms: {
    '/terms': strings.TERMS,
    '/terms/:termName/:termId': (params: { termName: string }) => params.termName,
  },
  invoices: {
    '/invoices': strings.INVOICES,
    '/invoices/:invoiceNumber/:invoiceId': (params: { invoiceNumber: string }) => params.invoiceNumber,
  },
  fuelAdvances: {
    '/fuel-advances': strings.FUEL_ADVANCES,
    '/fuel-advances/:referenceNumber/:fuelAdvanceId': (params: { referenceNumber: string }) => params.referenceNumber,
  },
  funding: {
    '/funding': strings.FUNDING,
    '/funding/invoiceGroups/:code/:invoiceGroupId': (params: { code: string }) => params.code,
    '/funding/disbursements/:disbursementCode/:disbursementId': (params: { disbursementCode: string }) =>
      params.disbursementCode,
  },
  payments: {
    '/payments': strings.PAYMENTS,
    '/payments/:transactionRef/:paymentId': (params: { transactionRef: string }) => params.transactionRef,
  },
  chargebacks: {
    '/chargebacks': strings.CHARGEBACKS,
  },
  accounting: {
    '/accounting': strings.ACCOUNTING,
  },
  collections: {
    '/collections': strings.COLLECTIONS,
  },
  creditRequest: {
    '/credit-request': strings.CREDIT_REQUEST,
  },
  ledgers: {
    '/ledgers': strings.LEDGERS,
  },
  reports: {
    '/reports': strings.REPORTS,
    '/reports/summary': 'Summary',
    '/reports/:reportName/:reportId': (params: { reportName: string }) => params.reportName,
  },
  reserveReleases: {
    '/reserve-releases': strings.RESERVE_RELEASES,
    '/reserve-releases/:code/:reserveReleaseId': (params: { code: string }) => params.code,
  },
  documents: {
    '/documents': strings.DOCUMENTS,
    '/documents/:name/:id': (params: { name: string }) => params.name,
  },
  settings: {
    '/settings': strings.SETTINGS,
    '/settings/adminSettings': 'Admin Settings',
    '/settings/profile': 'Profile',
  },
  outOfSystemInvoices: {
    '/out-of-system-invoices': strings.OUT_OF_SYSTEM_INVOICES,
    '/out-of-system-invoices/:outOfSystemInvoiceNumber/:outOfSystemInvoiceId': (params: { invoiceNumber: string }) =>
      params.invoiceNumber,
  },
}

const BreadcrumbIcons: { [key: string]: string } = {
  dashboard: 'menubar/icon_dashboard_active',
  companies: 'menubar/icon_companies_active',
  terms: 'menubar/icon_terms_active',
  invoices: 'menubar/icon_invoices_active',
  'fuel-advances': 'menubar/icon_fuel_advances_active',
  funding: 'menubar/icon_funding_active',
  payments: 'menubar/icon_payments_active',
  chargebacks: 'menubar/icon_chargeback_active',
  accounting: 'menubar/icon_accounting_active',
  collections: 'menubar/icon_collections_active',
  'credit-request': 'menubar/icon_credit_request_active',
  ledgers: 'menubar/icon_ledgers_active',
  reports: 'menubar/icon_reports_active',
  'reserve-releases': 'menubar/icon_reserve_release_active',
  documents: 'menubar/icon_documents_active',
  settings: 'menubar/icon_settings_active',
  'out-of-system-invoices': 'menubar/icon_invoices_active',
}

/** ***** Breadcrumb Logic *****
 * Switch statement to determine which breadcrumbs are to display on the pages
 */
const renderBreadcrumbs = (path: string) => {
  let breadcrumbs = null
  switch (true) {
    case /\/dashboard/.test(path):
      breadcrumbs = BreadcrumbList.dashboard
      break
    case /\/companies/.test(path):
      breadcrumbs = BreadcrumbList.companies
      break
    case /\/terms/.test(path):
      breadcrumbs = BreadcrumbList.terms
      break
    case /\/invoices/.test(path):
      breadcrumbs = BreadcrumbList.invoices
      break
    case /\/fuel-advances/.test(path):
      breadcrumbs = BreadcrumbList.fuelAdvances
      break
    case /\/funding/.test(path):
      breadcrumbs = BreadcrumbList.funding
      break
    case /\/payments/.test(path):
      breadcrumbs = BreadcrumbList.payments
      break
    case /\/chargebacks/.test(path):
      breadcrumbs = BreadcrumbList.chargebacks
      break
    case /\/accounting/.test(path):
      breadcrumbs = BreadcrumbList.accounting
      break
    case /\/ledgers/.test(path):
      breadcrumbs = BreadcrumbList.ledgers
      break
    case /\/collections/.test(path):
      breadcrumbs = BreadcrumbList.collections
      break
    case /\/credit-request/.test(path):
      breadcrumbs = BreadcrumbList.creditRequest
      break
    case /\/reports/.test(path):
      breadcrumbs = BreadcrumbList.reports
      break
    case /\/reserve-releases/.test(path):
      breadcrumbs = BreadcrumbList.reserveReleases
      break
    case /\/settings/.test(path):
      breadcrumbs = BreadcrumbList.settings
      break
    case /\/documents/.test(path):
      breadcrumbs = BreadcrumbList.documents
      break
    case /\/out-of-system-invoices/.test(path):
      breadcrumbs = BreadcrumbList.outOfSystemInvoices
      break
    default:
      break
  }
  return breadcrumbs
}

export const getBreadcrumbsIcon = (pathname: string) => {
  // Split the pathname into an array of parts using '/' as the delimiter
  const pathParts = pathname.split('/')

  // Get the first part of the path (excluding the empty string at the beginning)
  const firstName = pathParts[1]
  return BreadcrumbIcons[firstName]
}

export default renderBreadcrumbs

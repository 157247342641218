import { useState } from 'react'
import { SystemDefaultsSectionProps } from '../../SystemDefaultsComponent'
import { ift, isDefined } from 'tools/common'
import strings from 'l10n'
import { ActionButton } from 'types/common'
import { ScrollSpySection } from 'components/ui'
import styles from '../SystemDefaultsSections.module.scss'
import SectionField from 'components/ui/dataDisplay/SectionField'
import { RelationshipSystemDefaultUpdateBlade } from './RelationshipSystemDefaultUpdateBlade'

export const RelationshipSystemDefaults = (props: SystemDefaultsSectionProps) => {
  const { factorDefault, canModifyFactorDefaultValues } = props
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const relationshipDefaultInfo = factorDefault?.relationshipDefaultValues

  const btnEdit = ift(
    {
      label: strings.EDIT,
      variant: 'outlined',
      onClick: () => {
        setShowUpdateBlade(true)
      },
    },
    canModifyFactorDefaultValues
  ) as ActionButton

  const relationshipCreditLimitApproved = ift(
    relationshipDefaultInfo?.relationshipCreditLimitApproved ? strings.YES : strings.NO,
    isDefined(relationshipDefaultInfo?.relationshipCreditLimitApproved)
  ) as string

  const relationshipSendNoa = ift(
    relationshipDefaultInfo?.relationshipSendNoa ? strings.YES : strings.NO,
    isDefined(relationshipDefaultInfo?.relationshipSendNoa)
  ) as string

  return (
    <>
      <ScrollSpySection title={strings.DEBTOR_RELATIONSHIP} actionBtn={btnEdit}>
        <div className={styles.sectionContentContainer}>
          <SectionField
            label={strings.CREDIT_LIMIT}
            value={relationshipDefaultInfo?.relationshipCreditLimit}
            prefix="$"
          />
          <SectionField label={strings.CREDIT_LIMIT_APPROVED} value={relationshipCreditLimitApproved} />
          <SectionField label={strings.SEND_NOA} value={relationshipSendNoa} />
        </div>
      </ScrollSpySection>
      {relationshipDefaultInfo && (
        <RelationshipSystemDefaultUpdateBlade
          title={`${strings.UPDATE} ${strings.RELATIONSHIP_DEFAULT_VALUES}`}
          showBlade={showUpdateBlade}
          handleClose={() => setShowUpdateBlade(false)}
          relationshipDefaultValues={relationshipDefaultInfo}
        />
      )}
    </>
  )
}

export const reportsEn = {
  REPORTS: 'Reports',
  DOWNLOAD_REPORT: 'Download Report',
  RECEIVABLES_AGING: 'Receivables Aging',
  FEES: 'Fees',
  AVAILABLE_FOR_RELEASE: 'Available for Release',
}

export const reportsEs: typeof reportsEn = {
  REPORTS: 'Informes',
  DOWNLOAD_REPORT: 'Descargar Informe',
  RECEIVABLES_AGING: 'Antigüedad de los créditos',
  FEES: 'Tarifas',
  AVAILABLE_FOR_RELEASE: 'Disponible para liberar',
}

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const FundingPage = lazy(() => import('pages/funding/page'))
const InvoiceGroupPreviewPage = lazy(() => import('pages/funding/invoiceGroups/[invoiceGroupId]/page'))
const DisbursementPreviewPage = lazy(() => import('pages/funding/disbursements/[disbursementId]/page'))

const FundingRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<FundingPage />} />} />
      <Route
        path="/invoiceGroups/:code/:invoiceGroupId"
        element={<LoaderPage content={<InvoiceGroupPreviewPage />} />}
      />
      <Route
        path="/disbursements/:code/:disbursementId"
        element={<LoaderPage content={<DisbursementPreviewPage />} />}
      />
    </Routes>
  )
}

export default FundingRoutes

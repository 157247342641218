export const usersEn = {
  // BEGIN Michael
  USER: 'User',
  USERS: 'Users',
  COMPANY_USERS: 'Company Users',
  FACTOR_USERS: 'Factor Users',
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  ADD_USER: 'Add User',
  UPDATE_USER: 'Update User {username}',
  ADMIN: 'Admin',
  SYSTEM_CONFIG: 'System Config',
  FINANCE_ADMIN: 'Finance Admin',
  ACCOUNT_EXECUTIVE: 'Account Executive',
  MODULES: 'Modules',
  // END Fabian
  ROLE_CREATED_SUCCESSFULLY: 'role has been created and applied successfully',
  ROLE_UPDATED_SUCCESSFULLY: 'role has been updated and applied successfully',
  REMOVE_FROM_FACTOR: 'Remove from Factor',
  REMOVE_FROM_COMPANY: 'Remove from Company',
  REMOVE_USER_CONFIRMATION: 'Are you sure you want to remove this user?',
  REMOVED_USER_SUCCESSFULLY: 'User has been removed successfully',
}

export const usersEs: typeof usersEn = {
  // BEGIN Michael
  USER: 'Usuario',
  USERS: 'Usuarios',
  COMPANY_USERS: 'Usuarios de Empresa',
  FACTOR_USERS: 'Usuarios de Factor',
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  ADD_USER: 'Añadir Usuario',
  UPDATE_USER: 'Actualizar Usuario {username}',
  ADMIN: 'Administrador',
  SYSTEM_CONFIG: 'Configuración del sistema',
  FINANCE_ADMIN: 'Administrador de Finanzas',
  ACCOUNT_EXECUTIVE: 'Ejecutivo de Cuenta',
  // END Fabian
  MODULES: 'Módulos',
  ROLE_CREATED_SUCCESSFULLY: 'El rol ha sido creado y aplicado exitosamente.',
  ROLE_UPDATED_SUCCESSFULLY: 'El rol se ha actualizado y aplicado correctamente.',
  REMOVE_FROM_FACTOR: 'Remover del Factor',
  REMOVE_FROM_COMPANY: 'Remover de la Empresa',
  REMOVE_USER_CONFIRMATION: '¿Estás seguro de que deseas remover a este usuario?',
  REMOVED_USER_SUCCESSFULLY: 'El usuario ha sido removido exitosamente.',
}

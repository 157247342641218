import strings from 'l10n'
import styles from './ErrorPage.module.scss'

type ErrorPageProps = {
  isChunkLoad: boolean
}

const ErrorPage = (props: ErrorPageProps) => {
  const { isChunkLoad } = props

  const title = isChunkLoad ? strings.ERROR_CHUNK_LOAD_HEADER : strings.ERROR_BOUNDARY_HEADER
  const message = isChunkLoad ? strings.ERROR_CHUNK_LOAD_BODY : strings.ERROR_BOUNDARY_BODY

  return (
    <div className={styles.errorBoundary}>
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  )
}

export default ErrorPage

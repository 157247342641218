import { ReactNode, Suspense } from 'react'
import Container from '../layout/Container'
import Loader from './Loader'
import styles from './LoaderPage.module.scss'

const LoaderPageFallback = () => {
  return (
    <Container className={styles.loaderPage}>
      <Loader loading={true} />
    </Container>
  )
}

type LoaderPageProps = {
  content?: ReactNode
}
const LoaderPage = (props: LoaderPageProps) => {
  return <Suspense fallback={<LoaderPageFallback />}>{props.content}</Suspense>
}

export default LoaderPage

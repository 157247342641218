export const outOfSystemInvoiceEn = {
  //Start Esteban
  OOS: 'OOS',
  OOS_INVOICE_CREATED_SUCCESSFULLY: 'Out of System Invoice created successfully',
  INFO_OOS_INVOICE_CREATE: 'OOS invoice is not created until the payment is applied',
  //End Esteban

  //Start Fabian
  DOCUMENTS_ADDED_OOS: 'All documents were successfully added to the out of system invoice',
  OUT_OF_SYSTEM_INVOICES: 'Out of System Invoices',
  //End Fabian
  //Start Michael
  OUT_OF_SYSTEM_INVOICE_UPDATED_SUCCESSFULLY: 'Out of System Invoice updated successfully',
  OUT_OF_SYSTEM_INVOICE_NOTES_UPDATED_SUCCESSFULLY: 'Out of System Invoice notes updated successfully',
  //End Michael
}

export const outOfSystemInvoiceEs: typeof outOfSystemInvoiceEn = {
  //Start Esteban
  OOS: 'OOS',
  OOS_INVOICE_CREATED_SUCCESSFULLY: 'Factura fuera del sistema creada con éxito',
  INFO_OOS_INVOICE_CREATE: 'La factura fuera del sistema no se crea hasta que se aplique el pago',
  //End Esteban

  //Start Fabian
  DOCUMENTS_ADDED_OOS: 'Todos los documentos fueron agregados con éxito a la factura fuera del sistema',
  OUT_OF_SYSTEM_INVOICES: 'Facturas fuera del sistema',
  //End Fabian
  //Start Michael
  OUT_OF_SYSTEM_INVOICE_UPDATED_SUCCESSFULLY: 'Factura fuera del sistema actualizada con éxito',
  OUT_OF_SYSTEM_INVOICE_NOTES_UPDATED_SUCCESSFULLY: 'Notas de la factura fuera del sistema actualizadas con éxito',
  //End Michael
}

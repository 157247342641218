import { isDefined } from 'tools/common'
import { InvoiceSummaryInfo } from 'types/invoice'
import { AppliedInfo, PaymentLocalStorage, PaymentUserStorage } from 'types/payments'

const useLocalStoragePayment = (paymentId?: string, userId?: string) => {
  let paymentUserStorages: PaymentUserStorage[] = getPaymentUserStorages()
  let paymentUserStorage: PaymentUserStorage | undefined = getPaymentUserStorage()
  let paymentStatuses: PaymentLocalStorage[] = getPaymentStatuses()
  let paymentStatus: PaymentLocalStorage | undefined = getPaymentStatus()
  let appliedInfos: AppliedInfo[] = getAppliedInfos()

  function getPaymentUserStorages() {
    return JSON.parse(localStorage.getItem('paymentUserStorage') || '[]')
  }

  function getPaymentUserStorage() {
    return paymentUserStorages.find((item) => item.userId === userId)
  }

  function getPaymentStatuses() {
    return paymentUserStorage?.paymentStatuses || []
  }

  function getPaymentStatus(id: string | undefined = paymentId) {
    return paymentStatuses.find((item) => item.paymentId === id)
  }

  function getAppliedInfos() {
    return paymentStatus?.appliedInfos || []
  }

  function getAppliedInfo(invoiceId: string) {
    updateLastLocalStorageState()
    return appliedInfos.find((item) => item.invoiceId === invoiceId)
  }

  function updateLastLocalStorageState() {
    paymentUserStorages = getPaymentUserStorages()
    paymentUserStorage = getPaymentUserStorage()
    paymentStatuses = getPaymentStatuses()
    paymentStatus = getPaymentStatus()
    appliedInfos = getAppliedInfos()
  }

  const updateSelectedInvoices = (selectedInvoices: InvoiceSummaryInfo[]) => {
    console.log('updateSelectedInvoices')
    updateLastLocalStorageState()
    if (isDefined(paymentStatus)) {
      const invoiceIds = selectedInvoices.map((invoice) => invoice.id)
      paymentStatus.selectedInvoices = selectedInvoices
      paymentStatus.appliedInfos = appliedInfos.filter((item) => invoiceIds.includes(item.invoiceId))
      savePaymentStatus(paymentUserStorages)
    }
  }

  const updateAppliedInfo = (invoiceId: string, applyType: string, appliedAmount: number | null) => {
    console.log('updateAppliedInfo')
    updateLastLocalStorageState()
    if (isDefined(paymentStatus)) {
      const appliedInfo = getAppliedInfo(invoiceId)
      if (isDefined(appliedInfo)) {
        appliedInfo.appliedAmount = appliedAmount
        appliedInfo.applyType = applyType as 'FULL' | 'PART' | 'OVER'
      } else {
        appliedInfos.push({ invoiceId, appliedAmount, applyType: applyType as 'FULL' | 'PART' | 'OVER' })
      }
      paymentStatus.appliedInfos = appliedInfos
      savePaymentStatus(paymentUserStorages)
    }
  }

  const updateApplyType = (invoiceId: string, applyType: string) => {
    console.log('updateApplyType')
    updateLastLocalStorageState()
    if (isDefined(paymentStatus)) {
      const appliedInfo = getAppliedInfo(invoiceId)
      if (isDefined(appliedInfo)) {
        appliedInfo.applyType = applyType === 'full' ? 'FULL' : 'PART'
      }
      savePaymentStatus(paymentUserStorages)
    }
  }

  const removePaymentStatus = (paymentId: string) => {
    console.log('removePaymentStatus')
    updateLastLocalStorageState()
    paymentStatuses = paymentStatuses.filter((item) => item.paymentId !== paymentId)
    if (isDefined(paymentUserStorage)) {
      paymentUserStorage.paymentStatuses = paymentStatuses
    }
    savePaymentStatus(paymentUserStorages)
  }

  const storeNewStatus = () => {
    console.log('storeNewStatus')
    updateLastLocalStorageState()
    if (isDefined(paymentUserStorage)) {
      addNewPaymentStatuses(paymentUserStorage)
    } else {
      addNewUserStorage()
    }
    savePaymentStatus(paymentUserStorages)
  }

  const addNewPaymentStatuses = (paymentUserStorage: PaymentUserStorage) => {
    console.log('addNewPaymentStatuses')
    if (isDefined(paymentId)) {
      if (!isDefined(paymentUserStorage.paymentStatuses)) {
        paymentUserStorage.paymentStatuses = [{ paymentId: paymentId, storeDate: new Date().toISOString() }]
      } else {
        if (!isDefined(paymentStatus)) {
          if (paymentUserStorage.paymentStatuses.length >= 100) {
            removeOldPaymentStatuses(paymentUserStorage.paymentStatuses)
          }
          paymentUserStorage.paymentStatuses.push({ paymentId: paymentId, storeDate: new Date().toISOString() })
        }
      }
    }
  }

  const removeOldPaymentStatuses = (paymentStatuses: PaymentLocalStorage[]) => {
    console.log('removeOldPaymentStatuses')
    paymentStatuses.sort((a, b) => new Date(a.storeDate).getTime() - new Date(b.storeDate).getTime())
    paymentStatuses.splice(0, 10)
  }

  const addNewUserStorage = () => {
    console.log('addNewUserStorage')
    if (isDefined(userId) && isDefined(paymentId)) {
      const newPaymentUserStorage: PaymentUserStorage = {
        userId: userId,
        paymentStatuses: [{ paymentId: paymentId, storeDate: new Date().toISOString() }],
      }
      paymentUserStorages.push(newPaymentUserStorage)
    }
  }

  const savePaymentStatus = (newPaymentUserStorage: PaymentUserStorage[]) => {
    localStorage.setItem('paymentUserStorage', JSON.stringify(newPaymentUserStorage))
  }

  const getStoredSelectedInvoices = () => {
    updateLastLocalStorageState()
    return paymentStatus?.selectedInvoices || []
  }

  return {
    getPaymentUserStorages,
    getPaymentUserStorage,
    getPaymentStatuses,
    getPaymentStatus,
    updateSelectedInvoices,
    updateAppliedInfo,
    updateApplyType,
    removePaymentStatus,
    storeNewStatus,
    savePaymentStatus,
    getStoredSelectedInvoices,
    getAppliedInfo,
    getAppliedInfos,
  }
}

export default useLocalStoragePayment

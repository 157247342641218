import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFeatureMode,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid'
import Tabs, { TabDataLabel } from '../navigation/Tabs'
import DataTable, { ToolbarButton, IFilterList } from './DataTable'
import { SummaryComponentOptions } from 'types/common'
import { useSearchParams } from 'react-router-dom'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { AxiosResponseHeaders } from 'axios'
import { useState } from 'react'
import { PAGINATION_LIMIT_VALUE_NUM } from 'constants/headers'

export const buildSearchParamsKey = (customParamKey: string = '') => {
  const currentTabKey = 't'
  const currentPageKey = 'p'
  const searchValueKey = 'q'
  return {
    currentTab: customParamKey + currentTabKey,
    currentPage: customParamKey + currentPageKey,
    searchValue: customParamKey + searchValueKey,
  }
}

export const useTabbedDataTable = (customParamKey?: string, defaultTab?: number) => {
  const [paginationLimit, setPaginationLimit] = useState(PAGINATION_LIMIT_VALUE_NUM)
  const [searchParams] = useSearchParams()
  const keys = buildSearchParamsKey(customParamKey)

  const currentTab = searchParams.get(keys.currentTab) ? Number(searchParams.get(keys.currentTab)) : defaultTab || 0
  const currentPage = Number(searchParams.get(keys.currentPage)) || 0
  const searchValue = searchParams.get(keys.searchValue) || ''

  const setCurrentTab = (value: any) => {
    searchParams.set(keys.currentTab, value)
  }

  const setCurrentPage = (value: any) => {
    searchParams.set(keys.currentPage, value)
  }

  const setSearchValue = (value: any) => {
    searchParams.set(keys.searchValue, value)
  }

  const setPaginationLimitValue = (value: number) => {
    setPaginationLimit(value)
  }

  return {
    state: {
      currentTab,
      setCurrentTab,
      currentPage,
      setCurrentPage,
      searchValue,
      setSearchValue,
      paginationLimit,
      setPaginationLimitValue,
      keys,
    },
  }
}

type HookTabbedDataTable = ReturnType<typeof useTabbedDataTable>['state']

type ActionList = {
  icon: string
  name: string
  className?: string // Optional CSS class for customization
  iconSize?: number // Optional size for the icon
  selection?: boolean // Indicates if the action involves selection
}

type TabbedDataTableProps = {
  title?: string
  tabs?: TabDataLabel[]
  columns: GridColDef[]
  filters?: IFilterList[]
  data: any[]
  loading: boolean
  infoMessage?: string
  loadingFetching: boolean
  hasNextPage: boolean
  actionButton?: ToolbarButton
  openActionButton?: ToolbarButton
  actionSecondButton?: ToolbarButton
  actionThirdButton?: ToolbarButton
  actionFourthButton?: ToolbarButton
  state: HookTabbedDataTable
  debounceTime?: number
  checkboxSelection?: boolean
  reduceHeight?: number
  emptyRowOverlay?: React.ReactNode
  handleCheckboxSelection?: (selected: GridSelectionModel) => void
  handleCheckboxRowSelection?: (selected: any, ids: string[]) => void
  onFilterSubmit?: (value: { [key: string]: string | number | string[] }) => void
  handleActions?: (id: string | number, name: string) => void
  selectionMode?: boolean
  summaryContent?: SummaryComponentOptions[]
  clearCheckBoxSelection?: boolean
  resetClearCheckBoxSelection?: () => void
  onSortModelChange?: (model: GridSortModel) => void
  toolBar?: React.ReactNode
  searchFilter?: boolean
  pagination?: boolean
  showToolbar?: boolean
  getRowId?: (row: any) => string
  nameRowSelected?: string
  disableExportFunction?: boolean
  disableDensitySelector?: boolean
  hasCustomerToolbar?: boolean
  hideFilterbar?: boolean
  density?: 'compact' | 'standard' | 'comfortable'
  hideFooter?: boolean
  disableFreeSearch?: boolean
  minHeight?: number
  keepNonExistentRowsSelected?: boolean
  noRowsMessage?: string
  initialFilterValues?: { [key: string]: string | number | string[] }
  hidePaginationInSelection?: boolean
  isRowSelectable?: (params: GridRowParams) => boolean
  disabledCheckBoxTooltipText?: string
  hideSelectAllCheckbox?: boolean
  customComponent?: React.ReactNode
  actionList?: ActionList[]
  sortingMode?: GridFeatureMode
  initialState?: GridInitialStateCommunity
  hidePagination?: boolean
  customFooter?: React.ReactNode
  paginationHeaders?: AxiosResponseHeaders
  handleVisibilityChange?: (model: GridColumnVisibilityModel) => void
  allRecordsAccrossPagesSelection?: boolean
  handleAllRecordsAccrossPagesSelection?: (selection: boolean) => void
  txtFilterId?: string
  titleId?: string
  initialRowsSelected?: string[]
}

const TabbedDataTable = (props: TabbedDataTableProps) => {
  const {
    title,
    tabs,
    columns,
    actionList,
    filters,
    data,
    infoMessage,
    loading,
    hasNextPage,
    actionButton,
    openActionButton,
    actionSecondButton,
    actionThirdButton,
    actionFourthButton,
    state,
    debounceTime,
    loadingFetching,
    checkboxSelection = false,
    hideSelectAllCheckbox = false,
    reduceHeight,
    minHeight,
    hideFilterbar,
    emptyRowOverlay,
    handleCheckboxSelection,
    handleCheckboxRowSelection,
    handleActions,
    selectionMode = false,
    summaryContent = [],
    clearCheckBoxSelection = false,
    resetClearCheckBoxSelection = () => {},
    onSortModelChange,
    toolBar,
    searchFilter,
    pagination = true,
    showToolbar = true,
    getRowId,
    nameRowSelected,
    disableDensitySelector = false,
    disableExportFunction = false,
    density = 'standard',
    hideFooter = false,
    keepNonExistentRowsSelected = false,
    noRowsMessage,
    initialFilterValues,
    disableFreeSearch = false,
    onFilterSubmit,
    hidePaginationInSelection = true,
    isRowSelectable,
    disabledCheckBoxTooltipText,
    customComponent,
    sortingMode = 'client',
    initialState,
    hidePagination = false,
    customFooter,
    paginationHeaders,
    handleVisibilityChange,
    allRecordsAccrossPagesSelection,
    handleAllRecordsAccrossPagesSelection,
    txtFilterId,
    titleId,
    initialRowsSelected = [],
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const { keys } = state

  const handleChangeFilterTab = (val: number) => {
    searchParams.set(keys.currentTab, val.toString())
    searchParams.set(keys.currentPage, '0')
    setSearchParams(searchParams)
  }

  const handlePagination = (pageNumber: number) => {
    searchParams.set(keys.currentPage, pageNumber.toString())
    setSearchParams(searchParams)
  }

  const handleQuickSearch = (value: string) => {
    searchParams.set(keys.searchValue, value)
    searchParams.set(keys.currentPage, '0')
    setSearchParams(searchParams)
  }

  const handlePaginationLimitChange = (value: number) => {
    state.setPaginationLimitValue(value)
  }

  let displayTitle = title
  if (!displayTitle && tabs) {
    displayTitle = tabs[state.currentTab].formatLabel
  }

  const showPagination = pagination && !selectionMode
  return (
    <>
      {tabs && <Tabs tabData={tabs} value={state.currentTab} handleChange={(_e, val) => handleChangeFilterTab(val)} />}
      <DataTable
        txtFilterId={txtFilterId}
        titleId={titleId}
        title={displayTitle}
        actionList={actionList}
        filters={filters}
        hideFooter={hideFooter}
        toolBar={toolBar}
        loading={loading}
        infoMessage={infoMessage}
        disableFreeSearch={disableFreeSearch}
        noRowsMessage={noRowsMessage}
        loadingFetching={loadingFetching}
        data={data}
        columns={columns}
        emptyRowOverlay={emptyRowOverlay}
        checkboxSelection={checkboxSelection}
        searchValue={state.searchValue}
        pageNo={state.currentPage}
        hasNextPage={hasNextPage}
        pagination={hidePaginationInSelection ? showPagination : true}
        handleQuickSearch={handleQuickSearch}
        handleActions={handleActions}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        searchFilter={searchFilter}
        handleCustomPagination={(pageNumber) => {
          handlePagination(pageNumber)
        }}
        actionButton={actionButton}
        openActionButton={openActionButton}
        actionSecondButton={actionSecondButton}
        actionThirdButton={actionThirdButton}
        actionFourthButton={actionFourthButton}
        debounceTime={debounceTime}
        reduceHeight={selectionMode && summaryContent?.length > 0 ? 250 : reduceHeight}
        minHeight={minHeight}
        handleCheckboxSelection={handleCheckboxSelection}
        handleCheckboxRowSelection={handleCheckboxRowSelection}
        summaryContent={summaryContent}
        clearCheckBoxSelection={clearCheckBoxSelection}
        resetClearCheckBoxSelection={resetClearCheckBoxSelection}
        onSortModelChange={onSortModelChange}
        showToolbar={showToolbar}
        getRowId={getRowId}
        nameRowSelected={nameRowSelected}
        disableDensitySelector={disableDensitySelector}
        disableExportFunction={disableExportFunction}
        hideSelectAllCheckbox={hideSelectAllCheckbox}
        density={density}
        onFilterSubmit={onFilterSubmit}
        hideFilterbar={hideFilterbar}
        initialFilterValues={initialFilterValues}
        isRowSelectable={isRowSelectable}
        disabledCheckBoxTooltipText={disabledCheckBoxTooltipText}
        customComponent={customComponent}
        sortingMode={sortingMode}
        initialState={initialState}
        hidePagination={hidePagination}
        customFooter={customFooter}
        paginationHeaders={paginationHeaders}
        paginationLimit={state.paginationLimit}
        handlePaginationLimitChange={handlePaginationLimitChange}
        handleVisibilityChange={handleVisibilityChange}
        allRecordsAccrossPagesSelection={allRecordsAccrossPagesSelection}
        handleAllRecordsAccrossPagesSelection={handleAllRecordsAccrossPagesSelection}
        initialRowsSelected={initialRowsSelected}
      />
    </>
  )
}
export default TabbedDataTable

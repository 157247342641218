import { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from 'app/ErrorPage/ErrorPage'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  isChunkLoad: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    isChunkLoad: false,
  }

  static getDerivedStateFromError(error: Error): State {
    console.error('Error Boundary getDerivedStateFromError:', error)
    return {
      hasError: true,
      isChunkLoad: error.name === 'ChunkLoadError',
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error Boundary Caught:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage isChunkLoad={this.state.isChunkLoad} />
    }

    return this.props.children
  }
}

export default ErrorBoundary

import MuiIconButton from '@mui/material/IconButton'
import Icon from '../ui/dataDisplay/Icon'

type IconButtonProps = {
  /** The icon variable provides the name of the icon to be displayed by the component.*/
  icon: string
  /** The size is used to change the size of the button. If no theme prop is provided, the component will use the default size value "large".*/
  size?: 'small' | 'medium' | 'large'
  onClick?: () => void
  disabled?: boolean
}

const SimpleIconButton = (props: IconButtonProps) => {
  const { icon, size = 'large', onClick, disabled } = props
  return (
    <MuiIconButton size={size} onClick={onClick} disabled={disabled}>
      <Icon name={icon} disabled={disabled} color={disabled ? '#cdcdcd' : ''} />
    </MuiIconButton>
  )
}

export default SimpleIconButton

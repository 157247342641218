export const loginEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  BETA_TITLE: 'Now in Beta',
  BETA_SUBTITLE: 'We are so glad you are here!',
  BETA_P1:
    'This beta product is a work-in-progress and may have bugs, unfinished features, and compatibility issues. Data loss and security vulnerabilities are possible. Limited customer support and incomplete documentation are available. Not suitable for production use or handling critical data. User experience and functionality might change without notice.',
  BETA_P2: 'Participation is voluntary, and feedback is appreciated.',
  BETA_FORM: 'Beta Feedback Survey',
  // END Fco
  // BEGIN Fabian
  EMAIL: 'Email',
  ROLE: 'Role',
  PREFERED_NAME: 'Preferred Name',
  LOGIN: 'Login',
  LOGOUT: 'Log Out',
  PASSWORD: 'Password',
  CHANGE_PASSWORD: 'Change Password',
  SET_PERMENANT_PASSWORD: 'Set a permanent password to login to your account.',
  FORGOT_PASSWORD: 'Forgot Password?',
  FORGOT_PASSWORD_EMAIL: 'Mail sent successfully!',
  FORGOT_PASSWORD_SUB: 'Check your email {email} We sent you a reset password link to change your password.',
  EMAIL_ERROR: 'Please enter a valid email address',
  EMAIL_ADDRESS: 'Email Address',
  EMAIL_ADDRESS_CONFIRMATION: 'Confirm Email Address',
  BACK_TO_LOGIN: 'Back to Login',
  REVIEW_CHANGELOG: 'Review full changelog',
  READ_BLOG: 'Read Our Blog',
  REGISTER: 'Register',
  MESSAGE_ONE_TIME_CODE_SENT: 'A one-time code has been emailed to you. Check your inbox.',
  LOGGED_SUCCESSFULLY: 'Logged In Successfully',
  LOGGED_OUT_SUCCESSFULLY: 'Logged Out Successfully',
  T_OPT_RESENT_SUCCESSFULLY: 'OTP resent successfully!',
  S_OPT_RESENT_SUCCESSFULLY: 'We sent an otp code to your mail {email}',
  RESEND_OPT: 'Resend One-time Password',
  PASSWORDS_DONT_MATCH: 'Passwords dont match',
  PASSWORD_RESET_SUCCESSFULLY: 'Password reset successfully!',
  PASSWORD_RESET_FEEDBACK: 'You can now login with your new password!',
  PASSWORD_RESET_FAILURE: 'Password does not meet the requirements!',

  SHOW_LOGIN_FORM: 0,
  SHOW_FORGOT_PASS_FORM: 1,
  SHOW_RESET_PASS_FORM: 2,
  SHOW_REGISTER_FORM: 3,
  SHOW_MULTI_FACTOR_FORM: 4,

  DOLLARS_TRANSMITTED: 'DOLLARS TRANSMITTED',
  PAYMENTS_TRANSMITTED: 'PAYMENTS TRANSMITTED',
  CHECKS_CAPTURED: 'CHECKS CAPTURED',
  FDIC_BANKS: 'FDIC BANKS',
  // END Fabian
}

export const loginEs: typeof loginEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  BETA_TITLE: 'Ahora en Beta',
  BETA_SUBTITLE: '¡Estamos muy contentos de que estés aquí!',
  BETA_P1:
    'Este producto beta es un trabajo en curso y puede tener errores, funciones inacabadas y problemas de compatibilidad. Es posible que se produzcan pérdidas de datos y vulnerabilidades de seguridad. La asistencia al cliente es limitada y la documentación incompleta. No es adecuado para su uso en producción ni para el manejo de datos críticos. La experiencia del usuario y la funcionalidad pueden cambiar sin previo aviso.',
  BETA_P2: 'La participación es voluntaria y se agradece cualquier comentario.',
  BETA_FORM: 'Encuesta de comentarios beta',
  // END Fco
  // BEGIN Fabian
  EMAIL: 'Correo electrónico',
  ROLE: 'Rol',
  PREFERED_NAME: 'Nombre Preferido',
  LOGIN: 'Iniciar Sesión',
  LOGOUT: 'Cerrar Sesión',
  PASSWORD: 'Contraseña',
  CHANGE_PASSWORD: 'Cambiar la contraseña',
  SET_PERMENANT_PASSWORD: 'Establezca una contraseña permanente para iniciar sesión en su cuenta.',
  FORGOT_PASSWORD: '¿Olvidó su contraseña?',
  FORGOT_PASSWORD_EMAIL: '¡Correo enviado correctamente!',
  FORGOT_PASSWORD_SUB: 'Revisa tu correo electrónico {email} Te enviamos un enlace para restablecer tu contraseña.',
  EMAIL_ERROR: 'Introduzca una dirección de correo electrónico válida',
  EMAIL_ADDRESS: 'Correo Electrónico',
  BACK_TO_LOGIN: 'Volver a Iniciar Sesión',
  REVIEW_CHANGELOG: 'Revisar registro de cambios completo',
  READ_BLOG: 'Leer nuestro blog',
  EMAIL_ADDRESS_CONFIRMATION: 'Confirmar Correo Electrónico',
  REGISTER: 'Registrarse',
  MESSAGE_ONE_TIME_CODE_SENT:
    'Se le ha enviado por correo electrónico un código de un solo uso. Compruebe su bandeja de entrada.',
  LOGGED_SUCCESSFULLY: 'Conectado correctamente.',
  LOGGED_OUT_SUCCESSFULLY: 'Cerró sesión exitosamente',
  T_OPT_RESENT_SUCCESSFULLY: '¡Reenvío del OTP realizado correctamente!',
  S_OPT_RESENT_SUCCESSFULLY: 'Hemos enviado un código OTP a tu correo electrónico {email}',
  RESEND_OPT: 'Reenviar contraseña de un solo uso',
  PASSWORDS_DONT_MATCH: 'Las contraseñas no coinciden',
  PASSWORD_RESET_SUCCESSFULLY: 'Contraseña restablecida correctamente!',
  PASSWORD_RESET_FEEDBACK: 'Ya puedes iniciar sesión con tu nueva contraseña!',
  PASSWORD_RESET_FAILURE: 'La contraseña no cumple los requisitos!',

  SHOW_LOGIN_FORM: 0,
  SHOW_FORGOT_PASS_FORM: 1,
  SHOW_RESET_PASS_FORM: 2,
  SHOW_REGISTER_FORM: 3,
  SHOW_MULTI_FACTOR_FORM: 4,

  DOLLARS_TRANSMITTED: 'DÓLARES TRANSMITIDOS',
  PAYMENTS_TRANSMITTED: 'PAGOS TRANSMITIDOS',
  CHECKS_CAPTURED: 'CHEQUES CAPTURADOS',
  FDIC_BANKS: 'FDIC BANCOS',
  // END Fabian
}

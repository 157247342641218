import { useState } from 'react'
import { SystemDefaultsSectionProps } from '../../SystemDefaultsComponent'
import { ift, isDefined } from 'tools/common'
import { ActionButton } from 'types/common'
import { Divider, ScrollSpySection, TypoGraph } from 'components/ui'
import strings from 'l10n'
import styles from '../SystemDefaultsSections.module.scss'
import SectionField from 'components/ui/dataDisplay/SectionField'
import { percentageFormat } from 'tools/format'
import { QuickPayFeeSection } from './QuickPayFeeSection/QuickPayFeeSection'
import { BrokerSystemDefaultUpdateBlade } from './BrokerSystemDefaultsUpdateBlade'
import { Button } from 'components/form'
import { QuickPayFeeAddBlade } from './QuickPayFeeSection/QuickPayFeeAddBlade'

export const BrokerSystemDefaults = (props: SystemDefaultsSectionProps) => {
  const { factorDefault, canModifyFactorDefaultValues } = props
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const [showNewQuickPayFeeBlade, setShowNewQuickPayFeeBlade] = useState<boolean>(false)
  const brokerDefaultValues = factorDefault?.brokerDefaultValues
  const brokerPaymentFees = brokerDefaultValues?.brokerPaymentFees

  const btnEdit = ift(
    {
      label: strings.EDIT,
      variant: 'outlined',
      onClick: () => {
        setShowUpdateBlade(true)
      },
    },
    canModifyFactorDefaultValues
  ) as ActionButton

  const brokerCreditLimitApproved = ift(
    brokerDefaultValues?.brokerCreditLimitApproved ? strings.YES : strings.NO,
    isDefined(brokerDefaultValues?.brokerCreditLimitApproved)
  ) as string

  return (
    <>
      <ScrollSpySection title={strings.BROKERS} actionBtn={btnEdit}>
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.PAYMENT_FEES} />
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.ACH_FEE} value={brokerPaymentFees?.achFee} prefix="$" />
          <SectionField label={strings.WIRE_FEE} value={brokerPaymentFees?.wireFee} prefix="$" />
          <SectionField label={strings.CHECK_FEE} value={brokerPaymentFees?.checkFee} prefix="$" />
          <SectionField label={strings.FUEL_CARD_FEE} value={brokerPaymentFees?.fuelCardFee} prefix="$" />
          <SectionField label={strings.SAME_DAY_ACH_FEE} value={brokerPaymentFees?.sameDayAchFee} prefix="$" />
          <SectionField label={strings.MONEY_CODE_FEE} value={brokerPaymentFees?.moneyCodeFee} prefix="$" />
        </div>
        <Divider />
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.NEGATIVE_CASH_RESERVE_FEE} />
        <div className={styles.sectionContentContainer}>
          <SectionField
            label={strings.DOLLAR_AMOUNT}
            value={brokerDefaultValues?.brokerNegativeFeeDollarAmount}
            prefix="$"
          />
          <SectionField
            label={strings.PERCENTAGE_AMOUNT}
            value={percentageFormat(brokerDefaultValues?.brokerNegativeFeePercentageAmount)}
          />
        </div>
        <Divider />
        <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.OTHERS} />
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.CREDIT_LIMIT} value={brokerDefaultValues?.brokerCreditLimit} prefix="$" />
          <SectionField label={strings.CREDIT_LIMIT_APPROVED} value={brokerCreditLimitApproved} />
        </div>
        <Divider />
        <div className={styles.quickPayFeeHeaderContainer}>
          <TypoGraph variant="h6" className={styles.sectionTitle} content={strings.QUICK_PAY_FEES} />
          <div>
            <Button
              label={`${strings.NEW} ${strings.QUICK_PAY_FEE}`}
              variant="contained"
              onClick={() => setShowNewQuickPayFeeBlade(true)}
            />
          </div>
        </div>
        <QuickPayFeeSection quickPayFees={brokerDefaultValues?.brokerQuickPayFee} />
      </ScrollSpySection>
      {factorDefault && (
        <BrokerSystemDefaultUpdateBlade
          title={`${strings.UPDATE} ${strings.BROKER_DEFAULT_VALUES}`}
          brokerDefaultValues={factorDefault?.brokerDefaultValues}
          showBlade={showUpdateBlade}
          handleClose={() => setShowUpdateBlade(false)}
        />
      )}

      <QuickPayFeeAddBlade
        title={`${strings.ADD} ${strings.QUICK_PAY_FEE}`}
        showBlade={showNewQuickPayFeeBlade}
        handleClose={() => setShowNewQuickPayFeeBlade(false)}
        quickPayFees={factorDefault?.brokerDefaultValues.brokerQuickPayFee}
      />
    </>
  )
}

import { DocumentInfo } from './document'
import { GridSelectionModel } from '@mui/x-data-grid'
import { InvoiceGroupInfo } from './invoiceGroup'
import { Warnings } from './common'
import { SplitFunding } from 'app/InvoiceManagement/components/SplitFundingForm'
import { LabelsInfo } from './labels'

/* ---------------------------------------------
                  Utils
 --------------------------------------------- */

const validInvoiceStatuses = ['PENDING', 'APPROVED_FOR_FUNDING', 'FUNDED', 'PAID', 'REJECTED', 'DELETED']
export type InvoiceStatus = 'PENDING' | 'APPROVED_FOR_FUNDING' | 'FUNDED' | 'PAID' | 'REJECTED' | 'DELETED'

export function isInvoiceStatus(val: string): val is InvoiceStatus {
  return validInvoiceStatuses.includes(val)
}

type InvoiceChangelogLogType = 'UPDATE' | 'VERIFICATION' | 'COLLECTION'

/* ---------------------------------------------
                  Responses
 --------------------------------------------- */

export type InvoiceListResponse = {
  invoices: InvoiceSummaryInfo[]
}

export type InvoiceResponse = {
  invoice: InvoiceSummaryInfo
  warnings: Warnings[]
}

export type InvoicePreviewResponse = {
  advanceAmount: number
  cashReserve: number
  escrowReserve: number
  feesAmount: number
  invoiceAmount: number
  status: string
  creditLimits: creditLimitInfo
  paymentFees: paymentFeesInfo
  fundingInstruction: FundingInstruction[]
  invoiceBreakdown: invoiceBreakdownInfo[]
  fuelAdvanceAmount: number
  quickPayFeeAmount: number
  vendorAdvanceAmount: number
}

export type InvoiceApproveForFundingResponse = {
  invoiceGroup: InvoiceGroupInfo
}

export type InvoiceCsvResponse = {
  code: number
  status: string
  output: string[]
}

export type InvoiceRebillResponse = {
  rebilledInvoiceIds: string[]
  rebilledInvoiceCount: number
  notRebilledInvoiceIds: string[]
  notRebilledInvoiceCount: number
}

/* ---------------------------------------------
                  Infos
 --------------------------------------------- */

export type InvoiceSummaryInfo = {
  id: string
  invoiceNumber: string
  referenceNumber: string
  companyClientId: string
  companyClientName: string
  companyDebtorId: string
  companyDebtorName: string
  termId: string
  termName: string
  status: string
  invoiceAmount: number
  invoiceBalance: number
  invoiceDate: string
  notes: string
  documents: DocumentInfo[]
  verificationStatus: string
  changelog: InvoiceChangelogInfo[]
  ineligible: boolean
  disputed: boolean
  createdOn: string
  purchaseFeeAmount: number
  escrowReserveAmount: number
  cashReserveAmount: number
  advanceAmount: number
  daysAged: number
  daysOutstanding: number
  paidDate: string
  paymentStatus: string
  fundedDate: string
  closedDate: string
  callBackDate: string
  willPayDate: string
  paymentTransactionNumber: string[]
  cashEscrowAmount?: number
  fuelAdvanceId: string
  fuelAdvanceReferenceNumber: string
  fuelAdvanceAmount: number
  forcedTermId: string
  forcedTermName: string
  companyBrokerId: string
  companyBrokerName: string
  invoiceType: string
  vendorPaymentAmount: number
  vendorInvoiceId: string
  vendorAdvanceAmount: number
  quickPayFeeAmount: number
  quickPayFeeId: string
  vendorPaymentDate: string
  vendorFundingDate: string
  companyVendorName: string
  companyVendorId: string
  overAdvanceType: string
  overAdvanceAmount: number
  snapshotTermPurchaseRate: number
  snapshotTermEscrowReserveRate: number
  nonFactoredInvoice: boolean | string
  verificationMethod: string
  cachedCompanyName?: string
  cachedCompanyId?: string
  isOutOfSystemInvoice?: boolean
}

export type InvoiceChangelogList = {
  changelogs: InvoiceChangelogInfo[]
}

export type InvoiceChangelogInfo = {
  logType: InvoiceChangelogLogType
  status: string
  method: string
  notes: string
  createdOn: Date
  userId: string
  userEmail: string
  ineligibleStatus: Boolean
  disputedStatus: Boolean
}

export type CreditLimit = {
  companyId: string
  companyName: string
  creditLimit: number
  creditLimitApproved: boolean
  currentOpenAr: number
  newApprovedAmount: number
  parentCreditLimit: number
  parentCurrentOpenAr: number
  parentName: string
  cashReserve: number
}

export type creditLimitInfo = {
  client: CreditLimit
  broker: CreditLimit
  debtors: CreditLimit[]
  clientDebtors: CreditLimit[]
  brokerDebtors: CreditLimit[]
}

export type paymentFeesInfo = {
  achFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  wireFee: number
  moneyCodeFee: number
}

export type FundingInstruction = {
  createdOn: string
  default: boolean
  id: string
  name: string
  paymentMethod: string
}

export type invoiceBreakdownInfo = {
  cashReserve: number
  escrowReserve: number
  feePerInvoice: number
  invoiceId: string
  invoiceNumber: string
  minimumPurchaseFee: number
  netFee: number
  purchaseFee: number
  termId: string
  termName: string
  quickPayFeeAmount: number
  vendorAdvanceAmount: number
  fuelAdvance: FuelAdvanceInvoiceBreakdownDetails
  invoiceReference: string
  invoiceAmount: number
  invoiceBalance: number
  daysOutstanding: number
  daysAged: number
  disputed: boolean
  ineligible: boolean
  paidShortPartial: boolean
  olderThan30Days: boolean
}

export type FuelAdvanceInvoiceBreakdownDetails = {
  referenceNumber: string
  fuelAdvanceAmount: number
}

/* ---------------------------------------------
                  Requests
 --------------------------------------------- */

export type InvoiceVerifyRequest = {
  invoiceIds?: string[]
  companyClientId?: string
  companyBrokerId?: string
  selectAllRequest?: boolean
  selectAllStatus?: string
  verificationStatus: string | null
  verificationNotes: string | null
  verificationMethod: string | null
}

export type InvoiceCreateRequest = {
  invoiceNumber: string
  referenceNumber: string
  companyClientId?: string | null
  companyDebtorId: string
  invoiceAmount: number
  invoiceDate: Date
  notes: string
  fuelAdvanceId?: string
  forcedTermId?: string
  companyBrokerId?: string | null
  vendorInvoiceId?: string | null
  vendorPaymentAmount?: number | null
  vendorPaymentDate?: Date | null
  quickPayId?: string | null
  companyVendorId?: string | null
  overAdvanceType?: string | null
  overAdvanceAmount?: number | null
  nonFactoredInvoice?: boolean | null
}

export type InvoicePreviewRequest = {
  invoices?: GridSelectionModel | undefined | string
  transactionId?: string | undefined | null
  selectAllRequest?: boolean
  companyClientId?: string
  companyBrokerId?: string
}

export type InvoiceApproveForFundingRequest = {
  companyClientId?: string
  companyBrokerId?: string
  fundingInstructionId?: string | null
  invoiceIds?: string[]
  splitFunding?: SplitFunding[] | null
  pendingTransactions?: PendingFundingInfo
  zeroOutPaymentFee?: boolean
}

export type PendingFundingInfo = {
  holdCashReserveAmount: number
  holdFeeAmount: number
  cashReserveNotes?: string
  feeNotes?: string
}

export type UpdateInvoiceRequest = {
  companyClientId?: string
  companyDebtorId?: string
  invoiceNumber?: number
  referenceNumber?: number
  invoiceAmount?: number
  invoiceDate?: string
  notes?: string
  documents?: string[]
  status?: string
  fuelAdvanceId?: string
  forcedTermId?: string | null
  companyVendorId?: string
  vendorPaymentDate?: Date
  vendorPaymentAmount?: number
  vendorInvoiceId?: string
  quickPayId?: string
  companyBrokerId?: string
  overAdvanceType?: string
  overAdvanceAmount?: number
}

export type RejectAllInvoicesRequest = {
  companyClientId?: string
  companyBrokerId?: string
  invoiceStatus?: string
}

export type InvoiceChangelogParams = {
  invoiceId?: string
  q?: string
}

export type ExportInvoicesParamsRequest = {
  status?: string
  q?: string
  client?: string
  broker?: string
  debtor?: string
  verificationStatus?: string
  sort?: string
  accountExecutiveUserId?: string
  brokerMod?: boolean
  nonFactoredInvoice?: boolean | null
  invoiceDateStart?: Date
  invoiceDateEnd?: Date
  fundingDateStart?: Date
  fundingDateEnd?: Date
  amountMin?: number
  amountMax?: number
  labels?: string
  skipPagination?: boolean
  exportType?: string
  startAt?: Date | null
  endAt?: Date | null
  balanceMin?: number | null
  balanceMax?: number | null
}

export type RejectedInvoiceRequest = {
  invoiceId: string
  payload: UpdateInvoiceRequest
}

export type DeletedInvoiceRequest = {
  invoiceId: string
}

export type InvoicesRebillRequest = {
  invoiceIds: string[]
  clientId: string
  regenerateInvoiceDocuments: boolean
}

/* ---------------------------------------------
                  List Params
 --------------------------------------------- */

export type InvoiceListParams = {
  q?: string
  status?: string
  client?: string | null
  broker?: string | null
  debtor?: string | null
  verificationStatus?: string | undefined | null
  hideCollectionUpdatedToday?: boolean
  callBackDate?: Date | null
  willPayDate?: Date | null
  ineligible?: boolean | null
  disputed?: boolean | null
  sort?: string
  invoiceGroupId?: string
  onlyZeroBalance?: boolean | null | undefined
  accountExecutiveUserId?: string | null
  brokerMod?: boolean
  nonFactoredInvoice?: boolean | null
  screen?: string
  invoiceDateStart?: Date | null
  invoiceDateEnd?: Date | null
  fundingDateStart?: Date | null
  fundingDateEnd?: Date | null
  amountMin?: number | null
  amountMax?: number | null
  labels?: string | null
  startAt?: Date | null
  endAt?: Date | null
  balanceMin?: number | null
  balanceMax?: number | null
  collectionAgentUserId?: string | null
  daysOutstandingMin?: number | null
  daysOutstandingMax?: number | null
  daysAgedMin?: number | null
  daysAgedMax?: number | null
}

export type PaymentInfoListParams = {
  companyClientId?: string
}

export type InvoiceLabelCollectionResponse = {
  labels: LabelsInfo[]
}

/* ---------------------------------------------
                  Props
 --------------------------------------------- */

export type SectionInformationProps = {
  invoice?: InvoiceSummaryInfo
  title: string
}

export type SectionInformationBrokerProps = {
  invoice?: InvoiceSummaryInfo
  canWriteBrokers: boolean
  title: string
}

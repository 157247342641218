import { Typography } from '@mui/material'
import styles from './SummarySelectedTable.module.scss'
import { SummaryComponentOptions } from 'types/common'

type SummaryComponentProps = {
  content?: SummaryComponentOptions[] | null
}

const SummaryComponent = (props: SummaryComponentProps) => {
  const { content } = props

  const formattedValue = (value?: string | number | null, prefix?: string, suffix?: string) => {
    if (prefix) {
      return `${prefix} ${value}`
    }

    if (suffix) {
      return `${value} ${suffix}`
    }

    return value
  }

  return (
    <div className={styles.summaryContainer}>
      {content?.map((item, index) => (
        <div className={styles.contentContainer} key={Math.random()}>
          <Typography className={styles.label}>{item.label}</Typography>
          <Typography className={styles.value}>{formattedValue(item.value, item.prefix, item.suffix)}</Typography>
        </div>
      ))}
    </div>
  )
}

export default SummaryComponent

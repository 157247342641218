export const OPEN_AR_KEY = 'openArKey'
export const FEES_KEY = 'feesKey'
export const ACCRUED_FEES_KEY = 'accruedFeesKey'
export const ACCRUED_INTEREST_KEY = 'accruedInterestKey'
export const ESCROW_RESERVE_KEY = 'escrowReserveKey'
export const CASH_RESERVE_KEY = 'cashReserveKey'
export const AP_BANK_ACCOUNT_KEY = 'apBankAccountKey'
export const AR_BANK_ACCOUNT_KEY = 'arBankAccountKey'
export const LIABILITY_KEY = 'liabilityKey'
export const UNAPPLIED_KEY = 'unappliedKey'
export const FUEL_ADVANCE_KEY = 'fuelAdvanceKey'

import Divider from '@mui/material/Divider'
import styles from './DividerComponent.module.scss'
import React from 'react'

type DividerComponentProps = {
  label?: string
  content?: React.ReactNode
  classNameContainer?: string
  classNameLabel?: string
  classNameDivider?: string
}

const DividerComponent = (props: DividerComponentProps) => {
  const { label, content, classNameLabel, classNameDivider, classNameContainer } = props

  return (
    <div className={`${styles.dividerContainer} ${classNameContainer}`}>
      <div className={`${styles.textContent} ${classNameLabel}`}>{content ? content : label}</div>
      <Divider className={`${styles.divider} ${classNameDivider} `} />
    </div>
  )
}

export default DividerComponent

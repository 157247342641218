export const fundingInstructionsEn = {
  //BEGIN Michael
  FUNDING_INSTRUCTION: 'Funding Instruction',
  FUNDING_INSTRUCTIONS: 'Funding Instructions',

  ACCOUNT_NICKNAME: 'Account Nickname',
  MARK_AS_DEFAULT: 'Mark as Default',
  FUNDING_INSTRUCTION_CREATED_SUCCESSFULLY: 'Funding Instruction Created Successfully',
  FUNDING_INSTRUCTION_UPDATED_SUCCESSFULLY: 'Funding Instruction Updated Successfully',
  PAY_TO: 'Pay To',
  BANK_INFO: 'Bank Info',
  BANK_NAME: 'Bank Name',
  BANK_ACCOUNT_NUMBER: 'Bank Account Number',
  ROUTING_NUMBER: 'Routing Number',
  BANK_CODE: 'Bank Code',
  SWIFT_CODE: 'Swift Code',
  BANK_CITY: 'Bank City',
  BANK_STATE: 'Bank State',
  BANK_COUNTRY: 'Bank Country',
  INTERMEDIATE_BANK_NAME: 'Intermediate Bank Name',
  INTERMEDIATE_BANK_ACCOUNT_NUMBER: 'Intermediate Bank Account Number',
  INTERMEDIATE_ROUTING_NUMBER: 'Intermediate Routing Number',
  INTERMEDIATE_BANK_CODE: 'Intermediate Bank Code',
  INTERMEDIATE_SWIFT_CODE: 'Intermediate Swift Code',
  INTERMEDIATE_BANK_STATE: 'Intermediate Bank State',
  INTERMEDIATE_BANK_COUNTRY: 'Intermediate Bank Country',
  CARD_INFO: 'Card Info',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  MARK_AS_DEFAULT_SUCCESS: 'Funding Instruction Marked as Default Successfully',
  BANK_ACCOUNT_TYPE: 'Bank Account Type',
  SPECIAL_INSTRUCTIONS: 'Special Instructions',
  HAS_INTERMEDIATE_BANK: 'Has Intermediate Bank',
  INTERMEDIATE_SPECIAL_INSTRUCTIONS: 'Intermediate Special Instructions',
  SPECIAL_INSTRUCTIONS_MAX_LENGTH: 'Special Instructions must be less than 250 characters',
  INFO_MESSAGE_PAYMENT_METHOD: 'Once the funding instruction has been created, the payment method cannot be changed.',
  //END Fabian
}

export const fundingInstructionsEs: typeof fundingInstructionsEn = {
  //BEGIN Michael
  FUNDING_INSTRUCTION: 'Instruccion de financiación',
  FUNDING_INSTRUCTIONS: 'Instrucciones de financiación',

  ACCOUNT_NICKNAME: 'Apodo de la cuenta',
  MARK_AS_DEFAULT: 'Marcar como predeterminado',
  FUNDING_INSTRUCTION_CREATED_SUCCESSFULLY: 'Instrucción de financiación creada con éxito',
  FUNDING_INSTRUCTION_UPDATED_SUCCESSFULLY: 'Instrucción de financiación actualizada con éxito',
  PAY_TO: 'Pagar a',
  BANK_INFO: 'Información Bancaria',
  BANK_NAME: 'Nombre del Banco',
  BANK_ACCOUNT_NUMBER: 'Número de Cuenta Bancaria',
  ROUTING_NUMBER: 'Número de Ruta',
  BANK_CODE: 'Código de Banco',
  SWIFT_CODE: 'Código Swift',
  BANK_CITY: 'Ciudad del Banco',
  BANK_STATE: 'Estado del Banco',
  BANK_COUNTRY: 'País del Banco',
  INTERMEDIATE_BANK_NAME: 'Nombre del Banco Intermediario',
  INTERMEDIATE_BANK_ACCOUNT_NUMBER: 'Número de Cuenta del Banco Intermediario',
  INTERMEDIATE_ROUTING_NUMBER: 'Número de Ruta Intermediario',
  INTERMEDIATE_BANK_CODE: 'Código de Banco Intermediario',
  INTERMEDIATE_SWIFT_CODE: 'Código Swift Intermediario',
  INTERMEDIATE_BANK_STATE: 'Estado del Banco Intermediario',
  INTERMEDIATE_BANK_COUNTRY: 'País del Banco Intermediario',
  CARD_INFO: 'Información de la tarjeta',
  //END Michael
  //BEGIN Fco
  //END Fco
  //BEGIN Fabian
  MARK_AS_DEFAULT_SUCCESS: 'Instrucción de financiación marcada como predeterminada con éxito',
  BANK_ACCOUNT_TYPE: 'Tipo de Cuenta Bancaria',
  SPECIAL_INSTRUCTIONS: 'Instrucciones Especiales',
  HAS_INTERMEDIATE_BANK: 'Tiene Banco Intermediario',
  INTERMEDIATE_SPECIAL_INSTRUCTIONS: 'Instrucciones Especiales Intermediarias',
  SPECIAL_INSTRUCTIONS_MAX_LENGTH: 'Las instrucciones especiales deben tener menos de 250 caracteres',
  INFO_MESSAGE_PAYMENT_METHOD: 'Una vez creada la instrucción de financiación, no se puede cambiar el método de pago.',
  //END Fabian
}

import * as React from 'react'
import Autocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete'
import { ChipProps } from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ClearIcon from '@mui/icons-material/Clear'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import TextField from '@mui/material/TextField'
import { TextFieldProps } from './TextField'
import { DropDownOptions } from 'types/common'
import { Tooltip } from 'components/ui'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export interface IMultiSelectProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  options?: DropDownOptions[]
  name?: string
  value?: DropDownOptions[] | string[] | string
  // value?: DropDownOptions | string[] | string
  hightLightColor?: string
  freeSolo?: boolean
  placeholder?: string
  readOnlyInput?: boolean
  chipProps?: ChipProps
  disablePortal?: boolean
  multiple?: boolean
  disableCloseOnSelect?: boolean
  disableClearable?: boolean
  openOnFocus?: boolean
  inputRef?: any
  tooltipTitle?: string
  renderTags?: (value: any[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode
  onOpen?: (e: React.SyntheticEvent<Element, Event>) => void
  onClose?: (e: React.SyntheticEvent<Element, Event>) => void
  onInputChange?: (e: React.SyntheticEvent<Element, Event>, val: string) => void
  onChange?: (e: React.SyntheticEvent<Element, Event>, val: any) => void
  setValue?: (name: string, value: any) => void
  width?: string
  txtInputId?: string
  withCheckbox?: boolean
}

const MultiSelectField: React.FunctionComponent<IMultiSelectProps> = (props) => {
  const {
    txtInputId,
    label,
    options,
    onInputChange,
    onChange,
    renderTags,
    multiple = true,
    value,
    // value = multiple ? [] : '', // ensure value is always defined to avoid the noncontrolled component warning
    error,
    helperText,
    disabled,
    freeSolo = false,
    className,
    placeholder,
    disablePortal = false,
    readOnlyInput = false,
    disableCloseOnSelect = false,
    disableClearable = false,
    openOnFocus = false,
    chipProps,
    inputRef,
    tooltipTitle = 'clear',
    onOpen,
    onClose,
    onBlur,
    width,
    withCheckbox = true,
  } = props

  function isDropdownOptions(value: any): value is DropDownOptions[] {
    return value && value[0] && value[0].label
  }

  const filteredOptions = React.useMemo(() => {
    if (multiple && value && isDropdownOptions(value)) {
      return options?.filter((option) => !value.some((selected) => selected.label === option.label)) || []
    }
    return options || []
  }, [options, value, multiple])

  return (
    <Autocomplete
      id={txtInputId}
      options={filteredOptions}
      value={value}
      style={{ width: width }}
      disabled={disabled}
      freeSolo={freeSolo}
      ChipProps={chipProps}
      renderTags={renderTags}
      // isOptionEqualToValue={(option, value) => {
      //   if (isDropdownOptions(value)) {
      //     return option.label === value.label
      //   } else {
      //     return option.label === value
      //   }
      // }}
      clearIcon={
        <Tooltip title={tooltipTitle}>
          <ClearIcon fontSize="small" />
        </Tooltip>
      }
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={(event, newInputValue) => {
        onInputChange && onInputChange(event, newInputValue)
      }}
      onChange={(e, val) => {
        onChange && onChange(e, val)
      }}
      renderOption={
        multiple
          ? (props, option, state) => {
              let selected = state.selected
              if (isDropdownOptions(value)) {
                selected = value.some((v) => v.label === option.label)
              }
              return withCheckbox ? (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.label}
                </li>
              ) : (
                <li {...props}>{option.label}</li>
              )
            }
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          error={error}
          helperText={helperText}
          disabled={disabled}
          className={className}
          onBlur={onBlur}
          inputRef={inputRef}
        />
      )}
      onKeyDown={(e) => readOnlyInput && e.preventDefault()}
      multiple={multiple}
      disablePortal={disablePortal}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      openOnFocus={openOnFocus}
      disableListWrap
    />
  )
}

export default MultiSelectField

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const ReportsPage = lazy(() => import('pages/reports/page'))
const ReportsDetailPage = lazy(() => import('pages/reports/[reportsId]/page'))
const ReportsSummaryPage = lazy(() => import('pages/reports/summary/page'))

const ReportsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<ReportsPage />} />} />
      <Route path="/summary" element={<LoaderPage content={<ReportsSummaryPage />} />} />
      <Route path="/:reportName/:reportId" element={<LoaderPage content={<ReportsDetailPage />} />} />
    </Routes>
  )
}

export default ReportsRoutes

import { Blade, Container, Toast as toast } from 'components/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { FactorDefaultFeesInfo, FactorDefaultFeesRequest, FactorDefaultRequest } from 'types/factorDefault'
import styles from '../SystemDefaultsSectionsUpdateBlade.module.scss'
import { TextFormField } from 'components/hookForm'
import strings from 'l10n'
import { minValueLimit, parseNumericValueFromForm } from 'tools/common'
import { Button } from 'components/form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'

type VendorSystemDefaultUpdateBladeProps = {
  title: string
  vendorPaymentFees?: FactorDefaultFeesInfo
  handleClose: () => void
  showBlade: boolean
}

type VendorSystemDefaultUpdateBladeForm = {
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
}

export const VendorSystemDefaultUpdateBlade = (props: VendorSystemDefaultUpdateBladeProps) => {
  const { title, vendorPaymentFees, handleClose, showBlade } = props
  const form = useForm<VendorSystemDefaultUpdateBladeForm>()
  const { handleSubmit, setValue } = form
  const queryClient = useQueryClient()

  const { mutate: updateVendorDefaultsValues, isLoading: isUpdateVendorDefaultsValuesLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        subTitle: strings.VENDOR_DEFAULTS_VALUES_UPDATED,
      })
      setInitialValues(data?.vendorDefaultValues?.vendorPaymentFees)
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      handleClose()
    },
  })

  const onSubmit = (data: VendorSystemDefaultUpdateBladeForm) => {
    const vendorPaymentFees: FactorDefaultFeesRequest = {
      achFee: parseNumericValueFromForm(data.achFee),
      wireFee: parseNumericValueFromForm(data.wireFee),
      checkFee: parseNumericValueFromForm(data.checkFee),
      fuelCardFee: parseNumericValueFromForm(data.fuelCardFee),
      sameDayAchFee: parseNumericValueFromForm(data.sameDayAchFee),
      moneyCodeFee: parseNumericValueFromForm(data.moneyCodeFee),
    }
    const payload: FactorDefaultRequest = {
      vendorDefaultValues: {
        vendorPaymentFees: { ...vendorPaymentFees },
      },
    }
    updateVendorDefaultsValues(payload)
  }

  const setInitialValues = (values: FactorDefaultFeesInfo) => {
    setValue('achFee', values.achFee)
    setValue('wireFee', values.wireFee)
    setValue('checkFee', values.checkFee)
    setValue('fuelCardFee', values.fuelCardFee)
    setValue('sameDayAchFee', values.sameDayAchFee)
    setValue('moneyCodeFee', values.moneyCodeFee)
  }

  const bladeContent = (
    <FormProvider {...form}>
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="achFee"
          label={strings.ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.achFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
        <TextFormField
          name="wireFee"
          label={strings.WIRE_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.wireFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
        <TextFormField
          name="checkFee"
          label={strings.CHECK_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.checkFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
        <TextFormField
          name="fuelCardFee"
          label={strings.FUEL_CARD_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.fuelCardFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
        <TextFormField
          name="sameDayAchFee"
          label={strings.SAME_DAY_ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.sameDayAchFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
        <TextFormField
          name="moneyCodeFee"
          label={strings.MONEY_CODE_FEE}
          type="number"
          startAdornment="$"
          initialValue={vendorPaymentFees?.moneyCodeFee}
          min={minValueLimit(0)}
          disabled={isUpdateVendorDefaultsValuesLoading}
        />
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button
        variant="outlined"
        label={strings.CANCEL}
        disabled={isUpdateVendorDefaultsValuesLoading}
        onClick={handleClose}
      />
      <Button
        id="btnCreateBladeCreateCompany"
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isUpdateVendorDefaultsValuesLoading}
        loading={isUpdateVendorDefaultsValuesLoading}
      />
    </Container>
  )

  return (
    <Blade
      variant="persistent"
      open={showBlade}
      title={title}
      onClose={handleClose}
      loading={isUpdateVendorDefaultsValuesLoading}
      bodyNode={bladeContent}
      footerNode={bladeFooter}
      hideBackdrop
    />
  )
}

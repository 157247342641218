import { useQuery } from '@tanstack/react-query'
import { getMeFactorSettings, getFeatureFlags } from 'api/factors'
import { qkGetFactorSettings, qkGetFeatureFlags } from 'constants/QueryKeys/factor'

const useAdminSettings = () => {
  const { data: factorMeSettingResponse, refetch: refetchMeAdminSettings } = useQuery({
    queryKey: qkGetFactorSettings('me'),
    queryFn: () => {
      return getMeFactorSettings()
    },
  })

  const { data: featureFlagsResponse } = useQuery({
    queryKey: qkGetFeatureFlags(),
    queryFn: () => {
      return getFeatureFlags()
    },
  })

  const brightboltIntegration =
    featureFlagsResponse?.brightbolt.enabled && factorMeSettingResponse?.settings?.enableBrightboltIntegration

  return {
    chargeAccruedInterest: factorMeSettingResponse?.settings.chargeAccruedInterest,
    enableFuelAdvance: factorMeSettingResponse?.settings.enableFuelAdvance,
    flagEnableBrokerMod: featureFlagsResponse?.brokerMod.enabled,
    enableBrokerMod: factorMeSettingResponse?.settings.enableBrokerMod,
    factorFrequencyOfCollectionOfAccruedFees:
      factorMeSettingResponse?.settings.factorFrequencyOfCollectionOfAccruedFees,
    enableBrightboltIntegration: brightboltIntegration,
    hideAllChargebacks: featureFlagsResponse?.hideAllChargebacks.enabled,
    refetchMeAdminSettings: refetchMeAdminSettings,
    factorSetting: factorMeSettingResponse?.settings,
    exportLedgers: featureFlagsResponse?.exportLedgers.enabled || false,
    exportLedgersMaxRows: featureFlagsResponse?.exportLedgers.maxRows || 10,
    editPayment: featureFlagsResponse?.editPayment.enabled || false,
    enableNonFactoredInvoices: featureFlagsResponse?.nonFactoredInvoices.enabled || false,
    //enableNonFactoredInvoices: false,
    enableWithdrawPayment: featureFlagsResponse?.undoPayment.enabled || false,
    //enableWithdrawPayment: false,
    enableCreditCheck: featureFlagsResponse?.creditCheck.enabled || false,
    //enableCreditCheck: false,
    enableOutOfSystemInvoices: featureFlagsResponse?.outOfSystemInvoices.enabled || false,
    //enableOutOfSystemInvoices: false,
    enableReserveReleases: featureFlagsResponse?.reserveReleases.enabled || false,
    //enableReserveReleases: false,
    enableRebillInvoices: featureFlagsResponse?.rebillInvoices.enabled || false,
    //rebillInvoices: false,
    enableNoaLevelSetting: featureFlagsResponse?.noaLevelSetting.enabled || false,
    //enableNoaLevelSetting: false,
  }
}

export default useAdminSettings

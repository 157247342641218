import React, { useState, useEffect } from 'react'
import { Tabs as MuiTabs, useMediaQuery, useTheme } from '@mui/material'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { Container } from 'components/ui'
import styles from './Tabs.module.scss'
import DropdownMenu from 'components/form/DropdownMenu'
import colors from 'theme/colors'

export type TabData = {
  label: React.ReactNode | string
  badgeColor?: string
  badgeContent?: string
  badgeTextColor?: string
  filterValue?: string
  id?: string
}

export type TabDataLabel = TabData & {
  formatLabel?: string
}

type MuiTabProps = {
  label: React.ReactNode | string
  value: number | string
  key: string
  iconPosition?: 'top' | 'start' | 'end' | 'bottom'
  icon?: any
  id?: string
}

const MuiTab = styled((props: MuiTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: 500,
  width: 'auto',
  minHeight: '45px',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
}))

type TabsProps = {
  tabData: TabData[]
  value: number
  iconPosition?: 'top' | 'start' | 'end' | 'bottom'
  containerClass?: string
  containerSx?: any
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
}

const Tabs = (props: TabsProps) => {
  const { tabData, value, handleChange, iconPosition = 'end', containerClass, containerSx } = props

  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down(835))
  const isMD = useMediaQuery(theme.breakpoints.down(1200))
  const isLG = useMediaQuery(theme.breakpoints.down(1620))

  const [visibleTabs, setVisibleTabs] = useState<TabData[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    if (tabData.length > 6) {
      if (isLG && isMD === false && isSM === false) {
        setVisibleTabs(tabData.slice(0, 6))
        setIsDropdownOpen(true)
      } else if (isMD && isLG === true && isSM === false) {
        setVisibleTabs(tabData.slice(0, 4))
        setIsDropdownOpen(true)
      } else if (isSM && isMD === true && isLG === true) {
        setVisibleTabs([])
        setIsDropdownOpen(true)
      } else {
        setVisibleTabs(tabData)
        setIsDropdownOpen(false)
      }
    } else {
      setVisibleTabs(tabData)
      setIsDropdownOpen(false)
    }
  }, [isLG, isMD, isSM, tabData])

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  if (visibleTabs?.length <= 1) return <></>

  return (
    <Container sx={{ position: 'relative', ...containerSx }} className={`fc-tabs ${containerClass}`}>
      <MuiTabs
        value={value}
        onChange={(e, val) => handleChange(e, val)}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        orientation={isSM ? 'vertical' : 'horizontal'}
      >
        {visibleTabs.map((item, index) => (
          <MuiTab
            key={`key-${index}`}
            icon={
              item.badgeContent && (
                <Container
                  className={styles.badge}
                  sx={{ background: item.badgeColor || colors.grey10, color: item.badgeTextColor || colors.white }}
                >
                  {item.badgeContent}
                </Container>
              )
            }
            value={index}
            label={item.label}
            iconPosition={iconPosition}
            id={item.id}
          />
        ))}
        {isDropdownOpen && (
          <DropdownMenu
            dropdownOptions={tabData.slice(visibleTabs.length)}
            numberOfVisibleTabs={visibleTabs.length}
            onChange={handleDropdownClick}
          />
        )}
      </MuiTabs>
    </Container>
  )
}

export default Tabs

import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const CreditRequestPage = lazy(() => import('pages/creditRequest/page'))

const CreditRequestRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<CreditRequestPage />} />} />
    </Routes>
  )
}

export default CreditRequestRoutes

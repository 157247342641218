import { Route, RouteProps, Routes } from 'react-router-dom'
import { NotFoundPage } from 'components/ui'
import { lazy } from 'react'
import LoaderPage from 'components/ui/feedback/LoaderPage'

const UsersListPage = lazy(() => import('pages/settings/users/page'))

const UsersRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<UsersListPage />} />} />
      <Route path="/:userId" element={<NotFoundPage />} />
    </Routes>
  )
}

export default UsersRoutes

export const ledgerNameEn = {
  //Begin Esteban
  OPEN_AR_KEY: 'Open AR',
  FEES_KEY: 'Fees',
  ACCRUED_FEES_KEY: 'Accrued Fees',
  ACCRUED_INTEREST_KEY: 'Accrued Interest',
  ESCROW_RESERVE_KEY: 'Escrow Reserve',
  CASH_RESERVE_KEY: 'Cash Reserve',
  AP_BANK_ACCOUNT_KEY: 'AP Bank Account',
  AR_BANK_ACCOUNT_KEY: 'AR Bank Account',
  LIABILITY_KEY: 'Liability',
  UNNAPLIED_KEY: 'Unnaplied',
  FUEL_ADVANCE_KEY: 'Fuel Advance',
  LEDGER_NAME: 'Ledger Name',
  UPDATE_LEDGER_NAME: 'Update Ledger Name',
  LEDGER_NAME_FOOTER: 'To reset the value, send empty ledger name.',
  //End Esteban
}

export const ledgerNameEs: typeof ledgerNameEn = {
  //Begin Esteban
  OPEN_AR_KEY: 'Open AR',
  FEES_KEY: 'Fees',
  ACCRUED_FEES_KEY: 'Accrued Fees',
  ACCRUED_INTEREST_KEY: 'Accrued Interest',
  ESCROW_RESERVE_KEY: 'Escrow Reserve',
  CASH_RESERVE_KEY: 'Cash Reserve',
  AP_BANK_ACCOUNT_KEY: 'AP Bank Account',
  AR_BANK_ACCOUNT_KEY: 'AR Bank Account',
  LIABILITY_KEY: 'Liability',
  UNNAPLIED_KEY: 'Unnaplied',
  FUEL_ADVANCE_KEY: 'Fuel Advance',
  LEDGER_NAME: 'Nombre del libro mayor',
  UPDATE_LEDGER_NAME: 'Actualizar nombre del libro mayor',
  LEDGER_NAME_FOOTER: 'Para restablecer el valor, envíe un nombre de libro mayor vacío.',
  //End Esteban
}

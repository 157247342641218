export const chargebackEn = {
  FULL: 'Full',
  PART: 'Part',
  CHARGEBACKS: 'Chargebacks',
  CHARGEBACK: 'Chargeback',
  APPLY_CHARGEBACK: 'Apply Chargeback',
  CHARGEBACK_APPLICATION_CONFIRMATION: 'Are you sure that you want to apply this chargeback to these invoices?',
  CHARGEBACK_FA_APPLICATION_CONFIRMATION: 'Are you sure that you want to apply this chargeback to these fuel advances?',
  CHARGEBACK_ASSIGN_SUCCESSFULLY: 'Chargeback assigned successfully',
  CHARGEBACK_SELECTED_INVOICES: "Chargeback is going to be applied to the selected invoices:",
  ADD_NOTES: "Add notes:",
  UNAPPLIED_FUND: 'Unapplied Fund',
  NO_INVOICE_SELECTED: 'No invoices selected',
  ONE_INVOICE_SELECTED: 'One invoice selected',
  MULTIPLE_INVOICE_SELECTED: '{amount} invoices selected',
  CONFIRM_APPLY_CHARGEBACK: 'Confirm Apply Chargeback'
}

export const chargebackEs: typeof chargebackEn = {
  FULL: 'Completo',
  PART: 'Parcial',
  CHARGEBACKS: 'Devoluciones',
  CHARGEBACK: 'Devolución',
  APPLY_CHARGEBACK: 'Aplicar Devolución',
  CHARGEBACK_APPLICATION_CONFIRMATION: '¿Está seguro que desea aplicar esta devolución a estas facturas?',
  CHARGEBACK_FA_APPLICATION_CONFIRMATION:
    '¿Está seguro que desea aplicar esta devolución a estos anticipos de combustible?',
  CHARGEBACK_ASSIGN_SUCCESSFULLY: 'Devolución asignada correctamente',
  CHARGEBACK_SELECTED_INVOICES: "La devolución será aplicada a las siguientes facturas:",
  ADD_NOTES: "Agregar notas:",
  UNAPPLIED_FUND: 'Fondo no aplicado',
  NO_INVOICE_SELECTED: 'Ninguna factura seleccionada',
  ONE_INVOICE_SELECTED: 'Una factura seleccionada',
  MULTIPLE_INVOICE_SELECTED: '{amount} facturas seleccionadas',
  CONFIRM_APPLY_CHARGEBACK: 'Confirmar Devolución'

}

import strings from 'l10n'
import { SectionData } from 'types/common'
import { SystemDefaultsSectionProps } from './SystemDefaultsComponent'
import { VendorSystemDefaults } from './SystemDefaultsSections/VendorSystemDefaults/VendorSystemDefaults'
import { DebtorSystemDefaults } from './SystemDefaultsSections/DebtorSystemDefaults/DebtorSystemDefaults'
import { ClientSystemDefaults } from './SystemDefaultsSections/ClientSystemDefaults/ClientSystemDefaults'
import { BrokerSystemDefaults } from './SystemDefaultsSections/BrokerSystemDefaults/BrokerSystemDefaults'
import { RelationshipSystemDefaults } from './SystemDefaultsSections/RelationshipSystemDefaults/RelationshipSystemDefaults'

export function generateSections(props: SystemDefaultsSectionProps) {
  const { factorDefault, canModifyFactorDefaultValues } = props
  let sectionData: SectionData[] = []

  sectionData.push({
    id: 'vendors',
    label: strings.VENDORS,
    content: (
      <VendorSystemDefaults factorDefault={factorDefault} canModifyFactorDefaultValues={canModifyFactorDefaultValues} />
    ),
  })

  sectionData.push({
    id: 'clients',
    label: strings.CLIENTS,
    content: (
      <ClientSystemDefaults factorDefault={factorDefault} canModifyFactorDefaultValues={canModifyFactorDefaultValues} />
    ),
  })

  sectionData.push({
    id: 'brokers',
    label: strings.BROKER_CLIENTS,
    content: (
      <BrokerSystemDefaults factorDefault={factorDefault} canModifyFactorDefaultValues={canModifyFactorDefaultValues} />
    ),
  })

  sectionData.push({
    id: 'debtors',
    label: strings.DEBTORS,
    content: (
      <DebtorSystemDefaults factorDefault={factorDefault} canModifyFactorDefaultValues={canModifyFactorDefaultValues} />
    ),
  })

  sectionData.push({
    id: 'relationships',
    label: strings.DEBTOR_RELATIONSHIP,
    content: (
      <RelationshipSystemDefaults
        factorDefault={factorDefault}
        canModifyFactorDefaultValues={canModifyFactorDefaultValues}
      />
    ),
  })

  return sectionData
}

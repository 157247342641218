import strings from 'l10n'
import { isDefined } from './common'
import colors from 'theme/colors'
import { currencyDropDownOptions } from 'constants/options'
import { InvoiceChangelogInfo, InvoiceSummaryInfo, isInvoiceStatus } from 'types/invoice'
import { TermSummaryInfo } from 'types/terms'
import { UserInfo } from 'types/users'
import { FieldValues } from 'react-hook-form'
import { FundingInstructionsCreateRequest, FundingInstructionsUpdateRequest } from 'types/fundingInstructions'
import { DropDownOptions } from 'types/common'
import { BrokerInfo, CompanyClientAdminSettingsResponse, CompanyEmployeeEnum } from 'types/company'
import uuid from 'react-uuid'
import { OutOfSystemInvoicesInfo } from 'types/payments'
import { FuelAdvanceInfo } from 'types/fuelAdvances'
import { ReserveReleaseInfo } from 'types/reserveRelease'
import { DraftPaymentStatus } from 'types/paymentBatches'

export function addAllValues(obj: Record<string, number>): number {
  const valuesArray = Object.values(obj)
  const sum = valuesArray.reduce((total, currentValue) => total + currentValue, 0)
  return sum
}

export function def(value?: string | number | null, def = '-') {
  return value ?? def
}

export const formattedTotalRate = (totalRate: string = '') => {
  return Number(removeCommas(totalRate.replace('$', '')))
}

export function percentage(value: number = 0) {
  const f = new Intl.NumberFormat(navigatorLocale(), {
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
  })
  return f.format(value * 100)
}

export const removeCommas = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  return value.replace(/,/g, '.')
}

export const removeSpecialCharactersFromTheMiddleOfAString = (value: string) => {
  if (isDefined(value) && value !== '') return value.replace(/(?!^)[^\d.]/g, '')
  return ''
}

export function toDecimal(value?: string | number) {
  if (isDefined(value)) {
    const finalValue: number = typeof value == 'string' ? Number(value.replace(/,/g, '.')) : value
    return (finalValue / 100).toFixed(4)
  }
  return null
}

export function toDecPercent(value?: string | number) {
  if (isDefined(value)) {
    const finalValue: number = typeof value == 'string' ? Number(value.replace(/,/g, '.')) : value
    return (finalValue / 100).toFixed(6)
  }
  return null
}

type DateTimeFormatArgs = {
  date?: string | null
  locale?: string
  defValue?: string
  formatOptions?: Intl.DateTimeFormatOptions
}

//Only for displaying, not for parsing to input components
export function dateTimeFormat(args: DateTimeFormatArgs) {
  const { date, locale = navigatorLocale(), defValue = '-', formatOptions = {} } = args
  const d = date ? new Date(date) : null
  if (d === null) return defValue
  const formatter = new Intl.DateTimeFormat(locale, formatOptions)
  return formatter.format(d)
}

export function utcDateFormat(date: string | null) {
  return dateTimeFormat({
    date: date,
    formatOptions: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    },
  })
}

export function localeDateTimeFormat(date: string | null, timeZone?: string | undefined) {
  return dateTimeFormat({
    date: date,
    formatOptions: {
      dateStyle: 'short',
      timeStyle: 'short',
      timeZone: timeZone
    },
  })
}

export function forceDisplayZero(value: number) {
  if (value === null || value === undefined) return null
  if (value === 0) return '0'
  return value
}

export function currencyFormat(amount?: number, currency: string = 'USD') {
  if (amount === null) return '-'
  const f = new Intl.NumberFormat(navigatorLocale(), {
    currencyDisplay: 'symbol',
    currency: currency,
    style: 'currency',
  })
  return f.format(amount ? amount : 0)
}

export function dollarFormat(amount: number) {
  return '$' + amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function percentageFormat(value?: number | null) {
  if (isDefined(value)) {
    return percentage(value) + '%'
  }
  return '-'
}

export function megaByteFormat(bytes?: number | null) {
  if (isDefined(bytes)) {
    const megaBytes = bytes / 1_048_576
    const f = new Intl.NumberFormat(navigatorLocale(), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return f.format(megaBytes) + 'MB'
  }
  return '-'
}

export function boolFormat(val?: boolean | null) {
  if (val === true) return strings.YES
  if (val === false) return strings.NO
  return '-'
}

function navigatorLocale() {
  const locale = navigator.language ?? 'en-US'
  return locale
}

export function addCurrentTime(dateString: Date): Date {
  const date = new Date(dateString)
  date.setSeconds(date.getSeconds() - 1)
  date.setDate(date.getDate() + 1)
  return date
}

export function setEndOfDay(date: Date): Date {
  const dateNoTime = date.toISOString().split('T')[0]
  const finalDateTime = `${dateNoTime}T23:59:59.999Z`
  const finalDate = new Date(finalDateTime)

  return finalDate
}

export function setTimeToZero(date: Date) {
  const dateNoTime = date.toISOString().split('T')[0]
  const finalDate = new Date(dateNoTime)
  return finalDate
}

export function findCurrencyOption(currencyAbbreviators: string | undefined) {
  return currencyDropDownOptions.find((option) => option.value === currencyAbbreviators)?.label
}

export const formattedDocumentType = (documentType: string, otherDocumentType?: string) => {
  switch (documentType) {
    case 'INVOICE':
      return 'Invoice'
    case 'PAYMENT':
      return 'Payment'
    case 'DISBURSEMENT':
      return 'Disbursement'
    case 'NOA':
      return 'Notice of Assignment'
    case 'POI':
      return 'Proof of Insurance'
    case 'UCC':
      return 'Uniform Commercial Code'
    case 'CONTRACT':
      return 'Contract'
    case 'TAX_DOC':
      return 'Tax Doc'
    case 'OTHER':
      if (otherDocumentType) {
        return formattedOtherDocumentType(otherDocumentType)
      }
      return 'Other'
    default:
      return 'Other'
  }
}

export const formatterVerificationStatusInvoice = (verificationStatus: string) => {
  switch (verificationStatus) {
    case 'DENIED':
      return strings.VERIFY_STATUS_DENIED
    case 'NOT_VERIFIED':
      return strings.VERIFY_STATUS_NOT_VERIFIED
    case 'PENDING':
      return strings.VERIFY_STATUS_PENDING
    case 'VERIFIED':
      return strings.VERIFY_STATUS_VERIFIED
    default:
      return '-'
  }
}

export const formattedOtherDocumentType = (otherDocumentType: string) => {
  const string = otherDocumentType.toLocaleLowerCase()
  const words = string.split(' ')
  const capitalizedWords = words.map((word) => {
    return word[0].toUpperCase() + word.slice(1)
  })
  return capitalizedWords.join(' ')
}

export const formattedInvoiceGroupsStatus = (status?: string) => {
  switch (status) {
    case 'NOT_FUNDED':
      return strings.NOT_FUNDED.SINGLE
    case 'FUNDED':
      return strings.FUNDED.SINGLE
    default:
      return ''
  }
}

export const formatChangelog = (log: InvoiceChangelogInfo) => {
  let output = log.userEmail
  if (log.logType === 'VERIFICATION') {
    output = `${formatVerificationInvoiceStatus(log.status)} by ${output}`
  } else if (log.logType === 'COLLECTION') {
    output = `${strings.COLLECTION}: ${output}`
  } else if (log.logType === 'UPDATE') {
    output = `${strings.INVOICE} ${strings.UPDATE}: ${output}`
  }
  return output
}

export const formatChangelogInvoiceStatus = (log: InvoiceChangelogInfo) => {
  let status = log.status
  if (!isInvoiceStatus(status)) return ''
  return `${strings.STATUS.LABEL} : ${formattedStatus(status)}`
}

export const formatChangelogInvoiceMethod = (log: InvoiceChangelogInfo) => {
  let method = log.method
  return `${strings.METHOD} : ${formatVerificationMethodInvoiceStatus(method)}`
}

export const formatVerificationInvoiceStatus = (type: string | undefined) => {
  if (type === 'VERIFIED') return strings.VERIFIED
  if (type === 'DENIED') return strings.DENIED
  if (type === 'PENDING') return strings.PENDING.SINGLE
  if (type === 'NOT_VERIFIED') return strings.NOT_VERIFIED
  if (type === '') return '-'

  return type
}

export const formatVerificationMethodInvoiceStatus = (type: string) => {
  return capitalizeWords(type)
}

export function formatInvoiceDate(invoiceDate: string) {
  const currentDate = new Date().toISOString().split('T')[1]
  return invoiceDate.concat('T').concat(currentDate)
}

export function isUpdatedInvoiceDate(invoiceDate: string, dataInvoiceDate: string) {
  return invoiceDate.split('T')[0] === dataInvoiceDate ? invoiceDate : formatInvoiceDate(dataInvoiceDate)
}

export const formatPaymentMethod = (type: string = '-') => {
  if (type === 'ACH') return 'ACH'
  if (type === 'SAME_DAY_ACH') return 'Same Day ACH'
  if (type === 'WIRE') return 'Wire'
  if (type === 'FUEL_CARD') return 'Fuel Card'
  if (type === 'CHECK') return 'Check'
  if (type === 'MONEY_CODE') return 'Money Code'

  return type
}

export const formattedStatus = (type: string) => {
  //if (!isInvoiceStatus(type)) return ''

  if (type === 'PENDING') return strings.PENDING.SINGLE
  if (type === 'APPROVED_FOR_FUNDING') return strings.APPROVED_FOR_FUNDING.SINGLE
  if (type === 'FUNDED') return strings.FUNDED.SINGLE
  if (type === 'PAID') return strings.CLOSED
  if (type === 'REJECTED') return strings.REJECTED
  if (type === 'DELETED') return strings.DELETED
  if (type === 'APPROVED') return strings.APPROVED
  if (type === 'OPEN') return strings.OPEN
  if (type === 'CLOSED') return strings.CLOSED

  return type
}

export const formattedCompanyType = (type: string) => {
  if (type === 'Client') return strings.CLIENTS
  if (type === 'Debtor') return strings.DEBTORS

  return type
}

export const formattedInformation = (listData?: InvoiceSummaryInfo[] | FuelAdvanceInfo[] | ReserveReleaseInfo[]) => {
  const modifiedData = listData?.map((data) => {
    return {
      ...data,
      status: formattedStatus(data.status),
    }
  })
  return modifiedData
}

export const formatValueMethodFee = (invoicePreviewResponse: any, watch: string) => {
  if (invoicePreviewResponse && watch) {
    const selectedOption = invoicePreviewResponse?.fundingInstruction.find((item: any) => item.id === watch)
    if (selectedOption?.paymentMethod === 'ACH') {
      return { nameFee: strings.ACH_FEE, valueFee: invoicePreviewResponse?.paymentFees.achFee || 0 }
    } else if (selectedOption?.paymentMethod === 'WIRE') {
      return { nameFee: strings.WIRE_FEE, valueFee: invoicePreviewResponse?.paymentFees.wireFee || 0 }
    } else if (selectedOption?.paymentMethod === 'CHECK') {
      return { nameFee: strings.CHECK_FEE, valueFee: invoicePreviewResponse?.paymentFees.checkFee || 0 }
    } else if (selectedOption?.paymentMethod === 'FUEL_CARD') {
      return {
        nameFee: strings.FUEL_CARD_FEE,
        valueFee: invoicePreviewResponse?.paymentFees.fuelCardFee || 0,
      }
    } else if (selectedOption?.paymentMethod === 'SAME_DAY_ACH') {
      return {
        nameFee: strings.SAME_DAY_ACH_FEE,
        valueFee: invoicePreviewResponse?.paymentFees.sameDayAchFee || 0,
      }
    } else if (selectedOption?.paymentMethod === 'MONEY_CODE') {
      return {
        nameFee: strings.MONEY_CODE_FEE,
        valueFee: invoicePreviewResponse?.paymentFees.moneyCodeFee || 0,
      }
    }
  }
}

export const formatTermType = (type?: string) => {
  switch (type) {
    case 'INVOICE_RATE':
      return strings.INVOICE
    case 'NFE_RATE':
      return strings.TERM_TYPE_NFE
    case 'ADVANCE_RATE':
      return strings.TERM_TYPE_ADVANCE
    default:
      return ''
  }
}

export const formatNfeRateType = (type?: string) => {
  switch (type) {
    case 'FLAT_RATE':
      return strings.FLAT_RATE
    case 'PRIME_PLUS':
      return strings.PRIME_PLUS
    case 'LIBOR':
      return strings.LIBOR
    case 'SOFR':
      return strings.SOFR
    default:
      return ''
  }
}

export const formatTermResponse = (terms?: TermSummaryInfo[]) => {
  const modifiedData = terms?.map((term) => {
    return {
      ...term,
      interest: term.interest ? strings.YES : strings.NO,
      type: formatTermType(term.type),
    }
  })
  return modifiedData
}

export const formatDaysPer = (type?: string) => {
  switch (type) {
    case 'DAYS_365':
      return '365'
    case 'DAYS_360':
      return '360'
    default:
      return ''
  }
}

export const formatFloatDaysAre = (type?: string) => {
  switch (type) {
    case 'BUSINESS_DAYS':
      return strings.BUSINESS_DAYS
    case 'CALENDAR_DAYS':
      return strings.CALENDAR_DAYS
    case 'MONDAY_FRIDAY':
      return strings.MONDAY_FRIDAY
    default:
      return ''
  }
}

export const formatInvoiceGroupType = (type?: string) => {
  switch (type) {
    case 'INVOICE':
      return strings.INVOICE
    case 'FUEL_ADVANCE':
      return strings.FUEL_ADVANCE
    case 'VENDOR':
      return strings.VENDOR
    case 'BROKER':
      return strings.BROKER
    default:
      return ''
  }
}

export const formatUserResponse = (users?: UserInfo[]) => {
  const modifiedData = users?.map((user) => {
    return {
      ...user,
      id: user.id,
      fullName: user.firstName.concat(' ', user.lastName),
      email: user.email,
      role: user.role.name,
    }
  })
  return modifiedData
}

export const formatClientUserResponse = (users?: UserInfo[]) => {
  const modifiedData = users?.map((user) => {
    return {
      ...user,
      id: user.id,
      fullName: user.firstName.concat(' ', user.lastName),
      email: user.email,
    }
  })
  return modifiedData
}

export const formatPermissionResponse = (data: any) => {
  let groupedData: any = {}

  data.forEach((item: any) => {
    // Check if the groupCode is already a key in the groupedData object
    if (!groupedData[item.groupCode]) {
      // If not, create an array under that key
      groupedData[item.groupCode] = []
    }

    // Push the current item to the array under the corresponding groupCode
    groupedData[item.groupCode].push(item)
  })

  return groupedData
}

export const formatPayloadFundingInstructions = (
  data: FieldValues,
  paymentMethod: string,
  hasIntermediateBank: boolean
) => {
  const payload: FundingInstructionsUpdateRequest | FundingInstructionsCreateRequest = {
    name: data.name,
    payTo: data.payTo,
    paymentMethod: data.paymentMethod,
  }
  if (paymentMethod === 'FUEL_CARD' || paymentMethod === 'CHECK' || paymentMethod === 'MONEY_CODE') {
    payload.specialInstructions = data.specialInstructions
    if (paymentMethod === 'FUEL_CARD') {
      payload.fuelCardNumber = data.fuelCardNumber
    } else if (paymentMethod === 'CHECK') {
      payload.checkCardNumber = data.checkCardNumber
    } else if (paymentMethod === 'MONEY_CODE') {
      payload.moneyCodeAccountNumber = data.moneyCodeAccountNumber
    }
  } else {
    payload.address1 = data.address1
    payload.address2 = data.address2
    payload.city = data.city
    payload.stateCode = data.stateCode
    payload.zipCode = data.zipCode
    payload.countryCode = data.countryCode
    payload.contactName = data.contactName
    payload.contactPhone = data.contactPhone
    payload.bankName = data.bankName
    payload.bankAccountType = data.bankAccountType ? data.bankAccountType.toUpperCase() : null
    payload.bankAccountNumber = data.bankAccountNumber
    payload.routingNumber = data.routingNumber
    payload.bankCode = data.bankCode
    payload.swiftCode = data.swiftCode
    payload.bankCity = data.bankCity
    payload.bankState = data.bankState
    payload.bankCountry = data.bankCountry
    payload.specialInstructions = data.specialInstructions
    payload.hasIntermediateBank = hasIntermediateBank

    if (hasIntermediateBank) {
      payload.intermediateBankName = data.intermediateBankName
      payload.intermediateAccountNumber = data.intermediateAccountNumber
      payload.intermediateRoutingNumber = data.intermediateRoutingNumber
      payload.intermediateBankCode = data.intermediateBankCode
      payload.intermediateSwiftCode = data.intermediateSwiftCode
      payload.intermediateBankCity = data.intermediateBankCity
      payload.intermediateBankState = data.intermediateBankState
      payload.intermediateBankCountry = data.intermediateBankCountry
      payload.intermediateSpecialInstructions = data.intermediateSpecialInstructions
    }
  }

  return payload
}

export const isPaymentMethod = (paymentMethod?: string): boolean => {
  return (
    paymentMethod === 'ACH' ||
    paymentMethod === 'CHECK' ||
    paymentMethod === 'WIRE' ||
    paymentMethod === 'FUEL_CARD' ||
    paymentMethod === 'SAME_DAY_ACH' ||
    paymentMethod === 'MONEY_CODE'
  )
}

export const getColorFromPercentage = (percentage: string) => {
  // Remove any leading or trailing whitespace
  percentage = percentage.trim()[0]

  // Check if the percentage string starts with a '+' or '-'
  if (percentage === '-') {
    return colors.red60 // Negative value, return red
  } else if (percentage === '+') {
    return colors.green60 // Positive value, return green
  } else {
    // Handle the case where there is no '+' or '-' sign (considered positive)
    return colors.green60
  }
}

export function transformGlCodesToOptions(glCodesResponse?: Record<string, any>): DropDownOptions[] {
  const feeOptions: DropDownOptions[] = []

  for (const key in glCodesResponse) {
    if (glCodesResponse[key] !== null) {
      const capitalizedLabel = capitalizeWords(key.replace(/([A-Z])/g, ' $1'))
      const label = `${capitalizedLabel} - ${glCodesResponse[key]}`

      feeOptions.push({
        label: label,
        value: key,
      })
    }
  }

  return feeOptions
}

function capitalizeWords(str: string): string {
  return str.replace(/\b\w/g, (match) => match.toUpperCase())
}

export function capitalizeCamelCase(str: string): string {
  if (!isDefined(str)) return ''
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (match) => match.toUpperCase())
}

type Summary = {
  id: string
  summaryName: string
  startBalance: number
  endBalance: number
  balanceChange: number
}

type SummaryArray = {
  data: Summary[]
  portfolioTotalsInfo: {
    beginningBalance: number
    endingBalance: number
    balanceChange: number
  }
}

export type CcBcc = {
  id: string
  email: string
  type: string
}

export type CcBccArray = {
  data: CcBcc[]
}

export function summaryToArray(summary: any): SummaryArray {
  const summaryArray: SummaryArray = {
    data: [],
    portfolioTotalsInfo: {
      beginningBalance: 0,
      endingBalance: 0,
      balanceChange: 0,
    },
  }

  for (const key in summary) {
    if (summary.hasOwnProperty(key)) {
      const summaryItem = summary[key]
      summaryArray.portfolioTotalsInfo.beginningBalance += summaryItem.startBalance
      summaryArray.portfolioTotalsInfo.endingBalance += summaryItem.endBalance
      summaryArray.portfolioTotalsInfo.balanceChange += summaryItem.balanceChange

      summaryArray.data.push({
        id: uuid(),
        summaryName: key,
        startBalance: summaryItem.startBalance,
        endBalance: summaryItem.endBalance,
        balanceChange: summaryItem.balanceChange,
      })
    }
  }

  return summaryArray
}

export function bccCcToArray(ccEmailList: string, bccEmailList: string): CcBccArray {
  const ccBccArray: CcBccArray = {
    data: [],
  }

  if (isDefined(ccEmailList) && ccEmailList.length > 0) {
    ccEmailList.split(',').forEach((email) => {
      ccBccArray.data.push({
        id: uuid(),
        email: email,
        type: 'CC',
      })
    })
  }
  if (isDefined(bccEmailList) && bccEmailList.length > 0) {
    bccEmailList.split(',').forEach((email) => {
      ccBccArray.data.push({
        id: uuid(),
        email: email,
        type: 'BCC',
      })
    })
  }

  return ccBccArray
}

type Ledger = {
  id: string
  ledgerName: string
  startBalance: number
  endBalance: number
  totalCredit: number
  totalDebit: number
  balanceChange: number
}

type LedgersArray = {
  data: Ledger[]
  ledgersTotalsInfo: {
    totalCredit: number
    totalDebit: number
    beginningBalance: number
    endingBalance: number
    balanceChange: number
  }
}

export function ledgersToArray(ledgers: any): LedgersArray {
  const ledgersArray: LedgersArray = {
    data: [],
    ledgersTotalsInfo: {
      totalCredit: 0,
      totalDebit: 0,
      beginningBalance: 0,
      endingBalance: 0,
      balanceChange: 0,
    },
  }

  for (const key in ledgers) {
    if (ledgers.hasOwnProperty(key)) {
      const ledger = ledgers[key]
      ledgersArray.ledgersTotalsInfo.totalCredit += ledger.totalCredit
      ledgersArray.ledgersTotalsInfo.totalDebit += ledger.totalDebit
      ledgersArray.ledgersTotalsInfo.beginningBalance += ledger.startBalance
      ledgersArray.ledgersTotalsInfo.endingBalance += ledger.endBalance
      ledgersArray.ledgersTotalsInfo.balanceChange += ledger.balanceChange

      ledgersArray.data.push({
        id: uuid(),
        ledgerName: key,
        startBalance: ledger.startBalance,
        endBalance: ledger.endBalance,
        totalCredit: ledger.totalCredit,
        totalDebit: ledger.totalDebit,
        balanceChange: ledger.balanceChange,
      })
    }
  }

  return ledgersArray
}

export function formatLedgerName(ledgerName: string) {
  switch (ledgerName) {
    case 'openAr':
      return strings.OPEN_AR
    case 'fees':
      return strings.FEES
    case 'escrow':
      return strings.ESCROW
    case 'cashReserve':
      return strings.CASH_RESERVE
    case 'apBankAccount':
      return strings.AP_BANK_ACCOUNT
    case 'arBankAccount':
      return strings.AR_BANK_ACCOUNT
    case 'nfe':
      return strings.NFE
    case 'fuelAdvance':
      return strings.FUEL_ADVANCE
    case 'liability':
      return strings.LIABILITY
    case 'unapplied':
      return strings.UNAPPLIED
    default:
      return ''
  }
}

export function formatLedgerType(ledgerType: string) {
  switch (ledgerType) {
    case 'AR':
      return strings.ACCOUNTS_RECEIVABLE
    case 'FEE':
      return strings.FEE
    case 'ACCRUED_FEE':
      return strings.ACCRUED_FEE
    case 'ESCROW':
      return strings.ESCROW_RESERVE
    case 'CASH_RESERVE':
      return strings.CASH_RESERVE
    case 'ACCRUED_INTEREST':
      return strings.ACCRUED_INTEREST
    case 'AP_BANK_ACCOUNT':
      return strings.AP_BANK_ACCOUNT
    case 'AR_BANK_ACCOUNT':
      return strings.AR_BANK_ACCOUNT
    case 'LIABILITY':
      return strings.LIABILITY
    default:
      return ''
  }
}

export function formatSpecialSorting(sort: string) {
  switch (sort) {
    case 'clientName':
      return 'companyClientName'
    case 'debtorName':
      return 'companyDebtorName'
    default:
      return sort
  }
}

export function generatePaymentMethodOptions(filterValues?: string[]): DropDownOptions[] {
  const allOptions: DropDownOptions[] = [
    { value: '', label: '-' },
    { value: 'ACH', label: 'ACH' },
    { value: 'CHECK', label: 'Check' },
    { value: 'FUEL_CARD', label: 'Fuel Card' },
    { value: 'SAME_DAY_ACH', label: 'Same Day ACH' },
    { value: 'WIRE', label: 'Wire' },
    { value: 'MONEY_CODE', label: 'Money Code' },
  ]

  if (filterValues && filterValues.length > 0) {
    return allOptions.filter((option) => !filterValues.includes(option.value))
  }
  return allOptions
}

export function addPaymentOption(paymentOptionsArray: DropDownOptions[], newOptionValue: string) {
  if (newOptionValue === 'ACH') return [...paymentOptionsArray, { value: 'ACH', label: 'ACH' }]
  if (newOptionValue === 'CHECK') return [...paymentOptionsArray, { value: 'CHECK', label: 'Check' }]
  if (newOptionValue === 'FUEL_CARD') return [...paymentOptionsArray, { value: 'FUEL_CARD', label: 'Fuel Card' }]
  if (newOptionValue === 'SAME_DAY_ACH')
    return [...paymentOptionsArray, { value: 'SAME_DAY_ACH', label: 'Same Day ACH' }]
  if (newOptionValue === 'WIRE') return [...paymentOptionsArray, { value: 'WIRE', label: 'Wire' }]
  if (newOptionValue === 'MONEY_CODE') return [...paymentOptionsArray, { value: 'MONEY_CODE', label: 'Money Code' }]

  return paymentOptionsArray
}

export const formattedCompanySortedValue = (value: string) => {
  if (value === 'companyName') return 'baseCompany.name'
  return `baseCompany.${value}`
}

export const toCamelCase = (str: string) => {
  if (isDefined(str)) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  return ''
}

export function handleAccruedInterestValue(companySettings?: CompanyClientAdminSettingsResponse) {
  const value =
    companySettings?.changeClientAccruedInterest !== null ? companySettings?.changeClientAccruedInterest : null
  return value === null ? '-' : value ? strings.YES : strings.NO
}

export function handleFrecuencyOfCollectionValue(companySettings?: CompanyClientAdminSettingsResponse) {
  const value =
    companySettings?.clientFrequencyOfCollectionOfAccruedFees !== null
      ? companySettings?.clientFrequencyOfCollectionOfAccruedFees
      : null

  return value === null ? '-' : value
}

export function validateIfClientSettingIsNull(companySetting?: CompanyClientAdminSettingsResponse) {
  const clientFrecuency = companySetting?.clientFrequencyOfCollectionOfAccruedFees === null
  const clientAccruedInterest = companySetting?.changeClientAccruedInterest === null

  return clientFrecuency || clientAccruedInterest
}

export function handleAccruedInterestBrokerValue(brokerSettings?: BrokerInfo) {
  const value = brokerSettings?.autochargeInterestFee !== null ? brokerSettings?.autochargeInterestFee : null
  return value === null ? '-' : value ? strings.YES : strings.NO
}

export function handleFrecuencyOfCollectionBrokerValue(brokerSettings?: BrokerInfo) {
  const value =
    brokerSettings?.frequencyOfAutochargeInterestFee !== null ? brokerSettings?.frequencyOfAutochargeInterestFee : null

  return value === null ? '-' : value
}

export function validateIfClientSettingBrokerIsNull(brokerSettings?: BrokerInfo) {
  const clientFrecuency = brokerSettings?.frequencyOfAutochargeInterestFee === null
  const clientAccruedInterest = brokerSettings?.autochargeInterestFee === null

  return clientFrecuency || clientAccruedInterest
}

export const formatOverAdvanceType = (overAdvanceType?: string | null) => {
  if (overAdvanceType === null) {
    return '-'
  }
  if (overAdvanceType === 'CASH_RESERVE') return strings.CASH_RESERVE
  if (overAdvanceType === 'BROKER_PAYMENT') return strings.BROKER_PAYMENT
}

export const chipColorFormat = (invoiceGroupType: string) => {
  if (invoiceGroupType === 'INVOICE') return 'info'
  if (invoiceGroupType === 'FUEL_ADVANCE') return 'error'
  if (invoiceGroupType === 'BROKER') return 'warning'
  if (invoiceGroupType === 'VENDOR') return 'success'
  if (invoiceGroupType === 'RESERVE_RELEASE') return 'primary'
  return 'default'
}

export const companyTypeChipColorFormat = (type: string) => {
  if (type === 'BROKER') return 'error'
  if (type === 'CLIENT') return 'info'
  if (type === 'DEBTOR') return 'success'
  return 'default'
}

export const chipLabelFormat = (invoiceGroupType: string) => {
  if (invoiceGroupType === 'INVOICE') return strings.INVOICE
  if (invoiceGroupType === 'FUEL_ADVANCE') return strings.FUEL_ADVANCE
  if (invoiceGroupType === 'BROKER') return strings.BROKER
  if (invoiceGroupType === 'VENDOR') return strings.VENDOR
  if (invoiceGroupType === 'RESERVE_RELEASE') return strings.RESERVE_RELEASE
  return ''
}

export const invoicePaymentTypeFormat = (invoicePaymentType: string) => {
  switch (invoicePaymentType) {
    case 'FULL':
      return strings.FULL
    case 'PARTIAL':
      return strings.SHORT
    case 'OVER':
      return strings.OVER
    case 'CLOSED_PAID':
      return strings.CLOSED_PAID
    case 'PARTIAL_FULL':
      return strings.SHORT_FULL
    default:
      return '-' //! This should never happen
  }
}

export const getColumOrderByLedgerType = (ledgerType: string) => {
  switch (ledgerType) {
    case 'accounts-receivable':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'fee':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, fuelAdvanceReferenceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'accrued-fee':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'accrued-interest':
      return 'createdOn, companyClientName, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'escrow-reserve':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'cash-reserve':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'ap-bank-account':
      return 'createdOn, companyClientName, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'ar-bank-account':
      return 'createdOn, companyClientName, paymentTransactionRef, description, account, increaseAmount, decreaseAmount, glCode, notes'
    case 'liability':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'unapplied':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, invoiceGroupCode, paymentTransactionRef, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'fuel-advance':
      return 'createdOn, companyClientName, invoiceDate, invoiceNumber, fuelAdvanceReferenceNumber, invoiceGroupCode, description, increaseAmount, decreaseAmount, glCode, notes'
    case 'manual-transaction':
      return 'createdOn, clientName, ledgerType, offsettingLedgerId, offsettingLedgerType, amount, amountType, notes, authorName, glCode, active'
    default:
      return ''
  }
}

export const orderLedgerColumns = (columns: string[], ledgerType: string): string[] => {
  const ledgerOrder = getColumOrderByLedgerType(ledgerType)
  if (ledgerOrder === '') return columns

  const orderedColumns = ledgerOrder.split(',').map((column) => column.trim())
  const orderedColumnsArray: string[] = []

  for (const column of orderedColumns) {
    const foundColumn = columns.find((c) => c.includes(column))
    if (foundColumn) {
      orderedColumnsArray.push(foundColumn)
    }
  }
  return orderedColumnsArray
}

export const ucfirst = (str: string) => {
  const lowerStr = str.toLowerCase()
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1)
}

export const formatLedgerNameKey = (keyId: string): string => {
  switch (keyId) {
    case 'openArKey':
      return strings.OPEN_AR_KEY
    case 'feesKey':
      return strings.FEES_KEY
    case 'accruedFeesKey':
      return strings.ACCRUED_FEES_KEY
    case 'accruedInterestKey':
      return strings.ACCRUED_INTEREST_KEY
    case 'escrowReserveKey':
      return strings.ESCROW_RESERVE_KEY
    case 'cashReserveKey':
      return strings.CASH_RESERVE_KEY
    case 'apBankAccountKey':
      return strings.AP_BANK_ACCOUNT_KEY
    case 'arBankAccountKey':
      return strings.AR_BANK_ACCOUNT_KEY
    case 'liabilityKey':
      return strings.LIABILITY_KEY
    case 'unappliedKey':
      return strings.UNNAPLIED_KEY
    case 'fuelAdvanceKey':
      return strings.FUEL_ADVANCE_KEY
    default:
      return keyId
  }
}

export const formattedCreditRequestSortedValue = (value: string) => {
  const atributeNeedsUserPrefix = ['userEmail', 'username']

  if (atributeNeedsUserPrefix.includes(value)) {
    if (value === 'userEmail') return 'user.email'
    if (value === 'username') return 'user.firstName'
  }

  return value
}

export const formattedDebtorRequestSortedValue = (value: string) => {
  const attributeNeedsUserPrefix = ['userEmail', 'username', 'debtorName', 'country', 'state', 'zip', 'debtorEmail']

  if (attributeNeedsUserPrefix.includes(value)) {
    if (value === 'userEmail') return 'user.email'
    if (value === 'username') return 'user.firstName'
    if (value === 'debtorName') return 'companyName'
    if (value === 'country') return 'countryCode'
    if (value === 'state') return 'stateCode'
    if (value === 'zip') return 'zipCode'
    if (value === 'debtorEmail') return 'email'
  }

  return value
}

export const formattedInternalCreditRequestSortedValue = (value: string) => {
  const attributeNeedsUserPrefix = ['companyName', 'type', 'requestedByUsername', 'updatedByUsername']

  if (attributeNeedsUserPrefix.includes(value)) {
    if (value === 'companyName') return 'cachedCompanyName'
    if (value === 'type') return 'type'
    if (value === 'requestedByUsername') return 'requestedBy.firstName'
    if (value === 'updatedByUsername') return 'updatedBy.firstName'
  }

  return value
}

export const isBaseVerificationMethod = (methodName: string): boolean => {
  return methodName === 'EMAIL' || methodName === 'MAIL' || methodName === 'ONLINE PORTAL' || methodName === 'PHONE'
}

export const formatOutOfSystemInvoices = (outOfSystemInvoices: OutOfSystemInvoicesInfo[]) => {
  const transformedInvoices: InvoiceSummaryInfo[] = []
  const outInvoicesIds: string[] = []

  outOfSystemInvoices.forEach((invoice) => {
    transformedInvoices.push({
      id: invoice.id,
      invoiceNumber: invoice.invoiceNumber,
      referenceNumber: invoice.referenceNumber,
      companyClientId: invoice.companyClientId,
      companyClientName: invoice.companyClientName,
      companyDebtorId: invoice.companyDebtorId,
      companyDebtorName: invoice.companyDebtorName,
      termId: 'default-term-id',
      termName: 'default-term-name',
      status: 'PENDING',
      invoiceAmount: invoice.invoiceAmount,
      invoiceBalance: invoice.invoiceAmount,
      invoiceDate: invoice.invoiceDate.toString(),
      closedDate: invoice.invoiceDate.toString(),
      callBackDate: invoice.invoiceDate.toString(),
      willPayDate: invoice.invoiceDate.toString(),
      notes: '',
      documents: [],
      verificationStatus: 'NOT_VERIFIED',
      changelog: [],
      ineligible: false,
      disputed: false,
      createdOn: new Date().toISOString(),
      purchaseFeeAmount: 0,
      escrowReserveAmount: 0,
      cashReserveAmount: 0,
      advanceAmount: 0,
      daysAged: 0,
      daysOutstanding: 0,
      paidDate: '',
      paymentStatus: 'Open',
      fundedDate: '',
      paymentTransactionNumber: [],
      cashEscrowAmount: 0,
      fuelAdvanceId: '',
      fuelAdvanceReferenceNumber: '',
      fuelAdvanceAmount: 0,
      forcedTermId: '',
      forcedTermName: '',
      companyBrokerId: invoice.companyBrokerId,
      companyBrokerName: invoice.companyBrokerName,
      invoiceType: 'CLIENT',
      vendorPaymentAmount: 0,
      vendorInvoiceId: invoice.companyVendorId,
      vendorAdvanceAmount: 0,
      quickPayFeeAmount: 0,
      quickPayFeeId: '',
      vendorPaymentDate: '',
      vendorFundingDate: '',
      companyVendorName: invoice.companyVendorName,
      companyVendorId: '',
      overAdvanceType: '',
      overAdvanceAmount: 0,
      snapshotTermPurchaseRate: 0,
      snapshotTermEscrowReserveRate: 0,
      nonFactoredInvoice: 'OOS',
      verificationMethod: '',
      isOutOfSystemInvoice: true,
    })
    outInvoicesIds.push(invoice.id)
  })

  return { transformedInvoices, outInvoicesIds }
}

export const formatEmailHistoryType = (type: string) => {
  switch (type) {
    case 'NOA':
      return strings.NOA
    case 'CLIENT_REMITTANCE':
      return strings.CLIENT_REMITTANCE
    case 'VENDOR_REMITTANCE':
      return strings.VENDOR_REMITTANCE
    case 'BROKER_REMITTANCE':
      return strings.BROKER_REMITTANCE
    case 'STATEMENT':
      return strings.STATEMENT
    case 'BILLING':
      return strings.BILLING
    case 'UPDATE_RELATIONSHIP_REQUEST':
      return strings.UPDATE_RELATIONSHIP_REQUEST
    case 'UPDATE_DEBTOR_REQUEST':
      return strings.UPDATE_DEBTOR_REQUEST
    default:
      return ''
  }
}

export function localDateTimeFormatWithDifferenceToday(date: string | null) {
  function calculateDaysDifference(date1: Date, date2: Date) {
    const oneDay = 24 * 60 * 60 * 1000
    const diffInTime = date2.getTime() - date1.getTime()
    return Math.round(diffInTime / oneDay)
  }

  if (date === null) {
    return null
  }

  const formattedDate = dateTimeFormat({
    date: date,
    formatOptions: {
      dateStyle: 'short',
      timeStyle: 'short',
    },
  })

  const inputDate = new Date(date)
  const currentDate = new Date()

  const differenceInDays = calculateDaysDifference(inputDate, currentDate)

  return {
    formattedDate: formattedDate,
    differenceInDays: differenceInDays,
  }
}

export const formatEmailHistoryStatus = (status: string) => {
  switch (status) {
    case 'PENDING':
      return strings.PENDING.SINGLE
    case 'SENT':
      return strings.SENT
    case 'FAILED':
      return strings.FAILED
    default:
      return ''
  }
}

export const formatEmailHistorySendType = (sendType: string) => {
  switch (sendType) {
    case 'RETRY':
      return strings.RETRY
    case 'SYSTEM':
      return strings.SYSTEM
    default:
      return ''
  }
}

export const formatEmployeeType = (employeeType: string) => {
  switch (employeeType) {
    case CompanyEmployeeEnum.ACCOUNT_EXECUTIVE:
      return strings.ACCOUNT_EXECUTIVE
    case CompanyEmployeeEnum.COLLECTION_AGENT:
      return strings.COLLECTION_AGENT
    case CompanyEmployeeEnum.SALES_EXECUTIVE:
      return strings.SALES_EXECUTIVE
    default:
      return ''
  }
}

type accountArray = {
  data: accountInfo[]
}

type accountInfo = {
  id: string
  accountName: string
}

export const paymentAccountsToArray = (paymentAccounts: String) => {
  const paymentAccountsArray: accountArray = {
    data: [],
  }
  if (isDefined(paymentAccounts) && paymentAccounts.length > 0) {
    paymentAccounts.split(',').forEach((account) => {
      paymentAccountsArray.data.push({
        id: uuid(),
        accountName: account,
      })
    })
  }
  return paymentAccountsArray
}

export const fileSizeConverter = (sizeInBytes: number): string => {
  if (sizeInBytes < 0) {
    throw new Error('Size cannot be negative')
  }

  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  let convertedSize = sizeInBytes

  if (sizeInBytes < 1024) {
    return `${convertedSize.toFixed(2)} ${units[unitIndex]}`
  }

  while (convertedSize >= 1024 && unitIndex < units.length - 1) {
    convertedSize /= 1024
    unitIndex++
  }

  if (unitIndex === 2 && convertedSize < 0.01) {
    unitIndex = 1
    convertedSize = sizeInBytes / 1024
  }

  return `${convertedSize.toFixed(2)} ${units[unitIndex]}`
}

export const formatLabelEntityType = (entityType: string): string => {
  switch (entityType) {
    case 'INVOICE':
      return strings.INVOICE
    case 'COMPANY':
      return strings.COMPANY
    case 'PAYMENT':
      return strings.PAYMENT
    case 'TERM':
      return strings.TERM
    default:
      return entityType
  }
}

export const formatLabelCategory = (category: string): string => {
  switch (category) {
    case 'GENERAL':
      return strings.CATEGORY_GENERAL
    case 'INVOICE_COLLECTIONS':
      return strings.CATEGORY_INVOICE_COLLECTIONS
    case 'INVOICE_CHARGEBACK':
      return strings.CATEGORY_INVOICE_CHARGEBACK
    case 'INVOICE_PAYMENT':
      return strings.CATEGORY_INVOICE_PAYMENT
    default:
      return category
  }
}

export const formatUnitTime = (unitTime?: string) => {
  switch (unitTime) {
    case 'DAYS':
      return strings.DAYS
    case 'WEEKS':
      return strings.WEEKS
    case 'MONTHS':
      return strings.MONTHS
    default:
      return '-'
  }
}

export function getDraftPaymentStatusChipColor(status: DraftPaymentStatus) {
  switch (status) {
    case 'PENDING':
      return 'warning'
    case 'PROCESSING':
      return 'primary'
    case 'ERROR':
      return 'error'
    case 'FINISHED':
      return 'success'
    default:
      return 'default'
  }
}

export function isEmptyHtml(htmlContent: string | undefined): boolean {
  if (!htmlContent) return true

  const tempElement = document.createElement('div')
  tempElement.innerHTML = htmlContent

  const cleanedContent = tempElement.textContent || tempElement.innerText || ''

  return cleanedContent.trim() === ''
}

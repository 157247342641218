import { TypoGraph, Container } from 'components/ui'
import { Button } from 'components/form'
import { ButtonProps } from 'components/form/Button'
import styles from './HeaderBar.module.scss'

type HeaderBarProps = {
  /** The title of the header. If no title is provided, it will display two spaces.*/
  title?: string
  /** The content prop allows to add any JSX content in the header bar. It overrides buttons.*/
  content?: JSX.Element
  contentSx?: object
  /** The primaryBtnProps is an object containing the properties to be passed to the primary button. If no object is provided, the primary button will not be displayed.*/
  primaryBtnProps?: ButtonProps
  /** The secondaryBtnProps is an object containing the properties to be passed to the secondary button. If no object is provided, the secondary button will not be displayed.*/
  secondaryBtnProps?: ButtonProps
  /** The tertiaryBtnProps is an object containing the properties to be passed to the tertiary button. If no object is provided, the tertiary button will not be displayed.*/
  tertiaryBtnProps?: ButtonProps
  quaternaryBtnProps?: ButtonProps
}

const HeaderBar = (props: HeaderBarProps) => {
  const {
    title,
    primaryBtnProps,
    secondaryBtnProps,
    tertiaryBtnProps,
    content,
    contentSx = {},
    quaternaryBtnProps,
  } = props

  let containerContent = <></>
  if (content) {
    containerContent = content
  } else {
    containerContent = (
      <>
        <>
          {tertiaryBtnProps && <Button variant="outlined" {...tertiaryBtnProps} />}
          {secondaryBtnProps && <Button variant="outlined" {...secondaryBtnProps} />}
          {quaternaryBtnProps && <Button variant="outlined" {...quaternaryBtnProps} />}
        </>
        {primaryBtnProps && <Button variant="contained" {...primaryBtnProps} />}
      </>
    )
  }

  return (
    <Container className={styles.headerContainer}>
      <Container>
        <TypoGraph variant="h2" mb={0} sx={{ lineHeight: '36px' }}>
          {title ? title : '  '}
        </TypoGraph>
      </Container>
      <Container className={styles.headerAction} sx={contentSx}>
        {containerContent}
      </Container>
    </Container>
  )
}

export default HeaderBar

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DropdownFormField, TextFormField } from 'components/hookForm'
import { Blade, Container, Toast as toast } from 'components/ui'
import strings from 'l10n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getValueForDropdownWithEmptyOption,
  isDefined,
  minValueLimit,
  parseBooleanValueFromForm,
  parseNumericValueFromForm,
} from 'tools/common'
import {
  DefaultUnitSystem,
  FactorDebtorDefaultInfo,
  FactorDebtorDefaultRequest,
  FactorDefaultRequest,
} from 'types/factorDefault'
import styles from '../SystemDefaultsSectionsUpdateBlade.module.scss'
import { Button } from 'components/form'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import { updateFactorDefaultValues } from 'api/factorDefault'

type DebtorSystemDefaultUpdateBladeProps = {
  title: string
  debtorDefaultValues?: FactorDebtorDefaultInfo
  handleClose: () => void
  showBlade: boolean
}

type DebtorSystemDefaultUpdateBladeForm = {
  creditLimit: number
  creditLimitApproved: string
  creditExpirationQuantity: number | string
  creditExpirationUnitTime: string
}

export const DebtorSystemDefaultUpdateBlade = (props: DebtorSystemDefaultUpdateBladeProps) => {
  const { title, debtorDefaultValues, handleClose, showBlade } = props
  const form = useForm<DebtorSystemDefaultUpdateBladeForm>()
  const { handleSubmit, setValue } = form
  const queryClient = useQueryClient()

  const { mutate: updateDebtorDefaultsValues, isLoading: isUpdateDebtorDefaultsValuesLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        subTitle: strings.DEBTOR_DEFAULTS_VALUES_UPDATED,
      })
      setInitialValues(data?.debtorDefaultValues)
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      handleClose()
    },
  })

  const setInitialValues = (values: FactorDebtorDefaultInfo) => {
    setValue('creditLimit', values.debtorCreditLimit)
    setValue('creditLimitApproved', getValueForDropdownWithEmptyOption(values.debtorCreditLimitApproved))
    setValue('creditExpirationQuantity', values.debtorCreditExpirationQuantity)
    setValue('creditExpirationUnitTime', values.debtorCreditExpirationUnitTime)
  }

  const onSubmit = (data: DebtorSystemDefaultUpdateBladeForm) => {
    const payload: FactorDefaultRequest = {}
    const debtorDefaultValues: FactorDebtorDefaultRequest = {
      debtorCreditLimit: parseNumericValueFromForm(data.creditLimit),
      debtorCreditLimitApproved: parseBooleanValueFromForm(data.creditLimitApproved),
      debtorCreditExpirationQuantity: parseNumericValueFromForm(data.creditExpirationQuantity),
      debtorCreditExpirationUnitTime: isDefined(parseNumericValueFromForm(data.creditExpirationQuantity))
        ? (data.creditExpirationUnitTime as DefaultUnitSystem)
        : null,
    }
    payload.debtorDefaultValues = debtorDefaultValues
    updateDebtorDefaultsValues(payload)
  }

  const availableUnitField = () => {
    const creditExpirationQuantity = form.watch('creditExpirationQuantity')
    return isDefined(creditExpirationQuantity) && creditExpirationQuantity !== ''
  }

  const bladeContent = (
    <FormProvider {...form}>
      <Container>
        <TextFormField
          name="creditLimit"
          label={strings.CREDIT_LIMIT}
          type="number"
          startAdornment="$"
          initialValue={debtorDefaultValues?.debtorCreditLimit}
          min={minValueLimit(0)}
          disabled={isUpdateDebtorDefaultsValuesLoading}
        />
        <DropdownFormField
          className={styles.dropDownContainer}
          name="creditLimitApproved"
          label={strings.CREDIT_LIMIT_APPROVED}
          options={[
            { label: '-', value: '' },
            { label: strings.YES, value: '1' },
            { label: strings.NO, value: '0' },
          ]}
          disabled={isUpdateDebtorDefaultsValuesLoading}
          initialValue={getValueForDropdownWithEmptyOption(debtorDefaultValues?.debtorCreditLimitApproved)}
        />
        <Container className={styles.twoRowFormat}>
          <TextFormField
            name="creditExpirationQuantity"
            label={strings.CREDIT_EXPIRATION}
            type="number"
            initialValue={debtorDefaultValues?.debtorCreditExpirationQuantity}
            min={minValueLimit(1)}
            disabled={isUpdateDebtorDefaultsValuesLoading}
          />
          <DropdownFormField
            className={styles.dropDownContainer}
            name="creditExpirationUnitTime"
            label={strings.UNIT_TIME}
            options={[
              { label: strings.DAYS, value: 'DAYS' },
              { label: strings.WEEKS, value: 'WEEKS' },
              { label: strings.MONTHS, value: 'MONTHS' },
            ]}
            disabled={isUpdateDebtorDefaultsValuesLoading || !availableUnitField()}
            initialValue={debtorDefaultValues?.debtorCreditExpirationUnitTime}
            required={availableUnitField()}
          />
        </Container>
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button
        variant="outlined"
        label={strings.CANCEL}
        disabled={isUpdateDebtorDefaultsValuesLoading}
        onClick={handleClose}
      />
      <Button
        id="btnCreateBladeCreateCompany"
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isUpdateDebtorDefaultsValuesLoading}
        loading={isUpdateDebtorDefaultsValuesLoading}
      />
    </Container>
  )

  return (
    <Blade
      variant="persistent"
      open={showBlade}
      title={title}
      onClose={handleClose}
      loading={isUpdateDebtorDefaultsValuesLoading}
      bodyNode={bladeContent}
      footerNode={bladeFooter}
      hideBackdrop
    />
  )
}

import LoaderPage from 'components/ui/feedback/LoaderPage'
import React, { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const ReserveReleases = lazy(() => import('pages/reserveRelease/page'))
const ReserveReleasePreviewPage = lazy(() => import('pages/reserveRelease/[reserveReleaseId]/page'))

const ReserveReleaseRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<ReserveReleases />} />} />
      <Route
        path="/:referenceNumber/:reserveReleaseId"
        element={<LoaderPage content={<ReserveReleasePreviewPage />} />}
      />
    </Routes>
  )
}

export default ReserveReleaseRoutes

import strings from 'l10n'
import generalRegex from './regex'

export const integerPattern = {
  value: generalRegex.INTEGER_NUMBERS_ONLY,
  message: strings.PLEASE_ENTER_A_INTEGER_NUMBER,
}

export const numericOnly = {
  value: generalRegex.ONLY_NUMBER_AND_DECIMALS,
  message: strings.PLEASE_ENTER_VALID_NUMBER,
}

import { ReactNode, useCallback, useReducer } from 'react'
import { userSessionReducer } from './userSessionReducer'
import { UserSessionContext, UserSessionContextState } from './UserSessionContext'
import { getMyUser } from 'api/users'
import { getMyRole } from 'api/roles'

const defaultState: UserSessionContextState = {}

export function UserSessionProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(userSessionReducer, defaultState)

  const token = state.token
  const userInfo = state.userInfo

  const updateUserInfo = useCallback(async () => {
    if (!token) return
    const userInfo = await getMyUser()
    dispatch({
      type: 'setUserInfo',
      userInfo,
    })
  }, [token, dispatch])

  const updatePermissions = useCallback(async () => {
    if (!userInfo) return
    const response = await getMyRole()
    dispatch({
      type: 'setPermissions',
      permissions: response.role.permissions,
    })
  }, [userInfo, dispatch])

  const setToken = useCallback(
    (token: string) => {
      dispatch({
        type: 'setToken',
        token,
      })
    },
    [dispatch]
  )

  const setFirstToken = useCallback(
    (token: string) => {
      dispatch({
        type: 'setFirstToken',
        token,
      })
    },
    [dispatch]
  )

  const loadToken = useCallback(() => {
    dispatch({ type: 'loadToken' })
  }, [dispatch])

  const loadFirstToken = useCallback(() => {
    dispatch({ type: 'loadFirstToken' })
  }, [dispatch])

  const clearSession = useCallback(() => {
    dispatch({ type: 'clearSession' })
  }, [dispatch])

  return (
    <UserSessionContext.Provider
      value={{
        state,
        updateUserInfo,
        updatePermissions,
        setToken,
        loadToken,
        clearSession,
        setFirstToken,
        loadFirstToken,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  )
}

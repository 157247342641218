import { Alert, CircularProgress, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { Toast, TypoGraph, Toast as toast, Container, Icon } from 'components/ui'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './DocumentUpload.module.scss'
import { useQueryClient } from '@tanstack/react-query'
import { DocumentInfo } from 'types/document'
import strings from 'l10n'
import { uploadFiles } from 'tools/upload'
import Button from './Button'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export type EntityWithDocuments = {
  fromCreateBlade?: boolean
  id: string
  documents?: DocumentInfo[]
  companyId?: string
  brokerId?: string
  type?: 'invoice' | 'payment' | 'fuel_advance' | 'out_of_system_invoice'
  isSingleFile?: boolean
}

export type DocumentUploadProps = {
  entity: EntityWithDocuments
  successMessage: string
  singleFiles?: boolean
  title?: string
  typesAllowed?: string[]
  messageError?: string
  displayUploadDocButton?: boolean
  callback?: () => void
  isSingleFile?: boolean
  acceptFiles?: Accept
}

export type DocumentUploadRef = {
  upload: (entityProps: EntityWithDocuments) => void
}

const DocumentUpload = forwardRef<DocumentUploadRef, DocumentUploadProps>((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      upload: (entityProps) => uploadHandler(entityProps),
    }
  })

  const {
    entity,
    successMessage,
    singleFiles,
    title,
    typesAllowed,
    messageError,
    displayUploadDocButton = true,
    acceptFiles,
  } = props

  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const [filesStatus, setFilesStatus] = useState(files.map(() => false))

  useEffect(() => {
    if (files.length > 0) {
      setFilesStatus(files.map(() => false))
    }
  }, [files])

  const handleFileStatus = (file: File, status: boolean) => {
    const fileIndex = files.findIndex((f) => f.name === file.name)
    setFilesStatus((prevStatus) => {
      const newStatus = [...prevStatus]
      newStatus[fileIndex] = status
      return newStatus
    })
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      document.getElementById('btn-document-upload')?.focus()

      if (typesAllowed) {
        const allowedFileTypes = typesAllowed || []
        const invalidFiles = acceptedFiles.filter((file) => !allowedFileTypes.includes(file.type))
        if (invalidFiles.length > 0) {
          toast({
            type: 'error',
            subTitle: messageError,
          })
          return
        }
      }
      if (singleFiles) {
        if (files.length === 1 && acceptedFiles.length === 1) {
          if (files[0].name === acceptedFiles[0].name) {
            return
          }
        }
        setFiles(acceptedFiles)
      } else {
        setFiles((prev) => {
          return prev.concat(acceptedFiles)
        })
      }
    },
    [files, singleFiles, typesAllowed, messageError]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFiles,
  })

  const removeFileHandler = (idx: number) => {
    setFiles((prev) => {
      const newFiles = [...prev]
      newFiles.splice(idx, 1)
      return newFiles
    })
  }

  const uploadHandler = async (entityProps?: EntityWithDocuments) => {
    setLoading(true)
    let result
    if (entity.fromCreateBlade === true && entityProps) {
      result = await uploadFiles(files, entityProps, {}, handleFileStatus)
    } else {
      result = await uploadFiles(files, entity, {}, handleFileStatus)
    }
    if (result === true && files.length > 0) {
      queryClient.invalidateQueries([entity.type, entity.id])
      setFiles([])
      if (props.callback) {
        props.callback()
      }
      Toast({
        subTitle: successMessage,
      })
    } else {
      if (files.length > 0) {
        Toast({
          type: 'error',
          subTitle: result,
        })
      }
    }
    setLoading(false)
    if (props.callback) {
      props.callback()
    }
  }

  return (
    <>
      <TypoGraph variant="h2" mb={1}>
        {title ?? ''}
      </TypoGraph>
      {loading ? (
        <Alert severity="warning" sx={{ marginTop: 2 }}>
          {strings.UPLOAD_WARNING}
        </Alert>
      ) : (
        <div {...getRootProps()} className={styles.dropZoneContainer}>
          <Container className={styles.dropzoneLayoutContainer}>
            <input {...getInputProps()} />
            <Icon name="icon_upload" size={35} />
            <div className={styles.dropzone}>{isDragActive ? strings.DROP_HERE : strings.DRAG_AND_DROP}</div>
            {!isDragActive && (
              <>
                <div className={styles.dropzoneCaption}>{strings.ALL_TYPE_ALLOWDED}</div>
                <Container className={styles.dropzoneBtnContainer}>
                  <Button key="btn-document-upload" variant="outlined" label={strings.UPLOAD_FILES} loading={loading} />
                </Container>
              </>
            )}
          </Container>
        </div>
      )}

      <List>
        {files.map((file, idx) => (
          <ListItem
            key={idx}
            secondaryAction={
              <IconButton edge="end" onClick={() => removeFileHandler(idx)} disabled={loading}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <div style={{ display: 'flex' }}>
              <ListItemText primary={file.name} />
              {loading && (
                <>
                  {filesStatus[idx] === false ? (
                    <CircularProgress className={styles.circularProgress} size={25} />
                  ) : (
                    <div className={styles.CheckCircle}>
                      <CheckCircleIcon htmlColor="#3CA164"></CheckCircleIcon>
                    </div>
                  )}
                </>
              )}
            </div>
          </ListItem>
        ))}
      </List>

      {displayUploadDocButton && files.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <Button
            key="btn-document-upload"
            label={strings.SUBMIT_FILES}
            onClick={uploadHandler}
            disabled={loading || files.length === 0}
            loading={loading}
          />
        </div>
      )}
      <Alert severity="info" sx={{ marginTop: 2 }}>
        {strings.INFO_DUPLICATED_DOCS}
      </Alert>
    </>
  )
})

export default DocumentUpload

import { InputAdornment, InputBaseProps, Typography } from '@mui/material'
import { TextField } from 'components/form'
import { TextFieldProps } from 'components/form/TextField'
import { useState } from 'react'
import { useFormContext, Controller, ValidationRule } from 'react-hook-form'
import styles from './TextFormField.module.scss'
import { ift, isDefined } from 'tools/common'
import strings from 'l10n'

type TextFormFieldProps = TextFieldProps & {
  /** The name indicating the name of the field. This is necessary for internal form handling and should be unique within the form.*/
  name: string
  /** The initialValue variable sets the initial value of the field when the form is first loaded. If not provided, it will be set to null.*/
  initialValue?: string | number | null
  /** The required variable indicates whether the field is required or not. If set to true, a validation rule will be added requiring a value to be entered before submitting the form.*/
  required?: boolean
  /** The maxLength variable sets the maximum length allowed for the field. If provided as an object, it should contain value (the integer representing the maximum length) and message (the error message displayed if the maximum length is exceeded) properties.*/
  maxLength?: number | { value: number; message: string }
  /** The minLength variable sets the minimum length allowed for the field. If provided as an object, it should contain value (the integer representing the minimum length) and message (the error message displayed if the minimum length is not reached) properties.*/
  minLength?: number | { value: number; message: string }
  /** The pattern defines a regular expression that the field value must match. This is used to validate input according to a specific pattern.*/
  pattern?: ValidationRule<RegExp> | undefined
  max?: number | { value: number | string; message: string }
  min?: number | { value: number | string; message: string }
  rows?: number
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  avoidOnKeyDown?: boolean

  inputProps?: InputBaseProps['inputProps']

  onKeyDownType?: 'number' | 'text' | 'numberAndText' | 'numberTextSpecial' | 'numberDash'

  requiredMessage?: string

  charCount?: { enabled: boolean; maxLength: number }
}

const TextFormField = (props: TextFormFieldProps) => {
  const {
    name,
    initialValue = null,
    required = false,
    requiredMessage = 'Field is Required',
    maxLength,
    minLength,
    pattern,
    disabled,
    max,
    min,
    rows,
    color,
    avoidOnKeyDown = false,
    onKeyDownType,
    inputProps,
    charCount,
  } = props
  const { control } = useFormContext()
  const isRequired = required ? requiredMessage : undefined
  const [characterCount, setCharacterCount] = useState(0)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue}
      rules={{
        required: isRequired,
        validate: {},
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
        min: min,
        max: max,
      }}
      render={({ field: { onChange, onBlur, value = '', ref }, fieldState: { error } }) => {
        if (charCount) {
          if (value !== null && value !== undefined) {
            setCharacterCount(value.length)
            if (value.length > charCount.maxLength) {
              error = {
                type: 'maxLength',
                message: `${strings.formatString(strings.CHARCOUNT_MAX_LENGTH_MESSAGE, {
                  value: charCount.maxLength,
                })}`,
              }
            }
          }
        }
        return (
          <>
            <TextField
              value={value !== null && value !== undefined ? value : ''}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              error={Boolean(error)}
              helperText={error?.message}
              inputProps={inputProps}
              disabled={disabled}
              rows={rows}
              multiline={rows !== undefined}
              color={color}
              sx={ift(
                {
                  '& .MuiInputBase-root': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row-reverse',
                    gap: '25px',
                  },
                },
                isDefined(rows)
              )}
              avoidOnKeyDown={avoidOnKeyDown}
              onKeyDownType={onKeyDownType}
              endAdornment={
                charCount &&
                charCount?.enabled && (
                  <InputAdornment position="end">
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={ift(styles.endAdornment2, isDefined(rows))}
                    >
                      {characterCount > charCount.maxLength ? (
                        <span style={{ color: 'red' }}>
                          {characterCount}/{charCount.maxLength}
                        </span>
                      ) : (
                        characterCount + '/' + charCount.maxLength
                      )}
                    </Typography>
                  </InputAdornment>
                )
              }
              {...props}
            />
          </>
        )
      }}
    />
  )
}

export default TextFormField

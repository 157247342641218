import { Route, RouteProps, Routes } from 'react-router-dom'
import { lazy } from 'react'
import LoaderPage from 'components/ui/feedback/LoaderPage'
import SystemDefaultsPage from 'pages/settings/systemDefaults/page'
const Settings = lazy(() => import('pages/settings/page'))
const AdminSettingsPage = lazy(() => import('pages/settings/adminSettings/page'))
const ProfilePage = lazy(() => import('pages/settings/profile/page'))

const SettingsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<Settings />} />} />
      <Route path="/adminSettings" element={<LoaderPage content={<AdminSettingsPage />} />} />
      <Route path="/profile" element={<LoaderPage content={<ProfilePage />} />} />
      <Route path="/systemDefaults" element={<LoaderPage content={<SystemDefaultsPage />} />} />
    </Routes>
  )
}

export default SettingsRoutes

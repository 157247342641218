import CircularProgress from '@mui/material/CircularProgress'
import { Container, Card } from 'components/ui'
import colors from 'theme/colors'

type LoaderProps = {
  /** The type determines whether the loader is displayed within a container or as a standalone component. Defaults to 'container'.*/
  type?: 'container' | 'default'
  /** The loading determines whether the loader should be displayed. If true, the loader is displayed; if false, nothing is displayed.*/
  loading?: boolean
  size?: number
  thickness?: number
}

const Loader = (props: LoaderProps) => {
  const { type = 'container', loading, size, thickness } = props
  const CircularLoader = () => {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '1rem',
        }}
      >
        <CircularProgress
          size={size || 70}
          thickness={thickness}
          sx={{
            color: colors.primary,
          }}
        />
      </Container>
    )
  }

  if (!loading) {
    return <></>
  }

  return type === 'container' ? (
    <Card
      sxContainer={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 2,
      }}
    >
      <CircularLoader />
    </Card>
  ) : (
    <CircularLoader />
  )
}

export default Loader

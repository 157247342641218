import { Route, Routes, RouteProps, Navigate } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import UsersRoutes from './UsersRoutes'
import FactorRoutes from './factorManagementRoute'
import CompaniesRoutes from './CompaniesRoutes'
import TermsRoutes from './TermsRoutes'
import InvoicesRoutes from './InvoicesRoutes'
import FundingRoutes from './FundingRoutes'
import { NotFoundPage } from 'components/ui'
import PaymentsRoutes from './PaymentsRoutes'
import LedgersRoutes from './LedgersRoutes'
import ChargebacksRoutes from './ChargebackRoutes'
import DashboardRoutes from './DashboardRoutes'
import AccountingRoutes from './AccountingRoutes'
import SettingsRoutes from './SettingsRoutes'
import GmailCallback from 'app/SettingsManagement/GmailManagement/GmailCallback'
import CollectionsRoutes from './CollectionsRoutes'
import ReportsRoutes from './ReportsRoutes'
import BbIntegrationRoutes from './BbIntegrationRoutes'
import { GLOBAL_CONFIG_SHOW_DASHBHOARD } from 'constants/globalConfig'
import FuelAdvancesRoutes from './FuelAdvancesRoutes'
import useAdminSettings from 'hooks/useAdminSettings'
import TankCallback from 'app/SettingsManagement/AdminManagement/DetailsSection/TankCallback'
import CreditRequestRoutes from './CreditRequestRoutes'
import OutOfSystemInvoicesRoutes from './OutOfSystemInvoicesRoutes'
import ReserveReleaseRoutes from './ReserveRealeasesRoutes'
import usePermissions from "../hooks/usePermissions";

export const DEFAULT_PATH = GLOBAL_CONFIG_SHOW_DASHBHOARD ? '/dashboard' : '/invoices'

type ApplicationRouteProps = RouteProps & {
  isAuth: boolean
}

const ApplicationRoute = (props: ApplicationRouteProps) => {
  const { isAuth } = props
  const { enableBrightboltIntegration } = useAdminSettings()
  const {canAccessDocuments} = usePermissions()

  return (
    <Routes>
      <Route
        path="/settings/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <SettingsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            {GLOBAL_CONFIG_SHOW_DASHBHOARD ? <DashboardRoutes /> : <NotFoundPage />}
          </ProtectedRoute>
        }
      />
      <Route
        path="/users/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <UsersRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/companies/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <CompaniesRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/terms/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <TermsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/invoices/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <InvoicesRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/fuel-advances/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <FuelAdvancesRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/funding/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <FundingRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payments/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <PaymentsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <ChargebacksRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ledgers/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <LedgersRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/factors"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <FactorRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounting/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <AccountingRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/collections/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <CollectionsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/credit-request/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <CreditRequestRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reports/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <ReportsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reserve-releases/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <ReserveReleaseRoutes />
          </ProtectedRoute>
        }
      />
      {enableBrightboltIntegration && canAccessDocuments && (
        <Route
          path="/documents/*"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <BbIntegrationRoutes />
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path="/out-of-system-invoices/*"
        element={
          <ProtectedRoute isAuth={isAuth}>
            <OutOfSystemInvoicesRoutes />
          </ProtectedRoute>
        }
      />

      <Route path="/auth/tank/callback" element={<TankCallback />} />
      <Route path="auth/*" element={<GmailCallback />} />

      <Route path="/" element={<Navigate to={DEFAULT_PATH} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default ApplicationRoute

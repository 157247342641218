import LocalizedStrings from 'react-localization'
import { commonEn, commonEs } from './common'
import { dashboardEn, dashboardEs } from './dashboard'
import { companiesEn, companiesEs } from './companies'
import { loginEn, loginEs } from './login'
import { profileEn, profileEs } from './profile'
import { usersEn, usersEs } from './users'
import { factorsEn, factorsEs } from './factors'
import { termsEn, termsEs } from './terms'
import { invoicesEn, invoicesEs } from './invoices'
import { disbursementsEn, disbursementsEs } from './disbursements'
import { invoiceGroupsEn, invoiceGroupsEs } from './invoiceGroups'
import { paymentsEn, paymentsEs } from './payments'
import { ledgersEn, ledgersEs } from './ledgers'
import { fundingInstructionsEn, fundingInstructionsEs } from './fundingInstructions'
import { documentsEn, documentsEs } from './document'
import { findDuplicates } from 'tools/l10nTools'
import { chargebackEn, chargebackEs } from './chargeback'
import { accountingEn, accountingEs } from './accounting'
import { gmailEn, gmailEs } from './gmail'
import { collectionsEn, collectionsEs } from './collections'
import { creditRequestEn, creditRequestEs } from './creditRequest'
import { reportsEn, reportsEs } from './reports'
import { fuelAdvancesEn, fuelAdvancesEs } from './fuelAdvances'
import { bbIntegrationEn, bbIntegrationEs } from './bbIntegration'
import { quickbooksEn, quickbooksEs } from './quickbooks'
import { quickPayFeeEn, quickPayFeeEs } from './quickPayFee'
import { ledgerNameEn, ledgerNameEs } from './ledgerName'
import { autochargeEn, autochargeEs } from './autocharge'
import { verificationMethodEn, verificationMethodEs } from './verificationMethod'
import { outOfSystemInvoiceEn, outOfSystemInvoiceEs } from './outOfSystemInvoice'
import { emailHistoryEn, emailHistoryEs } from './emailHistory'
import { reserveReleaseEn, reserveReleaseEs } from './reserveRelease'
import { labelsEn, labelsEs } from './labels'

const arrayOfFiles = [
  commonEn,
  loginEn,
  profileEn,
  dashboardEn,
  usersEn,
  companiesEn,
  factorsEn,
  termsEn,
  invoicesEn,
  disbursementsEn,
  invoiceGroupsEn,
  paymentsEn,
  ledgersEn,
  fundingInstructionsEn,
  documentsEn,
  chargebackEn,
  accountingEn,
  gmailEn,
  collectionsEn,
  creditRequestEn,
  reportsEn,
  fuelAdvancesEn,
  bbIntegrationEn,
  quickbooksEn,
  quickPayFeeEn,
  ledgerNameEn,
  autochargeEn,
  verificationMethodEn,
  outOfSystemInvoiceEn,
  emailHistoryEn,
  reserveReleaseEn,
  labelsEn,
]

let stringsObject = {
  en: {
    ...commonEn,
    ...loginEn,
    ...profileEn,
    ...dashboardEn,
    ...usersEn,
    ...companiesEn,
    ...factorsEn,
    ...termsEn,
    ...invoicesEn,
    ...disbursementsEn,
    ...invoiceGroupsEn,
    ...paymentsEn,
    ...ledgersEn,
    ...fundingInstructionsEn,
    ...documentsEn,
    ...chargebackEn,
    ...accountingEn,
    ...gmailEn,
    ...collectionsEn,
    ...creditRequestEn,
    ...reportsEn,
    ...fuelAdvancesEn,
    ...bbIntegrationEn,
    ...quickbooksEn,
    ...quickPayFeeEn,
    ...ledgerNameEn,
    ...autochargeEn,
    ...verificationMethodEn,
    ...outOfSystemInvoiceEn,
    ...emailHistoryEn,
    ...reserveReleaseEn,
    ...labelsEn,
  },
  es: {
    ...commonEs,
    ...loginEs,
    ...profileEs,
    ...dashboardEs,
    ...usersEs,
    ...companiesEs,
    ...factorsEs,
    ...termsEs,
    ...invoicesEs,
    ...disbursementsEs,
    ...invoiceGroupsEs,
    ...paymentsEs,
    ...ledgersEs,
    ...fundingInstructionsEs,
    ...documentsEs,
    ...chargebackEs,
    ...accountingEs,
    ...gmailEs,
    ...collectionsEs,
    ...creditRequestEs,
    ...reportsEs,
    ...fuelAdvancesEs,
    ...bbIntegrationEs,
    ...quickbooksEs,
    ...quickPayFeeEs,
    ...ledgerNameEs,
    ...autochargeEs,
    ...verificationMethodEs,
    ...outOfSystemInvoiceEs,
    ...emailHistoryEs,
    ...reserveReleaseEs,
    ...labelsEs,
  },
}

let strings = new LocalizedStrings(stringsObject)

if (process.env.NODE_ENV === 'development') findDuplicates(arrayOfFiles)

export default strings

import { ReactNode } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button, { ButtonProps } from '../../form/Button'
import { Container, TypoGraph } from 'components/ui'
import styles from './Blade.module.scss'
import { CircularProgress } from '@mui/material'
import { isDefined } from 'tools/common'

const sizes = {
  sm: 600,
  md: 892,
  lg: 1168,
}

type BladeProps = {
  /** The position specifies the position of the blade on the screen.*/
  position?: 'top' | 'bottom' | 'left' | 'right'
  /** The open determines whether the blade is open or closed.*/
  open: boolean
  /** The onClose is a function that is called when the blade is closed.*/
  onClose: () => void
  /** The hideBackdrop determines whether the backdrop behind the blade is hidden or not.*/
  hideBackdrop?: boolean
  /** The sx is an object containing custom styles to be applied to the blade.*/
  sx?: object
  /** The variant specifies the type of blade to be displayed.*/
  variant?: 'permanent' | 'persistent' | 'temporary'
  /** The className is a string containing additional CSS classes to be applied to the blade.*/
  className?: string
  /** The containerClassName is a string containing additional CSS classes to be applied to the container of the blade.*/
  containerClassName?: string
  /** The size is used to specify the width of the Blade component. It can be set to one of three string values: "sm", "md", or "lg", which correspond to pre-defined widths of 600px, 892px, and 1168px respectively. */
  size?: 'sm' | 'md' | 'lg' | number
  /** The bodyNode is a React node to be displayed in the body of the blade.*/
  bodyNode?: ReactNode
  /** The headerNode is a React node to be displayed in the header of the blade.*/
  headerNode?: ReactNode
  /** The footerNode is a React node to be displayed in the footer of the blade. */
  footerNode?: ReactNode
  /** The footerBtns is an object containing the properties of the primary and secondary buttons in the footer of the blade.*/
  footerBtns?: {
    primary: ButtonProps
    secondary?: ButtonProps
  }
  /** The footerInfo variable contains the additional information to be displayed in the footer of the sheet.*/
  footerInfo?: string
  /** The title is used to specify the title of the Blade component. It is displayed in the header section of the Blade along with a close button.*/
  title?: string
  /** The loading determines whether a loading spinner is displayed in the header of the blade. */
  loading?: boolean
  bodyLoading?: boolean
  bodyClass?: string
  hasBladeInfo?: boolean
  bladeInfoBody?: ReactNode
  bladeInfoTitle?: string
  bladeInfoReducedSize?: boolean
  hasBladeInfoContainerStyles?: string
  bodyContainerAdditionalInfoStyles?: string
  /** The main title is a title that will be used to englobe the main section and the bodyInfo  */
  mainTitle?: string
  /** The mainFooter is a React node to be displayed in the footer of the main section */
  mainFooter?: ReactNode
  bladeInfoWidth?: number | string
  classWrapper?: string
}

type DrawerHeaderProps = Pick<BladeProps, 'title' | 'loading' | 'onClose' | 'mainTitle'>
const DrawerHeader = (props: DrawerHeaderProps) => {
  const haveMainTitle = isDefined(props.mainTitle)
  const headerContainerStyles = props.title ? styles.headerContainer : ''
  return (
    <Container className={headerContainerStyles}>
      <Container className={styles.headerContent}>
        {props.title && (
          <TypoGraph variant="h2" mb={0} className={styles.title} sx={{ paddingBottom: haveMainTitle && '10px' }}>
            {props.title || 'Title'}
            &nbsp;
            {props.loading && <CircularProgress size={20} />}
          </TypoGraph>
        )}
        {!haveMainTitle && (
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Container>
    </Container>
  )
}

type DrawerBodyProps = Pick<BladeProps, 'bodyNode' | 'bodyLoading' | 'bodyClass'>
const DrawerBody = (props: DrawerBodyProps) => {
  return (
    <Container className={`${styles.bodyContainer} ${props.bodyClass} ${styles.removePadding}`}>
      {props.bodyLoading ? (
        <CircularProgress
          size={60}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <Container className={styles.bodyContent}>{props.bodyNode}</Container>
      )}
    </Container>
  )
}

type DrawerFooterProps = Pick<BladeProps, 'footerInfo' | 'footerBtns'>
const DrawerFooter = (props: DrawerFooterProps) => {
  const { primary, secondary } = props.footerBtns || {}
  return (
    <Container className={styles.footerContainer}>
      <Container className={styles.footerContent}>
        <p>{props.footerInfo}</p>
        <Container className={styles.footerBtn}>
          {secondary && <Button {...secondary} />}
          {primary && <Button {...primary} />}
        </Container>
      </Container>
    </Container>
  )
}

const Blade = (props: BladeProps) => {
  const {
    position = 'right',
    open = false,
    hideBackdrop = false,
    variant = 'temporary',
    onClose,
    sx = {},
    className,
    size = 'sm',
    title,
    bodyNode,
    headerNode,
    footerNode,
    containerClassName,
    footerBtns = {
      primary: { label: 'Primary', variant: 'contained' },
      secondary: { label: 'Secondary', variant: 'outlined' },
    },
    footerInfo,
    loading = false,
    bodyLoading = false,
    bodyClass,
    hasBladeInfo = false,
    bladeInfoBody,
    bladeInfoTitle,
    mainTitle,
    bodyContainerAdditionalInfoStyles = styles.bodyContainerAdditionalInfo,
    hasBladeInfoContainerStyles = styles.hasBladeInfoContainer,
    mainFooter,
    bladeInfoWidth,
    classWrapper,
    bladeInfoReducedSize,
  } = props

  let width = 600

  if (typeof size === 'string') {
    width = sizes[size]
  }

  if (typeof size === 'number') {
    width = size
  }

  const originalFooter = footerNode ?? <DrawerFooter footerInfo={footerInfo} footerBtns={footerBtns} />

  let originalDrawer = (
    <Container className={`${className} ${classWrapper ?? styles.wrapper}`} sx={{ width: width }}>
      {headerNode ?? <DrawerHeader title={title} loading={loading} onClose={onClose} mainTitle={mainTitle} />}
      <DrawerBody bodyNode={bodyNode} bodyLoading={bodyLoading} bodyClass={bodyClass} />
      {!isDefined(mainFooter) && originalFooter}
    </Container>
  )

  let bladeAdditionalInformation = (
    <div
      className={`${hasBladeInfoContainerStyles} ${bladeInfoReducedSize ? styles.smallAdditionalBlade : ''}`}
      style={{ width: bladeInfoWidth }}
    >
      {bladeInfoTitle && (
        <Container className={styles.headerContainerInfo}>
          <TypoGraph variant="h2" mb={0} className={styles.title}>
            {bladeInfoTitle}
            &nbsp;
            {loading && <CircularProgress size={20} />}
          </TypoGraph>
        </Container>
      )}
      <Container
        className={`${
          bladeInfoReducedSize ? styles.bodyContainerAdditionalInfoSmall : bodyContainerAdditionalInfoStyles
        } ${bodyClass}`}
      >
        {bodyLoading ? (
          <CircularProgress
            size={60}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <Container className={styles.bodyContent}>{bladeInfoBody}</Container>
        )}
      </Container>
    </div>
  )

  return (
    <MuiDrawer
      anchor={position}
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      sx={sx}
      variant={variant}
      className={`${containerClassName} ${styles.containerShadow}`}
    >
      {mainTitle && (
        <Container className={styles.mainTitleContainer}>
          <TypoGraph variant="h2" className={styles.mainTitle}>
            {mainTitle}
          </TypoGraph>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Container>
      )}
      {['top', 'bottom'].includes(position) ? (
        <Container sx={{ maxHeight: '50vh', minHeight: '100px' }}>
          <DrawerBody bodyNode={bodyNode} />
        </Container>
      ) : hasBladeInfo ? (
        <Container className={`${className} ${styles.wrapper}`} sx={{ display: 'flex' }}>
          {bladeAdditionalInformation}
          {originalDrawer}
        </Container>
      ) : (
        originalDrawer
      )}

      {mainFooter && mainFooter}
    </MuiDrawer>
  )
}

export default Blade

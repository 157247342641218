export const collectionsEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  COLLECTIONS: 'Collections',
  COLLECTION: 'Collection',
  HIDE_COLLECTION_UPDATE_TODAY: 'Hide Collection Updated Today',
  UPDATE_INVOICE_COLLECTION_SUCCESS: 'Invoice Collection Updated Successfully',
  INELIGIBLE: 'Ineligible',
  DISPUTED: 'Disputed',
  // END Fabian
  DAYS_AGED: 'Days Aged',
  DAYS_OUTSTANDING: 'Days Outstanding',
  OVER_30_DAYS: 'Over 30 Days',

}

export const collectionsEs: typeof collectionsEn = {
  // BEGIN Michael
  // END Michael
  // BEGIN Fco
  // END Fco
  // BEGIN Fabian
  COLLECTIONS: 'Cobranzas',
  COLLECTION: 'Cobranza',
  HIDE_COLLECTION_UPDATE_TODAY: 'Ocultar Cobranzas Actualizadas Hoy',
  UPDATE_INVOICE_COLLECTION_SUCCESS: 'Cobranza Actualizada Correctamente',
  INELIGIBLE: 'Inelegible',
  DISPUTED: 'Disputado',
  // END Fabian
  DAYS_AGED: 'Días de Antigüedad',
  DAYS_OUTSTANDING: 'Días Pendientes',
  OVER_30_DAYS: 'Sobre 30 Días',
}

import { FormEvent, useState } from 'react'
import { Container, Toast as toast } from 'components/ui'
import { TextField, Button } from 'components/form'
import { ObjectStrings } from 'interfaces'
import strings from 'l10n'
import regex from 'tools/regex'
import { resetPassword } from 'api/login'
import { useMutation } from '@tanstack/react-query'
import { ResetPasswordRequest } from 'types/login'

type ForgotPasswordFormProps = {
  styles: ObjectStrings
  factorId?: string
  handleStep: (val: number) => void
}

const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { styles, handleStep } = props
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string>()

  const { mutate: resetPasswordMutation, isLoading } = useMutation({
    mutationFn: (data: ResetPasswordRequest) => {
      return resetPassword(data.email, data.factorId)
    },
    onSuccess: () => {
      toast({
        type: 'success',
        title: `${strings.FORGOT_PASSWORD_EMAIL}`,
        subTitle: `${strings.formatString(strings.FORGOT_PASSWORD_SUB, {
          email: email,
        })}`,
      })
      handleStep(strings.SHOW_LOGIN_FORM)
    },
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!regex.EMAIL_REGEX_PATTERN.test(email)) {
      setError(`${strings.EMAIL_ERROR}`)
    } else {
      setError('')
      const payload: ResetPasswordRequest = {
        email: email,
        factorId: props.factorId,
      }
      resetPasswordMutation(payload)
    }
  }

  return (
    <form onSubmit={onSubmit} id="forgotPasswordForm">
      <Container className={styles.formContainer}>
        <TextField
          id="emailAddressForgotPasswordForm"
          label={strings.EMAIL_ADDRESS}
          type="text"
          onChange={(e: React.ChangeEvent<any>) => setEmail(e.target.value)}
          helperText={error}
          error={Boolean(error)}
        />
        <Container className={styles.buttonContainer}>
          <Button
            id="recoverPasswordButton"
            type="submit"
            loading={isLoading}
            disabled={email === ''}
            variant="contained"
            label={strings.RECOVER_PASSWORD}
          />
        </Container>
      </Container>
    </form>
  )
}

export default ForgotPasswordForm

import request from 'tools/request'
import { PAGINATION_LIMIT_VALUE_NUM, PAGINATION_NUM_VALUE_NUM } from 'constants/headers'
import { getPaginationHeaders } from 'tools/headers'
import {
  PaymentListParams,
  PaymentsListResponse,
  PaymentCreateRequest,
  PaymentResponse,
  AssignPaymentInvoiceResponse,
  AssignPaymentInvoiceRequest,
  PaymentUpdateRequest,
  PaymentUpdateCsvResponse,
  TotalPaymentListParams,
  TotalPaymentListResponse,
  PaymentInvoicesResponse,
  UndoPaymentRequest,
  ExportPaymentsParamsRequest,
  CreatePaymentBatchRequest,
  PaymentLabelCollectionResponse, PaymentInvoiceImportedDataResponse
} from 'types/payments'
import {AssociateInvoiceLabelResponse, LabelAssignRequest,} from "../types/labels";
import {BaseResponse} from "../types/common";

export const listAllPayments = async (
  params: PaymentListParams,
  pageNumber: number = PAGINATION_NUM_VALUE_NUM,
  pageLimit: number = PAGINATION_LIMIT_VALUE_NUM
) => {
  const data = await request
    .get<PaymentsListResponse>('payments', { params: params, headers: getPaginationHeaders(pageNumber, pageLimit) })
    .then((res) => {
      return { data: res.data.payments, headers: res.headers }
    })
  return data
}

export const createPayment = async (payload: PaymentCreateRequest) => {
  const data = request.post<PaymentResponse>('/payments', payload).then((res) => {
    return res.data
  })
  return data
}

export const createPaymentBatch = async (payload: CreatePaymentBatchRequest) => {
  const data = request.post<PaymentsListResponse>('/payments/payment-batch', payload).then((res) => {
    return res.data
  })
  return data
}

export const getPayment = async (id: string) => {
  const data = await request.get<PaymentResponse>(`payments/${id}`).then((res) => {
    return res.data
  })
  return data
}

export const assignPaymentToInvoice = (paymentId: string, payload: AssignPaymentInvoiceRequest | null) => {
  const data = request.patch<AssignPaymentInvoiceResponse>(`payments/${paymentId}/invoices`, payload).then((res) => {
    return res.data
  })
  return data
}

export const updatePayment = (paymentId: string, payload: PaymentUpdateRequest) => {
  const data = request.put<PaymentResponse>(`payments/${paymentId}`, payload).then((res) => {
    return res.data
  })
  return data
}

export const uploadCsv = async (inputFile: File[]) => {
  const formData = new FormData()
  formData.append('inputFile', inputFile[0])
  const data = await request.post<PaymentUpdateCsvResponse>('payments/csv-upload', formData).then((res) => {
    return res.data
  })
  return data
}

export const totalPaymentList = async (params: TotalPaymentListParams) => {
  const data = await request
    .get<TotalPaymentListResponse>('payments/total-payments', { params: params })
    .then((res) => {
      return { data: res.data.totalPaymentInfo }
    })
  return data
}

export const getPaymentInvoices = (paymentId: string, params: PaymentListParams) => {
  const data = request
    .get<PaymentInvoicesResponse>(`payments/${paymentId}/invoices`, {
      params: params,
    })
    .then((res) => res.data.invoices)
  return data
}

export const undoPayment = (payload: UndoPaymentRequest) => {
  const data = request.put<PaymentsListResponse>(`payments/undo-payments`, payload).then((res) => {
    return res.data
  })
  return data
}

export const exportPayments = async (params: ExportPaymentsParamsRequest) => {
  const data = await request.get<string>('payments/export', { params: params }).then((res) => {
    return res.data
  })
  return data
}

export const getPaymentLabels = async (paymentId: string) => {
    const data = await request.get<PaymentLabelCollectionResponse>('payments/' + paymentId + '/labels').then((res) => {
        return res.data
    })
    return data
}


export const associatePaymentLabel = async (paymentId: string, payload: LabelAssignRequest) => {
  const data = await request.patch<AssociateInvoiceLabelResponse>(`payments/${paymentId}/labels`, payload).then((res) => {
    return res.data
  })
  return data
}

export const removeLabelFromPayment = async (paymentId: string, labelId: string) => {
  const data = await request
      .delete<BaseResponse>(`payments/${paymentId}/labels/${labelId}`)
      .then((r) => r.data)
  return data
}

export const getImportedInvoiceData = async (paymentId: string) => {
  const data = await request
    .get<PaymentInvoiceImportedDataResponse>(`payments/${paymentId}/imported-invoices`)
    .then((res) => {
      return res.data
    })
  return data
}

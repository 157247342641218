import { FormEvent, useState } from 'react'
import { Container, TypoGraph, Toast as toast } from 'components/ui'
import { TextField, Button } from 'components/form'
import { ObjectStrings } from 'interfaces'
import strings from 'l10n'
import colors from 'theme/colors'
import { login } from 'api/login'
import { useMutation } from '@tanstack/react-query'
import { LoginRequest } from 'types/login'
import { saveFirstToken } from 'context/UserSession/userSessionReducer'
import useUserSession from 'hooks/useUserSession'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_PATH } from 'routes'

type LoginProps = {
  styles: ObjectStrings
  factorId: string
  handleStep: (val: number) => void
  handleFormValue: (data: { email: string; password: string }) => void
}

const LoginForm = (props: LoginProps) => {
  const { styles, handleStep, handleFormValue, factorId } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // const [checkedRemember, setCheckedRemember] = useState(false)
  const { _setLoginToken } = useUserSession()
  const navigate = useNavigate()

  const { mutate: loginMutation, isLoading } = useMutation({
    mutationFn: (data: LoginRequest) => {
      return login(data, { factorId: factorId })
    },
    onSuccess: (resp) => {
      if (resp.skip2fa) {
        _setLoginToken(resp.token)
        handleStep(strings.SHOW_LOGIN_FORM)
        toast({ title: strings.LOGGED_SUCCESSFULLY, type: 'success' })
        navigate(DEFAULT_PATH)
      } else {
        saveFirstToken(resp.token)
        handleFormValue({ email: email, password: password })
        handleStep(strings.SHOW_MULTI_FACTOR_FORM)
      }
    },
  })

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const payload: LoginRequest = {
      username: email,
      password: password,
    }
    loginMutation(payload)
  }

  return (
    <form onSubmit={onSubmit} id="loginForm">
      <Container className={styles.formContainer}>
        <TextField
          id="emailAddressLoginForm"
          label={strings.EMAIL_ADDRESS}
          type="text"
          onChange={(e: React.ChangeEvent<any>) => setEmail(e.target.value)}
        />
        <TextField
          id="passwordLoginForm"
          label={strings.PASSWORD}
          onChange={(e: React.ChangeEvent<any>) => setPassword(e.target.value)}
          showPasswordIcon
        />
        <Container className={styles.contentLinkContainer}>
          <div></div>
          {/*<CheckBoxField
            label="Remember me"
            checked={checkedRemember}
            labelVariant="subtitle1"
            onChange={() => setCheckedRemember(!checkedRemember)}
          />*/}
          <TypoGraph
            id="forgotPasswordLink"
            variant="body1"
            content={strings.FORGOT_PASSWORD}
            onClick={() => handleStep(strings.SHOW_FORGOT_PASS_FORM)}
            color={colors.primary}
            align="right"
            link
          />
        </Container>
        <Container className={styles.buttonContainer}>
          <Button
            id="loginButton"
            type="submit"
            loading={isLoading}
            disabled={password === '' || email === '' || isLoading}
            variant="contained"
            label={strings.LOGIN}
          />
        </Container>
      </Container>
    </form>
  )
}

export default LoginForm

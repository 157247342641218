import strings from 'l10n'
import { DropDownOptions } from 'types/common'
const quickbooksEnabled = process.env.REACT_APP_FEATURE_QUICKBOOKS?.toLowerCase() === 'true'

export const currencyDropDownOptions: DropDownOptions[] = [
  { label: strings.US_DOLLAR, value: 'USD' },
  { label: strings.CANADIAN_DOLLAR, value: 'CAD' },
  { label: strings.EURO, value: 'EUR' },
  { label: strings.BRITISH_POUND, value: 'GBP' },
  { label: strings.CHINESE_YUAN, value: 'CNY' },
  { label: strings.AUSTRALIAN_DOLLAR, value: 'AU' },
]

export const documentTypeDropDownOptions: DropDownOptions[] = [
  { label: 'Invoice', value: 'INVOICE' },
  { label: 'Payment', value: 'PAYMENT' },
  { label: 'Disbursement', value: 'DISBURSEMENT' },
  { label: 'Notice of Assignment', value: 'NOA' },
  { label: 'Proof of Insurance', value: 'POI' },
  { label: 'Uniform Commercial Code', value: 'UCC' },
  { label: 'Contract', value: 'CONTRACT' },
  { label: 'Tax Doc', value: 'TAX_DOC' },
  { label: 'Other', value: 'OTHER' },
]

export const documentTypeDropDownOptionsForDebtor: DropDownOptions[] = [
  { label: 'Payment', value: 'PAYMENT' },
  { label: 'Tax Doc', value: 'TAX_DOC' },
  { label: 'Other', value: 'OTHER' },
]

export const bankAccountTypeDropDownOptions: DropDownOptions[] = [
  { value: '', label: '-' },
  { value: 'CHECKING', label: 'Checking' },
  { value: 'SAVINGS', label: 'Savings' },
]

export const verificationInvoiceDropDownOptions: DropDownOptions[] = [
  { label: '-', value: '' },
  { label: strings.VERIFIED, value: 'VERIFIED' },
  { label: strings.DENIED, value: 'DENIED' },
  { label: strings.PENDING.SINGLE, value: 'PENDING' },
]

export const typePaymentTypeDropDownOptions: DropDownOptions[] = [
  { label: strings.ACH, value: 'ACH' },
  { label: strings.CHECK, value: 'CHECK' },
  { label: strings.WIRE, value: 'WIRE' },
]

export const perDaysDropDownOptions: DropDownOptions[] = [
  { label: '360', value: 'DAYS_360' },
  { label: '365', value: 'DAYS_365' },
]

export const typeDaysDropDownOptions: DropDownOptions[] = [
  { label: strings.CALENDAR_DAYS, value: 'CALENDAR_DAYS' },
  { label: strings.BUSINESS_DAYS, value: 'BUSINESS_DAYS' },
  { label: strings.MONDAY_FRIDAY, value: 'MONDAY_FRIDAY' },
]

export const verifyDropDownOptions: DropDownOptions[] = [
  { label: strings.VERIFY_STATUS_DENIED, value: 'DENIED' },
  { label: strings.VERIFY_STATUS_VERIFIED, value: 'VERIFIED' },
  { label: strings.VERIFY_STATUS_NOT_VERIFIED, value: 'NOT_VERIFIED' },
  { label: strings.VERIFY_STATUS_PENDING, value: 'PENDING' },
]

export const debitOrCreditDropdownOptions: DropDownOptions[] = [
  { value: 'INCREASE', label: 'Increase' },
  { value: 'DECREASE', label: 'Decrease' },
]

export const ledgersTypeDropdownOptions: DropDownOptions[] = [
  { value: 'AR', label: strings.ACCOUNTS_RECEIVABLE },
  { value: 'FEE', label: strings.FEE },
  { value: 'ACCRUED_FEE', label: strings.ACCRUED_FEE },
  { value: 'ESCROW', label: strings.ESCROW_RESERVE },
  { value: 'CASH_RESERVE', label: strings.CASH_RESERVE },
  { value: 'ACCRUED_INTEREST', label: strings.ACCRUED_INTEREST },
  { value: 'AP_BANK_ACCOUNT', label: strings.AP_BANK_ACCOUNT },
  { value: 'AR_BANK_ACCOUNT', label: strings.AR_BANK_ACCOUNT },
  { value: 'LIABILITY', label: strings.LIABILITY },
]

export const billingDeliveryDropdownOptions: DropDownOptions[] = [
  { value: 'EMAIL', label: 'Email' },
  { value: 'MAIL', label: 'Mail' },
  { value: 'PORTAL', label: 'Portal' },
]

export const statementDeliveryDropdownOptions: DropDownOptions[] = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
]

export const invoiceGroupTypeDropdownOptions: DropDownOptions[] = [
  { value: 'INVOICE', label: strings.INVOICE },
  { value: 'FUEL_ADVANCE', label: strings.FUEL_ADVANCE },
  { value: 'RESERVE_RELEASE', label: strings.RESERVE_RELEASE },
]

export const paginationLimitOptions: DropDownOptions[] = [
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
]

export const importCsvFileOptions: DropDownOptions[] = [
  { label: 'Clients', value: 'CLIENTS' },
  { label: 'Debtors', value: 'DEBTORS' },
  { label: 'Brokers', value: 'BROKERS' },
  { label: 'Vendors', value: 'VENDORS' },
]

export const accountingDocumentOptions: () => DropDownOptions[] = () => {
  const array = [
    { label: strings.IFF_EXPORT, value: 'IFF_EXPORT' },
    { label: strings.ACCOUNTING_EXPORT, value: 'ACCOUNTING_EXPORT' },
    { label: strings.ACCOUNTING_DETAILS, value: 'ACCOUNTING_DETAILS' },
  ]
  if (quickbooksEnabled) {
    array.push(
      { label: strings.QUICKBOOKS_ENTRY_AUDIT, value: 'QUICKBOOKS_ENTRY_AUDIT' },
      { label: strings.PUSH_TO_QUICKBOOKS, value: 'PUSH_TO_QUICKBOOKS' }
    )
  }
  return array
}

export const labelEntityTypeOptions: DropDownOptions[] = [
  { label: strings.ENTITY_TYPE_INVOICE, value: 'INVOICE' },
  { label: strings.ENTITY_TYPE_PAYMENT, value: 'PAYMENT' },
  /*   { label: strings.ENTITY_TYPE_COMPANY, value: 'COMPANY' },
  { label: strings.ENTITY_TYPE_TERM, value: 'TERM' }, */
]

export const labelCategoryOptions: DropDownOptions[] = [{ label: strings.CATEGORY_GENERAL, value: 'GENERAL' }]

export const invoiceLabelCategoryOptions: DropDownOptions[] = [
  { label: strings.CATEGORY_INVOICE_COLLECTIONS, value: 'INVOICE_COLLECTIONS' },
  { label: strings.CATEGORY_INVOICE_CHARGEBACK, value: 'INVOICE_CHARGEBACK' },
  { label: strings.CATEGORY_INVOICE_PAYMENT, value: 'INVOICE_PAYMENT' },
]

export const paymentLabelCategoryOptions: DropDownOptions[] = []

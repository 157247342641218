export const commonEn = {
  // BEGIN Michael
  CREATE: 'Create',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DENY: 'Deny',
  VIEW: 'View',
  CANCEL: 'Cancel',
  SELECT: 'Select',
  PROCEED: 'Proceed',
  ASSIGN: 'Assign',

  CLEAR_SELECTION: 'Clear Selection',
  STORY_BOOK: 'View Document',
  DEMO_TABLE_COMPONENT: 'Demo Table Component',
  PRIMARY_ACTION: 'Primary Action',
  SECONDARY_ACTION: 'Secondary Action',
  CONTROLLER_LIST: 'List of Controllers',
  INPUT_CONTROLLER: 'Input Field Controller',
  SINGLE_SELECT_CONTROLLER: 'Single Select Controller',
  CHECKBOX_CONTROLLER: 'Checkbox Controller',
  COMPONENTS: 'Components',
  CHILD: 'Child',
  PARENT: 'Parent',
  NO_RECORDS: 'No records in the table',
  RECORDS_ON_THIS_PAGE_ARE_SELECTED: 'records on this page are selected',
  RECORDS_ARE_SELECTED: 'records are selected',
  RECORDS_ACROSS_ALL_PAGES: 'records across all pages',
  NO_RESULTS: 'No Results Found!',
  ADJUST_SEARCH: "Try adjusting your search or filter to find what you're looking for.",
  YES: 'Yes',
  NO: 'No',
  NONE: 'None',
  NEW: 'New',
  APPLY: 'Apply',
  FILTER: 'Filter',
  DEFAULT: 'Default',
  ACTIONS: 'Actions',
  PREVIEW: 'Preview',
  ADD: 'Add',
  REMOVE: 'Remove',
  AVAILABLE: 'Available',
  REQUESTED: 'Requested',
  DENIED: 'Denied',
  APPROVED: 'Approved',
  ROLE: 'Role',
  ROLES: 'Roles',
  ROLES_AND_PERMISSION: 'Roles & Permissions',
  ROLE_DETAILS: 'Role Details',
  PERMISSIONS_OVERVIEW: 'Permissions Overview',
  PERMISSION_DETAILS: 'Permission Details',
  REPORTS_CATEGORY: 'Reports Category',
  CREATE_ROLE: 'Create Role',
  CREATE_ROLE_ASSIGN_PERMISSIONS: 'Create Role and Assign Permissions',
  SELECT_SECTION_PERMISSION: 'Please select section and its permissions',
  UPDATE_ROLE: 'Update Role',
  ROLE_NAME: 'Role Name',
  YOU_DONT_HAVE_PERMISSION: 'You do not have permission to access this resource.',
  STATUS: {
    LABEL: 'Status',

    ALL: {
      TYPE_1: 'All',
      TYPE_2: 'All',
      TYPE_3: 'ALL',
      TYPE_4: 'All',
    },

    ACTIVE: {
      PLURAL: 'Active',
      SINGLE: 'Active',

      PLURAL_TYPE_2: 'Active',
      SINGLE_TYPE_2: 'Active',
    },

    INACTIVE: {
      PLURAL: 'Inactive',
      SINGLE: 'Inactive',

      PLURAL_TYPE_2: 'Inactive',
      SINGLE_TYPE_2: 'Inactive',
    },

    ACTIVATE: 'Activate',
    DESACTIVATE: 'Deactivate',
  },

  CHANGELOG: 'Changelog',

  AMOUNT: 'Amount',
  CODE: 'Code',
  DATE_TIME: 'Date & Time',
  DOWNLOAD: 'Download',
  TOTAL_AMOUNT: 'Total Amount',
  SELECTED_AMOUNT: 'Selected Amount',

  ALL_OBJECTS: '{state} {object}',
  ACTIVE_OBJECTS: '{state} {object} ',
  INACTIVE_OBJECTS: '{state} {object} ',

  SESSION_HAS_TIMED_OUT: 'Session has timed out',
  PLEASE_RE_ENTER_YOUR_CREDENTIALS: 'Please re-enter your credentials to login again.',

  STRONG_PASSWORD: 'Password must contain',

  ERROR_BOUNDARY_HEADER: 'We ran into a problem.',
  ERROR_BOUNDARY_BODY: 'Try to refresh your browser or use your browser back button to go back to the previous page.',
  ERROR_CHUNK_LOAD_HEADER: 'Application Update',
  ERROR_CHUNK_LOAD_BODY: 'This application has been updated, please refresh your browser to see the latest content.',

  PAGE_404_TITLE: '404',
  PAGE_404_SUBHEADER: 'This page cannot be found',
  PAGE_404_MESSAGE: `The page you are looking for might have been removed,
        had its name changed, or is temporarily unavailable.`,

  PAGE_UNDER_CONSTRUCTION: 'Page Under Construction',
  PASSWORD: 'Password',
  R1VS_BASE_TITLE: 'R1vs Base UI backed by React and Ts',
  RECOVER_PASSWORD: 'Recover Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  RESET_PASSWORD: 'Reset Password',
  NOT_REGISTERED: 'Not registered?',
  WANT_TO_SEE_DEMO: 'want to see a demo?',

  USER_MANAGEMENT: {
    USER_CREATED_SUCCESSFULLY: 'User created successfully',
    USER_UPDATED_SUCCESSFULLY: 'User updated successfully',
    CHANGE_PASSWORD: {
      PASSWORD_NOT_MATCH: 'Password does not match',
      PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
      PASSWORD_PATTERN_FAILED:
        'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character',
    },
    USER_ACTIVATE_CONFIRMATION: 'Are you sure you want to activate this user?',
    USER_DEACTIVATE_CONFIRMATION: 'Are you sure you want to deactivate this user?',
  },

  NUMBER_MUST_BE_GREATER_EQUALS_THAN: 'Number must be greater than or equal to {number}',
  NUMBER_MUST_BE_LESS_EQUALS_THAN: 'Number must be less than or equal to {number}',

  DATE_CANNON_BE_EARLIER_THAN: 'Date cannot be earlier than {date}',
  DATE_CANNON_BE_LATER_THAN: 'Date cannot be later than {date}',

  CURRENT_DATE: 'Current Date',
  ACCOUNT: 'Account',
  NICKNAME: 'Nickname',
  CONTACT_NAME: 'Contact Name',
  CONTACT_PHONE_NUMBER: 'Contact Phone Number',
  ADDITIONAL_INFO: 'Additional Info',
  ACCOUNT_NUMBER: 'Account Number',
  ACCOUNT_INFO: 'Account Info',
  PLEASE_ENTER_VALID_NUMBER: 'Please enter a valid number.',
  PLEASE_ENTER_A_INTEGER_NUMBER: 'Please enter an integer number.',
  DAY: 'Day',
  DAYS: 'Days',
  DAYS_SUFFIX: { SINGLE: 'day', PLURAL: 'days' },
  ACH: 'ACH',
  CHECK: 'CHECK',
  EXPRESS_CODE: 'EXPRESS CODE',
  FUEL_CARD: 'FUEL CARD',
  SAME_DAY_ACH: 'SAME DAY ACH',
  WIRE: 'WIRE',
  TANK: 'TANK',
  CONTACT: 'Contact',
  JOB_TITLE: 'Job Title',
  EXT: 'Ext',
  ENABLED: 'Enabled',
  ADMIN_SETTINGS: 'Admin Settings',
  FIELD_IS_REQUIRED: 'Field is Required',
  DATE_OF_FUNDING: 'Date of Funding',
  NUMBER_OF_COMPANIES: 'Number of Companies',
  TOTAL_BATCH_AMOUNT: 'Total Batch Amount',
  USER_RESPONSIBLE: 'User Responsible',
  UNKNOWN: 'Unknown',
  UPDATED_ON: 'Updated On',
  ROWS_PER_PAGE: 'Rows per page',
  NOT_RECORDS_FOUND: 'No records found',
  REMIT_EMAILS: 'Remit Emails',
  REMIT_EMAIL: 'Remit Email',
  BROKER_IMPORT_SUCCESS: 'Brokers imported successfully',
  CLIENT_IMPORT_SUCCESS: 'Clients imported successfully',
  VENDOR_IMPORT_SUCCESS: 'Vendors imported successfully',
  DEBTOR_IMPORT_SUCCESS: 'Debtors imported successfully',
  ADDITIONAL_NOTES: 'Additional Notes',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
  OTHERS: 'Others',
  TITLE: 'Title',
  // END Michael

  // BEGIN Fco
  CONTACTS: 'Contacts',
  SETTINGS: 'Settings',
  UPDATE: 'Update',
  SEARCH: 'Search',

  FUNDING: 'Funding',
  WS_ERROR: 'Something Went Wrong',
  INVALID_SESSION: 'Invalid Credentials',
  FACTOR_SETTINGS: 'Factor Settings',
  CHECKING_EMAIL: 'Checking email',
  USER_ALREADY_CONNECTED: 'This user is already connected to the Factor, this action will update its current Role.',
  CLIENT_FACTOR_USER_MISMATCH: 'Company Users needs to be managed from the Company Users tab. Same with Factor Users.',
  INFO_TEMPLATE_RESET: 'To reset the subject line or the body of the template, clear the text and click Update.',
  LABEL: {
    PLURAL: 'Labels',
    SINGLE: 'Label',
  },
  // END Fco

  // BEGIN Fabian
  NAME: 'Name',
  PHONE_NUMBER: 'Phone Number',
  FULL_NAME: 'Full Name',
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  MAIL: 'Mail',
  ONLINE_PORTAL: 'Online Portal',
  CONFIRMATION_EMAIL: 'Confirmation Email',
  PHONE: 'Phone',
  REGISTRATION_SUCCESSFULLY: 'Thank you for registering!',
  REGISTRATION_SUCCESSFULLY_SUBTITLE: 'Please check your inbox for an email to set your password.',
  REGISTRATION_FAILURE_SUBTITLE: 'An error occurred during the registration process.',
  REGISTRATION_EMAIL_CONFIRMATION_FAILURE: 'Email Addresses do not match.',
  REGISTRATION_EMAIL_REGEX_FAILURE: 'Please enter a valid email.',
  REGISTRATION_REGEX_PHONE_FAILURE: 'Please enter a valid phone.',
  REGISTRATION_MIN_PHONE_LENGTH: 'Minimum length is 12',
  REGISTRATION_MAX_PHONE_LENGTH: 'Maximum length is 12',
  FAX: 'Fax',
  ADDRESS: {
    TYPE_1: 'Address',
    TYPE_2: 'Alternate Address',
  },
  COUNTRY: 'Country',
  CITY: 'City',
  STATE_CODE: 'State Code',
  ZIP: 'Zip Code',
  EIN: 'EIN',
  TYPE: 'Type',
  FACTOR_NAME: 'Factor Name',
  MAIN_ADDRESS: 'Main Address',
  ALTERNATIVE_ADDRESS: 'Alternative Address',
  COUNTRY_CODE: 'Country Code',
  ALTERNATIVE_PHONE_NUMBER: 'Alternative Phone Number',
  SUCCESSFULLY: 'Successfully!',
  ROWS: 'Rows',
  SELECTED: 'Selected',
  DETAILS: 'Details',
  INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
  INVALID_MC_NUMBER: 'Please enter a valid MC number (MC 12345678 or 12345678)',
  INVALID_DOT_NUMBER: 'Please enter a valid DOT number (DOT 12345678 or 12345678)',
  INVALID_ZIP_CODE: 'Please enter a valid zip code',
  INVALID_FAX_NUMBER: 'Please enter a valid fax number',
  MAX_LENGTH_ZIP_CODE: 'Maximum length is 10',
  USERNAME: 'Username',
  TEXT_MUST_BE_LESS_THAN: 'The text must be less than {number} characters',
  TEXT_MUST_BE_GREATER_THAN: 'The text must be greater than {number} characters',
  SEARCH_AND_FILTER: 'Search and Filter',
  MAX_LENGTH_QUILL: 'The text must be less than {number} characters',
  VIEW_PAYMENT: 'View Payment',
  BROKER_SETTINGS: 'Broker Settings',
  // END Fabian

  // BEGING Pato
  PAGE_403_TITLE: '403',
  PAGE_403_SUBHEADER: 'Access Forbidden',
  PAGE_403_MESSAGE: `You don't have permission to access this resource`,
  LOG_TYPE: 'Log Type',
  CLIENT_SETTINGS: 'Client Settings',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  UPDATE_CLIENT_SETTING_SUCCEED: 'Client Setting Updated Successfully',
  UPDATE_FACTOR_SETTING_SUCCEED: 'Factor setting updated successfully',
  AUTOCHARGE_SETTING: 'Autocharge Setting',
  CREATE_NEW: 'Create New',
  ID: 'ID',
  EXPIRATION_DATE: 'Expiration Date',
  POSTAL_CODE: 'Postal Code',
  MAX_LENGTH_POSTAL_CODE: 'The length must be 6',
  INVALID_POSTAL_CODE: 'Please enter a valid postal code',
  TEMPLATES: 'Templates',
  IMPORT_CSV: 'Import CSV',
  FILE_NAME: 'File Name',
  IMPORTED_ON: 'Imported On',
  UPLOAD: 'Upload',
  IMPORT_TYPE: 'Import Type',
  IMPORT_SUCCESS: 'Data Imported Successfully',
  IMPORT_DETALIS: 'Import Details',
  IMPORT_N_RECORDS: 'N° Companies Imported',
  FILE_SIZE: 'File Size',
  IMPORT_INFORMATION_HEADER_DIALOG: 'Note: File Upload is Permanent',
  IMPORT_INFORMATION_BODY_DIALOG:
    'Once you upload this file, the action cannot be undone. Please ensure that you have selected the correct file before proceeding.',
  IMPORT_CSV_INFO:
    'To change the current file, please select a new CSV file. The current file will be replaced by the newly selected file.',
  IMPORT_WARNING: 'Some records failed to import. Please download the error report.',
  SIGNATURE: 'Signature',
  LEGAL_NOTICE: 'Legal Notice',
  FACTOR_ADDRESS: 'Factor Address',
  // END Pato
  //BEGIN Esteban
  FUEL_ADVANCE_SETTING: 'Fuel Advance Setting',
  BENCHMARK_INTEREST_RATES: 'Benchmark Interest Rates',
  CHARCOUNT_MAX_LENGTH_MESSAGE: 'The text must be less than {value} characters',
  TIMEZONE: 'Timezone',
  MOVE_TO: 'Move to',
  UNGROUP: 'Ungroup',
  CREATE_GROUP: 'Create Group',
  CLEAR_FILTER: 'Clear Filter',
  AMOUNT_PERCENTAGE: 'Amount Percentage',
  CC_BCC: 'CC/BCC',
  EXPORTS: 'Exports',
  USER_ALREADY_CONNECTED_TO_COMPANY:
    'This user is already connected to the company, this action will update its current Role.',
  //END Esteban
  PERMISSION: 'Permission',

  // #region CSV Columns
  CSV_COLUMNS_BLADE: {
    COLUMN: 'Column',
    ORDER: 'Order',
  },
  CSV_COLUMNS: {
    ADD_COLUMN_OPTION: 'Add Column',
    BANK_ACCOUNT_NUMBER: 'Bank Account Number',
    BANK_ACCOUNT_TYPE: 'Bank Account Type',
    BANK_CITY: 'Bank City',
    BANK_CODE: 'Bank Code',
    BANK_COUNTRY: 'Bank Country',
    BANK_NAME: 'Bank Name',
    BANK_STATE: 'Bank State',
    CURRENCY_TYPE: 'Currency Type',
    DATE: 'Date',
    INTERMEDIATE_BANK_ACCOUNT_NUMBER: 'Intermediate Bank Account Number',
    INTERMEDIATE_BANK_CODE: 'Intermediate Bank Code',
    INTERMEDIATE_BANK_COUNTRY: 'Intermediate Bank Country',
    INTERMEDIATE_BANK_NAME: 'Intermediate Bank Name',
    INTERMEDIATE_BANK_STATE: 'Intermediate Bank State',
    INTERMEDIATE_ROUTING_NUMBER: 'Intermediate Routing Number',
    INTERMEDIATE_SPECIAL_INSTRUCTIONS: 'Intermediate Special Instructions',
    INTERMEDIATE_SWIFT_CODE: 'Intermediate Swift Code',
    INVOICE_GROUP_TYPE: 'Invoice Group Type',
    NET_PAYMENT_AMOUNT: 'Net Payment Amount',
    PAY_TO: 'Pay To',
    PAY_TO_ADDRESS_LINE_1: 'Pay To Address Line 1',
    PAY_TO_ADDRESS_LINE_2: 'Pay To Address Line 2',
    PAY_TO_CITY: 'Pay To City',
    PAY_TO_COUNTRY: 'Pay To Country',
    PAY_TO_STATE_PROVIDENCE: 'Pay To State/Providance',
    PAY_TO_ZIP_CODE: 'Pay To Zip Code',
    PAYMENT_AMOUNT: 'Payment Amount',
    PAYMENT_BATCH: 'Payment Batch',
    ROUTING_NUMBER: 'Routing Number',
    SPECIAL_INSTRUCTIONS: 'Special Instructions',
    SWIFT_CODE: 'Swift Code',
    TRANSACTION_TYPE: 'Transaction Type',
    MC_NUMBER: 'MC Number',
  },
  EDITED_FIELDS: 'Edited Fields',
  EDITED: 'Edited',
}

export const commonEs: typeof commonEn = {
  // BEGIN Michael
  CREATE: 'Crear',
  EDIT: 'Editar',
  DELETE: 'Borrar',
  DENY: 'Denegar',
  VIEW: 'Ver',
  CANCEL: 'Cancelar',
  SELECT: 'Seleccionar',
  PROCEED: 'Continuar',
  ASSIGN: 'Asignar',

  CLEAR_SELECTION: 'Limpiar Selección',
  STORY_BOOK: 'Ver Documento',
  DEMO_TABLE_COMPONENT: 'Demo Componente Tabla',
  PRIMARY_ACTION: 'Acción Primaria',
  SECONDARY_ACTION: 'Acción Secundaria',
  CONTROLLER_LIST: 'Lista de Controladores',
  INPUT_CONTROLLER: 'Controlador de Campo de Entrada',
  SINGLE_SELECT_CONTROLLER: 'Controlador de Selección Única',
  CHECKBOX_CONTROLLER: 'Controlador de Casilla de Verificación',
  COMPONENTS: 'Componentes',
  CHILD: 'Hijo',
  PARENT: 'Padre',
  NO_RECORDS: 'No hay registros en la tabla',
  RECORDS_ON_THIS_PAGE_ARE_SELECTED: 'registros en esta página están seleccionados',
  RECORDS_ARE_SELECTED: 'registros están seleccionados',
  RECORDS_ACROSS_ALL_PAGES: 'registros de todas las páginas',
  NO_RESULTS: '¡No se han encontrado resultados!',
  ADJUST_SEARCH: 'Intente ajustar su búsqueda o filtro para encontrar lo que está buscando.',
  YES: 'Sí',
  NO: 'No',
  NONE: 'Ninguno',
  NEW: 'Nuevo',
  APPLY: 'Aplicar',
  FILTER: 'Filtrar',
  DEFAULT: 'Por Defecto',
  ACTIONS: 'Acciones',
  PREVIEW: 'Vista Previa',
  ADD: 'Agregar',
  REMOVE: 'Remover',
  AVAILABLE: 'Disponible',
  REQUESTED: 'Solicitado',
  DENIED: 'Denegado',
  APPROVED: 'Aprobado',
  ROLE: 'Rol',
  ROLES: 'Roles',
  ROLES_AND_PERMISSION: 'Roles y permisos',
  ROLE_DETAILS: 'Detalles del rol',
  PERMISSIONS_OVERVIEW: 'Descripción general de permisos',
  PERMISSION_DETAILS: 'Detalles del permiso',
  REPORTS_CATEGORY: 'Categoría de informes',
  CREATE_ROLE: 'Crear Rol',
  CREATE_ROLE_ASSIGN_PERMISSIONS: 'Crear rol y asignar permisos',
  SELECT_SECTION_PERMISSION: 'Por favor seleccione la sección y sus permisos',
  UPDATE_ROLE: 'Actualizar rol',
  ROLE_NAME: 'Nombre de rol',
  YOU_DONT_HAVE_PERMISSION: 'No tiene permiso para acceder a este recurso.',
  STATUS: {
    LABEL: 'Estado',

    ALL: {
      TYPE_1: 'Todos',
      TYPE_2: 'Todas',
      TYPE_3: 'TODAS',
      TYPE_4: 'Los',
    },

    ACTIVE: {
      PLURAL: 'Activos',
      SINGLE: 'Activo',

      PLURAL_TYPE_2: 'Activas',
      SINGLE_TYPE_2: 'Activa',
    },

    INACTIVE: {
      PLURAL: 'Inactivos',
      SINGLE: 'Inactivo',

      PLURAL_TYPE_2: 'Inactivas',
      SINGLE_TYPE_2: 'Inactiva',
    },

    ACTIVATE: 'Activar',
    DESACTIVATE: 'Desactivar',
  },

  CHANGELOG: 'Control de Cambios',

  AMOUNT: 'Monto',
  CODE: 'Código',
  DATE_TIME: 'Fecha y Hora',
  DOWNLOAD: 'Descargar',
  TOTAL_AMOUNT: 'Monto Total',
  SELECTED_AMOUNT: 'Monto Seleccionado',

  ALL_OBJECTS: '{state} {link} {object}',
  ACTIVE_OBJECTS: '{object} {state}',
  INACTIVE_OBJECTS: '{object} {state}',

  SESSION_HAS_TIMED_OUT: 'La sesión ha expirado',
  PLEASE_RE_ENTER_YOUR_CREDENTIALS: 'Ingrese nuevamente sus credenciales para iniciar sesión nuevamente.',

  STRONG_PASSWORD: 'La contraseña debe contener',

  ERROR_BOUNDARY_HEADER: 'Nos encontramos con un problema.',
  ERROR_BOUNDARY_BODY:
    'Intente actualizar su navegador o use el botón de retroceso de su navegador para volver a la página anterior.',
  ERROR_CHUNK_LOAD_HEADER: 'Actualización de la Aplicación',
  ERROR_CHUNK_LOAD_BODY:
    'Esta aplicación ha sido actualizada, por favor refresque su navegador para ver el contenido más reciente.',
  PAGE_404_TITLE: '404',
  PAGE_404_SUBHEADER: 'Esta página no se puede encontrar',
  PAGE_404_MESSAGE: `Es posible que la página que está buscando se haya eliminado,
        cambiado su nombre o no esté disponible temporalmente.`,

  PAGE_UNDER_CONSTRUCTION: 'Página en Construcción',

  PASSWORD: 'Contraseña',
  R1VS_BASE_TITLE: 'R1vs Base UI respaldado por React y Ts',

  RECOVER_PASSWORD: 'Recuperar Contraseña',
  NEW_PASSWORD: 'Nueva Contraseña',
  CONFIRM_PASSWORD: 'Confirmar Contraseña',
  RESET_PASSWORD: 'Restablecer Contraseña',

  NOT_REGISTERED: '¿No está registrado?',
  WANT_TO_SEE_DEMO: '¿Quieres ver una demostración?',

  USER_MANAGEMENT: {
    USER_CREATED_SUCCESSFULLY: 'Usuario creado exitosamente',
    USER_UPDATED_SUCCESSFULLY: 'Usuario actualizado exitosamente',
    CHANGE_PASSWORD: {
      PASSWORD_NOT_MATCH: 'La contraseña no coincide',
      PASSWORD_CHANGED_SUCCESSFULLY: 'Contraseña cambiada exitosamente',
      PASSWORD_PATTERN_FAILED:
        'La contraseña debe contener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial',
    },
    USER_ACTIVATE_CONFIRMATION: '¿Está seguro de que desea activar este usuario?',
    USER_DEACTIVATE_CONFIRMATION: '¿Está seguro de que desea desactivar este usuario?',
  },
  NUMBER_MUST_BE_GREATER_EQUALS_THAN: 'El número debe ser mayor o igual que {number}',
  NUMBER_MUST_BE_LESS_EQUALS_THAN: 'El número debe ser menor o igual que {number}',

  DATE_CANNON_BE_EARLIER_THAN: 'La fecha no puede ser anterior a {date}',
  DATE_CANNON_BE_LATER_THAN: 'La fecha no puede ser posterior a {date}',

  CURRENT_DATE: 'Fecha Actual',
  ACCOUNT: 'Cuenta',
  NICKNAME: 'Apodo',
  CONTACT_NAME: 'Nombre de Contacto',
  CONTACT_PHONE_NUMBER: 'Número de Teléfono de Contacto',
  ADDITIONAL_INFO: 'Información Adicional',
  ACCOUNT_NUMBER: 'Número de Cuenta',
  ACCOUNT_INFO: 'Información de la Cuenta',
  PLEASE_ENTER_VALID_NUMBER: 'Por favor, ingrese un número válido.',
  PLEASE_ENTER_A_INTEGER_NUMBER: 'Por favor, ingrese un número entero.',
  DAY: 'Día',
  DAYS: 'Días',
  DAYS_SUFFIX: { SINGLE: 'día', PLURAL: 'días' },
  ACH: 'ACH',
  CHECK: 'CHECK',
  EXPRESS_CODE: 'EXPRESS CODE',
  FUEL_CARD: 'FUEL CARD',
  SAME_DAY_ACH: 'SAME DAY ACH',
  WIRE: 'WIRE',
  CONTACT: 'Contacto',
  JOB_TITLE: 'Título del Trabajo',
  EXT: 'Ext',
  ENABLED: 'Habilitado',
  ADMIN_SETTINGS: 'Configuración de Administrador',
  FIELD_IS_REQUIRED: 'Campo es Requerido',
  DATE_OF_FUNDING: 'Fecha de Financiamiento',
  NUMBER_OF_COMPANIES: 'Número de Compañías',
  TOTAL_BATCH_AMOUNT: 'Monto Total del Lote',
  USER_RESPONSIBLE: 'Usuario Responsable',
  UNKNOWN: 'Desconocido',
  UPDATED_ON: 'Actualizado el',
  ROWS_PER_PAGE: 'Filas por página',
  NOT_RECORDS_FOUND: 'No se encontraron registros',
  REMIT_EMAILS: 'Correos de Remisión',
  REMIT_EMAIL: 'Correo de Remisión',
  BROKER_IMPORT_SUCCESS: 'Brokers importados exitosamente',
  CLIENT_IMPORT_SUCCESS: 'Clientes importados exitosamente',
  VENDOR_IMPORT_SUCCESS: 'Vendedores importados exitosamente',
  DEBTOR_IMPORT_SUCCESS: 'Deudores importados exitosamente',
  ADDITIONAL_NOTES: 'Notas Adicionales',
  WEEKS: 'Semanas',
  MONTHS: 'Meses',
  OTHERS: 'Otros',
  TITLE: 'Título',
  // END Michael

  // BEGIN Fco
  CONTACTS: 'Contactos',
  SETTINGS: 'Configuración',
  UPDATE: 'Actualizar',
  SEARCH: 'Buscar',

  FUNDING: 'Financiamiento',
  WS_ERROR: 'Ocurrio un error inesperado',
  INVALID_SESSION: 'Credenciales Inválidas',
  FACTOR_SETTINGS: 'Configuración del Factor',
  CHECKING_EMAIL: 'Verificando correo',
  USER_ALREADY_CONNECTED: 'Este usuario ya está conectado al Factor, esta acción actualizará su Rol actual.',
  CLIENT_FACTOR_USER_MISMATCH:
    'Los usuarios de Empresas necesitan ser administrados desde la tab Usuarios de Empresa. De igual manera con los Usuarios de Factor.',
  INFO_TEMPLATE_RESET:
    'Para restablecer la línea de asunto o el cuerpo de la plantilla, borre el texto y haga clic en Actualizar.',
  LABEL: {
    PLURAL: 'Etiquetas',
    SINGLE: 'Etiqueta',
  },
  // END Fco

  // BEGIN Fabian
  NAME: 'Nombre',
  PHONE_NUMBER: 'Número de Teléfono',
  FULL_NAME: 'Nombre Completo',
  FIRST_NAME: 'Nombre',
  MIDDLE_NAME: 'Segundo Nombre',
  LAST_NAME: 'Apellido',
  EMAIL: 'Correo Electrónico',
  MAIL: 'Correo',
  ONLINE_PORTAL: 'Portal en Línea',
  CONFIRMATION_EMAIL: 'Confirmación de Correo Electrónico',
  PHONE: 'Teléfono',
  REGISTRATION_SUCCESSFULLY: '¡Gracias por registrarse!',
  REGISTRATION_SUCCESSFULLY_SUBTITLE: 'Por favor revise su correo electrónico para establecer su contraseña.',
  REGISTRATION_FAILURE_SUBTITLE: 'Ocurrió un error durante el proceso de registro.',
  REGISTRATION_EMAIL_CONFIRMATION_FAILURE: 'Las direcciones de correo electrónico no coinciden.',
  REGISTRATION_EMAIL_REGEX_FAILURE: 'Por favor ingrese un correo electrónico válido.',
  REGISTRATION_REGEX_PHONE_FAILURE: 'Por favor ingrese un teléfono válido.',
  REGISTRATION_MIN_PHONE_LENGTH: 'La longitud mínima es 12',
  REGISTRATION_MAX_PHONE_LENGTH: 'La longitud máxima es 12',
  FAX: 'Fax',
  ADDRESS: {
    TYPE_1: 'Dirección',
    TYPE_2: 'Dirección Alternativa',
  },
  COUNTRY: 'País',
  CITY: 'Ciudad',
  STATE_CODE: 'Código de Estado',
  ZIP: 'Código Postal',
  EIN: 'EIN',
  TYPE: 'Tipo',
  FACTOR_NAME: 'Nombre del Factor',
  MAIN_ADDRESS: 'Dirección Principal',
  ALTERNATIVE_ADDRESS: 'Dirección Alternativa',
  COUNTRY_CODE: 'Código de País',
  ALTERNATIVE_PHONE_NUMBER: 'Número de Teléfono Alternativo',
  SUCCESSFULLY: 'Exitosamente!',
  ROWS: 'Filas',
  SELECTED: 'seleccionada(s)',
  DETAILS: 'Detalles',
  INVALID_PHONE_NUMBER: 'Por favor ingrese un número de teléfono válido',
  INVALID_MC_NUMBER: 'Por favor ingrese un número de MC válido (MC 123456)',
  INVALID_DOT_NUMBER: 'Por favor ingrese un número de DOT válido (DOT 12345678)',
  INVALID_ZIP_CODE: 'Por favor ingrese un código postal válido',
  INVALID_FAX_NUMBER: 'Por favor ingrese un número de fax válido',
  MAX_LENGTH_ZIP_CODE: 'La longitud máxima es 10',
  USERNAME: 'Nombre de Usuario',
  TEXT_MUST_BE_LESS_THAN: 'El texto debe ser menor a {number} caracteres',
  TEXT_MUST_BE_GREATER_THAN: 'El texto debe ser mayor a {number} caracteres',
  SEARCH_AND_FILTER: 'Buscar y Filtrar',
  MAX_LENGTH_QUILL: 'El texto debe ser menor a {number} caracteres',
  VIEW_PAYMENT: 'Ver Pago',
  BROKER_SETTINGS: 'Configuración de Broker',
  TANK: 'TANK',
  // END Fabian

  // BEGING Pato
  PAGE_403_TITLE: '403',
  PAGE_403_SUBHEADER: 'Acceso Prohibido',
  PAGE_403_MESSAGE: `No tienes los permisos necesarios para acceder a este recurso`,
  LOG_TYPE: 'Tipo de Log',
  CLIENT_SETTINGS: 'Configuración del Cliente',
  DAILY: 'Diario',
  WEEKLY: 'Semanal',
  MONTHLY: 'Mensual',
  UPDATE_CLIENT_SETTING_SUCCEED: 'Configuración del Cliente Actualizada Exitosamente',
  UPDATE_FACTOR_SETTING_SUCCEED: 'Configuración del Factor Actualizada Exitosamente',
  AUTOCHARGE_SETTING: 'Configuración de Autocarga',
  CREATE_NEW: 'Crear Nuevo',
  ID: 'ID',
  EXPIRATION_DATE: 'Fecha de Expiración',
  POSTAL_CODE: 'Código Postal',
  MAX_LENGTH_POSTAL_CODE: 'La longitud debe ser 6',
  INVALID_POSTAL_CODE: 'Por favor ingrese un código postal válido',
  TEMPLATES: 'Plantillas',
  IMPORT_CSV: 'Importar CSV',
  FILE_NAME: 'Nombre de Archivo',
  IMPORTED_ON: 'Importado el',
  UPLOAD: 'Subir',
  IMPORT_TYPE: 'Tipo de Importación',
  IMPORT_SUCCESS: 'Datos Importados Exitosamente',
  IMPORT_DETALIS: 'Detalles de Importación',
  IMPORT_N_RECORDS: 'N° Compañías Importadas',
  FILE_SIZE: 'Tamaño de Archivo',
  IMPORT_INFORMATION_HEADER_DIALOG: 'Nota: La Carga de Archivos es Permanente',
  IMPORT_INFORMATION_BODY_DIALOG:
    'Una vez que cargue este archivo, la acción no se puede deshacer. Asegúrese de haber seleccionado el archivo correcto antes de continuar.',
  IMPORT_CSV_INFO:
    'Para cambiar el archivo actual, selecciona un nuevo archivo CSV. El archivo actual será reemplazado por el nuevo archivo seleccionado',
  IMPORT_WARNING: 'Algunos registros no se importaron. Descargue el informe de errores para mas información.',
  SIGNATURE: 'Firma',
  LEGAL_NOTICE: 'Aviso Legal',
  FACTOR_ADDRESS: 'Dirección del Factor',
  // END Pato
  //BEGIN Esteban
  FUEL_ADVANCE_SETTING: 'Configuración Avance por Combustible',
  BENCHMARK_INTEREST_RATES: 'Tipos de Interés de Referencia',
  CHARCOUNT_MAX_LENGTH_MESSAGE: 'El texto debe ser menor a {value} caracteres',
  TIMEZONE: 'Zona Horaria',
  MOVE_TO: 'Mover a',
  UNGROUP: 'Desagrupar',
  CREATE_GROUP: 'Crear Grupo',
  CLEAR_FILTER: 'Limpiar Filtro',
  AMOUNT_PERCENTAGE: 'Porcentaje de Monto',
  CC_BCC: 'CC/BCC',
  EXPORTS: 'Exportaciones',
  USER_ALREADY_CONNECTED_TO_COMPANY:
    'Este usuario ya está conectado a la empresa, esta acción actualizará su Rol actual.',
  //END Esteban
  PERMISSION: 'Permiso',

  // #region CSV Columns

  CSV_COLUMNS_BLADE: {
    COLUMN: 'Columna',
    ORDER: 'Orden',
  },
  CSV_COLUMNS: {
    ADD_COLUMN_OPTION: 'Agregar Columna',
    BANK_ACCOUNT_NUMBER: 'Número de Cuenta Bancaria',
    BANK_ACCOUNT_TYPE: 'Tipo de Cuenta Bancaria',
    BANK_CITY: 'Ciudad del Banco',
    BANK_CODE: 'Código del Banco',
    BANK_COUNTRY: 'País del Banco',
    BANK_NAME: 'Nombre del Banco',
    BANK_STATE: 'Estado del Banco',
    CURRENCY_TYPE: 'Tipo de Moneda',
    DATE: 'Fecha',
    INTERMEDIATE_BANK_ACCOUNT_NUMBER: 'Número de Cuenta del Banco Intermedio',
    INTERMEDIATE_BANK_CODE: 'Código del Banco Intermedio',
    INTERMEDIATE_BANK_COUNTRY: 'País del Banco Intermedio',
    INTERMEDIATE_BANK_NAME: 'Nombre del Banco Intermedio',
    INTERMEDIATE_BANK_STATE: 'Estado del Banco Intermedio',
    INTERMEDIATE_ROUTING_NUMBER: 'Número de Ruta del Banco Intermedio',
    INTERMEDIATE_SPECIAL_INSTRUCTIONS: 'Instrucciones Especiales del Banco Intermedio',
    INTERMEDIATE_SWIFT_CODE: 'Código SWIFT del Banco Intermedio',
    INVOICE_GROUP_TYPE: 'Tipo de Grupo de Facturas',
    NET_PAYMENT_AMOUNT: 'Monto de Pago Neto',
    PAY_TO: 'Pagar a',
    PAY_TO_ADDRESS_LINE_1: 'Línea de Dirección 1 para Pagar a',
    PAY_TO_ADDRESS_LINE_2: 'Línea de Dirección 2 para Pagar a',
    PAY_TO_CITY: 'Ciudad para Pagar a',
    PAY_TO_COUNTRY: 'País para Pagar a',
    PAY_TO_STATE_PROVIDENCE: 'Estado/Provincia para Pagar a',
    PAY_TO_ZIP_CODE: 'Código Postal para Pagar a',
    PAYMENT_AMOUNT: 'Monto de Pago',
    PAYMENT_BATCH: 'Lote de Pago',
    ROUTING_NUMBER: 'Número de Ruta',
    SPECIAL_INSTRUCTIONS: 'Instrucciones Especiales',
    SWIFT_CODE: 'Código SWIFT',
    TRANSACTION_TYPE: 'Tipo de Transacción',
    MC_NUMBER: 'Número de MC',
  },
  EDITED_FIELDS: 'Campos Editados',
  EDITED: 'Editado',
}

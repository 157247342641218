import Isvg from 'react-inlinesvg'
import IconButton from '@mui/material/IconButton'
import styles from './Icon.module.scss'

type IconProps = {
  /** The keyName specifies a unique key for the icon component. This is used by React to optimize rendering performance.*/
  keyName?: string
  /** The name specifies the name of the icon or image to be rendered.*/
  name?: string
  /** The type specifies whether the component should render an icon or an image. The default value is icons.*/
  type?: 'icons' | 'images'
  /** The boxClassName specifies the CSS class name for the container element of the icon or image.*/
  boxClassName?: string
  /** The iconClassName specifies the CSS class name for the SVG icon element.*/
  iconClassName?: string | object | undefined
  /** The hover specifies whether the icon or image should have a hover effect. The default value is false.*/
  hover?: boolean
  /** The title specifies the title attribute for the icon or image.*/
  title?: string
  /** The onClick specifies the callback function to be called when the icon or image is clicked.*/
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** The color specifies the color of the icon or image.*/
  color?: string
  /** The size specifies the size of the icon or image. The default value is 20.*/
  size?: number
  /** The height specifies the height of the icon or image.*/
  height?: number
  /** The width specifies the width of the icon or image.*/
  width?: number
  /** The alt specifies the alt attribute for the image.*/
  alt?: string
  /** The children specifies additional content to be rendered within the icon or image container.*/
  children?: React.ReactNode
  /** The iconButton specifies whether the icon or image should be rendered within an IconButton component from the Material-UI library. The default value is false.*/
  iconButton?: boolean
  /** The reference specifies a reference to the icon or image element.*/
  reference?: any
  /** The imageId specifies the id attribute for the image element.*/
  imageId?: string
  /** The url specifies the URL of the image to be rendered. If this prop is provided, the component will render an image instead of an SVG icon.*/
  url?: string
  /** The disabled specifies whether the IconButton component should be disabled. The default value is false.*/
  disabled?: boolean
  /** The id specifies the id attribute for the icon or image element.*/
  id?: string
}

const Icon = (props: IconProps) => {
  const {
    keyName = '',
    title,
    name,
    url,
    boxClassName,
    iconClassName,
    hover,
    height,
    width,
    size = 20,
    disabled = false,
    color,
    iconButton,
    type = 'icons',
    onClick = () => {},
    alt = 'vector_images',
    children,
    reference,
    imageId,
    id,
  } = props

  const node = (isIconButton?: boolean) => {
    try {
      return type === 'icons' ? (
        <span
          id={id}
          key={keyName}
          title={title}
          className={`${styles.iconContainer} ${hover ? styles.pointer : ''}
              ${boxClassName}`}
          onClick={(!isIconButton && onClick) || undefined}
        >
          <Isvg
            className={`${iconClassName} ${styles.iconSvg}`}
            src={require(`../../../assets/${type}/${name}.svg`)}
            width={width || size}
            height={height || size}
            preProcessor={(code) => code.replace(color ? /fill=".*?"/g : '', `fill='${color}'`)}
          />
        </span>
      ) : (
        <div id={id} className={boxClassName}>
          <img
            id={imageId}
            src={url ? url : require(`../../../assets/${type}/${name}.png`)}
            alt={alt}
            ref={reference}
          />
        </div>
      )
    } catch (e: any) {
      const err = `Icon Error: ${!name ? 'name is missing in prop' : e.message}`
      return <span title={err}>icon error</span>
    }
  }

  const isIconButton = Boolean(children || iconButton)

  return (
    <>
      {isIconButton ? (
        <IconButton onClick={!disabled ? onClick : () => {}} className={boxClassName} key={keyName} disabled={disabled}>
          {children ? children : node(isIconButton)}
        </IconButton>
      ) : (
        node()
      )}
    </>
  )
}

export default Icon

import { UserInfo } from 'types/users'
import { createContext } from 'react'
import { PermissionInfo } from 'types/rolePermission'

export type UserSessionContextState = {
  userInfo?: UserInfo
  permissions?: PermissionInfo[]
  token?: string | null
  firstToken?: string | null
  factorTimezone?: string
}
const baseContext: {
  state: UserSessionContextState
  updateUserInfo: () => void
  updatePermissions: () => void
  setToken: (_: string) => void
  setFirstToken: (_: string) => void
  loadToken: () => void
  loadFirstToken: () => void
  clearSession: () => void
} = {
  state: {},
  updateUserInfo: () => {},
  updatePermissions: () => {},
  setToken: () => {},
  setFirstToken: () => {},
  loadToken: () => {},
  loadFirstToken: () => {},
  clearSession: () => {},
}

export const UserSessionContext = createContext(baseContext)

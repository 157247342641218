import request from 'tools/request'
import {
  CreateOutOfSystemInvoiceRequest,
  GetOutOfSystemSingletonParams,
  OutOfSystemInvoiceSingletonInfo,
  OutOfSystemInvoiceSingletonResponse,
  UpdateOutOfSystemInvoiceRequest,
} from 'types/outOfSystemInvoice'

export const createOOSInvoice = async (payload: CreateOutOfSystemInvoiceRequest) => {
  return await request.post<OutOfSystemInvoiceSingletonResponse>('out-of-system-invoices', payload)
}

export const addDocumentsOutOfSystemInvoice = async (oosId: string, documents?: string[]) => {
  const data = await request
    .post<OutOfSystemInvoiceSingletonResponse>(`out-of-system-invoices/${oosId}/documents`, {
      documentIds: documents,
    })
    .then((res) => {
      return res.data
    })
  return data
}

export const getOutOfSystemInvoice = async (outOfSystemInvoiceId: string, params: GetOutOfSystemSingletonParams) => {
  const data = await request
    .get<OutOfSystemInvoiceSingletonInfo>(`out-of-system-invoices/${outOfSystemInvoiceId}`, {
      params,
    })
    .then((r) => {
      return r.data
    })
  return data
}

export const updateOutOfSystemInvoice = async (
  outOfSystemInvoiceId: string,
  payload: UpdateOutOfSystemInvoiceRequest
) => {
  const data = await request
    .put<OutOfSystemInvoiceSingletonResponse>(`out-of-system-invoices/${outOfSystemInvoiceId}`, payload)
    .then((r) => {
      return r.data
    })
  return data
}

import { useLocation, useNavigate } from 'react-router-dom'
import renderBreadcrumbs, { getBreadcrumbsIcon } from 'constants/breadCrumb'
import { TypoGraph, Container, Icon } from 'components/ui'
import styles from './Breadcrumbs.module.scss'
import colors from 'theme/colors'
import useBreadcrumbOptions from '../../hooks/Breadcrumbs/useBreadcrumbOptions'
import { getBreadcrumbs } from './tools/utils'
import { Fragment } from 'react'

type BreadcrumbProps = {
  path: string
}

/** **** Breadcrumbs element *****
 *
 * Renders Breadcrumbs
 * @param {Object} routes Object with valid routes
 * @param {Undefined} match Current match (not used)
 * @param {String} location Current path
 */
const Breadcrumbs = (props: BreadcrumbProps) => {
  const { path } = props
  const location = useLocation()
  const navigate = useNavigate()
  const { lastSectionName } = useBreadcrumbOptions()
  const routes = renderBreadcrumbs(path)
  const breadcrumbs = getBreadcrumbs(routes, location).filter((breadcrumb) => breadcrumb.name)
  const breadcrumbIcon = getBreadcrumbsIcon(location.pathname)

  return (
    <Container className={styles.breadcrumbs}>
      {breadcrumbIcon && (
        <Icon name={breadcrumbIcon} size={25} boxClassName={styles.breadcrumbIcon} color={colors.black} />
      )}
      {breadcrumbs.map((breadcrumb, i) => {
        const isBeforeLast = i + 1 < breadcrumbs.length

        return (
          <Fragment key={`key-${i}`}>
            {isBeforeLast ? (
              <>
                <TypoGraph
                  onClick={() => navigate(breadcrumb.path)}
                  color={colors.grey}
                  variant="h2"
                  content={breadcrumb.name}
                  link
                />
                <TypoGraph color={colors.grey} content="/" />
              </>
            ) : (
              <TypoGraph
                id="breadcrumbsCurrentPage"
                color={colors.primary}
                variant="h2"
                content={lastSectionName || breadcrumb.name}
              ></TypoGraph>
            )}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default Breadcrumbs

import { useState } from 'react'
import { Box } from '@mui/material'
import { Icon, Container, TypoGraph, Toast as toast } from 'components/ui'
import LoginForm from '../../app/LoginManagement/LoginForm'
import ResetPasswordForm from '../../app/LoginManagement/ResetPasswordForm'
import MarketingChangelog from '../../app/LoginManagement/MarketingChangelog'
import ForgotPasswordForm from '../../app/LoginManagement/ForgotPasswordForm'
import MultifactorAuth from '../../app/LoginManagement/MultifactorAuth'
import styles from './LoginComponent.module.scss'
import strings from 'l10n'
import colors from 'theme/colors'
import { resendOtp } from 'api/login'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ResendOtpRequest } from 'types/login'
import { getWhiteLabel } from 'api/factors'
import { qkGetWhiteLabel } from 'constants/QueryKeys/factor'

export type LoginComponentProps = {
  currentStep?: number
}

const LoginComponent = (props: LoginComponentProps) => {
  const { currentStep } = props
  /**
   * step state used to show/hide the login forms
   * step number(0) -> to show the login form
   * step number(1) -> to show the forgotPassword form
   * step number(2) -> to show the resetForm
   */
  const [step, setStep] = useState(currentStep || 0)
  const [formValue, setFormValue] = useState({ email: '', password: '' })

  const { data: whiteLabel } = useQuery({
    queryKey: qkGetWhiteLabel(),
    queryFn: () => {
      return getWhiteLabel({ domainUrl: window.location.hostname })
    },
  })

  const FormComponent = (props: { step: number }) => {
    const { step } = props
    switch (step) {
      case strings.SHOW_LOGIN_FORM:
        return (
          <LoginForm
            styles={styles}
            factorId={whiteLabel?.id || ''}
            handleStep={(val) => setStep(val)}
            handleFormValue={(form) => setFormValue(form)}
          />
        )
      case strings.SHOW_FORGOT_PASS_FORM:
        return <ForgotPasswordForm styles={styles} handleStep={(val) => setStep(val)} factorId={whiteLabel?.id} />
      case strings.SHOW_RESET_PASS_FORM:
        return <ResetPasswordForm styles={styles} handleStep={(val) => setStep(val)} />
      case strings.SHOW_MULTI_FACTOR_FORM:
        return <MultifactorAuth styles={styles} formValue={formValue} />
      default:
        return (
          <LoginForm
            styles={styles}
            factorId={whiteLabel?.id || ''}
            handleStep={(val) => setStep(val)}
            handleFormValue={(form) => setFormValue(form)}
          />
        )
    }
  }

  const { mutate: resendOtpMutation, isLoading } = useMutation({
    mutationFn: (data: ResendOtpRequest) => {
      return resendOtp(data.email)
    },
    onSuccess: () => {
      toast({
        title: `${strings.T_OPT_RESENT_SUCCESSFULLY}`,
        subTitle: `${strings.formatString(strings.S_OPT_RESENT_SUCCESSFULLY, {
          email: formValue.email,
        })}`,
      })
    },
  })

  const handleResendOtp = () => {
    const payload: ResendOtpRequest = {
      email: formValue.email,
    }
    resendOtpMutation(payload)
  }

  return (
    <Container>
      <Box component="main">
        <Container className={styles.loginWrapperContainer}>
          <MarketingChangelog styles={styles} />
          <Container className={styles.bgGrey}>
            <Container className={styles.loginContainer}>
              <Container className={styles.brandDetail}>
                <Icon name="factorcloud-new" color="black" width={500} height={100} />
              </Container>
              <FormComponent step={step} />
              {step !== strings.SHOW_LOGIN_FORM && step !== strings.SHOW_MULTI_FACTOR_FORM && (
                <Container className={styles.demoTxtContainer}>
                  <TypoGraph
                    id="backToLoginFromForgotPassword"
                    content={strings.BACK_TO_LOGIN}
                    onClick={() => setStep(0)}
                    align="center"
                    color={colors.primary}
                    link
                  />
                </Container>
              )}
              {step === strings.SHOW_MULTI_FACTOR_FORM && (
                <Container className={styles.resendOtpContainer}>
                  <TypoGraph
                    id="resendOtpLink"
                    content={strings.RESEND_OPT}
                    onClick={!isLoading ? handleResendOtp : undefined}
                    align="center"
                    color={isLoading ? colors.grey : colors.primary}
                    link={!isLoading}
                  />
                  <TypoGraph
                    id="backToLoginFromMultiFactor"
                    content={strings.BACK_TO_LOGIN}
                    onClick={() => setStep(0)}
                    align="center"
                    color={colors.primary}
                    link
                  />
                </Container>
              )}
            </Container>
          </Container>
        </Container>
      </Box>
    </Container>
  )
}

export default LoginComponent

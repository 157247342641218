const regex = {
  EMAIL_REGEX_PATTERN:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMERIC_ONLY: /^(0|[1-9][0-9]*)$/,
  NUMERIC_ONLY_EXACT_LENGTH_9: /^([0-9]{9})$/,
  NUMERIC_BETWEEN_0_AND_100: /^(?:100(?:\.0{1,2})?|(?:\d{1,2}(?:\.\d{1,2})?))$/,
  NUMERIC_POSITIVE_ONLY: /^[0-9][0-9]*$/,
  INTEGER_NUMBERS_ONLY: /^[0-9]*$/,
  ONLY_NUMBER_AND_DECIMALS: /^([-+]?\d{1,3}(,\d{3})*(\.\d+)?|[-+]?\d+(\.\d+)?|\d{1,3}(.\d{3})*(,\d+)?)$/,
  POSTAL_CODE_REGEX_PATTERN: /^(?!.*[dfioquDFIOQU])[a-vxyA-VXY]\d[a-zA-Z](\s\d[a-zA-Z]\d)?$/,
  NUMERIC_ONLY_EXACT_LENGTH_10: /^(\+1)?[-]?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/,
  PHONE_FAX_REGEX_PATTERN: /^(?=.*?\d)[\d\s()+\-*#]{1,20}[+-]?$/,
  MC_NUMBER_REGEX_PATTERN: /^(MC\s?)?\d{1,20}$/i,
  DOT_NUMBER_REGEX_PATTERN: /^(DOT|USDOT)?\s?\d{1,12}$/i,
  PASSWORD_REGEX_PATTERN:
    /^(?=(?:.*[A-Z]))(?=(?:.*[a-z]))(?=(?:.*\d))(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]))([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.>]{8,20})$/,
  POSITIVE_AND_NEGATIVE_NUMBERS: /^-?\d+(\.\d+)?$/,

  // REGEX FOR ZIP/POSTAL CODE
  ZIP_CODE_REGEX_PATTERN: /^[0-9]{5}(?:-[0-9]{4})?$/,
  POSTAL_CODE_REGEX_PATTERN_CANADA: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/i,
  POSTAL_CODE_REGEX_PATTERN_MEXICO: /^\d{5}$/,
  POSTAL_CODE_REGEX_PATTERN_UK: /^(?:[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2})$/i,
  POSTAL_CODE_REGEX_PATTERN_AUSTRALIA: /^\d{4}$/,
  POSTAL_ANY_CHARACTERS: /^.*$/,
}

export default regex

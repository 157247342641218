import { Route, RouteProps, Routes } from 'react-router-dom'
import { lazy } from 'react'
import LoaderPage from 'components/ui/feedback/LoaderPage'

const Accounting = lazy(() => import('pages/accounting/page'))

const AccountingRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<Accounting />} />} />
    </Routes>
  )
}

export default AccountingRoutes

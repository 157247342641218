export const quickbooksEn = {
  QUICKBOOKS_ENTRY_AUDIT: 'QuickBooks Entry Audit',
  PUSH_TO_QUICKBOOKS: 'Push to Quickbooks',
  TIME: 'Time',
  PUBLISHED_DATE: 'Published Date',
  TRIGGERED_BY: 'Triggered by',
  ERRORS: 'Errors',
  JOURNAL_SETUP: 'Journal Setup',
  QUICKBOOK_SETUP: 'Quickbooks Setup',
  TANK_SETUP: 'Tank Setup',
  GMAIL_SETTINGS: 'Gmail Settings',
  ACCOUNT_NO: 'Account No',
  TYPE: 'Type',
  QUICKBOOKS_ONLINE: 'Quickbooks Online',
  ADD_EXTERNAL_LEDGER: 'Add Ledger',
  QUICKBOOKS_ONLINE_SETUP: 'Quickbooks Online Setup',
  TANK_ONLINE_SETUP: 'Tank Online Setup',
  INTEGRATE_QUICKBOOKS: 'Integrate FactorCloud with Quickbooks Accounts.',
  INTEGRATE_TANK: 'Integrate FactorCloud with Tank Accounts.',
  AUTHORIZE_SETUP: 'Authorize Setup',
  AUTHORIZE: 'Authorize',
  AUTHORIZED: 'Authorized',
  CREATE_LEDGER: 'Create Ledger',
  UPDATE_LEDGER: 'Update Ledger',
  EXPORT_LABEL: 'Export Label',
  WEIGHT: 'Weight',
  ASSIGNED_ACCOUNTS: 'Assigned Account',
  AUDIT_START_DATE: 'Audit Start Date',
  AUDIT_END_DATE: 'Audit End Date',
  RECORDS: 'Records',
  LEDGER_CREATE_INFO: 'Ledger Association updated successfully!',
  QUICKBOOK_EXPIRE_INFO: 'Quickbooks Authorization has expired, please authorize to view the Journal!',
  AUTHORIZE_QUICKBOOK_SETUP: 'Authorize Quickbooks Setup',
  UNASSIGN: 'Unassign',
  ASSIGN: 'Assign',
  EXPIRED: 'EXPIRED',
  QUICKBOOK_CLIENT_ID: 'Quickbook client Id',
  QUICKBOOK_CLIENT_SECRET: 'Quickbook client Secret',
}

export const quickbooksEs: typeof quickbooksEn = {
  QUICKBOOKS_ENTRY_AUDIT: 'Auditoría de entrada de QuickBooks',
  PUSH_TO_QUICKBOOKS: 'Enviar a Quickbooks',
  TIME: 'Tiempo',
  PUBLISHED_DATE: 'Fecha de Publicación',
  TRIGGERED_BY: 'Desencadenado por',
  ERRORS: 'Errores',
  JOURNAL_SETUP: 'Configuración del diario',
  QUICKBOOK_SETUP: 'Configuración de Quickbooks',
  TANK_SETUP: 'Integración de pago de Tank',
  GMAIL_SETTINGS: 'Configuración de Gmail',
  ACCOUNT_NO: 'Número de cuenta',
  TYPE: 'Tipo',
  QUICKBOOKS_ONLINE: 'Libros rápidos en línea',
  ADD_EXTERNAL_LEDGER: 'Agregar libro mayor',
  QUICKBOOKS_ONLINE_SETUP: 'Configuración de Quickbooks en línea',
  TANK_ONLINE_SETUP: 'Configuración de Tank en línea',
  INTEGRATE_QUICKBOOKS: 'Integre FactorCloud con cuentas de Quickbooks.',
  INTEGRATE_TANK: 'Integre FactorCloud con cuentas de Tank.',
  AUTHORIZE_SETUP: 'Autorizar configuración',
  AUTHORIZE: 'Autorizar',
  AUTHORIZED: 'Autorizado',
  CREATE_LEDGER: 'Crear libro mayor',
  UPDATE_LEDGER: 'Actualizar libro mayor',
  EXPORT_LABEL: 'Etiqueta de exportación',
  WEIGHT: 'Peso',
  ASSIGNED_ACCOUNTS: 'Cuenta asignada',
  AUDIT_START_DATE: 'Fecha de inicio de la auditoría',
  AUDIT_END_DATE: 'Fecha de finalización de la auditoría',
  RECORDS: 'Registros',
  LEDGER_CREATE_INFO: '¡La Asociación de contabilidad se actualizó correctamente!',
  QUICKBOOK_EXPIRE_INFO: 'La autorización de Quickbooks ha caducado. ¡Autorice para ver el diario!',
  AUTHORIZE_QUICKBOOK_SETUP: 'Autorizar la configuración de Quickbooks',
  UNASSIGN: 'Desasignar',
  ASSIGN: 'Asignar',
  EXPIRED: 'VENCIÓ',
  QUICKBOOK_CLIENT_ID: 'ID de cliente de Quickbook',
  QUICKBOOK_CLIENT_SECRET: 'Secreto del cliente de Quickbook',
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateFactorDefaultValues } from 'api/factorDefault'
import { Blade, Container, Toast as toast } from 'components/ui'
import { qkBaseFactorDefaults } from 'constants/QueryKeys/factorDefaults'
import strings from 'l10n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FactorClientDefaultInfo,
  FactorClientDefaultRequest,
  FactorDefaultFeesRequest,
  FactorDefaultRequest,
} from 'types/factorDefault'
import styles from '../SystemDefaultsSectionsUpdateBlade.module.scss'
import { DropdownFormField, TextFormField } from 'components/hookForm'
import {
  getValueForDropdownWithEmptyOption,
  isDefined,
  minValueLimit,
  parseBooleanValueFromForm,
  parseNumericValueFromForm,
  parsePercentageValueFromForm,
} from 'tools/common'
import { Button } from 'components/form'
import { percentage } from 'tools/format'
import DividerComponent from 'components/ui/layout/DividerComponent'

type ClientSystemDefaultUpdateBladeProps = {
  title: string
  clientDefaultValues?: FactorClientDefaultInfo
  handleClose: () => void
  showBlade: boolean
}

type ClientSystemDefaultUpdateBladeForm = {
  achFee: number
  wireFee: number
  checkFee: number
  fuelCardFee: number
  sameDayAchFee: number
  moneyCodeFee: number
  clientNegativeFeeDollarAmount: number
  clientNegativeFeePercentageAmount: string
  clientGenerateInvoice: string
  clientCreditLimit: number
  clientCreditLimitApproved: string
}

export const ClientSystemDefaultUpdateBlade = (props: ClientSystemDefaultUpdateBladeProps) => {
  const { title, clientDefaultValues, handleClose, showBlade } = props
  const form = useForm<ClientSystemDefaultUpdateBladeForm>()
  const { handleSubmit, setValue } = form
  const queryClient = useQueryClient()

  const { mutate: updateClientDefaultsValues, isLoading: isUpdateClientDefaultsValuesLoading } = useMutation({
    mutationFn: (data: FactorDefaultRequest) => {
      return updateFactorDefaultValues(data)
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        subTitle: strings.CLIENT_DEFAULTS_VALUES_UPDATED,
      })
      setInitialValues(data?.clientDefaultValues)
      queryClient.invalidateQueries(qkBaseFactorDefaults)
      handleClose()
    },
  })

  const setInitialValues = (values: FactorClientDefaultInfo) => {
    setValue('achFee', values.clientPaymentFees.achFee)
    setValue('wireFee', values.clientPaymentFees.wireFee)
    setValue('checkFee', values.clientPaymentFees.checkFee)
    setValue('fuelCardFee', values.clientPaymentFees.fuelCardFee)
    setValue('sameDayAchFee', values.clientPaymentFees.sameDayAchFee)
    setValue('moneyCodeFee', values.clientPaymentFees.moneyCodeFee)
    setValue('clientNegativeFeeDollarAmount', values.clientNegativeFeeDollarAmount)
    setValue(
      'clientNegativeFeePercentageAmount',
      isDefined(values.clientNegativeFeePercentageAmount) ? percentage(values.clientNegativeFeePercentageAmount) : ''
    )
    setValue('clientGenerateInvoice', getValueForDropdownWithEmptyOption(values.clientGenerateInvoice))
    setValue('clientCreditLimit', values.clientCreditLimit)
    setValue('clientCreditLimitApproved', getValueForDropdownWithEmptyOption(values.clientCreditLimitApproved))
  }

  const onSubmit = (data: ClientSystemDefaultUpdateBladeForm) => {
    const payload: FactorDefaultRequest = {}
    const clientPaymentFees: FactorDefaultFeesRequest = {
      achFee: parseNumericValueFromForm(data.achFee),
      wireFee: parseNumericValueFromForm(data.wireFee),
      checkFee: parseNumericValueFromForm(data.checkFee),
      fuelCardFee: parseNumericValueFromForm(data.fuelCardFee),
      sameDayAchFee: parseNumericValueFromForm(data.sameDayAchFee),
      moneyCodeFee: parseNumericValueFromForm(data.moneyCodeFee),
    }
    const clientDefaultValues: FactorClientDefaultRequest = {
      clientPaymentFees,
      clientNegativeFeeDollarAmount: parseNumericValueFromForm(data.clientNegativeFeeDollarAmount),
      clientNegativeFeePercentageAmount: parsePercentageValueFromForm(data.clientNegativeFeePercentageAmount),
      clientGenerateInvoice: parseBooleanValueFromForm(data.clientGenerateInvoice),
      clientCreditLimit: parseNumericValueFromForm(data.clientCreditLimit),
      clientCreditLimitApproved: parseBooleanValueFromForm(data.clientCreditLimitApproved),
    }
    payload.clientDefaultValues = clientDefaultValues
    updateClientDefaultsValues(payload)
  }

  const bladeContent = (
    <FormProvider {...form}>
      <DividerComponent label={strings.PAYMENT_FEES} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="achFee"
          label={strings.ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.achFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="wireFee"
          label={strings.WIRE_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.wireFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="checkFee"
          label={strings.CHECK_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.checkFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="fuelCardFee"
          label={strings.FUEL_CARD_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.fuelCardFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="sameDayAchFee"
          label={strings.SAME_DAY_ACH_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.sameDayAchFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="moneyCodeFee"
          label={strings.MONEY_CODE_FEE}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientPaymentFees.moneyCodeFee}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
      </Container>
      <DividerComponent label={strings.NEGATIVE_CASH_RESERVE_FEE} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="clientNegativeFeeDollarAmount"
          label={strings.DOLLAR_AMOUNT}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientNegativeFeeDollarAmount}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <TextFormField
          name="clientNegativeFeePercentageAmount"
          label={strings.PERCENTAGE_AMOUNT}
          type="number"
          startAdornment="%"
          initialValue={
            isDefined(clientDefaultValues?.clientNegativeFeePercentageAmount)
              ? percentage(clientDefaultValues?.clientNegativeFeePercentageAmount)
              : ''
          }
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
      </Container>
      <DividerComponent label={strings.OTHERS} />
      <Container className={styles.twoRowFormat}>
        <TextFormField
          name="clientCreditLimit"
          label={strings.CREDIT_LIMIT}
          type="number"
          startAdornment="$"
          initialValue={clientDefaultValues?.clientCreditLimit}
          min={minValueLimit(0)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <DropdownFormField
          name="clientCreditLimitApproved"
          label={strings.CREDIT_LIMIT_APPROVED}
          options={[
            { value: '', label: '-' },
            { value: '1', label: strings.YES },
            { value: '0', label: strings.NO },
          ]}
          initialValue={getValueForDropdownWithEmptyOption(clientDefaultValues?.clientCreditLimitApproved)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
        <DropdownFormField
          name="clientGenerateInvoice"
          label={strings.GENERATE_INVOICE}
          options={[
            { value: '', label: '-' },
            { value: '1', label: strings.YES },
            { value: '0', label: strings.NO },
          ]}
          initialValue={getValueForDropdownWithEmptyOption(clientDefaultValues?.clientGenerateInvoice)}
          disabled={isUpdateClientDefaultsValuesLoading}
        />
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button
        variant="outlined"
        label={strings.CANCEL}
        disabled={isUpdateClientDefaultsValuesLoading}
        onClick={handleClose}
      />
      <Button
        id="btnCreateBladeCreateCompany"
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={isUpdateClientDefaultsValuesLoading}
        loading={isUpdateClientDefaultsValuesLoading}
      />
    </Container>
  )

  return (
    <Blade
      variant="persistent"
      open={showBlade}
      title={title}
      onClose={handleClose}
      loading={isUpdateClientDefaultsValuesLoading}
      bodyNode={bladeContent}
      footerNode={bladeFooter}
      hideBackdrop
    />
  )
}

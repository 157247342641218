import LoaderPage from 'components/ui/feedback/LoaderPage'
import { lazy } from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'

const InvoicesListPage = lazy(() => import('pages/invoices/page'))
const InvoicePreviewPage = lazy(() => import('pages/invoices/[invoiceId]/page'))

const InvoicesRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<InvoicesListPage />} />} />
      <Route path="/:invoiceNumber/:invoiceId" element={<LoaderPage content={<InvoicePreviewPage />} />} />
    </Routes>
  )
}

export default InvoicesRoutes

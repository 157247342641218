import { Toast } from 'components/ui'
import { useNavigate } from 'react-router'
import { ToastProps } from 'components/ui/feedback/Toast'
import { useQueryClient } from '@tanstack/react-query'
import { getTokenContent, isDefined } from 'tools/common'
import { useMemo, useContext, useCallback, useEffect } from 'react'
import { UserSessionContext } from 'context/UserSession/UserSessionContext'
import jwt_Decode from 'jwt-decode'
import useLocalStoragePayment from './useLocalStoragePayment'

let FLAG_IS_PENDO_INITIALIZED = false
let FLAG_IS_INTERCOM_INITIALIZED = false

// Flag to read session token from localStorage one time on first run
let FLAG_LOAD_TOKEN_ON_FIRST_RUN = false

const useUserSession = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setToken, loadToken, clearSession, updateUserInfo, updatePermissions, state } = useContext(UserSessionContext)
  const token = state.token
  const userInfo = state.userInfo
  const permissions = state.permissions
  const { getPaymentUserStorages, savePaymentStatus } = useLocalStoragePayment()

  useEffect(() => {
    if (isDefined(token) && isDefined(userInfo) === false) {
      updateUserInfo()
    }
  }, [token, userInfo, updateUserInfo])

  useEffect(() => {
    if (isDefined(userInfo) && isDefined(permissions) === false) {
      updatePermissions()
    }
  }, [userInfo, permissions, updatePermissions])

  const logout = (toastMessage?: ToastProps) => {
    const paymentStatuses = getPaymentUserStorages()
    queryClient.clear()
    localStorage.clear()
    savePaymentStatus(paymentStatuses)
    navigate('/login')
    if (toastMessage) Toast(toastMessage)
    clearSession()
    FLAG_IS_PENDO_INITIALIZED = false
    shutdownIntercom()
  }

  const loadTokenFromStorage = useCallback(() => {
    if (FLAG_LOAD_TOKEN_ON_FIRST_RUN) return
    loadToken()
    FLAG_LOAD_TOKEN_ON_FIRST_RUN = true
  }, [loadToken])

  const isAuth = useMemo(() => {
    if (typeof token === 'undefined') return null

    if (token === null) {
      return false
    }

    const decodedToken: any = jwt_Decode(token)

    if (decodedToken.userpriv === 0) {
      return false
    }

    const currentTime = Math.floor(Date.now() / 1000)
    const remainingTime = decodedToken.exp - currentTime

    if (remainingTime < 0) {
      return false
    }
    return true
  }, [token])

  const setLoginToken = useCallback(
    (token: string) => {
      setToken(token)
    },
    [setToken]
  )

  const initPendo = useCallback(() => {
    if (FLAG_IS_PENDO_INITIALIZED) return
    if (process.env.NODE_ENV === 'development') return

    // @ts-ignore
    if (pendo && token) {
      const tokenData = getTokenContent(token)

      // @ts-ignore
      pendo.initialize({
        visitor: {
          id: tokenData.userId,
          email: tokenData.sub,
          full_Name: tokenData.fullName,
        },
        account: {
          id: tokenData.factorId,
          name: tokenData.factorName,
        },
      })

      FLAG_IS_PENDO_INITIALIZED = true
    }
  }, [token])

  const initIntercom = useCallback(() => {
    if (FLAG_IS_INTERCOM_INITIALIZED) return
    if (process.env.NODE_ENV === 'development') return

    // @ts-ignore
    if (Intercom && token) {
      const tokenData = getTokenContent(token)

      // @ts-ignore
      Intercom('boot', {
        // @ts-ignore
        app_id: INTERCOM_APP_ID,
        email: tokenData.sub,
        name: tokenData.fullName,
        user_id: tokenData.userId,
      })

      FLAG_IS_INTERCOM_INITIALIZED = true
    }
  }, [token])

  function shutdownIntercom() {
    FLAG_IS_INTERCOM_INITIALIZED = false
    // @ts-ignore
    if (Intercom) {
      // @ts-ignore
      Intercom('shutdown')
    }
  }

  const refreshUserInfo = useCallback(() => {
    updateUserInfo()
    updatePermissions()
  }, [updateUserInfo, updatePermissions])

  return {
    /** use with caution */
    _loadTokenFromStorage: loadTokenFromStorage,
    /** use with caution */
    _setLoginToken: setLoginToken,
    /** use with caution */
    _initPendo: initPendo,
    /** use with caution */
    _initIntercom: initIntercom,
    /** use with caution */
    _token: token,

    /** boolean that indicates if the user is authenticated */
    isAuth,
    /** call this function to end the user session and redirect to login screen */
    logout,
    /** Info for the user, obtained fron get User Endpoint */
    userInfo: state.userInfo,
    /** Info for the user, obtained fron get User Endpoint */
    permissions: state.permissions,
    /** Refresh the user info */
    refreshUserInfo,
  }
}

export default useUserSession

import { ScrollSpySection } from 'components/ui'
import strings from 'l10n'
import { ift } from 'tools/common'
import { ActionButton } from 'types/common'
import { useState } from 'react'
import { SystemDefaultsSectionProps } from '../../SystemDefaultsComponent'
import styles from '../SystemDefaultsSections.module.scss'
import SectionField from 'components/ui/dataDisplay/SectionField'
import { VendorSystemDefaultUpdateBlade } from './VendorSystemDefaultUpdateBlade'

export const VendorSystemDefaults = (props: SystemDefaultsSectionProps) => {
  const { factorDefault, canModifyFactorDefaultValues } = props
  const [showUpdateBlade, setShowUpdateBlade] = useState<boolean>(false)
  const vendorPaymentFees = factorDefault?.vendorDefaultValues.vendorPaymentFees

  const btnEdit = ift(
    {
      label: strings.EDIT,
      variant: 'outlined',
      onClick: () => {
        setShowUpdateBlade(true)
      },
    },
    canModifyFactorDefaultValues
  ) as ActionButton

  return (
    <>
      <ScrollSpySection title={strings.VENDORS} actionBtn={btnEdit}>
        <div className={styles.sectionContentContainer}>
          <SectionField label={strings.ACH_FEE} value={vendorPaymentFees?.achFee} prefix="$" />
          <SectionField label={strings.WIRE_FEE} value={vendorPaymentFees?.wireFee} prefix="$" />
          <SectionField label={strings.CHECK_FEE} value={vendorPaymentFees?.checkFee} prefix="$" />
          <SectionField label={strings.FUEL_CARD_FEE} value={vendorPaymentFees?.fuelCardFee} prefix="$" />
          <SectionField label={strings.SAME_DAY_ACH_FEE} value={vendorPaymentFees?.sameDayAchFee} prefix="$" />
          <SectionField label={strings.MONEY_CODE_FEE} value={vendorPaymentFees?.moneyCodeFee} prefix="$" />
        </div>
        {factorDefault && (
          <VendorSystemDefaultUpdateBlade
            title={`${strings.UPDATE} ${strings.VENDOR_DEFAULTS_VALUES}`}
            vendorPaymentFees={vendorPaymentFees}
            handleClose={() => setShowUpdateBlade(false)}
            showBlade={showUpdateBlade}
          />
        )}
      </ScrollSpySection>
    </>
  )
}

import { FormProvider, useForm } from 'react-hook-form'

import { Blade, Container } from 'components/ui'
import styles from '../../SystemDefaultsSectionsUpdateBlade.module.scss'
import { TextFormField } from 'components/hookForm'
import strings from 'l10n'
import { maxValueLimit, minValueLimit } from 'tools/common'
import { integerPattern } from 'tools/patterns'
import { Button } from 'components/form'
import { useEffect } from 'react'
import { percentage, toDecPercent } from 'tools/format'
import { FactorDefaultQuickPayFeeInfo } from 'types/factorDefault'

type QuickPayFeesUpdateBladeProps = {
  title: string
  selectedQuickPayFee: FactorDefaultQuickPayFeeInfo
  handleClose: () => void
  showBlade: boolean
  handleSubmitted: (qp: FactorDefaultQuickPayFeeInfo) => void
  loading?: boolean
}

type QuickPayFeesUpdateBladeForm = {
  name: string
  brokerFee: number
  brokerRate: string
  days: number
  factorFee: number
  factorRate: string
}

export const QuickPayFeesUpdateBlade = (props: QuickPayFeesUpdateBladeProps) => {
  const { title, selectedQuickPayFee, handleClose, showBlade, handleSubmitted, loading } = props
  const form = useForm<QuickPayFeesUpdateBladeForm>()
  const { handleSubmit, setValue } = form

  useEffect(() => {
    setValue('name', selectedQuickPayFee.name)
    setValue('days', selectedQuickPayFee.days)
    setValue('factorRate', percentage(selectedQuickPayFee.factorRate))
    setValue('brokerRate', percentage(selectedQuickPayFee.brokerRate))
    setValue('factorFee', selectedQuickPayFee.factorFee)
    setValue('brokerFee', selectedQuickPayFee.brokerFee)
  }, [selectedQuickPayFee, setValue])

  const onSubmit = (data: QuickPayFeesUpdateBladeForm) => {
    const qp = {
      id: selectedQuickPayFee.id,
      name: data.name,
      days: data.days,
      factorRate: Number(toDecPercent(data.factorRate)),
      factorFee: data.factorFee,
      brokerRate: Number(toDecPercent(data.brokerRate)),
      brokerFee: data.brokerFee,
    }
    handleSubmitted(qp)
  }

  const bladeContent = (
    <FormProvider {...form}>
      <Container>
        <Container className={styles.twoRowFormat}>
          <TextFormField name="name" label={strings.NAME} required maxLength={20} disabled={false} />
          <TextFormField
            name="days"
            label={strings.DAYS}
            type="number"
            pattern={integerPattern}
            min={minValueLimit(0)}
            disabled={loading}
          />
          <TextFormField
            name="factorRate"
            label={strings.FACTOR_RATE}
            min={minValueLimit(0)}
            type="number"
            max={maxValueLimit(100)}
            startAdornment="%"
            disabled={loading}
          />
          <TextFormField
            name="brokerRate"
            label={strings.BROKER_RATE}
            min={minValueLimit(0)}
            type="number"
            max={maxValueLimit(100)}
            startAdornment="%"
            disabled={loading}
          />
          <TextFormField
            name="factorFee"
            label={strings.FACTOR_FEE}
            min={minValueLimit(0)}
            type="number"
            startAdornment="$"
            disabled={loading}
          />
          <TextFormField
            name="brokerFee"
            label={strings.BROKER_FEE}
            min={minValueLimit(0)}
            type="number"
            startAdornment="$"
            disabled={loading}
          />
        </Container>
      </Container>
    </FormProvider>
  )

  const bladeFooter = (
    <Container className={styles.actionContainer}>
      <Button variant="outlined" label={strings.CANCEL} onClick={handleClose} disabled={loading} />
      <Button
        variant="contained"
        label={strings.UPDATE}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        loading={loading}
      />
    </Container>
  )
  return (
    <>
      <Blade
        variant="persistent"
        open={showBlade}
        title={title}
        onClose={handleClose}
        bodyNode={bladeContent}
        footerNode={bladeFooter}
        loading={loading}
        hideBackdrop
      />
    </>
  )
}

import { useState } from 'react'
import OtpInput from 'react18-input-otp'
import { verifyOtp } from 'api/login'
import { Container, TypoGraph, Toast as toast } from 'components/ui'
import { useNavigate } from 'react-router-dom'
import { ObjectStrings } from 'interfaces'
import strings from 'l10n'
import { Button } from 'components/form'
import { DEFAULT_PATH } from 'routes'
import { VerifyOtpRequest } from 'types/login'
import { useMutation } from '@tanstack/react-query'
import useUserSession from 'hooks/useUserSession'

type MultifactorAuthProps = {
  styles: ObjectStrings
  formValue: {
    email: string
    password: string
  }
}

const MultifactorAuth = (props: MultifactorAuthProps) => {
  const { styles, formValue } = props
  const [otp, setOtp] = useState('')
  const navigate = useNavigate()
  const { _setLoginToken } = useUserSession()

  const { mutate: verifyOtpMutation, isLoading } = useMutation({
    mutationFn: (data: VerifyOtpRequest) => {
      return verifyOtp(data)
    },
    onSuccess: (resp) => {
      _setLoginToken(resp.token)
      localStorage.removeItem('factorcloud_first_token')
      toast({ title: strings.LOGGED_SUCCESSFULLY, type: 'success' })
      navigate(DEFAULT_PATH)
    },
  })

  const handleOtpVerify = () => {
    const payload: VerifyOtpRequest = {
      username: formValue.email,
      password: formValue.password,
      otpCode: otp,
    }
    verifyOtpMutation(payload)
  }

  return (
    <Container className={styles.formContainer} id="multiFactorAuthFormContainer">
      <TypoGraph
        id="messageOneTimeCodeSent"
        variant="body2"
        content={strings.MESSAGE_ONE_TIME_CODE_SENT}
        align="center"
      />
      <Container id="otpInputMultifactorAuthContainer" className={styles.otpContainer}>
        <OtpInput
          id="otpInputMultifactorAuth"
          value={otp}
          onChange={(val: string) => setOtp(val)}
          numInputs={6}
          inputStyle={styles.otpInput}
          isInputNum
        />
      </Container>
      <Container className={styles.buttonContainer}>
        <Button
          id="otpVerifyButton"
          disabled={otp?.length < 6 || isLoading}
          variant="contained"
          onClick={handleOtpVerify}
          loading={isLoading}
          label={strings.LOGIN}
        />
      </Container>
    </Container>
  )
}

export default MultifactorAuth

import { useState, useEffect, useCallback, useRef } from 'react'
import _ from 'lodash'
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridActionsCellItem,
  GridSelectionModel,
  GridSortModel,
  GridRowParams,
  GridFeatureMode,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FilterListIcon from '@mui/icons-material/FilterList'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import TextField, { TextFieldProps } from 'components/form/TextField'
import MultiSelectField, { IMultiSelectProps } from 'components/form/MultiSelectField'
import { dataGridSx } from 'constants/tableData'
import { Icon, TypoGraph, Container, Card, Popover, Tooltip } from 'components/ui'
import Popper from '@mui/material/Popper'
import { Button, DropdownField } from 'components/form'
import styles from './DataTable.module.scss'
import colors from 'theme/colors'
import strings from 'l10n'
import { CircularProgress, Fade, Paper, MenuList, MenuItem, ListItemText, Chip, Checkbox, Alert } from '@mui/material'
import SummaryComponent from './SummarySelectedTable'
import { SummaryComponentOptions } from 'types/common'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { AxiosResponseHeaders } from 'axios'
import { paginationLimitOptions } from 'constants/options'
import { utcDateFormat } from 'tools/format'

const tableCustomizeList = [
  {
    label: 'Column Customization',
    key: 'column',
  },
  {
    label: 'Density',
    key: 'density',
  },
]

/***************************
 * Private Components
 ***************************/
type CustomPaginationProps = {
  hasNextPage?: boolean
  pageNo: number
  loading?: boolean
  handleCustomPagination?: (pageNumber: number) => void
  customComponent?: React.ReactNode
  hidePagination?: boolean
  customFooter?: React.ReactNode
  paginationHeaders?: AxiosResponseHeaders
  paginationLimit?: number
  handlePaginationLimitChange?: (value: number) => void
}

function CustomPagination(props: CustomPaginationProps) {
  const {
    hasNextPage = false,
    pageNo,
    loading,
    handleCustomPagination,
    customComponent,
    hidePagination,
    customFooter,
    paginationHeaders,
    handlePaginationLimitChange,
    paginationLimit,
  } = props

  const totalRecords = Number(paginationHeaders?.['x-pagination-total-records'])
  const firstElementNumber = pageNo * Number(paginationLimit) + 1
  const totalPages = Number(paginationHeaders?.['x-pagination-total-pages'])

  useEffect(() => {
    if (pageNo > totalPages) {
      handleCustomPagination && handleCustomPagination(0)
    }
  }, [totalPages, pageNo, handleCustomPagination])

  const getLastElementNumber = () => {
    var lastElementNumber: number = pageNo * Number(paginationLimit) + Number(paginationLimit)
    if (lastElementNumber > totalRecords) {
      lastElementNumber = totalRecords
    }
    return lastElementNumber
  }

  const getRecordsCount = () => {
    if (totalRecords > 0) {
      return `${firstElementNumber}-${getLastElementNumber()} of ${totalRecords}`
    }
    return strings.NOT_RECORDS_FOUND
  }

  return (
    <Container>
      {customComponent && (
        <Container className={styles.detailContent}>{customComponent && <>{customComponent}</>}</Container>
      )}
      {customFooter && <>{customFooter}</>}
      <Container className={styles.paginationContainer}>
        {hidePagination === false && (
          <>
            <div className={styles.paginationSelector}>
              <TypoGraph className={styles.selectorLabel} content={`${strings.ROWS_PER_PAGE}:`} />
              <DropdownField
                className={styles.paginationDropdown}
                value={paginationLimit}
                options={paginationLimitOptions}
                inputProps={{ sx: { padding: '0 10px' } }}
                onChange={(e: any) => {
                  handlePaginationLimitChange && handlePaginationLimitChange(e.target.value)
                }}
              />
            </div>
            <TypoGraph className={styles.paginationNumberInformation} content={getRecordsCount()} />
            <div className={styles.paginationIconsContainer}>
              <Icon
                boxClassName={styles.paginationIcon}
                disabled={pageNo === 0 || loading}
                onClick={(_event) => handleCustomPagination && handleCustomPagination(pageNo - 1)}
              >
                <ChevronLeftIcon />
              </Icon>
              <Icon
                disabled={!hasNextPage || loading}
                onClick={(_event) => {
                  handleCustomPagination && handleCustomPagination(pageNo + 1)
                }}
              >
                <ChevronRightIcon />
              </Icon>
            </div>
          </>
        )}
      </Container>
    </Container>
  )
}

export type ToolbarButton = {
  id?: string
  label: string
  onClick: () => void
  variant?: 'text' | 'outlined' | 'contained'
  className?: string
  tooltip?: string
  tooltipClassName?: string
  disabled?: boolean
  color?: 'warning' | 'success' | 'error' | 'info'
  tooltipBackGroundColor?: string
  tooltipTextColor?: string
  tooltipArrowColor?: string
  tooltipMaxWidth?: number
}

type CustomToolbarProps = {
  actionButton?: ToolbarButton
  actionSecondButton?: ToolbarButton
  actionThirdButton?: ToolbarButton
  actionFourthButton?: ToolbarButton
}

function CustomToolbar(props: CustomToolbarProps) {
  const { actionButton, actionSecondButton, actionThirdButton, actionFourthButton } = props || {}
  return (
    <Container>
      {actionButton || actionSecondButton || actionThirdButton ? (
        <div className={styles.buttonContainer}>
          {actionFourthButton && (
            <Button
              label={actionFourthButton.label}
              variant={actionFourthButton.variant}
              onClick={actionFourthButton.onClick}
              className={`${styles.buttonHeader} ${actionFourthButton.className}`}
              tooltip={actionFourthButton.tooltip}
              tooltipClassName={actionFourthButton.tooltipClassName}
              disabled={actionFourthButton.disabled}
              color={actionFourthButton.color}
              tooltipBackGroundColor={actionFourthButton.tooltipBackGroundColor}
              tooltipTextColor={actionFourthButton.tooltipTextColor}
              tooltipArrowColor={actionFourthButton.tooltipArrowColor}
              tooltipMaxWidth={actionFourthButton.tooltipMaxWidth}
            />
          )}
          {actionThirdButton && (
            <Button
              label={actionThirdButton.label}
              variant={actionThirdButton.variant}
              onClick={actionThirdButton.onClick}
              className={`${styles.buttonHeader} ${actionThirdButton.className}`}
              tooltip={actionThirdButton.tooltip}
              tooltipClassName={actionThirdButton.tooltipClassName}
              disabled={actionThirdButton.disabled}
              color={actionThirdButton.color}
              tooltipBackGroundColor={actionThirdButton.tooltipBackGroundColor}
              tooltipTextColor={actionThirdButton.tooltipTextColor}
              tooltipArrowColor={actionThirdButton.tooltipArrowColor}
              tooltipMaxWidth={actionThirdButton.tooltipMaxWidth}
            />
          )}
          {actionSecondButton && (
            <Button
              label={actionSecondButton.label}
              variant={actionSecondButton.variant}
              onClick={actionSecondButton.onClick}
              className={`${styles.buttonHeader} ${actionSecondButton.className}`}
              tooltip={actionSecondButton.tooltip}
              tooltipClassName={actionSecondButton.tooltipClassName}
              disabled={actionSecondButton.disabled}
              color={actionSecondButton.color}
              tooltipBackGroundColor={actionSecondButton.tooltipBackGroundColor}
              tooltipTextColor={actionSecondButton.tooltipTextColor}
              tooltipArrowColor={actionSecondButton.tooltipArrowColor}
              tooltipMaxWidth={actionSecondButton.tooltipMaxWidth}
            />
          )}
          {actionButton && (
            <Button
              id={actionButton.id}
              label={actionButton.label}
              variant={actionButton.variant}
              onClick={actionButton.onClick}
              className={`${styles.buttonHeader} ${actionButton.className}`}
              tooltip={actionButton.tooltip}
              tooltipClassName={actionButton.tooltipClassName}
              disabled={actionButton.disabled}
              color={actionButton.color}
              tooltipBackGroundColor={actionButton.tooltipBackGroundColor}
              tooltipTextColor={actionButton.tooltipTextColor}
              tooltipArrowColor={actionButton.tooltipArrowColor}
              tooltipMaxWidth={actionButton.tooltipMaxWidth}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </Container>
  )
}

type CustomControllerProps = {
  controllerProp?: IFilterList
  filterValues?: { [key: string]: string | number | string[] }
  initialFilterValues?: { [key: string]: string | number | string[] }
  filters?: IFilterList[]
  handleFilterController: (e: any, name?: string, val?: any, index?: number) => void
}

const CustomController = (props: CustomControllerProps) => {
  const { controllerProp, filterValues, initialFilterValues, filters, handleFilterController } = props
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { type, label, id, dollarAdornment } = controllerProp || {}
  const value: any = filterValues && id ? filterValues[id] ?? initialFilterValues?.[id] : undefined
  const filterMap = new Map(filters?.map((item) => [item.id, item])) // Create a Map of filter items for easy lookup
  const filterList = id && filterMap.get(id) // Get the filter details based on the key
  let selectedOption: any = []
  // let selectedOption: DropDownOptions | string[] | string | undefined = []
  if (filterList && filterList?.type === 'dropdown') {
    console.log('filterList', filterList, value)
    if (Array.isArray(value)) {
      selectedOption = [...value]
    } else {
      selectedOption = filterList.dropdownProps?.options?.find((item: any) => item.value === value) // Find the selected option
    }
  }
  const [dataRangeStart, setDataRangeStart] = useState<string>(type === 'dateRange' && value ? value[0] : '')
  const [dataRangeEnd, setDataRangeEnd] = useState<string>(type === 'dateRange' && value ? value[1] : '')
  const [numberRangeMin, setNumberRangeMin] = useState<number>(type === 'numberRange' && value ? value[0] : 0)
  const [numberRangeMax, setNumberRangeMax] = useState<number>(type === 'numberRange' && value ? value[1] : 0)

  useEffect(() => {
    // Check if the inputRef and its current property exist before focusing

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 300)
  }, [inputRef]) // Empty dependency array ensures this effect runs only once on mount

  switch (type) {
    case 'text':
      return (
        <TextField
          label={label}
          onChange={(e: any) => handleFilterController(e, id)}
          value={value}
          inputRef={inputRef}
          {...controllerProp?.textfieldProps}
        />
      )
    case 'date':
      return (
        <TextField
          type="date"
          label={label}
          onChange={(e: any) => handleFilterController(e, id)}
          value={value}
          inputRef={inputRef}
          {...controllerProp?.textfieldProps}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    case 'dateRange':
      return (
        <>
          <TextField
            type="date"
            label={`Start`}
            onChange={(e: any) => {
              setDataRangeStart(e.target.value)
              handleFilterController(e, id, e.target.value, 0)
            }}
            value={value ? value[0] : ''}
            inputRef={inputRef}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: dataRangeEnd ? dataRangeEnd : '',
            }}
          />
          <TextField
            type="date"
            label={`End`}
            onChange={(e: any) => {
              setDataRangeEnd(e.target.value)
              handleFilterController(e, id, e.target.value, 1)
            }}
            value={value ? value[1] : ''}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: dataRangeStart ? dataRangeStart : '',
            }}
          />
        </>
      )
    case 'numberRange':
      return (
        <>
          <TextField
            type="number"
            startAdornment={dollarAdornment ? '$' : undefined}
            label={`Min`}
            onChange={(e: any) => {
              setNumberRangeMin(e.target.value)
              handleFilterController(e, id, e.target.value, 0)
            }}
            onBlur={(e: any) => {
              if (Number(e.target.value) > numberRangeMax) {
                setNumberRangeMax(e.target.value)
                handleFilterController(e, id, e.target.value, 1)
              }
            }}
            value={numberRangeMin}
            inputProps={{
              min: 0,
            }}
          />
          <TextField
            type="number"
            startAdornment={dollarAdornment ? '$' : undefined}
            label={`Max`}
            onChange={(e: any) => {
              setNumberRangeMax(e.target.value)
              handleFilterController(e, id, e.target.value, 1)
            }}
            onBlur={(e: any) => {
              if (Number(e.target.value) < numberRangeMin) {
                setNumberRangeMin(e.target.value)
                handleFilterController(e, id, e.target.value, 0)
              }
            }}
            value={numberRangeMax}
            inputProps={{
              min: 0,
            }}
          />
        </>
      )
    case 'dropdown':
      return (
        <MultiSelectField
          label={label}
          onChange={(e: any, val: any) => handleFilterController(e, id, val)}
          value={selectedOption}
          multiple={false}
          disableCloseOnSelect={false}
          disableClearable={true}
          openOnFocus={true}
          inputRef={inputRef}
          {...controllerProp?.dropdownProps}
        />
      )
    default:
      return <></>
  }
}

const ChipLabel = (props: any) => {
  const { option } = props
  return (
    <Container className={styles.chipLabelContainer}>
      <TypoGraph content={option.label} className={styles.chipLabel} />
      <TypoGraph content={option.chipCondition} className={styles.chipCondition} />
      <TypoGraph content={` ‘${option.chipValue}’`} className={styles.chipValue} />
    </Container>
  )
}

/***************************
 * Main Components
 ***************************/
type ActionList = {
  icon: string
  name: string
  className?: string // Optional CSS class for customization
  iconSize?: number // Optional size for the icon
  selection?: boolean // Indicates if the action involves selection
}

export type IFilterList = {
  id: string
  label: string
  type: 'dropdown' | 'text' | 'date' | 'dateRange' | 'numberRange' | string // Type of filter: dropdown, text, date, dateRange, numberRange or custom
  dollarAdornment?: boolean // Start adornment for number range
  dropdownProps?: IMultiSelectProps // Additional props specific to dropdown filters
  textfieldProps?: TextFieldProps // Additional props specific to text field filters
}

type DataTableProps = {
  /** The loading is a boolean value that determines whether the table should display a loading indicator or not. When loading is set to true, the table will display a loading indicator until the data is loaded and rendered in the table.*/
  loading?: boolean
  /** The columns is an array of objects that defines the columns of the table. Each object in the array represents a column and has the following properties: field, headerName, width, type,valueFormatter, renderCell, sortable, filterable,editable, cellClassName, headerClassName, align and hide.*/
  columns: GridColDef[]
  /** The searchValue is used to control the value of the search field in the CustomToolbar component. When the user types in the search field, the handleQuickSearch function is called with the new value.*/
  searchValue?: string
  /** The data is an array of objects that represents the data to be displayed in the table. Each object in the array represents a row in the table, and the keys in the object represent the columns in the table.*/
  data: GridRowsProp
  /** The totalCount is used to specify the total number of rows in the table. This prop is used in conjunction with the paginationMode prop to determine how the table should handle pagination.*/
  totalCount?: number
  /** The title is a string that specifies the title of the table. It is displayed in the top left corner of the table, above the column headers. */
  title?: string
  /** The defaultPageSize is a number that sets the default number of rows to be displayed per page in the table. */
  defaultPageSize?: number
  /** The checkboxSelection is a boolean prop that determines whether or not to show a checkbox column on the left side of each row in the table.*/
  checkboxSelection?: boolean
  /** The paginationMode is used to determine how pagination is handled in the DataTable component. It can be set to either 'client' or 'server'.*/
  paginationMode?: 'client' | 'server'
  /** The actionList is an array of objects that defines the list of actions that can be performed on each row of the table. Each object in the array represents an action and has the following properties: icon, name, className and selection.*/
  actionList?: ActionList[]
  /** The actionButton is an optional prop to display a button alongside the search */
  actionButton?: ToolbarButton
  /**  */
  actionSecondButton?: ToolbarButton
  /** The currentPageSize is used to set the current page size of the table. It is an optional prop that can be passed to the DataTable component.*/
  actionThirdButton?: ToolbarButton
  // Additional button option
  actionFourthButton?: ToolbarButton
  // Additional button option
  openActionButton?: ToolbarButton
  // List of filters
  filters?: IFilterList[]
  // Current page size
  currentPageSize?: number
  // Reduces table container height by a specified amount
  reduceHeight?: number
  // Additional loading indicator
  loadingFetching?: boolean

  /** The density is used to set the density of the table. It is a string that can take one of three values: 'compact', 'standard', or 'comfortable'.*/
  density?: 'compact' | 'standard' | 'comfortable'
  /** The hasNextPage is used to indicate whether there are more pages of data to be loaded in a paginated table.*/
  hasNextPage?: boolean
  /** The pageNo is used to specify the current page number of the table. It is an optional prop that defaults to 0 if not provided.*/
  pageNo?: number
  /** The debounceTime is used to adjust the default time (850 ms) for the search process. This parameter determines how quickly or slowly the search is executed when a new value is received. */
  debounceTime?: number
  /** The getRowId is a function that is used to get the unique identifier for each row in the table. It takes a row object as an argument and returns a string or number that represents the ID of the row. */
  getRowId?: (row: any) => string
  /** The handleQuickSearch is a function that is called when the user types in the search field of the table. It takes a single argument, which is the current value of the search field. */
  handleQuickSearch?: (value: string) => void
  /** The handleCheckboxSelection is a function that is called when the user selects or deselects a row in the table using the checkbox selection feature.*/
  handleCheckboxSelection?: (selections: GridSelectionModel) => void

  handleCheckboxRowSelection?: (selections: any, ids: string[]) => void
  /** The handleActions  is a function that is called when the user clicks on an action button in a row of the table. It takes two arguments: id and name.*/
  handleActions?: (id: string | number, name: string) => void
  /** The handleSelectionActions is a function that is called when the user selects one or more rows in the table and clicks on an action button in the toolbar.*/
  handleSelectionActions?: (name: string, selectionModel: GridSelectionModel) => void
  /** The handlePagination is a function that is called when the user changes the page size or navigates to a different page in the table.*/
  handlePagination?: (pageCount: number, pageSize: number) => void
  /** The handleCustomPagination is a function that is called when the user selects a specific page number in case of custom pagination. This function will be passed the page number as argument.*/
  handleCustomPagination?: (pageNumber: number) => void
  // Function for filter submission
  onFilterSubmit?: (value: { [key: string]: string | number | string[] }) => void
  // Summary component options
  summaryContent?: SummaryComponentOptions[]
  // Option to clear checkbox selection
  clearCheckBoxSelection?: boolean
  // Function to reset checkbox selection
  resetClearCheckBoxSelection?: () => void
  // Function for sort model changes
  onSortModelChange?: (model: GridSortModel) => void
  // Custom toolbar for the table
  toolBar?: React.ReactNode
  // Custom empty message component
  emptyRowOverlay?: React.ReactNode
  /* The allRecordsAccrossPagesSelection is a boolean prop that determines whether or not to show the option to select all records across all pages in the table.*/
  allRecordsAccrossPagesSelection?: boolean
  /* The handleAllRecordsAccrossPagesSelection is a function that is called when the user selects or deselects all records across all pages in the table.*/
  handleAllRecordsAccrossPagesSelection?: (selection: boolean) => void
  // Boolean to enable search filtering
  searchFilter?: boolean
  // Boolean to enable pagination
  pagination?: boolean
  // Boolean to disable free search
  disableFreeSearch?: boolean
  // Boolean to show the toolbar
  showToolbar?: boolean
  // Name of the selected row
  nameRowSelected?: string
  // Boolean to disable density selector
  disableDensitySelector?: boolean
  // Boolean to disable export function
  disableExportFunction?: boolean
  // Boolean to hide the footer
  hideFooter?: boolean
  // Minimum height for the table
  minHeight?: number
  // Option to keep non-existent rows selected
  keepNonExistentRowsSelected?: boolean
  // Boolean to hide the filter bar
  hideFilterbar?: boolean
  // Message to display when no rows exist
  noRowsMessage?: string
  // Initial values for filters
  initialFilterValues?: { [key: string]: string | number | string[] }
  // Method to validate if some row is selectable
  isRowSelectable?: (params: GridRowParams) => boolean
  // Tooltip for disabled checkbox
  disabledCheckBoxTooltipText?: string

  customComponent?: React.ReactNode

  sortingMode?: GridFeatureMode

  infoMessage?: string

  initialState?: GridInitialStateCommunity
  //Boolean to hide pagination inside the CustomPagination component. This not hide a customFooter or customComponent.
  hidePagination?: boolean
  customFooter?: React.ReactNode
  // Pagination headers. Util to get values from the headers
  paginationHeaders?: AxiosResponseHeaders
  // Pagination limit. How many record per page
  paginationLimit?: number
  hideSelectAllCheckbox?: boolean
  // Function to handle pagination limit change
  handlePaginationLimitChange?: (value: number) => void
  handleVisibilityChange?: (model: GridColumnVisibilityModel) => void
  txtFilterId?: string
  titleId?: string
  initialRowsSelected?: string[]
}

const DataTable = (props: DataTableProps) => {
  const {
    loading, // shows the loader inside the table
    searchValue,
    filters,
    currentPageSize,
    hasNextPage = false,
    loadingFetching,
    pageNo = 0,
    infoMessage,
    data, // array of datas which will be paried from the column name
    totalCount, // table total count
    paginationMode = 'client', // you can change the paginationMode to handle pagination in server side or client side
    columns, // colums with array of objects
    title = '', // shows the title in top of the table
    defaultPageSize = 21,
    checkboxSelection = true, // To show or hide the checkbox selection in table
    actionList, // list of actions items to show while hover the row
    actionButton,
    openActionButton,
    actionSecondButton,
    actionThirdButton,
    actionFourthButton,
    reduceHeight = 280,
    minHeight,
    density = 'standard',
    hideFilterbar = false,
    emptyRowOverlay,
    allRecordsAccrossPagesSelection,
    handleAllRecordsAccrossPagesSelection,
    handleActions, // func to handle the actions
    handleCheckboxSelection, // func to handle the checkbox selection
    handleCheckboxRowSelection,
    handlePagination, // func to handle the pagination
    handleQuickSearch,
    handleCustomPagination, // handles custom pagination
    getRowId,
    debounceTime = 850,
    summaryContent = [],
    clearCheckBoxSelection = false,
    resetClearCheckBoxSelection = () => {},
    onSortModelChange,
    onFilterSubmit,
    pagination = true,
    disableDensitySelector = false,
    hideFooter = false,
    keepNonExistentRowsSelected = false,
    disableFreeSearch = false,
    noRowsMessage,
    initialFilterValues = {},
    isRowSelectable,
    disabledCheckBoxTooltipText = '',
    customComponent,
    sortingMode = 'client',
    initialState,
    hidePagination = false,
    customFooter,
    paginationHeaders,
    paginationLimit,
    hideSelectAllCheckbox = false,
    handlePaginationLimitChange,
    handleVisibilityChange,
    titleId,
    txtFilterId,
    initialRowsSelected,
  } = props

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [customColumns, setCustomColumns] = useState(() => columns)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [inputVal, setInputVal] = useState('')
  const [tablePopover, setTablePopover] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filterListPopper, setFilterListPopper] = useState(null)
  const [filterPopper, setFilterPopper] = useState(null)
  const [key, setMultiKey] = useState<number>(0)
  const [filterValues, setFilterValues] = useState<any>({
    freeSearches: searchValue || null,
    filters: initialFilterValues,
  })
  const [tempFilterValues, setTempFilterValues] = useState<any>({ ...initialFilterValues })
  const [multiSelectValues, setMultiSelectValues] = useState<any>([])
  const [activeFilter, setActiveFilter] = useState<IFilterList>()
  const [filtersList, setFiltersList] = useState<IFilterList[]>()
  const [showSelectAllRecords, setShowSelectAllRecords] = useState<boolean>(false)
  const [allRecordsSelected, setAllRecordsSelected] = useState<boolean>(false)
  const selectionModelRef = useRef(selectionModel)

  const showFilterList = filters && filters?.length > 0
  const totalRecords = Number(paginationHeaders?.['x-pagination-total-records'])

  let timeoutId: ReturnType<typeof setTimeout>

  useEffect(() => {
    setCustomColumns(columns)
  }, [columns])

  useEffect(() => {
    setFiltersList(filters)
  }, [filters])

  useEffect(() => {
    selectionModelRef.current = selectionModel
  }, [selectionModel])

  useEffect(() => {
    if (initialRowsSelected && initialRowsSelected.length > 0) {
      const allSelectedRows = [...selectionModelRef.current, ...initialRowsSelected]
      setSelectionModel(allSelectedRows)
    }
  }, [initialRowsSelected])

  useEffect(() => {
    const values = [] // Initialize an array to store filter values
    const { freeSearches, filters: filterValue } = filterValues // Destructure filterValues for easier access

    if (filterValue) {
      const filterMap = new Map(filters?.map((item) => [item.id, item])) // Create a Map of filter items for easy lookup

      Object.entries(filterValue).forEach(([key, value]) => {
        const filterList = filterMap.get(key) // Get the filter details based on the key

        let filterVal = '' // Initialize a variable to store filter value information

        if (filterList?.type === 'dropdown') {
          if (Array.isArray(value)) {
            //const selectedOption = filterList.dropdownProps?.options?.find((i) => i.value === item.value) // Find the selected option
            //filterVal = selectedOption?.label || '' // Store the label of the selected option
            values.push({
              label: filterList.label,
              value: value,
              key: filterList?.id,
              chipCondition: 'is',
              chipValue: value.map((item: any) => item.label).join(', '),
            })
          } else {
            // Check if the filter type is 'dropdown'
            const selectedOption = filterList.dropdownProps?.options?.find((item) => item.value === value) // Find the selected option
            filterVal = selectedOption?.label || '' // Store the label of the selected option
            // Create an object with label, id, and key for the selected filter option and add it to the values array
            values.push({
              label: filterList.label,
              value: value,
              key: filterList?.id,
              chipCondition: 'is',
              chipValue: filterVal,
            })
          }
        }

        if (filterList?.type === 'dateRange' && Array.isArray(value)) {
          const [startDate, endDate] = value
          if (startDate) {
            values.push({
              label: `${filterList.label} Start`,
              value: startDate,
              key: `${filterList.id}_start`,
              chipKey: filterList.id,
              chipCondition: 'from',
              chipValue: utcDateFormat(startDate as string),
              range: endDate ? 'closed' : 'opened',
            })
          }
          if (endDate) {
            values.push({
              label: `${filterList.label} End`,
              value: endDate,
              key: `${filterList.id}_end`,
              chipKey: filterList.id,
              chipCondition: 'to',
              chipValue: utcDateFormat(endDate as string),
              range: startDate ? 'closed' : 'opened',
            })
          }
        }

        if (filterList?.type === 'numberRange' && Array.isArray(value)) {
          const [minNumber, maxNumber] = value
          if (minNumber) {
            values.push({
              label: `${filterList.label} Min`,
              value: minNumber,
              key: `${filterList.id}_min`,
              chipKey: filterList.id,
              chipCondition: 'from',
              chipValue: minNumber as number,
              range: maxNumber ? 'closed' : 'opened',
            })
          }
          if (maxNumber) {
            values.push({
              label: `${filterList.label} Max`,
              value: maxNumber,
              key: `${filterList.id}_max`,
              chipKey: filterList.id,
              chipCondition: 'to',
              chipValue: maxNumber as number,
              range: minNumber ? 'closed' : 'opened',
            })
          }
        }

        if (filterList?.type === 'text' || filterList?.type === 'date') {
          // Check if the filter type is 'text' or 'date'
          // Create an object with label, id, and key for the text or date filter and add it to the values array
          values.push({
            label: filterList.label,
            value: value,
            key: filterList?.id,
            chipCondition: filterList?.type === 'text' ? 'is' : 'on',
            chipValue: filterList?.type === 'date' ? utcDateFormat(value as string) : value,
          })
        }
      })
    }

    if (freeSearches) {
      // Check if freeSearches has a value
      // Create an object with label, id, and key for the free search and add it to the values array
      values.push({
        label: 'Record',
        value: freeSearches,
        key: 'freeSearch',
        chipCondition: 'contains',
        chipValue: freeSearches,
      })
    }
    const filterKeys = new Set(values.map((item) => item.key))
    const result = filters && filters.filter((item) => !filterKeys.has(item.id))
    setFiltersList(result)
    setMultiSelectValues(values) // Update the state with the collected filter values
  }, [filterValues, filters]) // Run this effect whenever filterValues or filters change

  /*
   * function to build list of action cells
   * {param} id => will contain the row ID and will be passed to handleActions
   * ${return} => list of action array
   */
  const buildActionData = useCallback(
    (id: number | string) => {
      const actionItems = actionList?.map((item) => {
        return (
          <GridActionsCellItem
            icon={
              <Tooltip title={item.name}>
                <Icon name={item?.icon} size={item?.iconSize || 25} />
              </Tooltip>
            }
            label={item ? item.name : 'no data'}
            className={`actionButton ${item?.className}`}
            onClick={() => handleActions && handleActions(id, item?.name)}
            color="inherit"
          />
        )
      })
      return actionItems
    },
    [actionList, handleActions]
  )

  useEffect(() => {
    if (clearCheckBoxSelection) {
      setSelectionModel(initialRowsSelected ?? [])
      resetClearCheckBoxSelection()
    }
  }, [clearCheckBoxSelection, resetClearCheckBoxSelection, initialRowsSelected])

  useEffect(() => {
    if (totalRecords > data.length && selectionModel?.length === data.length) {
      if (!showSelectAllRecords) setShowSelectAllRecords(true)
    } else {
      if (showSelectAllRecords) setShowSelectAllRecords(false)
      if (allRecordsSelected) {
        setAllRecordsSelected(false)
        handleAllRecordsAccrossPagesSelection && handleAllRecordsAccrossPagesSelection(false)
      }
    }
  }, [
    data,
    totalRecords,
    selectionModel,
    allRecordsSelected,
    showSelectAllRecords,
    handleAllRecordsAccrossPagesSelection,
  ])

  /**
   * This effect is used to merge the action object with columns
   * This logic will only if we list of actions from the props
   */
  useEffect(() => {
    const action = {
      field: 'actions',
      type: 'actions',
      width: 0,
      cellClassName: 'actions',
      getActions: ({ id }: { id: string | number }) => buildActionData(id),
    }
    const actionsLength = actionList?.length
    if (actionsLength) {
      setCustomColumns([...columns, action])
    }
  }, [actionList, columns, buildActionData])

  useEffect(() => {
    if (currentPageSize) {
      setPageSize(currentPageSize)
    }
  }, [currentPageSize])

  const handleDebounceSearch = _.debounce((value: string) => {
    handleQuickSearch && handleQuickSearch(value ? value : '')
  }, debounceTime)

  const handleFilterKey = (val: IFilterList) => {
    setActiveFilter(val)
    setFilterPopper(filterListPopper)
    setFilterListPopper(null)
  }

  const handleTableCustomize = (val: any) => {
    setTablePopover(null)
  }

  // Function called when Clicking to apply free search
  const handleRecordVal = (val: string) => {
    setFilterValues((prev: any) => ({
      filters: prev.filters,
      freeSearches: val,
    }))
    handleDebounceSearch(val)
    setInputVal('')
    setAnchorEl(null)
    setMultiKey(Math.random() * 1000)
  }

  const handleFilterController = (e: any, name?: string, val?: any, index?: number) => {
    if (val && typeof index !== 'undefined') {
      handleDateRangeFilterController(index, val, name)
    } else {
      let storedValue: any = undefined
      if (val) {
        if (val?.value) {
          storedValue = val?.value
        } else if (Array.isArray(val)) {
          storedValue = val
        }
      }
      if (typeof storedValue === 'undefined') {
        storedValue = e.target.value
      }

      name &&
        setTempFilterValues((prev: any) => ({
          ...prev,
          [name]: storedValue,
        }))
    }
  }

  const handleDateRangeFilterController = (index: number, val: any, name?: string) => {
    if (name) {
      let filter = tempFilterValues[name] || []
      filter[index] = val
      setTempFilterValues((prev: any) => ({
        ...prev,
        [name]: filter,
      }))
    }
  }

  const handleFilterSubmit = (filter?: IFilterList) => {
    const filterVal = filter && tempFilterValues[filter?.id]
    const id = filter?.id
    if (filterVal && id) {
      setFilterValues((prev: any) => ({
        ...prev,
        filters: { ...prev.filters, [id]: filterVal },
      }))
      setTempFilterValues((prev: any) => ({
        ...prev,
        [id]: filterVal,
      }))
      setFilterPopper(null)
      onFilterSubmit && onFilterSubmit({ ...filterValues.filters, [id]: filterVal })
    }
    setFilterPopper(null)
  }

  const handleFilterRemove = (id: string) => {
    if (id) {
      setFilterValues((prev: any) => {
        const { [id]: _, ...restFilters } = prev.filters
        return {
          ...prev,
          filters: restFilters,
        }
      })
      setTempFilterValues((prev: any) => {
        const { [id]: _, ...restTempFilters } = prev
        return restTempFilters
      })
      onFilterSubmit && onFilterSubmit({ ...filterValues.filters, [id]: undefined })
    }
    setFilterPopper(null)
  }

  const handleFreeSearchFilterRemove = (id: string) => {
    if (id) {
      setFilterValues((prev: any) => {
        return {
          ...prev,
          freeSearches: '',
        }
      })
      setTempFilterValues((prev: any) => {
        return {
          ...prev,
          freeSearches: '',
        }
      })
      handleDebounceSearch('')
      onFilterSubmit && onFilterSubmit({ ...filterValues.filters, freeSearches: '' })
    }
    setFilterPopper(null)
  }

  const handleDateRangeFilterRemove = (id: string, index: number, range: string) => {
    if (id) {
      setFilterValues((prev: any) => {
        let updatedFilters = { ...prev.filters }
        if (range === 'closed') {
          updatedFilters[id][index] = undefined
          return {
            ...prev,
            filters: updatedFilters,
          }
        } else {
          const { [id]: _, ...restFilters } = prev.filters
          return {
            ...prev,
            filters: restFilters,
          }
        }
      })
      if (range === 'opened') {
        setTempFilterValues((prev: any) => {
          const { [id]: _, ...restTempFilters } = prev
          return restTempFilters
        })
        onFilterSubmit && onFilterSubmit({ ...filterValues.filters, [id]: undefined })
      } else {
        onFilterSubmit && onFilterSubmit({ ...filterValues.filters })
      }
    }
    setFilterPopper(null)
  }

  const handleTextFieldChange = (e: any, val: any) => {
    const { freeSearches, filters: filterValue = {} } = filterValues
    const filterVal: any = filterValue
    let searchVal = null

    // Check if the 'val' array contains a single string
    if (val?.length === 1 && typeof val[0] === 'string') {
      searchVal = val[0] // Assign the string to 'searchVal'
    } else if (filterValue || freeSearches) {
      if (Object.entries(filterValue)?.length === 0 && freeSearches) {
        val?.forEach((item: any) => {
          if (!item?.value) {
            searchVal = item // Assign the item to 'searchVal' if it doesn't have an 'id'
          }
        })
      }
      // Loop through each entry in 'filterValue'
      Object.entries(filterValue).forEach(([key, value]) => {
        // Check each item in 'val'
        val?.forEach((item: any) => {
          if (item?.value === value && item?.key === key) {
            filterVal[key] = value // Assign the value to 'filterVal' under the respective key
          } else if (item?.key === 'freeSearch') {
            searchVal = freeSearches // Assign 'freeSearches' to 'searchVal'
          } else if (!item?.value) {
            searchVal = item // Assign the item to 'searchVal' if it doesn't have an 'id'
          }
        })
      })
    }

    // Update 'filterValues' state with the new filter and search values
    setFilterValues({
      filters: filterVal,
      freeSearches: searchVal,
    })
    setTempFilterValues({ ...initialFilterValues, ...filterVal })

    // Perform the search operation using 'searchVal' or an empty string if 'searchVal' is falsy
    handleDebounceSearch(searchVal || '')

    // Reset UI states
    setAnchorEl(null)
    handleFilterOpen(e.target)

    // Invoke the 'onFilterSubmit' callback if it exists, passing the 'filterVal'
    onFilterSubmit?.(filterVal)
  }

  const clearFilters = () => {
    setFilterValues({
      filters: {},
      freeSearches: '',
    })
    onFilterSubmit?.({})
    handleDebounceSearch('')
  }

  const handleFilterOpen = (e: any, quickOpen?: boolean) => {
    if (filtersList && filtersList?.length > 0) {
      setFilterListPopper(e.target)
    }
    setAnchorEl(null)
  }

  const handleChipClick = (e: any, selectedText: string) => {
    if (selectedText === 'Record') return
    const selectedKey = multiSelectValues?.find((item: any) => item?.label === selectedText)
    const key = selectedKey?.chipKey ? selectedKey?.chipKey : selectedKey?.key
    const filterKey = filters?.find((item) => item?.id === key)
    if (filterKey) {
      setActiveFilter(filterKey)
      setFilterPopper(e.target)
    }
  }

  const handleChipDelete = (option: any) => {
    const key = option.key
    if (key.includes('_start')) {
      handleDateRangeFilterRemove(key.replace('_start', ''), 0, option.range)
    } else if (key.includes('_min')) {
      handleDateRangeFilterRemove(key.replace('_min', ''), 0, option.range)
    } else if (key.includes('_end')) {
      handleDateRangeFilterRemove(key.replace('_end', ''), 1, option.range)
    } else if (key.includes('_max')) {
      handleDateRangeFilterRemove(key.replace('_max', ''), 1, option.range)
    } else if (key === 'freeSearch') {
      handleFreeSearchFilterRemove(key)
    } else {
      handleFilterRemove(key)
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setFilterPopper(null)
      }
    }

    const handleWindowBlur = () => {
      setFilterPopper(null)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('blur', handleWindowBlur)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('blur', handleWindowBlur)
    }
  }, [])

  const isSearchFilters = multiSelectValues?.length > 0

  const showPagination = () => {
    //If hidePagination is true, that means we are not showing the pagination on custom pagination, but the custom pagination must be shown if Pagination is true.
    //Pagination should be false, for example, on selection mode.
    if (hidePagination) {
      return pagination
    } else {
      //If hidePagination is false, that means we are showing the pagination on custom pagination, but the custom component must be shown if Pagination is true
      //and paginationHeaders and paginationLimit must be present to avoid bad behavior on rendering.
      return pagination && paginationHeaders && paginationLimit
    }
  }

  const onHandleVisibilityChange = (model: GridColumnVisibilityModel) => {
    handleVisibilityChange && handleVisibilityChange(model)
  }

  const handleSelectAllRecordsClick = () => {
    const selection: boolean = !allRecordsSelected
    setAllRecordsSelected(selection)
    handleAllRecordsAccrossPagesSelection && handleAllRecordsAccrossPagesSelection(selection)
    if (!selection) {
      setSelectionModel([])
      setShowSelectAllRecords(false)
      handleCheckboxRowSelection && handleCheckboxRowSelection([], [])
      handleCheckboxSelection && handleCheckboxSelection([])
    }
  }

  return (
    <Card
      sxContainer={{
        border: 'none',
        boxShadow: '0px 4px 56px -16px #00040d29',
        borderRadius: '8px',
      }}
      sxContent={{
        padding: 0,
        paddingBottom: '0!important',
      }}
    >
      <Container className={styles.tableHeaderBarContainer}>
        {title && (
          <Container sx={{ padding: '1rem' }}>
            <TypoGraph variant="h2" content={title} id={titleId} />
          </Container>
        )}
        {selectionModel && selectionModel?.length > 0 ? (
          <CustomToolbar
            actionButton={actionButton}
            actionSecondButton={actionSecondButton}
            actionThirdButton={actionThirdButton}
            actionFourthButton={actionFourthButton}
          />
        ) : (
          openActionButton && (
            <Container>
              <Button
                label={openActionButton.label}
                variant="outlined"
                onClick={openActionButton.onClick}
                className={styles.buttonHeader}
              />
            </Container>
          )
        )}
      </Container>
      {allRecordsAccrossPagesSelection && showSelectAllRecords && (
        <Container className={styles.selectAllRecordsInAllPagesContainer}>
          <TypoGraph
            variant="body1"
            content={
              !allRecordsSelected
                ? `${strings.STATUS.ALL.TYPE_4} ${paginationLimit} ${strings.RECORDS_ON_THIS_PAGE_ARE_SELECTED}`
                : `${strings.STATUS.ALL.TYPE_4} ${totalRecords} ${strings.RECORDS_ARE_SELECTED}`
            }
          />
          <TypoGraph
            variant="body1"
            className={styles.selectAllRecordsInAllPages}
            content={
              !allRecordsSelected
                ? `${strings.SELECT} ${totalRecords} ${strings.RECORDS_ACROSS_ALL_PAGES}`
                : strings.CLEAR_SELECTION
            }
            onClick={() => handleSelectAllRecordsClick()}
          />
        </Container>
      )}
      {infoMessage && <Alert severity="info">{infoMessage}</Alert>}
      {!hideFilterbar && (
        <Container className={`${styles.filterContainer} ${!showFilterList && styles.hideFilterIcon}`}>
          <Container className={styles.moreContainer}>
            {!showFilterList && loadingFetching ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                onClick={(e: any) => {
                  setAnchorEl(null)
                  setFilterListPopper(null)
                }}
              >
                <MoreVertIcon htmlColor="grey" />
              </IconButton>
            )}
          </Container>
          {showFilterList && (
            <Container className={styles.filterIconContainer}>
              {loadingFetching ? (
                <CircularProgress size={20} />
              ) : (
                <Tooltip title="Filter">
                  <IconButton onClick={(e: any) => handleFilterOpen(e, true)}>
                    <Badge badgeContent={multiSelectValues?.length} color="error">
                      <FilterListIcon htmlColor="grey" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </Container>
          )}
          <Container>
            <MultiSelectField
              txtInputId={txtFilterId}
              label=""
              key={key}
              options={[]}
              value={multiSelectValues}
              tooltipTitle="Clear Filter"
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    id={option.key}
                    label={<ChipLabel option={option} />}
                    onClick={(e: any) => handleChipClick(e, option.label)}
                    onDelete={() => handleChipDelete(option)}
                    clickable
                  />
                ))
              }}
              freeSolo={true}
              placeholder={showFilterList ? strings.SEARCH_AND_FILTER : strings.SEARCH}
              className={styles.noFieldset}
              readOnlyInput={disableFreeSearch}
              onInputChange={(e: any, val: any) => {
                setInputVal(val)
                setAnchorEl(val ? e.target : null)
                setFilterListPopper(null)
                setFilterPopper(null)
                clearTimeout(timeoutId)
              }}
              onChange={(e: any, val) => {
                handleTextFieldChange(e, val)
              }}
              onOpen={(e: any) => {
                handleFilterOpen(e)
              }}
              onClose={(e) =>
                setTimeout(() => {
                  setFilterListPopper(null)
                }, 300)
              }
            />
          </Container>
        </Container>
      )}
      <Box
        sx={{
          height: minHeight ? `${minHeight}px` : `calc(100vh - ${reduceHeight}px)`,
          width: '100%',
        }}
      >
        <DataGrid
          sx={dataGridSx}
          hideFooter={hideFooter}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          rows={data}
          onSortModelChange={(model) => {
            onSortModelChange && onSortModelChange(model)
          }}
          sortingMode={sortingMode}
          initialState={initialState}
          density={density}
          columns={customColumns}
          onColumnVisibilityModelChange={(model) => onHandleVisibilityChange(model)}
          isRowSelectable={isRowSelectable}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize)
            handlePagination && handlePagination(currentPage, newPageSize)
          }}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          loading={loading}
          paginationMode={paginationMode}
          rowCount={totalCount}
          onPageChange={(page, details) => {
            setCurrentPage(page)
            handlePagination && handlePagination(page, pageSize)
          }}
          disableDensitySelector={disableDensitySelector}
          components={{
            NoRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '50%',
                  margin: 'auto',
                  flexDirection: 'column',
                }}
              >
                {emptyRowOverlay ?? (
                  <>
                    {' '}
                    <Icon name={isSearchFilters ? 'icon_search' : 'icon_emptyRecords'} size={100} />
                    {noRowsMessage ? (
                      <TypoGraph variant="body1" content={noRowsMessage} color={colors.primary} />
                    ) : (
                      <Container className={styles.emptyResultContainer}>
                        {isSearchFilters ? (
                          <>
                            <TypoGraph variant="body1" content={strings.NO_RESULTS} />
                            <TypoGraph variant="subtitle1" sx={{ fontSize: '14px' }} content={strings.ADJUST_SEARCH} />
                            <Button className={styles.clearBtn} label={strings.CLEAR_FILTER} onClick={clearFilters} />
                          </>
                        ) : (
                          <TypoGraph variant="body1" content={strings.NO_RECORDS} color={colors.primary} />
                        )}
                      </Container>
                    )}
                  </>
                )}
              </Box>
            ),
            BaseCheckbox(props) {
              const { indeterminate, ...other } = props
              return (
                <Tooltip
                  title={other.disabled ? disabledCheckBoxTooltipText : ''}
                  maxWidth={201}
                  backgroundColor="#ffffff"
                  arrow
                  arrowColor="#ffffff"
                  textColor="#000000"
                  placement="right"
                >
                  <Checkbox id="tableCheckboxId" indeterminate={indeterminate} {...other} />
                </Tooltip>
              )
            },

            Pagination: () =>
              showPagination() ? (
                <CustomPagination
                  hasNextPage={hasNextPage}
                  loading={loading}
                  pageNo={pageNo}
                  handleCustomPagination={handleCustomPagination}
                  customComponent={customComponent}
                  hidePagination={hidePagination}
                  customFooter={customFooter}
                  paginationHeaders={paginationHeaders}
                  paginationLimit={paginationLimit}
                  handlePaginationLimitChange={handlePaginationLimitChange}
                />
              ) : (
                <>{customFooter}</>
              ),
          }}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
            const selectedRowObjects = newSelectionModel.map((id) => data.find((row) => row.id === id))
            setSelectionModel(newSelectionModel)
            handleCheckboxSelection && handleCheckboxSelection(newSelectionModel)
            handleCheckboxRowSelection &&
              handleCheckboxRowSelection(
                selectedRowObjects.filter((element) => element !== undefined),
                newSelectionModel.map((id) => id.toString())
              )
          }}
          selectionModel={selectionModel}
          disableColumnFilter
          disableSelectionOnClick
          getRowId={(row) => (getRowId ? getRowId(row) : row.id)}
          className={`${styles.MuiDataGrid} ${hideSelectAllCheckbox && 'fc-hide-header-checkbox'}`}
        />
      </Box>
      <Box>{!pagination && summaryContent?.length > 0 && <SummaryComponent content={summaryContent} />}</Box>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{
          position: 'relative',
          zIndex: 8,
          boxShadow: '0px 4px 16px 0px rgba(33, 33, 33, 0.08)',
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        }}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ boxShadow: 'none' }}>
              <MenuList>
                <MenuItem onClick={() => handleRecordVal(inputVal)}>
                  <ListItemText>
                    <span className={styles.recordContains}>Records contains</span>{' '}
                    <span className={styles.recordValue}>‘{inputVal}’</span>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
      {/** Filter List Popover */}
      <Popper
        open={Boolean(filterListPopper)}
        anchorEl={filterListPopper}
        sx={{
          position: 'relative',
          zIndex: 8,
          boxShadow: '0px 4px 16px 0px rgba(33, 33, 33, 0.08)',
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        }}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ boxShadow: 'none' }}>
              <MenuList className={styles.filterListContainer}>
                {filtersList?.map((item, index) => (
                  <MenuItem onClick={() => handleFilterKey(item)} key={`key-${index}`}>
                    <ListItemText>{item.label}</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
      {/** Filter controller Popover */}
      <Popover open={Boolean(filterPopper)} anchorEl={filterPopper} onClose={() => setFilterPopper(null)}>
        <Container className={styles.filterController}>
          <Container className={styles.headerBar}>
            <TypoGraph content={activeFilter?.label} />
            <IconButton onClick={() => setFilterPopper(null)}>
              <CloseIcon />
            </IconButton>
          </Container>
          <Container className={styles.controller}>
            <CustomController
              controllerProp={activeFilter}
              filterValues={tempFilterValues}
              initialFilterValues={initialFilterValues}
              filters={filters}
              handleFilterController={handleFilterController}
            />
          </Container>
          <Container className={styles.submitButton}>
            <Button
              label={strings.APPLY}
              variant="text"
              tooltipClassName={styles.button}
              onClick={() => handleFilterSubmit(activeFilter)}
            />
          </Container>
        </Container>
      </Popover>
      {/* Table customization popover ex: column hide/show, density, download options  */}
      <Popover
        open={Boolean(tablePopover)}
        anchorEl={tablePopover}
        onClose={() => {
          setTablePopover(null)
        }}
      >
        <MenuList>
          {tableCustomizeList?.map((item, index) => (
            <MenuItem onClick={() => handleTableCustomize(inputVal)} key={`key-${index}`}>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Card>
  )
}

export default DataTable

import { Route, RouteProps, Routes } from 'react-router-dom'
import { lazy } from 'react'
import LoaderPage from 'components/ui/feedback/LoaderPage'

const Companies = lazy(() => import('pages/companies/page'))
const CompanyPreviewPage = lazy(() => import('pages/companies/[companyId]/page'))

const CompaniesRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<LoaderPage content={<Companies />} />} />
      <Route path="/:companyName/:companyId" element={<LoaderPage content={<CompanyPreviewPage />} />} />
    </Routes>
  )
}

export default CompaniesRoutes

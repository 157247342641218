const colors = {
  primary: '#00040d', // brand primary
  secondary: '#eef7f7', // brand secondary

  // Feedback colors
  error: '#F9635E',
  success: '#5ABFAE',
  warning: '#F5B529',

  // Status colors
  new: '#8BB8E2',
  que: '#97E6AC',
  held: '#F5B529',
  //waiting: '#F2B90C', // very close to 'warning' need to understand utility

  // let's review and discuss color implements and nomenclature
  //subTitle1: '#636363',
  //body: '#F8F8F8',
  //greyOld: 'rgb(102, 102, 102)',
  //black: 'black',
  //white: '#FFFFFF',

  // Grey tones (Dark -> Light)
  black: '#000', // (use sparingly: when everything is black, nothing is.)
  midnight: '#212121',
  charcoal: '#333333',
  grey: '#696969', // default body text WCAG compliance
  silver: '#d8d8d8',
  platinum: '#ededed',
  paper: '#fff', // body background
  white: '#fff',

  grey10: '#F5F7FA',
  
  grey70: '#707A8B',

  red60: '#DE1B1B',
  green60: '#2C844E',
}

export default colors

import { TypoGraph, Container, Card } from 'components/ui'
import strings from 'l10n'
import styles from './NotFoundPage.module.scss'

const NotFoundPage = () => {
  return (
    <Container className={styles.container}>
      <Card sxContainer={{ width: 400, margin: 'auto' }} sxContent={{ textAlign: 'center' }}>
        <TypoGraph variant="h1" align="center">
          {strings.PAGE_404_TITLE}
        </TypoGraph>
        <TypoGraph align="center">{strings.PAGE_404_SUBHEADER}</TypoGraph>
        <TypoGraph variant="caption" align="center">
          {strings.PAGE_404_MESSAGE}
        </TypoGraph>
      </Card>
    </Container>
  )
}

export default NotFoundPage

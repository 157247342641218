import { Dialog as MuiDialog, DialogContent } from '@mui/material'
import { Container, TypoGraph } from 'components/ui'
import { Button } from 'components/form'
import { ButtonProps } from 'components/form/Button'
import strings from 'l10n'

type DialogProps = {
  /** The open is a boolean value that determines whether the Dialog component is open or closed. When open is set to true ,the dialog box is displayed on the screen.*/
  open: boolean
  /** The fullWidth is a boolean indicating whether the dialog should take up the full width of its container. Defaults to true.*/
  fullWidth?: boolean
  /** The action is a boolean indicating whether to render the action buttons at the bottom of the dialog. Defaults to true. */
  action?: boolean
  /** The maxWidth is an optional string representing the maximum width of the dialog. Defaults to "sm".  */
  maxWidth?: 'sm' | 'xs' | 'md' | 'lg' | 'xl'
  /** The title is an optional string that represents the title of the dialog box.*/
  title?: string
  /** The className is an optional string representing the class name to be applied to the dialog. */
  className?: string
  /** The contentClassName is an optional string representing the class name to be applied to the content of the dialog. */
  contentClassName?: string
  /** The content is an optional React node representing the content of the dialog. */
  content?: React.ReactNode
  /** The children is a special prop in React that allows components to pass arbitrary children to other components. */
  children?: React.ReactNode
  /** The primaryBtnProps is an object that contains props to be passed down to the primary button rendered by the Dialog component. */
  primaryBtnProps?: ButtonProps
  /** The secondaryBtnProps is an optional object containing props to be passed down to the secondary button. */
  secondaryBtnProps?: ButtonProps
  /** The handleClose is an optional function to be called when the dialog is closed. */
  handleClose?: () => void
}

const Dialog = (props: DialogProps) => {
  const {
    open,
    title,
    children,
    content,
    primaryBtnProps,
    secondaryBtnProps,
    action = true,
    fullWidth = true,
    contentClassName,
    maxWidth = 'sm',
    handleClose,
  } = props
  return (
    <MuiDialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
      onClose={handleClose}
    >
      <DialogContent className={contentClassName}>
        {title && <TypoGraph variant="h2" content={title} />}
        {children || content}
        {action && (
          <Container
            sx={{
              display: 'grid',
              gridGap: '1rem',
              gridTemplateColumns: 'auto auto',
              justifyContent: 'end',
              padding: '1rem 0',
            }}
          >
            <Button
              label={strings.CANCEL}
              variant="text"
              fullWidth={false}
              onClick={handleClose}
              {...secondaryBtnProps}
            />
            {primaryBtnProps && <Button {...primaryBtnProps} />}
          </Container>
        )}
      </DialogContent>
    </MuiDialog>
  )
}

export default Dialog

import { Typography } from '@mui/material'
import styles from './SectionField.module.scss'
import { Link, To } from 'react-router-dom'
import { isDefined } from 'tools/common'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from './Tooltip'

type SectionFieldProps = {
  /** The label prop to display the "title" of the Section Field */
  label: string
  /** The value prop to display the "content" of the Section "Field" */
  value?: string | number
  /** The prefix is a optional prop to display a prefix with the "value" */
  prefix?: string
  /** The suffix is a optional prop to display a prefix with the "value" */
  suffix?: string
  /** The labelClassName is a optional prop to use a custom styles in addition to main styles of the "label"*/
  labelClassName?: string
  /** The valueClassName is a optional prop to use a custom styles in addition to main Style of the "value"*/
  valueClassName?: string
  /** The link is a optional prop to use a "value" as a link*/
  link?: boolean
  /** The "path" that the "link" will be use to redirect*/
  path?: To

  tooltip?: string

  tooltipColor?: 'disabled' | 'inherit' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

  lastRowElement?: boolean

  valueId?: string
}

const SectionField = (props: SectionFieldProps) => {
  const { label, value, prefix, suffix, labelClassName, valueClassName, link, path, lastRowElement, tooltip, valueId, tooltipColor } =
    props
  const defaultPath: To = ''

  let fValue = '-'
  if (value || value === 0) {
    fValue = '' + value
    if (prefix) fValue = prefix + fValue
    if (suffix) fValue = fValue + suffix
  }

  const stylesClassname = lastRowElement ? styles.sectionFieldLastRow : styles.sectionFields
  const labelTruncateClassName = labelClassName ? labelClassName + ' ' + styles.labelTruncate : styles.labelTruncate
  const infoIconColor = tooltipColor || 'disabled';

  return (
    <div className={stylesClassname}>
      <Typography variant="h6" className={`${styles.sectionContentRowLabel} ${labelTruncateClassName}`}>
        <span className={labelClassName}> {label} </span>
        {tooltip && (
          <Tooltip title={tooltip} margin="0">
            <InfoIcon color={infoIconColor} sx={{ fontSize: '1rem', marginLeft: '0.3rem', marginTop: '0.3rem' }} />
          </Tooltip>
        )}
      </Typography>
      <Typography variant="body1" className={`${styles.sectionContentRowValue} ${styles.preLine}`}>
        {link && isDefined(value) ? (
          <Link to={path || defaultPath} className={valueClassName}>
            {fValue}
          </Link>
        ) : (
          <span className={valueClassName} id={valueId}>
            {' '}
            {fValue}{' '}
          </span>
        )}
      </Typography>
    </div>
  )
}

export default SectionField

import { useEffect, useState } from 'react'
import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Icon from 'components/ui/dataDisplay/Icon'
import { InputBaseProps, SxProps, Theme } from '@mui/material'

export type TextFieldProps = {
  /** The id is the unique identifier of the text field. It's usually used if you need to associate a label with an input field.*/
  id?: string
  /** The label variable is the label of the input field that is displayed above it*/
  label?: string
  /** The type defines the input type for the text field (text, password or number).*/
  type?: 'password' | 'number' | 'text' | 'date' | 'hidden'
  /** The variant is the visual style of the text field (outlined, filled, or standard).*/
  variant?: 'outlined' | 'filled' | 'standard'
  /** The defaultValue is used to set the initial value of the input field when it is first rendered. It is similar to the value prop, but it is only used to set the initial value and does not update the value of the input field when it changes.*/
  defaultValue?: string | number
  /** The value is used to set the current value of the input field and can be updated dynamically based on user input or other events.*/
  value?: string | number
  /** The InputLabelProps is used to pass additional props to the InputLabel component that is rendered above the input field. The InputLabel component is used to display a label for the input field, and it is typically used to describe the purpose of the input field or to provide instructions to the user.*/
  InputLabelProps?: object
  /** The placeholder variable is the placeholder that appears inside the input when it's empty.*/
  placeholder?: string
  /** The readOnly specifies whether the text field is editable or read-only.*/
  readOnly?: boolean
  /** The required is used to indicate whether the input field is required or not. If the required prop is set to true, the input field will be marked as required and the user will not be able to submit the form without filling in the input field.*/
  required?: boolean
  /** The disabled variable if true, the text field is disabled and cannot be interacted with, otherwise the text field is enabled and can be interacted with.*/
  disabled?: boolean
  /** The helperText is a Optional text that is displayed below the text field to provide additional information.*/
  helperText?: string
  /** The error indicates whether the text field has errors and, if so, a specific error message will be displayed.*/
  error?: boolean
  /** The multiline is used to indicate whether the input field should allow multiple lines of text or not.*/
  multiline?: boolean
  /** The rows is a number of rows that appear in the text field if multiline is true.*/
  rows?: number
  /** The inputRef is used to pass a reference to the underlying input element of the TextField component. This can be useful if you need to access the input element directly.*/
  inputRef?: any
  /** The select Is used to indicate whether the TextField component should be displayed as a select element or not. If the select property is true the TextField component will be displayed as a selection element, which allows the user to choose from a list of predefined options.*/
  select?: boolean
  /** The fullWidth is used to indicate whether the TextField component should take up the full width of its container or not.*/
  fullWidth?: boolean
  /** The showPasswordIcon is used to indicate whether the TextField component should display a password toggle icon or not.*/
  showPasswordIcon?: boolean
  /** The children is a special prop in React that is used to pass child elements to a component. In the case of the TextField component, the children prop is used to pass child elements that will be rendered inside the input field.*/
  children?: React.ReactNode
  /** The startAdornment is used to add a custom element or component to the beginning of the input field in the TextField component. This can be useful if you want to add a prefix or an icon to the input field.*/
  startAdornment?: React.ReactNode
  /** The endAdornment is used to add a custom element or component to the end of the input field in the TextField component. This can be useful if you want to add a suffix or an icon to the input field.*/
  endAdornment?: React.ReactNode
  /** The className is a standard prop in React that is used to add a CSS class to the TextField component.*/
  className?: string
  /** The autoFocus is used to indicate whether the TextField component should be focused automatically when the component is mounted or not.*/
  autoFocus?: boolean
  size?: 'medium' | 'small'
  /** The onChange is used to pass the handleChange function, which updates the state of the parent component whenever the value of the TextField component changes.*/
  onChange?: (e: React.ChangeEvent) => void
  /** The onBlur is a function that is called when the TextField component loses focus. This function is typically used to perform some action when the user moves away from the input field, such as validating the input or updating the state of the parent component.*/
  onBlur?: (e: React.ChangeEvent) => void

  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  avoidOnKeyDown?: boolean

  inputProps?: InputBaseProps['inputProps']

  onKeyDownType?: 'number' | 'text' | 'numberAndText' | 'numberTextSpecial' | 'numberDash'

  sx?: SxProps<Theme>

  endAdormentMarginTop?: string
}

const TextField = (props: TextFieldProps) => {
  const {
    id,
    label = '',
    type = 'text',
    variant = 'outlined',
    size = 'medium',
    defaultValue,
    value,
    InputLabelProps = {},
    placeholder,
    readOnly = false,
    required = false,
    disabled = false,
    helperText,
    error = false,
    multiline = false,
    rows = 4,
    inputRef,
    select = false,
    fullWidth = false,
    showPasswordIcon = false,
    children,
    startAdornment = '',
    endAdornment = '',
    className,
    autoFocus = false,
    onChange = () => {},
    onBlur,
    color,
    avoidOnKeyDown = false,
    onKeyDownType,
    inputProps,
    sx,
    endAdormentMarginTop,
  } = props
  const [customType, setCustomType] = useState<'password' | 'number' | 'text' | 'date' | 'hidden'>(type)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    if (type === 'password') {
      setShowPassword(true)
    }
  }, [type])

  useEffect(() => {
    if (showPassword) {
      setCustomType('text')
    } else if (showPasswordIcon) {
      setCustomType('password')
    } else {
      setCustomType(type)
    }
  }, [showPassword, showPasswordIcon, type])

  return (
    <MuiTextField
      id={id}
      className={className}
      fullWidth={fullWidth}
      size={size}
      placeholder={placeholder}
      type={customType}
      select={select}
      inputRef={inputRef}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      variant={variant}
      required={required}
      helperText={helperText}
      disabled={disabled}
      error={error}
      multiline={multiline}
      inputProps={inputProps}
      rows={rows}
      InputLabelProps={InputLabelProps}
      margin="normal"
      onWheel={(event: any) => {
        event.target.blur()
      }}
      sx={sx}
      onKeyDown={
        avoidOnKeyDown || type === 'text'
          ? (evt) => {
              if (
                evt.key === 'Backspace' ||
                evt.key === 'Delete' ||
                evt.key.includes('Arrow') ||
                evt.key.includes('Tab') ||
                evt.key === ' ' ||
                (evt.key === 'c' && (evt.metaKey || evt.ctrlKey)) ||
                (evt.key === 'v' && (evt.metaKey || evt.ctrlKey))
              ) {
                return
              }
              if (onKeyDownType === 'number') {
                !/^[0-9()+\-#*.]+$/.test(evt.key) && evt.preventDefault()
              } else if (onKeyDownType === 'numberAndText') {
                !/^[a-zA-Z\d]+$/.test(evt.key) && evt.preventDefault()
              } else if (onKeyDownType === 'text') {
                !/^[a-zA-Z]+$/.test(evt.key) && evt.preventDefault()
              } else if (onKeyDownType === 'numberTextSpecial') {
                !/^[A-Za-z0-9-.]+$/.test(evt.key) && evt.preventDefault()
              }
            }
          : type === 'number'
          ? (evt) => {
              if (
                evt.key === 'Backspace' ||
                evt.key === 'Delete' ||
                evt.key.includes('Arrow') ||
                evt.key.includes('Tab') ||
                evt.key === ' '
              ) {
                return
              }
              !/^[0-9.]+$/.test(evt.key) && evt.preventDefault()
            }
          : (evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
      }
      InputProps={{
        readOnly: readOnly,
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: (showPasswordIcon || endAdornment) && (
          <InputAdornment position="end">
            {showPasswordIcon ? (
              <Icon onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Icon>
            ) : (
              <div style={{ marginTop: endAdormentMarginTop }}>{endAdornment}</div>
            )}
          </InputAdornment>
        ),
      }}
      autoFocus={autoFocus}
      label={label}
      color={color}
      SelectProps={{
        sx: {
          // Si hay adornment, mueve la flecha
          '& .MuiSelect-icon': {
            marginRight: endAdornment ? '22px' : '0px', // Ajusta según la posición del Adornment
            transition: 'margin-right 0.3s ease',
          },
        },
      }}
    >
      {children}
    </MuiTextField>
  )
}

export default TextField

export const documentsEn = {
  //BEGIN Michael
  DOCUMENT: 'Document',
  DOCUMENTS: 'Documents',
  DOCUMENT_TYPE: 'Document Type',
  OTHER: 'Other',
  EXPIRATION_DATE: 'Expiration Date',
  INFO_ALL_DOCS_SAME: 'All the files will be uploaded with the same document type and expiration date.',
  OTHER_FUNCTION_LINKED: 'This document type is linked to other functions.',
  NOA_WARNING:
    'A client can only have one NOA active at any given time. If you upload a new NOA document, the previous documents will be deactivated. If you upload more than one document with the NOA Type, only the last document will be kept active.',
  ONLY_PDF: 'Only PDF files are allowed.',
  ARCHIVED: 'Archived',
  ARCHIVED_DOCUMENT: 'Archived Document',
  ARCHIVED_DOCUMENTS: 'Archived Documents',
  PREVIEW_DOCUMENT: 'Preview Document',
  DOWNLOAD_DOCUMENT: 'Download Document',
  EDIT_DOCUMENT: 'Edit Document',
  DOCUMENT_UPDATED_SUCCESSFULLY: 'Document updated successfully!',
  //END Michael
  //BEGIN Fco
  FILE_SIZE: 'File Size',
  DOWNLOAD_URL: 'Download URL',
  DRAG_AND_DROP: 'Drag and drop files or click upload',
  DROP_HERE: 'Drop the files here!',
  UPLOAD_FILES: 'Upload Files',
  SUBMIT_FILES: 'Submit Files',
  INFO_DUPLICATED_DOCS: 'Duplicated files will be omitted during the upload process.',
  UPLOAD_WARNING: 'Do not close the screen while the files are being uploaded.',
  ONLY_PDF_FILES: 'Only PDF files.',
  ALL_TYPE_ALLOWDED: 'PDF, PNG and JPG',
  //END Fco
  //BEGIN Fabian
  DRAG_AND_DROP_SINGLE: 'Drag & drop a file here, or click to select a file.',
  //END Fabian
  //BEGIN Elias
  INFO_MAX_FILE_SIZE: "Attachments can't exceed 25MB total.",
  ERROR_FILE_SIZE_EXCEEDED_TITLE: 'Attachments size limit exceeded',
  ERROR_FILE_SIZE_EXCEEDED:
    'Uploading this file will cause the total size of your files to exceed the 25 MB upload limit. Please ensure that your combined document is 25 MB or smaller. Consider compressing your file or removing unnecessary content to reduce its size before attempting to upload again.',
  //END Elias
  // START patricio
  ONLY_CSV_FILE_RESTRICTION: 'CSV File (Import limit: 5,000 rows per file, one file at a time.)',
  // END patricio
}

export const documentsEs: typeof documentsEn = {
  //BEGIN Michael
  DOCUMENT: 'Documento',
  DOCUMENTS: 'Documentos',
  DOCUMENT_TYPE: 'Tipo de Documento',
  OTHER: 'Otro',
  EXPIRATION_DATE: 'Fecha de Expiración',
  INFO_ALL_DOCS_SAME: 'Todos los archivos serán subidos con el mismo Tipo de Documento y Fecha de Expiración.',
  OTHER_FUNCTION_LINKED: 'Este tipo de documento está vinculado a otras funciones.',
  ONLY_PDF: 'Solo se permiten archivos PDF.',
  ONLY_PDF_FILES: 'Sólo archivos PDF.',
  ALL_TYPE_ALLOWDED: 'PDF, PNG y JPG',
  NOA_WARNING:
    'Un Cliente solo puede tener un documento NOA activo a la vez. Si subes un nuevo documento NOA, los anteriores serán desactivados. Si subes más de un documento con el Tipo NOA, solo el último documento se mantendrá activo.',
  ARCHIVED: 'Archivado',
  ARCHIVED_DOCUMENT: 'Documento Archivado',
  ARCHIVED_DOCUMENTS: 'Documentos Archivados',
  PREVIEW_DOCUMENT: 'Vista Previa del Documento',
  DOWNLOAD_DOCUMENT: 'Descargar Documento',
  EDIT_DOCUMENT: 'Editar Documento',
  DOCUMENT_UPDATED_SUCCESSFULLY: '¡Documento actualizado exitosamente!',
  //END Michael
  //BEGIN Fco
  FILE_SIZE: 'Tamaño de Archivo',
  DOWNLOAD_URL: 'URL de Descarga',
  DRAG_AND_DROP: 'Arrastra uno o más archivos aquí, o haz click para seleccionar archivos.',
  DROP_HERE: '¡Suelta los archivos aquí!',
  UPLOAD_FILES: 'Subir Archivos',
  SUBMIT_FILES: 'Enviar Archivos',
  INFO_DUPLICATED_DOCS: 'Documentos duplicados serán ignorados al momento de ser subidos.',
  UPLOAD_WARNING: 'No cierres esta ventana mientras los archivos se están subiendo.',
  //END Fco
  //BEGIN Fabian
  DRAG_AND_DROP_SINGLE: 'Arrastra un archivo aquí, o haz click para seleccionar un archivo.',
  //END Fabian
  //BEGIN Elias
  INFO_MAX_FILE_SIZE: 'Los archivos adjuntos no pueden exceder 25MB en total.',
  ERROR_FILE_SIZE_EXCEEDED_TITLE: 'Tamaño de adjuntos excedido',
  ERROR_FILE_SIZE_EXCEEDED:
    'Subir este archivo hará que el tamaño total de sus archivos exceda el límite de 25 MB. Por favor asegurése que el tamaño combinado de sus documentos sea 25 MB o menos. Considere comprimir el archivo o remover contenido innecesario para reducir su tamaño antes de intentar subirlo de nuevo.',
  //END Elias
  // START patricio
  ONLY_CSV_FILE_RESTRICTION: 'Archivo CSV (Límite de importación: 5,000 filas por archivo, un archivo a la vez.)',
  // END patricio
}

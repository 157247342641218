import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Container, TypoGraph } from 'components/ui'

export type CheckBoxProps = {
  /** The checked indicates whether the checkbox is checked.*/
  checked?: boolean
  /** The label represents the label that will be displayed next to the checkbox. */
  label?: string
  /** The onChange is a function that will be called when the checkbox is toggled, taking as argument a change event object from React and returning nothing. */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** The labelVariant represents the variant of the label text, with allowed values including different heading sizes ('h1' to 'h6'), subtitles, body text, button text, captions, and overline. */
  labelVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'overline'
  /** The defaultChecked indicate whether the checkbox is initially checked by default. */
  defaultChecked?: boolean
  /** The value indicate the value associated with the checkbox. */
  value?: boolean
  /** The inputRef is used to reference the underlying HTML input element and to access and manipulate the input element. */
  inputRef?: any
  /** The labelPlacement indicates where the label should appear in relation to the checkbox, with allowed values including top, bottom, left and right. */
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start'
  /** The checkboxSize ss a number indicating the size of the checkbox (in pixels).*/
  checkboxSize?: number
  /** The color indicates the color scheme to be used for the checkbox, with allowed values including default, primary, secondary, error, information, success and warning. */
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  /** The disable indicates if the checkbox is disabled and the user cannot interact with it. */
  disabled?: boolean
  /** The onClick is a function that will be called when the checkbox is clicked, taking as argument a change event object from React and returning nothing. */
  onClick?: () => void
  /** The name represents the name of the checkbox. */
  name?: string
  /** The labelClassName represents the class name of the label. */
  labelClassName?: string
  /** The checkBoxClassName represents the class name of the checkbox. */
  checkBoxClassName?: string
  /** The ref is used to reference the underlying HTML input element and to access and manipulate the input element. */
  ref?: any
  /** The sx prop allows for passing custom CSS styles to the container component. */
  sx?: Object
  /** The formControlLabelSx prop allows for passing custom CSS styles to the FormControlLabel component. */
  formControlLabelSx?: Object
  uncheckedBackgroundColor?: string
}

const CheckBoxField = (props: CheckBoxProps) => {
  const {
    checked = false,
    onChange,
    onClick,
    label,
    labelVariant = 'body1',
    defaultChecked,
    value,
    inputRef,
    checkboxSize = 28,
    labelPlacement = 'end',
    color = 'primary',
    disabled,
    name,
    labelClassName,
    checkBoxClassName,
    ref,
    sx = {},
    formControlLabelSx,
    uncheckedBackgroundColor = 'transparent',
  } = props

  return (
    <Container
      sx={
        sx
          ? sx
          : {
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
            }
      }
    >
      <FormControlLabel
        control={
          <Checkbox
            className={checkBoxClassName}
            defaultChecked={defaultChecked}
            ref={ref}
            sx={{
              '& .MuiSvgIcon-root': checkboxSize ? { fontSize: checkboxSize } : '',
              '&:not(.Mui-checked) .MuiSvgIcon-root': {
                backgroundColor: uncheckedBackgroundColor, // Set background for unchecked
                borderRadius: '4px', // Optional: match checkbox shape
              },
              padding: '0 !important',
              paddingLeft: '9px !important',
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            checked={checked || value}
            onChange={onChange}
            onClick={onClick}
            inputRef={inputRef}
            color={color}
            name={name}
          />
        }
        label={<TypoGraph variant={labelVariant} content={label} className={labelClassName} />}
        labelPlacement={labelPlacement}
        disabled={disabled}
        sx={formControlLabelSx}
      />
    </Container>
  )
}

export default CheckBoxField

export const paymentsEn = {
  // BEGIN Michael
  PAYMENT: 'Payment',
  PAYMENTS: 'Payments',
  PAYMENT_PREVIEW: 'Payment Preview',
  PAYMENT_CREATED_SUCCESSFULLY: 'Payment created successfully',
  PAYMENT_ASSIGN_SUCCESSFULLY: 'Payment assigned successfully',
  PAYMENT_WARNING_MESSAGE:
    'Total Invoices Payment must be equal to the payment amount. Please adjust the invoice balances and try again.',
  REMITTER: 'Remitter',
  PAYMENT_DETAILS: 'Payment Details',
  TOTAL_PAYMENT: 'Payment Total',

  OVER_MESSAGE: 'Warning! Total Invoices Payment exceeds Payment Total',
  UNDER_MESSAGE: 'Warning! Total Invoices Payment is less than Payment Total',
  EQUALS_MESSAGE: 'The total balance is equal than the payment amount',

  DEBTOR_FILTER_INFO: 'Filtering by invoices of the same debtor',

  VALIDATION_MAX_MESSAGE: 'The amount must be equal or less than the Invoice balance ',
  CLICK_HERE_TO_SEE_PAYMENT: 'Click here to see the payment.',
  NUMBER_OF_DOCUMENTS: 'N° Documents',
  DOCUMENTS_ADDED_PAYMENT: 'All documents were successfully added to the payment.',
  PAYMENT_APPLICATION_CONFIRMATION: 'Are you sure that you want to apply this payment to these invoices?',
  ADD_SPLIT: 'Add Split',
  CANCELLED: 'Cancelled',
  CANCEL_PAYMENT: 'Cancel Payment',
  CANCEL_PAYMENTS_SUCCESS: 'Payments cancelled successfully',
  CONFIRM_CANCELLATION: 'Confirm Cancellation',
  CANCEL_DIALOG_FIRST_BODY_TEXT: 'Are you sure you want to cancel those payments?',
  UNABLE_TO_SELECT_PAYMENT_APPLIED: 'Unable to select payment when in Applied state.',
  UNABLE_TO_SELECT_PAYMENT_CANCELLED: 'Unable to select payment when in Cancelled state.',
  UNABLE_TO_CANCEL_PAYMENT_NOT_UNAPPLIED: 'Unable to cancel payment when not in Unapplied state.',
  UNABLE_TO_SELECT_PAYMENT: 'Unable to select payment, not actions available.',
  IMPORT_PAYMENTS: 'Import Payments',
  TRANSACTION_ID: 'Transaction ID',
  PAYMENT_AMOUNT: 'Payment Amount',
  MAX_LENGTH_ACCOUNT_100: 'The account name must be less than 100 characters',
  OVER_AMOUNT: 'Over Amount',
  APPLIED_AMOUNT: 'Applied Amount',
  PAID_SHORT_PARTIAL: 'Paid Short Partial',
  SHORT: 'Short Partial',
  OVER: 'Over',
  CLOSED_PAID: 'Closed Paid',
  CREATED_DATE: 'Created Date',
  PAYMENT_APPLIED: 'Payment Applied',
  SOME_INVOICES_ARE_HIDDEN: 'Some selected invoices are hidden by the current filters or pagination.',
  CREATE_DRAFT_PAYMENT_MESSAGE: 'Create payments to select document for assigning',
  PAGES_ATTACHED_SUCCESSFULLY: 'Pages attached successfully',
  CONFIRM_APPLY_PAGES_TITLE: 'Confirm Apply Pages',
  CONFIRM_APPLY_PAGES_MESSAGE:
    'Some payments already have pages attached. The previous pages will be replaced with the new ones. Do you want to continue?',
  NO_OF_PAYMENTS: 'No. of Payments',
  BATCH_NO: 'Batch No.',
  BATCH_PAYMENT_CREATED_SUCCESSFULLY: 'Batch Payment created successfully',
  CONFIRM_BATCH_PAYMENT: 'Confirm Batch Payment',
  PAYMENT_BATCH_FORCE_CLOSED_SUCCESSFULLY: 'Batch Payment force closed successfully',
  ALL_PAYMENTS_FINISHED_CLOSE_BATCH: 'All payments are finished. You can close this blade now.',
  APPLY_AR_BALANCE: 'Apply AR Balance',
  APPLY_REMAINING: 'Apply Remaining',
  PAYMENT_AMOUNT_CANNOT_BE_NEGATIVE: 'Payment amount cannot be negative',
  FULL_PAYMENT_AMOUNT_LESS_INVOICE_BALANCE: "Full payment amount is less than the invoice's current balance",
  PAYMENT_AMOUNT_OVER_INVOICE_AMOUNT: 'The payment amount is over than the invoice amount',
  DRAFT_PAYMENT: 'Draft Payment',
  DRAFT_PAYMENTS: 'Draft Payments',
  EDIT_DRAFT_PAYMENT: 'Edit Draft Payment',
  FIELDS_REQUIRED_ONLY_WHEN_CRATE_PAYMENT_BATCH: 'These fields are required only when creating the Payment Batch.',
  APPLY: 'Apply',
  NO_PAGES_ASSIGNED_WARNING_MESSAGE: 'No pages are assigned to any of the payments.',
  // END Michael
  // BEGIN Fco
  BTN_OVER: 'Over',
  BTN_FULL: 'Full',
  BTN_PART: 'Part',
  WARNING_LESS_INVOICE_BALANCE: "Full payment amount is less than the invoice's current balance",
  WARNING_OVER_INVOICE_BALANCE: 'The payment amount is over than the invoice amount',
  // END Fco
  // BEGIN Fabian
  UNAPPLIED: 'Unapplied',
  APPLIED: 'Applied',
  TRANSACTION_REF: 'Transaction Ref',
  PAYMENT_TYPE: 'Payment Type',
  PAYMENT_DATE: 'Payment Date',
  ALERT_PAYMENT_CSV: 'Only one file is allowed and it must be a .csv file.',
  PAYMENT_CSV_SUCCESS: 'Payments have been added correctly.',
  UPLOAD_DOCUMENTS: 'Upload Documents',
  SUBMIT_DOCUMENTS: 'Submit Documents',
  UPLOAD_CSV: 'Upload CSV',
  PAYMENT_CSV_ERROR: 'Only CSV files are allowed.',
  TEXT_DOCUMENT_UPLOAD:
    'In the following link you will find a template for the format of the document to be uploaded. ',
  DOWNLOAD_CSV: 'Download CSV template',
  TOTAL_UNAPPLIED_AMOUNT: 'Total Unapplied Amount',
  TOTAL_APPLIED_AMOUNT: 'Total Applied Amount',
  TOTAL_CANCELLED_AMOUNT: 'Total Cancelled Amount',
  SELECTED_PAYMENT_AMOUNT: 'Selected Payment Amount',
  SEE_MORE: 'See more',
  SEE_LESS: 'See less',
  EXPORT_PAYMENTS_SUCCESSES: 'Payments exported successfully',
  ARE_YOU_SURE_EXPORT_PAYMENTS: 'Are you sure you want to export the payments?',
  EXPORT_PAYMENTS: 'Export Payments',
  PAYMENT_BATCH_DOCUMENTS_UPLOAD_MESSAGE:
    'After submitting documents you will not be able to upload or remove documents, please review and submit document for create/assigning payments',
  PAYMENT_NO_DOCUMENT_UPLOAD_MESSAGE:
    'Once all documents are uploaded, you will be able to create payments and assign them. Until then, creating payments will be disabled.',
  CREATE_BATCH_PAYMENT: 'Create Batch Payment',
  CREATE_BATCH_PAYMENTS: 'Create Batch Payments',
  CONFIRM_DOCUMENTS: 'Confirm Documents!',
  CREATE_PAYMENTS: 'Create Payments',
  APPLY_PAYMENTS: 'Apply Payments',
  EDIT_PAYMENT: 'Edit Payment',
  CONFIRM: 'Confirm',
  FORCE_CLOSE: 'Force Close',
  MESSAGE_FORCE_CLOSE: 'Are you sure to force close this payment batch?',
  PAYMENT_NOT_NEGATIVE: 'Payment amount cannot be negative',
  SELECTED_DOCUMENTS_ENABLED_PAYMENT: 'Select documents to enable assign payments',
  DOCUMENTS_UPLOAD_ERROR: 'The following files had problems when trying to upload them.',
  DOCUMENTS_UPLOAD_INFO_TEXT:
    'An error has occurred, in order to continue please force close the current payment batch. Press Force close.',
  // END Fabian
  //BEGIN Esteban
  PAYMENT_UPDATE: 'Payment Update',
  PAYMENT_UPDATE_SUCCESS: 'Payment updated successfully',
  ONLY_ZERO_BALANCE: 'Zero balance invoices',
  IMPORT_INVOICES: 'Import Invoices',
  ALERT_INVOICE_CSV: 'Only one file is allowed and it must be a .csv file.',
  INVOICE_CSV_SUCCESS: 'Invoices have been added correctly.',
  WITHDRAW_PAYMENT: 'Withdraw Payment',
  WITHDRAW_DIALOG_FIRST_BODY_TEXT: 'Are you sure you want to withdraw the selected payment(s)?',
  CONFIRM_WITHDRAW: 'Confirm Withdraw',
  UNABLE_TO_WITHDRAW_PAYMENT_APPLIED: 'The payment can only be withdrew on the same day it was applied.',
  WITHDRAW_PAYMENTS_SUCCESS: 'Payments withdrawn successfully',
  //END Esteban

  // BEGIN Patricio
  DOWNLOAD_DISBURSEMENT_CSV: 'Download Disbursement CSV',
  BODY_DOWNLOAD_DISBURSEMENT_CSV: 'Are you sure you want to download the Disbursement CSV file?',
  // END Patricio
  FULL: 'Full',
  SHORT_FULL: 'Short Full',

  PREVIEW_BALANCE_LESS_THAN_PAYMENT_TOTAL: 'Preview balance is less than the payment total',
  PREVIEW_BALANCE_EXCEEDS_PAYMENT_TOTAL: 'Preview balance exceeds the payment total',
  PREVIEW_BALANCE_PAID_IN_FULL: 'Preview balance is paid in full',

  BATCH_NUMBER: 'Batch Number',
  BATCH_USER: 'Batch User',
  CREATE_BATCH: 'Create Batch',
  CREATE_PAYMENT_BATCH: 'Create Payment Batch',
  PAYMENT_BATCH_CREATED_SUCCESSFULLY: 'Payment Batch created successfully',
  UNABLE_TO_BATCH_PAYMENT:
    'Unable to create batch when a selected payment is not Unapplied or already belongs to a batch',
}

export const paymentsEs: typeof paymentsEn = {
  // BEGIN Michael
  PAYMENT: 'Pago',
  PAYMENTS: 'Pagos',
  PAYMENT_CREATED_SUCCESSFULLY: 'Pago creado exitosamente',
  PAYMENT_ASSIGN_SUCCESSFULLY: 'Pago asignado exitosamente',
  PAYMENT_WARNING_MESSAGE:
    'El pago total de las facturas debe ser igual al monto del pago. Ajuste los saldos de las facturas e intente nuevamente.',
  REMITTER: 'Remitente',
  PAYMENT_PREVIEW: 'Vista previa del pago',
  PAYMENT_DETAILS: 'Detalles del pago',
  TOTAL_PAYMENT: 'Pago total',
  OVER_MESSAGE: '¡Advertencia! El pago total de las facturas excede el monto del pago',
  UNDER_MESSAGE: '¡Advertencia! El pago total de las facturas es menor que el monto del pago',
  EQUALS_MESSAGE: 'El balance total es igual al monto del pago',

  DEBTOR_FILTER_INFO: 'Filtrado por facturas del mismo deudor',

  VALIDATION_MAX_MESSAGE: 'El monto debe ser igual o menor que el balance de la factura',
  CLICK_HERE_TO_SEE_PAYMENT: 'Haga clic aquí para ver el pago',
  NUMBER_OF_DOCUMENTS: 'N° Documentos',
  DOCUMENTS_ADDED_PAYMENT: 'Todos los documentos se agregaron correctamente al pago.',
  PAYMENT_APPLICATION_CONFIRMATION: '¿Está seguro de que desea aplicar este pago a estas facturas?',
  ADD_SPLIT: 'Agregar Split',
  CANCELLED: 'Cancelado',
  CANCEL_PAYMENT: 'Cancelar Pago',
  CANCEL_PAYMENTS_SUCCESS: 'Pagos cancelados exitosamente',
  CONFIRM_CANCELLATION: 'Confirmar Cancelación',
  CANCEL_DIALOG_FIRST_BODY_TEXT: '¿Está seguro de que desea cancelar esos pagos?',
  UNABLE_TO_SELECT_PAYMENT_APPLIED: 'No se puede seleccionar el pago cuando está en estado Aplicado.',
  UNABLE_TO_SELECT_PAYMENT_CANCELLED: 'No se puede seleccionar el pago cuando está en estado Cancelado.',
  UNABLE_TO_CANCEL_PAYMENT_NOT_UNAPPLIED: 'No se puede cancelar el pago cuando no está en estado No aplicado.',
  UNABLE_TO_SELECT_PAYMENT: 'No se puede seleccionar el pago, no hay acciones disponibles.',
  IMPORT_PAYMENTS: 'Importar Pagos',
  TRANSACTION_ID: 'Id de transacción',
  PAYMENT_AMOUNT: 'Monto del pago',
  MAX_LENGTH_ACCOUNT_100: 'El nombre de la cuenta debe ser menor a 100 caracteres',
  OVER_AMOUNT: 'Monto excedido',
  APPLIED_AMOUNT: 'Monto aplicado',
  PAID_SHORT_PARTIAL: 'Pago Parcial',
  SHORT: 'Corto Parcial',
  OVER: 'Excedido',
  CLOSED_PAID: 'Cerrado Pagado',
  CREATED_DATE: 'Fecha de creación',
  PAYMENT_APPLIED: 'Pago aplicado',
  SOME_INVOICES_ARE_HIDDEN: 'Algunas facturas están ocultas por los filtros o la paginación actuales.',
  CREATE_DRAFT_PAYMENT_MESSAGE: 'Cree pagos para seleccionar documentos para asignar',
  PAGES_ATTACHED_SUCCESSFULLY: 'Páginas adjuntas exitosamente',
  CONFIRM_APPLY_PAGES_TITLE: 'Confirmar Aplicación de Páginas',
  CONFIRM_APPLY_PAGES_MESSAGE:
    'Algunos pagos ya tienen páginas adjuntas. Las páginas anteriores se reemplazarán por las nuevas. ¿Desea continuar?',
  NO_OF_PAYMENTS: 'N° de Pagos',
  BATCH_NO: 'N° de Lote',
  BATCH_PAYMENT_CREATED_SUCCESSFULLY: 'Lote de pagos creado exitosamente',
  CONFIRM_BATCH_PAYMENT: 'Confirmar Lote de Pagos',
  PAYMENT_BATCH_FORCE_CLOSED_SUCCESSFULLY: 'Se forzó el cierre del lote de pagos exitosamente',
  ALL_PAYMENTS_FINISHED_CLOSE_BATCH: 'Todos los pagos están finalizados. Puede cerrar esta ventana ahora.',
  APPLY_AR_BALANCE: 'Aplicar saldo de AR',
  APPLY_REMAINING: 'Aplicar restante',
  PAYMENT_AMOUNT_CANNOT_BE_NEGATIVE: 'El monto del pago no puede ser negativo',
  FULL_PAYMENT_AMOUNT_LESS_INVOICE_BALANCE: 'El monto total del pago es menor que el saldo actual de la factura',
  PAYMENT_AMOUNT_OVER_INVOICE_AMOUNT: 'El monto del pago es mayor que el monto de la factura',
  DRAFT_PAYMENT: 'Pago en borrador',
  DRAFT_PAYMENTS: 'Pagos en borrador',
  EDIT_DRAFT_PAYMENT: 'Editar Pago en Borrador',
  FIELDS_REQUIRED_ONLY_WHEN_CRATE_PAYMENT_BATCH: 'Estos campos son requeridos solo al crear el lote de pagos.',
  APPLY: 'Aplicar',
  NO_PAGES_ASSIGNED_WARNING_MESSAGE: 'No hay páginas asignadas a ninguno de los pagos.',
  // END Michael
  // BEGIN Fco
  BTN_OVER: 'Sobr',
  BTN_FULL: 'Comp',
  BTN_PART: 'Parc',
  WARNING_LESS_INVOICE_BALANCE: 'El importe total del pago es inferior al saldo actual de la factura',
  WARNING_OVER_INVOICE_BALANCE: 'El importe del pago es superior al de la factura',
  // END Fco
  // BEGIN Fabian
  UNAPPLIED: 'No aplicado',
  APPLIED: 'Aplicado',
  TRANSACTION_REF: 'Referencia de transacción',
  PAYMENT_TYPE: 'Tipo de pago',
  PAYMENT_DATE: 'Fecha de pago',
  ALERT_PAYMENT_CSV: 'Solo se permite un archivo y debe ser un archivo .csv.',
  PAYMENT_CSV_SUCCESS: 'Los pagos se han agregado correctamente.',
  UPLOAD_DOCUMENTS: 'Subir documentos',
  SUBMIT_DOCUMENTS: 'Entregar documentos',
  UPLOAD_CSV: 'Subir CSV',
  PAYMENT_CSV_ERROR: 'Solo se permiten archivos CSV.',
  TEXT_DOCUMENT_UPLOAD:
    'En el siguiente enlace encontrará una plantilla para el formato del documento que debe cargar. ',
  DOWNLOAD_CSV: 'Descargar plantilla CSV',
  TOTAL_UNAPPLIED_AMOUNT: 'Monto total no aplicado',
  TOTAL_APPLIED_AMOUNT: 'Monto total aplicado',
  TOTAL_CANCELLED_AMOUNT: 'Monto total cancelado',
  SELECTED_PAYMENT_AMOUNT: 'Monto del pago seleccionado',
  SEE_MORE: 'Ver más',
  SEE_LESS: 'Ver menos',
  EXPORT_PAYMENTS_SUCCESSES: 'Pagos exportados exitosamente',
  ARE_YOU_SURE_EXPORT_PAYMENTS: '¿Está seguro de que desea exportar los pagos?',
  EXPORT_PAYMENTS: 'Exportar Pagos',
  PAYMENT_BATCH_DOCUMENTS_UPLOAD_MESSAGE:
    'Después de entregar los documentos, no podrá cargar ni eliminar documentos, revise y entregue el documento para crear/asignar pagos',
  PAYMENT_NO_DOCUMENT_UPLOAD_MESSAGE:
    'Una vez que se hayan cargado todos los documentos, podrá crear pagos y asignarlos. Hasta entonces, la creación de pagos estará deshabilitada.',
  CREATE_BATCH_PAYMENT: 'Crear Pago en Lote',
  CREATE_BATCH_PAYMENTS: 'Crear Pagos en Lote',
  CONFIRM_DOCUMENTS: '¡Confirmar Documentos!',
  CREATE_PAYMENTS: 'Crear Pagos',
  APPLY_PAYMENTS: 'Aplicar Pagos',
  EDIT_PAYMENT: 'Editar Pago',
  CONFIRM: 'Confirmar',
  FORCE_CLOSE: 'Forzar Cierre',
  MESSAGE_FORCE_CLOSE: '¿Está seguro de forzar el cierre de este lote de pagos?',
  PAYMENT_NOT_NEGATIVE: 'El monto del pago no puede ser negativo',
  SELECTED_DOCUMENTS_ENABLED_PAYMENT: 'Seleccione documentos para habilitar la asignación de pagos',
  DOCUMENTS_UPLOAD_ERROR: 'Los siguientes archivos tuvieron problemas al intentar cargarlos.',
  DOCUMENTS_UPLOAD_INFO_TEXT:
    'Ha ocurrido un error, para continuar por favor fuerce el cierre del lote de pagos actual. Presione Forzar cierre.',
  // END Fabian
  //BEGIN Esteban
  PAYMENT_UPDATE: 'Actualizar pago',
  PAYMENT_UPDATE_SUCCESS: 'Pago actualizado exitosamente',
  ONLY_ZERO_BALANCE: 'Facturas saldo cero',
  IMPORT_INVOICES: 'Importar Facturas',
  ALERT_INVOICE_CSV: 'Solo se permite un archivo y debe ser un archivo .csv.',
  INVOICE_CSV_SUCCESS: 'Las facturas se han agregado correctamente.',
  WITHDRAW_PAYMENT: 'Deshacer pago',
  WITHDRAW_DIALOG_FIRST_BODY_TEXT: '¿Está seguro de que desea deshacer el pago seleccionado?',
  CONFIRM_WITHDRAW: 'Confirmar deshacer',
  UNABLE_TO_WITHDRAW_PAYMENT_APPLIED: 'El pago solo puede ser deshecho el mismo día que fue aplicado.',
  WITHDRAW_PAYMENTS_SUCCESS: 'Pagos deshechos exitosamente',
  //END Esteban

  // BEGIN Patricio
  DOWNLOAD_DISBURSEMENT_CSV: 'Descargar CSV',
  BODY_DOWNLOAD_DISBURSEMENT_CSV: '¿Está seguro que desea descargar el archivo CSV de Desembolso?',
  // END Patricio
  FULL: 'Completo',
  SHORT_FULL: 'Corto Completo',

  PREVIEW_BALANCE_LESS_THAN_PAYMENT_TOTAL: 'El balance de vista previa es menor que el total del pago',
  PREVIEW_BALANCE_EXCEEDS_PAYMENT_TOTAL: 'El balance de vista previa excede el total del pago',
  PREVIEW_BALANCE_PAID_IN_FULL: 'El balance de vista previa está pagado en su totalidad',

  BATCH_NUMBER: 'Número de lote',
  BATCH_USER: 'Usuario de lote',
  CREATE_BATCH: 'Crear lote',
  CREATE_PAYMENT_BATCH: 'Crear lote de pagos',
  PAYMENT_BATCH_CREATED_SUCCESSFULLY: 'Lote de pagos creado exitosamente',
  UNABLE_TO_BATCH_PAYMENT:
    'No se puede crear un lote cuando un pago seleccionado no está sin aplicar o ya pertenece a un lote',
}

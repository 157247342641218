export const verificationMethodEn = {
  VERIFICATION_METHOD: 'Verification Method',
  TITLE_CUSTOM_VERIFICATION_SECTION: 'Custom Verification Method',
  TITLE_CREATE_CUSTOM_VERIFICATION_METHOD: 'Create Verification Method',
  UPDATE_VERIFICATION_METHOD_SUCCESS: 'Verification Method updated successfully',
  CREATE_VERIFICATION_METHOD_SUCCESS: 'Verification Method created successfully',
  DELETE_VERIFICATION_METHOD_SUCCESS: 'Verification Method deleted successfully',
  TITLE_EDIT_VERIFICATION_METHOD: 'Edit Verification Method',
  VERIFICATION_NAME_TOO_LONG_ERROR: 'The verification name cannot be longer than 20 characters',
  DELETE_VERIFICATION_METHOD: 'Are you sure you want to delete the following verification method?',
}

export const verificationMethodEs: typeof verificationMethodEn = {
  VERIFICATION_METHOD: 'Método de Verificación',
  TITLE_CUSTOM_VERIFICATION_SECTION: 'Método de Verificación Personalizado',
  TITLE_CREATE_CUSTOM_VERIFICATION_METHOD: 'Crear Método de Verificación',
  UPDATE_VERIFICATION_METHOD_SUCCESS: 'Método de Verificación actualizado correctamente',
  CREATE_VERIFICATION_METHOD_SUCCESS: 'Método de Verificación creado correctamente',
  DELETE_VERIFICATION_METHOD_SUCCESS: 'Método de Verificación eliminado correctamente',
  TITLE_EDIT_VERIFICATION_METHOD: 'Editar Método de Verificación',
  VERIFICATION_NAME_TOO_LONG_ERROR: 'El nombre de verificación no puede ser mayor a 20 caracteres',
  DELETE_VERIFICATION_METHOD: '¿Está seguro de que desea eliminar el siguiente método de verificación?',
}

import strings from 'l10n'
import { MenuBarListItem } from './MenuBar'

export const mainMenuBar: MenuBarListItem[] = [
  {
    name: strings.DASHBOARD,
    icon: 'menubar/icon_dashboard',
    id: 'dashboard',
  },
  {
    name: strings.COMPANIES,
    icon: 'menubar/icon_companies',
    id: 'companies',
  },
  {
    name: strings.TERMS,
    icon: 'menubar/icon_terms',
    id: 'terms',
  },
  {
    name: strings.INVOICES,
    icon: 'menubar/icon_invoices',
    id: 'invoices',
  },
  {
    name: strings.FUEL_ADVANCES,
    icon: 'menubar/icon_fuel_advances',
    id: 'fuel-advances',
  },
  {
    name: strings.FUNDING,
    icon: 'menubar/icon_funding',
    id: 'funding',
  },
  {
    name: strings.PAYMENTS,
    icon: 'menubar/icon_payments',
    id: 'payments',
  },
  {
    name: strings.CHARGEBACKS,
    icon: 'menubar/icon_chargeback',
    id: 'chargebacks',
  },
  {
    name: strings.LEDGERS,
    icon: 'menubar/icon_ledgers',
    id: 'ledgers',
  },
  {
    name: strings.ACCOUNTING,
    icon: 'menubar/icon_accounting',
    id: 'accounting',
  },
  {
    name: strings.COLLECTIONS,
    icon: 'menubar/icon_collections',
    id: 'collections',
  },
]

export const footerMenuBar: MenuBarListItem[] = [
  {
    name: strings.SETTINGS,
    icon: 'menubar/icon_settings',
    id: 'settings',
  },
]

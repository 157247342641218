export const reserveReleaseEn = {
  RESERVE_RELEASE_UPDATE_SUCCESS: 'Reserve Release updated successfully',
  APPROVE_FOR_FUNDING_RESERVE_RELEASE_CONFIRMATION:
    'Are you sure you want to approve these reserve releases for funding?',
  RESERVE_RELEASE_CREATED_SUCCESSFULLY: 'Reserve Release created successfully',
  REJECT_RESERVE_RELEASE: 'Reject Reserve Release',
  RESERVE_RELEASES: 'Reserve Releases',
  RESERVE_RELEASES_APPROVED_FOR_FUNDING_SUCCESSFULLY: 'Reserve releases approved for funding successfully!',
  RESERVE_RELEASE_WAS_SELECTED_FOR_THIS_ACTION: 'Reserve release was selected for this action',
  RESERVE_RELEASE_REJECTED_SUCCESSFULLY: 'Reserve release/s rejected successfully!',
  RESERVE_RELEASE_PENDING_CONFIRMATION: 'Are you sure you want to move these reserve releases to pending?',
  RESERVE_RELEASE_PENDING_SUCCESSFULLY: 'Reserve release/s moved to pending successfully!',
  RESERVE_RELEASE_REJECTED_CONFIRMATION: 'Are you sure you want to reject these reserve releases?',
  DELETE_RESERVE_RELEASE: 'Delete Reserve Release',
  RESERVE_RELEASE_DELETING_CONFIRMATION: 'Are you sure you want to delete these reserve releases?',
  RESERVE_RELEASE_DELETED_SUCCESSFULLY: 'Reserve release/s deleted successfully!',
}

export const reserveReleaseEs: typeof reserveReleaseEn = {
  RESERVE_RELEASE_UPDATE_SUCCESS: 'Reserva de liberación actualizada correctamente',
  APPROVE_FOR_FUNDING_RESERVE_RELEASE_CONFIRMATION:
    '¿Estás seguro de que deseas aprobar estas liberaciones de reserva para su financiación?',
  RESERVE_RELEASE_CREATED_SUCCESSFULLY: 'Reserva de liberación creada correctamente',
  REJECT_RESERVE_RELEASE: 'Rechazar liberación de reserva',
  RESERVE_RELEASES: 'Reservas de liberación',
  RESERVE_RELEASES_APPROVED_FOR_FUNDING_SUCCESSFULLY:
    '¡Reservas de liberación aprobadas para financiamiento con éxito!',
  RESERVE_RELEASE_WAS_SELECTED_FOR_THIS_ACTION: 'La liberación de reserva fue seleccionada para esta acción',
  RESERVE_RELEASE_REJECTED_SUCCESSFULLY: '¡Reserva de liberación rechazada con éxito!',
  RESERVE_RELEASE_PENDING_CONFIRMATION: '¿Estás seguro de que deseas mover estas liberaciones de reserva a pendiente?',
  RESERVE_RELEASE_PENDING_SUCCESSFULLY: '¡Reserva de liberación movida a pendiente con éxito!',
  RESERVE_RELEASE_REJECTED_CONFIRMATION: '¿Estás seguro de que deseas rechazar estas liberaciones de reserva?',
  DELETE_RESERVE_RELEASE: 'Eliminar liberación de reserva',
  RESERVE_RELEASE_DELETING_CONFIRMATION: '¿Estás seguro de que deseas eliminar estas liberaciones de reserva?',
  RESERVE_RELEASE_DELETED_SUCCESSFULLY: '¡Reserva de liberación eliminada con éxito!',
}
